import React from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Row, Col, CardTitle, Card, CardHeader, CardBody } from "reactstrap";

// import images
import Location from "../../../assets/images/location.png";
import Globe from "../../../assets/images/earth.png";
import Group from "../../../assets/images/multiple-users-silhouette.png";
import Map from "../../../assets/images/map.png";
import Vehicle from "../../../assets/images/travelling-vehicles-of-a-road.png";

import { useState } from "react";
import { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";

import "../ConsumptionPayout.scss";

function EniVistaarSchemeSetup(props) {
  const PROGRAM_SCHEME = [
    {
      type: "POINT_BONUS",
      backTo: "/eni-vistaar-listing",
      isLink: true,
      title: "SELECT BONUS TYPE(ENI-VISTAAR)",
    },
    {
      type: "SCHEME_SETUP",
      backTo: "POINT_BONUS",
      isLink: false,
      title: "Setup Consumption based Payouts",
    },
    {
      type: "SCHEME_FOC",
      backTo: "POINT_BONUS",
      isLink: false,
      title: "Setup FOC based Payouts",
    },
    {
      type: "SCHEME_GEOGRAPHY",
      backTo: "SCHEME_SETUP",
      isLink: false,
      title: "Select Geography",
    },
  ];

  const history = useHistory();
  const [consumptionScheme, setConsumptionScheme] = useState(PROGRAM_SCHEME[0]);

  const handleSelectionRoute = (val) => {
    history.push(val);
  };

  const handleSelection = (val) => {
    const _selection = PROGRAM_SCHEME.find((item) => {
      return item.type === val;
    });

    setConsumptionScheme(_selection);
  };

  useEffect(() => {
    console.log(consumptionScheme, "consumptionScheme");
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="g-0 d-flex justify-content-around">
            <Col lg={12}>
              {/* <div className="d-flex  m-3">
                <CardTitle className="font-size-24 text-danger">
                  MECHANIC WISE: FREQUENCY SCHEME
                </CardTitle>
              </div> */}
              <Card className="m-2">
                <CardHeader className="d-flex align-items-center">
                  <button
                    className="btn btn-secondary float-left"
                    type="submit"
                    onClick={() => {
                      consumptionScheme.isLink
                        ? handleSelectionRoute(consumptionScheme.backTo)
                        : handleSelection(consumptionScheme.backTo);
                    }}
                    color="primary"
                  >
                    Back
                  </button>
                  <CardTitle className="font-size-20 text-uppercase mx-3 m-0">
                    {consumptionScheme.title}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row className="g-0 d-flex justify-content-center">
                    <Col sm={12}>
                      {consumptionScheme.type === "POINT_BONUS" && (
                        <React.Fragment>
                          <div className="box-selection-wrap">
                            <div
                              className="box-selection-item"
                              onClick={() => {
                                handleSelection("SCHEME_SETUP");
                              }}
                            >
                              <p className="box-inner-title">POINT BONUS</p>
                            </div>
                            <div
                              className="box-selection-item"
                              onClick={() => {
                                handleSelection("SCHEME_FOC");
                              }}
                            >
                              <p className="box-inner-title">FOC</p>
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                      {consumptionScheme.type === "SCHEME_SETUP" && (
                        <React.Fragment>
                          <h4 className="card-title m-2">
                            How do you want to setup scheme ?
                          </h4>
                          <div className="box-selection-wrap">
                            <div
                              className="box-selection-item"
                              onClick={() => {
                                handleSelectionRoute(
                                  "/mechanic-wise-scheme/5/point-bonus"
                                );
                              }}
                            >
                              <img src={Group} alt="group" />
                              <p className="box-inner-subtitle">
                                Retailer Wise
                              </p>
                            </div>
                            <div
                              className="box-selection-item"
                              onClick={() => {
                                handleSelection("SCHEME_GEOGRAPHY");
                              }}
                            >
                              <img src={Map} alt="group" />
                              <p className="box-inner-subtitle">
                                Geography Wise
                              </p>
                            </div>
                            {/* <div
                              className="box-selection-item"
                              onClick={() => {
                                handleSelectionRoute(
                                  "/vehicle-wise-scheme/5/point-bonus"
                                );
                              }}
                            >
                              <img src={Vehicle} alt="group" />
                              <p className="box-inner-subtitle">
                                Vehicle Segment
                              </p>
                            </div> */}
                          </div>
                        </React.Fragment>
                      )}
                      {consumptionScheme.type === "SCHEME_FOC" && (
                        <React.Fragment>
                          <h4 className="card-title m-2">
                            How do you want to setup scheme ?
                          </h4>
                          <div className="box-selection-wrap">
                            <div
                              className="box-selection-item"
                              onClick={() => {
                                handleSelectionRoute(
                                  "/foc-retailer/5/point-bonus"
                                );
                              }}
                            >
                              <img src={Group} alt="group" />
                              <p className="box-inner-subtitle">
                                Retailer Wise
                              </p>
                            </div>
                            <div
                              className="box-selection-item"
                              onClick={() => {
                                handleSelectionRoute(
                                  "foc-scheme/5/point-bonus"
                                );
                              }}
                            >
                              <img src={Map} alt="group" />
                              <p className="box-inner-subtitle">
                                Geography Wise
                              </p>
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                      {consumptionScheme.type === "SCHEME_GEOGRAPHY" && (
                        <React.Fragment>
                          <h4 className="card-title m-2">
                            Please Select State, District or restrict with list
                            of Pin Codes
                          </h4>
                          <div className="box-selection-wrap">
                            <div
                              className="box-selection-item"
                              onClick={() => {
                                handleSelectionRoute(
                                  "/geography-wise-pincode-scheme/5/point-bonus"
                                );
                              }}
                            >
                              <img src={Location} alt="group" />
                              <p className="box-inner-subtitle">
                                Add a list of Pincodes
                              </p>
                            </div>
                            <div
                              className="box-selection-item"
                              onClick={() => {
                                handleSelectionRoute(
                                  "/geography-wise-state-scheme/5/point-bonus"
                                );
                              }}
                            >
                              <img src={Globe} alt="group" />
                              <p className="box-inner-subtitle">
                                Select States
                              </p>
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EniVistaarSchemeSetup;
