import React, { useState } from "react";
import { Table, Col, Row } from "reactstrap";
import "../../pages/ViewMechanic/ViewMechanic.css";
import CustomModal from "../Modal/CustomModalRlp";
import getSegment from "../../services/segment.service";
import moment from "moment";

const ViewRetailerTable = ({ data = [] }) => {
  const [profileModal, setProfileModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [modalData, setModalData] = useState([]);
  // const [prevSlab, setPrevSlab] = useState("");
  // const [currentSlab, setCurrentSlab] = useState("");

  const profileToggleModal = () => {
    setProfileModal(!profileModal);
  };
  const fetchProfile = async (userId) => {
    const user_id = userId;
    setUserId(user_id);
    const params = { user_id: user_id };
    const response = await getSegment.GetRlpUserDetails(params);

    if (response.status === true) {
      setModalData(response.data);
    }
    // await getSegment.GetSlab(params).then((res) => {
    //   if (res?.status === true) {
    //     setCurrentSlab(res.data.data.curr_fin_year.class);
    //     setPrevSlab(res.data.data.last_fin_year.class);
    //   }
    // });
  };
  // Map each object in the data array to a table row
  const rows = data?.map((item, index) => (
    <tr key={index}>
      <td>
        <i
          className="fas fa-external-link-alt icon-table"
          onClick={async () => {
            await fetchProfile(item.user_id);
            profileToggleModal();
          }}
          title="View More"
        />
      </td>
      <td>{item.retailer_name ? item.retailer_name : "N/A"}</td>
      <td>{item.mobile ? item.mobile : "N/A"}</td>
      <td>{item.shop_name ? item.shop_name : "N/A"}</td>
      <td>{item.state ? item.state : "N/A"}</td>
      <td>{item.district ? item.district : "N/A"}</td>
      <td>{item.city ? item.city : "N/A"}</td>
      <td>{item.so_name ? item.so_name : "N/A"}</td>
      <td>{item.sh_name ? item.sh_name : "N/A"}</td>
      <td>{item.distributor_name ? item.distributor_name : "N/A"}</td>
      <td>{item.kyc_number ? item.kyc_number : "N/A"}</td>
      <td>{item.kyc_status ? item.kyc_status : "Pending"}</td>
      <td>{item.app_status ? item.app_status : "N/A"}</td>
      <td>
        {item.app_reg_date
          ? moment.unix(item.app_reg_date).format("DD-MM-YYYY")
          : "N/A"}
      </td>
      <td>
        {item.app_version_date
          ? moment.unix(item.app_version_date).format("DD-MM-YYYY")
          : "N/A"}
      </td>
      <td>{item.app_version ? item.app_version : "N/A"}</td>
      <td align="right">
        {item.points_data ? item.points_data.total_points : "0"}
      </td>
      <td align="right">
        {item.points_data ? item.points_data.burn_points : "0"}
      </td>
      <td align="right">
        {item.points_data ? item.points_data.balance_points : "0"}
      </td>
      <td>{item.last_transaction_date ? "Active" : "InActive"}</td>

      {/* Add more cells for each property in the object */}
    </tr>
  ));
  return (
    <>
      <div className="table-rep-plugin pt-1">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <Table id="tech-companies-1" striped bordered responsive hover>
            <thead>
              <tr>
                <th></th>
                <th>Retailer Name</th>
                <th>Retailer Mobile Number</th>
                <th>Shop Name</th>
                <th>State</th>
                <th>District</th>
                <th>City</th>
                <th>SO Name</th>
                <th>SH Name</th>
                <th>Distributor Name</th>
                <th>Kyc Number</th>
                <th>Kyc Status</th>
                <th>App Status</th>
                <th>App Reg Date</th>
                <th>App Version Updated Date</th>
                <th>App Version</th>
                <th>Earn Point</th>
                <th>Burn Point</th>
                <th>Balance Point</th>
                <th>Active User</th>
                {/* Add more headers for each property in the objects */}
              </tr>
            </thead>
            {data?.length > 0 ? (
              <tbody>{rows}</tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="9" className="text-center">
                    No Data Found
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
      </div>{" "}
      <Row>
        <Col sm={6} md={4} xl={3}>
          <CustomModal
            isOpen={profileModal}
            toggle={profileToggleModal}
            user_id={userId}
            onConfirm={() => console.log("Confirmed!")}
            confirmLabel="Confirm"
            data={modalData}
            mechanic={true}
            prevSlab={""}
            currentSlab={""}
          >
            {/* <p>Modal Content Here</p> */}
          </CustomModal>
        </Col>
      </Row>
    </>
  );
};

export default ViewRetailerTable;
