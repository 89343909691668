import React from "react";
import { Table } from "reactstrap";
import Spinner from "../Loader/Spinner";

const MechanicCountTable = ({ data = [], loading, date_range, segment }) => {
  // const data = props.data;
  console.log(date_range);
  // Map each object in the data array to a table row
  const rows = data.map((item, index) => (
    <tr key={index}>
      <td>{item.classification}</td>
      <td>{item.slab}</td>
      <td className="text-center">{item.first_month_count}</td>
      <td className="text-center">{item.second_month_count}</td>
      <td className="text-center">{item.difference} </td>
      <td className="text-center">
        {item.change}{" "}
        {item.change > 0 ? (
          <i className="ri-arrow-up-line align-middle icon-green mx-2"></i>
        ) : item.change < 0 ? (
          <i className="ri-arrow-down-line align-middle icon-red mx-2"></i>
        ) : null}
      </td>

      {/* Add more cells for each property in the object */}
    </tr>
  ));

  // Render the table with the mapped rows and cells
  return (
    <div className="table-rep-plugin pt-4">
      <div className="table-responsive mb-0" data-pattern="priority-columns">
        <Table id="tech-companies-1" striped bordered responsive>
          <thead>
            <tr>
              <th>Classification</th>
              <th>Slab</th>

              <th>
                {`No. of Mechanics: ${
                  date_range.first_month_start === undefined
                    ? ""
                    : date_range.first_month_start
                } -
                ${
                  date_range.first_month_end === undefined
                    ? ""
                    : date_range.first_month_end
                }`}
              </th>
              <th>
                {`No. of Mechanics: ${
                  date_range.second_month_start === undefined
                    ? ""
                    : date_range.second_month_start
                } -
                ${
                  date_range.second_month_end === undefined
                    ? ""
                    : date_range.second_month_end
                }`}
              </th>
              <th>Difference</th>
              <th>% Change</th>

              {/* Add more headers for each property in the objects */}
            </tr>
          </thead>
          {loading === true ? (
            <tbody>
              <tr>
                <td colSpan="11" className="text-center">
                  <Spinner />
                </td>
              </tr>
            </tbody>
          ) : data?.length > 0 ? (
            <tbody>{rows}</tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan="11" className="text-center">
                  No Data Found
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>
    </div>
  );
};
export default MechanicCountTable;
