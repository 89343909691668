/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

// Redux
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  CardTitle,
  Card,
  CardHeader,
  CardBody,
  Input,
} from "reactstrap";

import { Formik } from "formik";

import campaignSer from "../../../services/campaignService";

import { toast } from "react-toastify";
import Select from "react-select";
import moment from "moment";

// import images
import "react-toastify/dist/ReactToastify.css";

// import css
// import "../../ConsumptionPayout.scss";
import { campaign_programs } from "../../../Config/config";
import { SpinnerSmall } from "../../../components/Loader/Spinner";

function FocScheme(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const routeParams = props.match.params;
  const program_id = routeParams.programId;
  const history = useHistory();
  if (
    !routeParams ||
    campaign_programs.includes(routeParams.programId) == false
  ) {
    history.goBack();
  }
  console.log("routeParams------", routeParams.id);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(routeParams.id ? true : false);
  const [editDetails, setEditVal] = useState({});
  const [tempSegment, setTempSegment] = useState("");
  const [segment, setSegment] = useState("ALL");
  const [dayWiseButton, setdayWiseButton] = useState(false);
  const [segmentOption, setSegmentOption] = useState([
    { value: "Select", label: "Select" },
  ]);
  const [product, setProduct] = useState("ALL");
  const [productOption, setProductOption] = useState([
    { value: "Select", label: "Select" },
  ]);
  console.log("isEdit---", isEdit);
  const [packSize, setPackSize] = useState([]);
  const [packSizeOption, setPackSizeOption] = useState([
    { value: "Select", label: "Select" },
  ]);
  const [payoutVolume, setPayoutVolume] = useState("");
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedStates, setSelectedStates] = useState("");
  const [districtOptions, setDistrictOptions] = useState([]);
  let [selectedDistricts, setSelectedDistricts] = useState("");
  const [areaOpions, setareaOpions] = useState([]);
  let [selectedArea, setSelectedArea] = useState("");
  const [numberOfPacks, setNumberOfPack] = useState(1);
  const [isDistrictSelectDisabled, setIsDistrictSelectDisabled] =
    useState(true);
  const [series, setSeries] = useState([]);
  const [seriesOption, setSeriesOptions] = useState([]);
  const [buttonDisabled, setButtonDisable] = useState(false);
  const [offerStatus, setOfferStatus] = useState();
  const [radioValue, setRadioValue] = useState("DRAFT");
  const [vehicleSegment, setVehicleSegment] = useState("");

  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [targetVolume, setTargetVolume] = useState("");
  const [title, setTitle] = useState("");
  let setFieldValueFunction = null;

  const handleSelectFieldObject = (setFieldValue) => {
    setFieldValueFunction = setFieldValue;
  };
  const [dayWiseList, setdayWiseList]=useState([
    {
      id: 0,
      name: "Sunday",
      value:false
    },
    {
      id: 1,
      name: "Monday",
      value:false
    },
    {
      id: 2,
      name: "Tuesday",
      value:false
    },
    {
      id: 3,
      name: "Wednesday",
      value:false
    },
    {
      id: 4,
      name: "Thursday",
      value:false
    },
    {
      id: 5,
      name: "Friday",
      value:false
    },
    {
      id: 6,
      name: "Saturday",
      value:false
    },
  ])
  const [dayWise, setdayWise] = useState({"Sunday":false,"Monday":false,"Tuesday":false,"Wednesday":false,"Thursday":false,"Friday":false,"Saturday":false});

  const handleProduct = async (event) => {
    setProduct(event);
    setPackSize([]);
    setFieldValueFunction("pack_size", []);
    setFieldValueFunction("product_id", event);
    // setTempSegment(event.id);
    const options = await campaignSer.GetPackSize({
      product_id: event.value,
    });
    if (options.status) {
      setPackSizeOption(options?.data);
    }
  };

  const handlePackSize = async (event) => {
    setFieldValueFunction("pack_size", event);
    setPackSize(event);
  };
  const handleSegment = async (event) => {
    setSeries([]);
    setFieldValueFunction("product_series", []);
    setFieldValueFunction("segment", event);
    setTempSegment(event.id);
    const options = await campaignSer.GetSegmentSeriesListFoc({
      id: event.map((obj) => obj.id),
      program_id: program_id,
    });
    if (options.status) {
      setSeriesOptions(options?.data);
    }
  };

  const handleSeries = async (event) => {
    setFieldValueFunction("product_series", event);
    setSeries(event);
  };

  // fetch Geography
  const fetchStates = async () => {
    await campaignSer.getStateList().then((res) => {
      if (res.status === true) {
        setStateOptions(res.data);
      }
    });
  };
  const fetchDistricts = async (stateIds) => {
    let result = stateIds.map(function (x) { 
      return parseInt(x, 10); 
    });
    const params = {
      state_id: result,
      user_id: user.user_id,
    };
    const districtData = await campaignSer.getDistrictList(params);
    const options = districtData?.data?.map((district) => ({
      district_id: district.value,
      value: district.value,
      label: district.label,
    }));
    setDistrictOptions(options);
    console.log("disrict list----", options);
    let ids = options.map((obj) => obj.value);
    let areaData = [];
    if(selectedDistricts && selectedDistricts.length) {
      await selectedDistricts.forEach(async (ele,index) => {
        console.log("ele--",ele,index);
        console.log('outer',ids.indexOf(ele.value));
        if(ids.indexOf(ele.value) > -1) {
          console.log('inner',ids.indexOf(ele.value));
            areaData.push(ele);
        }
      });
      setFieldValueFunction("district", areaData);
      setSelectedDistricts(areaData);
      selectedDistricts = areaData;
      console.log("areaData1--",areaData);
      console.log("selectedDistricts---",selectedDistricts);
      await handleDistrictChange(areaData);
    }
  };
  // handle Geography changes
  const handleStateChange = async (selectedOption) => {
    console.log("selected district id ----",selectedOption,selectedDistricts);
    setFieldValueFunction("state", selectedOption);
    // setFieldValueFunction("district", "");
    // setFieldValueFunction("pincode", "");
    // setFieldValueFunction("area", "");
    setSelectedStates(selectedOption);
    const state_ids = selectedOption?.map((obj) => obj.value);
    await fetchDistricts(state_ids);
  };
  const handleDistrictChange = async (selectedOption) => {
    console.log("selectedOption---",selectedOption);
    console.log("selectedArea-",selectedArea);
    setFieldValueFunction("district", selectedOption);
    setFieldValueFunction("pincode", "");
    // setFieldValueFunction("area", "");
    setSelectedDistricts(selectedOption);
    // const districtIds = selectedOption?.map((obj) => obj.value);
    let result = selectedOption.map(function (x) { 
      return parseInt(x.value, 10); 
    });
    const params = {
      district_id: result,
      user_id: user.user_id,
    };
    console.log("params--",params);
    const options = await campaignSer.GetAreaList(params);
    let areaData = [];
    if (options?.status) {
      setareaOpions(options?.data);
      let data = options.data;
      console.log("areaoptions-",data);
      let ids = data.map((obj) => obj.value);
      console.log("ids-",ids);
      if(selectedArea && selectedArea.length) {
        await selectedArea.forEach(async (ele,index) => {
          console.log("ele--",ele,index);
          console.log('outer',ids.indexOf(ele.value));
          if(ids.indexOf(ele.value) > -1) {
            console.log('inner',ids.indexOf(ele.value));
              areaData.push(ele);
          }
          
          console.log("areaData--",areaData);
        });
        setFieldValueFunction("area", areaData);
        setSelectedArea(areaData);
         selectedArea = areaData;
        console.log("areaData1--",areaData);
        console.log("selectedArea---",selectedArea);
      }
    }
  };
  const handleAreaChange = async (selectedOption) => {
    setSelectedArea(selectedOption);
    setFieldValueFunction("area", selectedOption);
  };

  // API Submission
  const submitValuetoApi = async (data,status) => {
    console.log(data, "..........");
    const startMoment = moment(data.start_date, "YYYY-MM-DD");
    const endMoment = moment(data.end_date, "YYYY-MM-DD");
    // Compare the dates
    if (endMoment.isBefore(startMoment)) {
      // Raise an error or handle the error as needed
      toast.error("End date is before the start date");
      return;
    }

    let params = {
      title: title,
      program_id: routeParams.programId,
      start_date: moment(data.start_date, "YYYY-MM-DD").unix(),
      end_date: moment(data.end_date, "YYYY-MM-DD").unix(),
      numberofPacks: numberOfPacks,
      segment: data.segment.id,
      volume: data.target_volume,
      pack_size: data.pack_size.value,
      payout_volume: (data.pack_size.label * data.number_of_packs),
      product: data.product_id?.value,
      district_id: data.district && data?.district?.map((obj) => obj.value),
      state_id: data.state.map((obj) => obj.value),
      city_id: data.area && data?.area?.map((obj) => obj.value),
      bonusType: routeParams.bonusType,
      form_type: "state_wise",
      status: status,
      dayWise: dayWiseButton === true ? JSON.stringify(dayWise) : null
    };
    let segment = [];
    for (let i = 0; i < data.segment.length; i++) {
      segment.push(data.segment[i].id);
    }
    params["segment"] = segment;
    let series = [];
    for (let i = 0; i < data.product_series.length; i++) {
      series.push(data.product_series[i].series_id);
    }
    params["series"] = series;
    console.log(params, "..........");
    setLoading(true);
    await campaignSer.saveFOCStateWiseCampaign(params).then((response) => {
      setLoading(false);
      if (response.status) {
        setLoading(false);
        toast.success(
          !isEdit
            ? "Offer created successfully."
            : "Offer updated successfully."
        );
        if (routeParams.programId == 5) {
          history.push("/eni-vistaar-listing");
        }
      }
      setLoading(false);
    });
  };
  const updateValues = async (data) => {
    console.log("data---", data);
    const startMoment = moment(data.start_date, "YYYY-MM-DD");
    const endMoment = moment(data.end_date, "YYYY-MM-DD");
    // Compare the dates
    if (endMoment.isBefore(startMoment)) {
      // Raise an error or handle the error as needed
      toast.error("End date is before the start date");
      return;
    }
    setLoading(true);
    let params = {
      title: title,
      program_id: routeParams.programId,
      start_date: moment(data.start_date, "YYYY-MM-DD").unix(),
      end_date: moment(data.end_date, "YYYY-MM-DD").unix(),
      numberofPacks: parseInt(numberOfPacks, 10),
      segment: data.segment.id,
      product: data.product_id?.value,
      pack_size: packSize,
      volume: data.target_volume,
      payout_volume: (packSize * data.number_of_packs),
      district_id: data.district && data?.district?.map((obj) => obj.value),
      state_id: data.state.map((obj) => obj.value),
      city_id: data.area && data?.area?.map((obj) => obj.value),
      bonusType: routeParams.bonusType,
      offer_id: routeParams.id,
      form_type: "state_wise",
      status: offerStatus,
      dayWise: dayWiseButton === true ? JSON.stringify(dayWise) : null
    };
    let segment = [];
    for (let i = 0; i < data.segment.length; i++) {
      segment.push(data.segment[i].id);
    }
    params["segment"] = JSON.stringify(segment);
    let series = [];
    for (let i = 0; i < data.product_series.length; i++) {
      series.push(
        data.product_series[i].series_id
          ? data.product_series[i].series_id
          : data.product_series[i].value
      );
    }
    params["series"] = series;
    setLoading(true);
    let response = await campaignSer.updateFOCStateWiseCampaign(params);
    setLoading(false);
    if (response.status) {
      setLoading(false);
      toast.success(
        !isEdit ? "Offer created successfully." : "Offer updated successfully."
      );
      if (routeParams.programId == 5) {
        history.push("/eni-vistaar-listing");
      }
      setLoading(false);
    }
  };
  const updateStatus = async (action) => {
    let response = await campaignSer.updateCampaign(action, routeParams.id);
    if (response.status) {
      toast.success(
        action == "INACTIVE"
          ? "Campaign inactivated successfully."
          : "Campaign Published Successfully."
      );
      if (routeParams.programId == 5) {
        history.push("/eni-vistaar-listing");
      }
    } else {
      toast.error("Something went wrong.");
    }
  };

  useEffect(async () => {
    fetchStates();
    await campaignSer.GetProductList().then((response) => {
      if (response.status === true) {
        setProductOption(response.data);
      }
    });
    const segmentoptions = await campaignSer.GetSegmentList();
    if (segmentoptions?.status) {
      setSegmentOption(segmentoptions?.data);
    }
    if (isEdit) {
      let params = {
        program_id: routeParams.programId,
        offer_id: routeParams.id,
      };
      let details = await campaignSer.GetOfferDetails(params);
      let editDetails = details.data.offerDetails;
      console.log("editDetails---", editDetails);
      let selectSegment = segmentoptions.data.find(
        (item) => item.id == editDetails.segment
      );
      const options = await campaignSer.GetSegmentSeriesList({
        id: editDetails.segment,
        program_id: routeParams.programId
      });
      if (options.status) {
        setSeriesOptions(options?.data);
      }

      setEditVal(editDetails);
      setProduct(details.data.productData);
      setFieldValueFunction("product_id", details.data.productData);

      setTargetVolume(editDetails.target_volume);
      setFieldValueFunction("target_volume", editDetails.target_volume);
      setPackSize(editDetails.pack_size_volume);
      setNumberOfPack(editDetails.packs);
      setFieldValueFunction("number_of_packs", editDetails.packs);
      setPayoutVolume(editDetails.payout_volume);
      setStartDate(moment(editDetails.start_date).format("YYYY-MM-DD"));
      setFieldValueFunction(
        "start_date",
        moment(editDetails.start_date).format("YYYY-MM-DD")
      );
      setEndDate(moment(editDetails.end_date).format("YYYY-MM-DD"));
      setFieldValueFunction(
        "end_date",
        moment(editDetails.end_date).format("YYYY-MM-DD")
      );
      setSeries(details.data.series);
      setFieldValueFunction("product_series", details.data.series);
      setFieldValueFunction("segment", selectSegment);
      setFieldValueFunction("segment", details.data.segmentData);
      setSegment(details.data.segmentData);

      setSelectedStates(editDetails.state);
      if (editDetails.state) {
        const stateIds = editDetails.state.map((obj) => obj.value);
        const params = {
          state_id: stateIds,
          user_id: user.user_id,
        };
        const districtData = await campaignSer.getDistrictList(params);
        const options = districtData?.data?.map((district) => ({
          district_id: district.value,
          value: district.value,
          label: district.label,
        }));
        setDistrictOptions(options);
      }
      setFieldValueFunction("state", editDetails.state);
      setSelectedDistricts(editDetails.district);

      setFieldValueFunction("district", editDetails.district);
      if (editDetails.district) {
        const districtIds = editDetails.district.map((obj) => obj.value);
        const params = {
          district_id: districtIds,
          user_id: user.user_id,
        };
        const options = await campaignSer.GetAreaList(params);
        if (options?.status) {
          setareaOpions(options?.data);
        }
      }

      setSelectedArea(editDetails.area);
      setFieldValueFunction("area", editDetails.area);
      setOfferStatus(editDetails.status);

      setTitle(editDetails.title);
      setFieldValueFunction("title", editDetails.title);
      setSeries(details.data.trading);
      setFieldValueFunction("vehicleSegment", details.data.trading);
      if(editDetails.dayWise) {
        setdayWiseButton(true);
        // setFieldValueFunction("dayWiseButton",true);
        console.log("dayWiseButton----",dayWiseButton);
        let dayWiseData = JSON.parse(editDetails.dayWise);
        console.log("dayWise---",dayWiseData);
        setdayWise(dayWiseData);
        setFieldValueFunction("dayWise",dayWiseData);
        console.log("true data,",dayWiseData['Sunday']);
        await Promise.all(dayWiseList.map(ele => {
          console.log("ele--", ele);
          console.log("data===",ele.value,typeof ele.name,dayWiseData[ele.name]);
          ele.value = dayWiseData[ele.name];
        }))
        setFieldValueFunction("dayWiseList",dayWiseList);
        setdayWiseList(dayWiseList);
        console.log("dayWise List",dayWiseList);
        console.log("dayWise---",dayWise);
      }
    }
  }, []);

  useEffect(() => {
    if (isEdit === true) {
      setPayoutVolume(parseFloat(packSize * numberOfPacks));
    } else {
      const pack = parseFloat(packSize.value);
      setPayoutVolume(parseFloat(pack * numberOfPacks));
    }});

  const handleClose = () => {
    history.goBack();
  };
  const changeDay = async  (event,index,ele) => {
    console.log("dayWise--",dayWise);
   console.log("event---",event.target.value,index,event.target.checked);
   console.log("data---",dayWise[ele.name]);
   dayWise[ele.name] = event.target.checked;
   dayWiseList[index]['value'] = event.target.checked;
   setdayWise(dayWise);
   setdayWiseList(dayWiseList);
   setFieldValueFunction("dayWiseList",dayWiseList);
  }; 

  return (
    <React.Fragment>
    <div className="page-content">
      <div className="container-fluid">
        <Row className="g-0 d-flex justify-content-around">
          <Col lg={12}>
            <Card className="m-2">
              <CardHeader className="d-flex align-items-center">
                <button
                  className="btn btn-secondary float-left"
                  type="submit"
                  onClick={() => {
                    handleClose();
                  }}
                  color="primary"
                >
                  Back
                </button>
                <CardTitle className="font-size-20 mx-3 m-0 text-uppercase">
                  Geography Wise State Scheme
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Formik
                  initialValues={{
                    state: selectedStates,
                    district: selectedDistricts,
                    area: selectedArea,
                    segment: segment,
                    product_series: series,
                    target_volume: targetVolume,
                    product_id: product,
                    pack_size: packSize,
                    start_date: startDate,
                    end_date: endDate,
                    title: title,
                    number_of_packs: numberOfPacks,
                    payout_volume: payoutVolume,
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.state) {
                      errors.state = "Required";
                    }
                    if (!values.title) {
                      errors.title = "Required";
                    }
                    if (!values.segment) {
                      errors.segment = "Required";
                    }
                    // if (!values.product_series) {
                    //   errors.product_series = "Required";
                    // }
                    if (!values.target_volume) {
                      errors.target_volume = "Required";
                    }
                    if (!values.number_of_packs) {
                      errors.number_of_packs = "Required";
                    }
                    if (!values.product) {
                      errors.product = "Required";
                    }
                    if (!values.pack_size) {
                      errors.pack_size = "Required";
                    }

                    if (!values.start_date) {
                      errors.start_date = "Required";
                    }
                    if (!values.end_date) {
                      errors.end_date = "Required";
                    }
                    return errors;
                  }}
                  onSubmit={(values) => {
                    console.log(values, "here");
                    console.log("isEdit value------",isEdit);
                    if (isEdit) {
                      updateValues(values);
                    } else {
                      submitValuetoApi(values);
                    }
                  }}
                >
                  {({
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    values,
                    errors,
                    touched,
                  }) => (
                    <React.Fragment>
                      {handleSelectFieldObject(setFieldValue)}
                      <Row>
                        <Col lg={8} md={6} sm={12}>
                          <div className="form-row">
                            <label className="form-label">Offer Title</label>
                            <div className="input-group">
                              <Input
                                type="search"
                                placeholder="Type offer title"
                                id="title"
                                name="title"
                                value={values.title}
                                disabled={isEdit ? true : false}
                                onChange={(e) => {
                                  setTitle(e.target.value);
                                  setFieldValueFunction(
                                    "title",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            {errors.title && touched.title && (
                              <p className="form-error">
                                {errors.title &&
                                  touched.title &&
                                  errors.title}
                              </p>
                            )}
                          </div>
                        </Col>
                        {/* <Col lg={4} md={6} sm={12}>
                          <div className="form-row">
                            <label className="form-label">
                              Choose vehicle Segment:
                            </label>
                            <div className={`input-group select-group `}>
                              <Select
                                isDisabled={isEdit ? true : false}
                                className="w-100"
                                closeMenuOnSelect={true}
                                value={values.vehicleSegment}
                                options={vehicleSegmentOption}
                                id="vehicleSegment"
                                name="vehicleSegment"
                                onChange={(event) => {
                                  handleVehicleSegment(event);
                                }}
                                onBlur={(event) => {
                                  handleBlur(event);
                                }}
                              />
                            </div>
                          </div>
                        </Col> */}
                      </Row>
                      <Row className="mt-3">
                        <Col lg={4} md={6} sm={12}>
                          <div className="form-row">
                            <label className="form-label">
                              Select State:
                            </label>
                            <div
                              className={`input-group select-group ${
                                errors.state && touched.state
                                  ? "input-error"
                                  : ""
                              }`}
                            >
                              <Select
                                isMulti
                                // isDisabled={isEdit ? true : false}
                                className="w-100"
                                closeMenuOnSelect={false}
                                value={values.state}
                                isSearchable={true}
                                options={stateOptions}
                                blurInputOnSelect={true}
                                id="state"
                                name="state"
                                onChange={(event) => {
                                  handleStateChange(event);
                                }}
                                onBlur={(event) => {
                                  handleBlur(event);
                                }}
                              />
                            </div>
                            {errors.state && touched.state && (
                              <p className="form-error">
                                {errors.state &&
                                  touched.state &&
                                  errors.state}
                              </p>
                            )}
                          </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                          <div className="form-row">
                            <label className="form-label">
                              Select District:
                            </label>
                            <div
                              className={`input-group select-group ${
                                errors.district && touched.district
                                  ? "input-error"
                                  : ""
                              }`}
                            >
                              <Select
                                isMulti
                                // isDisabled={isEdit ? true : false}
                                className="w-100"
                                closeMenuOnSelect={false}
                                value={values.district}
                                isSearchable={true}
                                options={districtOptions}
                                blurInputOnSelect={true}
                                id="district"
                                name="district"
                                onChange={(event) => {
                                  handleDistrictChange(event);
                                }}
                                onBlur={(event) => {
                                  handleBlur(event);
                                }}
                              />
                            </div>
                            {errors.district && touched.district && (
                              <p className="form-error">
                                {errors.district &&
                                  touched.district &&
                                  errors.district}
                              </p>
                            )}
                          </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                          <div className="form-row">
                            <label className="form-label">Select Area:</label>
                            <div
                              className={`input-group select-group ${
                                errors.area && touched.area
                                  ? "input-error"
                                  : ""
                              }`}
                            >
                              <Select
                                isMulti
                                // isDisabled={isEdit ? true : false}
                                className="w-100"
                                closeMenuOnSelect={false}
                                value={values.area}
                                isSearchable={true}
                                options={areaOpions}
                                blurInputOnSelect={true}
                                id="area"
                                name="area"
                                onChange={(event) => {
                                  handleAreaChange(event);
                                }}
                                onBlur={(event) => {
                                  handleBlur(event);
                                }}
                              />
                            </div>
                            {errors.area && touched.area && (
                              <p className="form-error">
                                {errors.area && touched.area && errors.area}
                              </p>
                            )}
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col lg={4} md={6} sm={12}>
                          <div className="form-row">
                            <label className="form-label">
                              Select Product Segment:
                            </label>
                            <div
                              className={`input-group select-group ${
                                errors.segment && touched.segment
                                  ? "input-error"
                                  : ""
                              }`}
                            >
                              <Select
                                isMulti
                                // isDisabled={isEdit ? true : false}
                                className="w-100"
                                closeMenuOnSelect={true}
                                value={values.segment}
                                isSearchable={false}
                                options={segmentOption}
                                blurInputOnSelect={true}
                                id="segment"
                                name="segment"
                                onChange={(event) => {
                                  handleSegment(event);
                                }}
                                onBlur={(event) => {
                                  handleBlur(event);
                                }}
                              />
                            </div>
                            {errors.segment && touched.segment && (
                              <p className="form-error">
                                {errors.segment &&
                                  touched.segment &&
                                  errors.segment}
                              </p>
                            )}
                          </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                          <div className="form-row">
                            <label className="form-label">
                              Choose Product Series:
                            </label>
                            <div
                              className={`input-group select-group ${
                                errors.product_series &&
                                touched.product_series
                                  ? "input-error"
                                  : ""
                              }`}
                            >
                              <Select
                                className="w-100"
                                // isDisabled={isEdit ? true : false}
                                closeMenuOnSelect={false}
                                value={values.product_series}
                                isMulti
                                options={seriesOption}
                                id="product_series"
                                name="product_series"
                                onChange={(event) => {
                                  handleSeries(event);
                                }}
                                onBlur={(event) => {
                                  handleBlur(event);
                                }}
                              />
                            </div>
                            {/* {errors.product_series &&
                              touched.product_series && (
                                <p className="form-error">
                                  {errors.product_series &&
                                    touched.product_series &&
                                    errors.product_series}
                                </p>
                              )} */}
                          </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                          <div className="form-row">
                            <label className="form-label">
                              Target Volume
                            </label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  Litre
                                </span>
                              </div>
                              <Input
                                type="search"
                                placeholder="Target Volume (Ltr)"
                                id="target_volume"
                                name="target_volume"
                                value={values.target_volume}
                                onChange={(e) => {
                                  const input = e.target.value;

                                  // Use a regular expression to remove non-numeric characters
                                  const numericInput = input.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  setTargetVolume(numericInput);
                                  setFieldValueFunction(
                                    "target_volume",
                                    numericInput
                                  );
                                }}
                                onBlur={(event) => {
                                  handleBlur(event);
                                }}
                              />
                            </div>
                            {errors.target_volume &&
                              touched.target_volume && (
                                <p className="form-error">
                                  {errors.target_volume &&
                                    touched.target_volume &&
                                    errors.target_volume}
                                </p>
                              )}
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col lg={4} md={6} sm={12}>
                          <div className="form-row">
                            <label className="form-label">
                              Select Product:
                            </label>
                            <div
                              className={`input-group select-group ${
                                errors.product && touched.product
                                  ? "input-error"
                                  : ""
                              }`}
                            >
                              <Select
                                isDisabled={isEdit ? true : false}
                                className="w-100"
                                closeMenuOnSelect={true}
                                value={values.product_id}
                                isSearchable={false}
                                options={productOption}
                                blurInputOnSelect={true}
                                id="product"
                                name="product"
                                onChange={(event) => {
                                  handleProduct(event);
                                }}
                                onBlur={(event) => {
                                  handleBlur(event);
                                }}
                              />
                            </div>
                            {errors.product && touched.product && (
                              <p className="form-error">
                                {errors.product &&
                                  touched.product &&
                                  errors.product}
                              </p>
                            )}
                          </div>
                        </Col>
                        {isEdit ? (
                            <Col lg={4} md={6} sm={12}>
                              <div className="form-row">
                                <label className="form-label">
                                  Selected Pack Size:
                                </label>
                                <div className="input-group">
                                  <Input
                                    disabled={true}
                                    type="search"
                                    id="pack_size"
                                    name="pack_size"
                                    value={packSize}
                                  />
                                </div>
                              </div>
                            </Col>
                          ) : (
                            <Col lg={4} md={6} sm={12}>
                              <div className="form-row">
                                <label className="form-label">
                                  Select Pack Size:
                                </label>
                                <div
                                  className={`input-group select-group ${
                                    errors.pack_size && touched.pack_size
                                      ? "input-error"
                                      : ""
                                  }`}
                                >
                                  <Select
                                    isDisabled={isEdit ? true : false}
                                    className="w-100"
                                    closeMenuOnSelect={true}
                                    value={values.pack_size}
                                    isSearchable={false}
                                    options={packSizeOption}
                                    blurInputOnSelect={true}
                                    id="pack_size"
                                    name="pack_size"
                                    onChange={(event) => {
                                      handlePackSize(event);
                                    }}
                                    onBlur={(event) => {
                                      handleBlur(event);
                                    }}
                                  />
                                </div>
                                {errors.pack_size && touched.pack_size && (
                                  <p className="form-error">
                                    {errors.pack_size &&
                                      touched.pack_size &&
                                      errors.pack_size}
                                  </p>
                                )}
                              </div>
                            </Col>
                          )}
                        <Col lg={2} md={4} sm={12}>
                          <div className="form-row">
                            <label className="form-label">
                              Number of Packs
                            </label>
                            <div className="input-group">
                              <Input
                                type="search"
                                placeholder="Enter number of packs"
                                id="number_of_packs"
                                name="number_of_packs"
                                value={values.number_of_packs}
                                onChange={(e) => {
                                  const input = e.target.value;

                                  // Use a regular expression to remove non-numeric characters
                                  const numericInput = input.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  setNumberOfPack(numericInput);
                                  setFieldValueFunction(
                                    "number_of_packs",
                                    numericInput
                                  );
                                }}
                                onBlur={(event) => {
                                  handleBlur(event);
                                }}
                              />
                            </div>
                            {errors.number_of_packs &&
                              touched.number_of_packs && (
                                <p className="form-error">
                                  {errors.number_of_packs &&
                                    touched.number_of_packs &&
                                    errors.number_of_packs}
                                </p>
                              )}
                          </div>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                          <div className="form-row">
                            <label className="form-label">
                              Payout Volume
                            </label>
                            <div className="form-control">
                              {isNaN(payoutVolume)
                                ? "Payout Volume"
                                : payoutVolume}
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col lg={4} md={12} sm={12}>
                          <div className="form-row">
                            <label className="form-label">Start Date:</label>
                            <div className="input-group">
                              <Input
                                type="date"
                                placeholder="Start Date"
                                id="start_date"
                                name="start_date"
                                value={values.start_date}
                                min={moment()
                                  .add(1, "day")
                                  .format("YYYY-MM-DD")}
                                onChange={(event) => {
                                  setStartDate(
                                    moment(
                                      new Date(event.target.value)
                                    ).format("YYYY-MM-DD")
                                  );
                                  setFieldValueFunction(
                                    "start_date",
                                    moment(
                                      new Date(event.target.value)
                                    ).format("YYYY-MM-DD")
                                  );
                                }}
                                onBlur={(event) => {
                                  handleBlur(event);
                                }}
                                invalid={
                                  errors.start_date && touched.start_date
                                }
                              />
                            </div>
                            {errors.start_date && touched.start_date && (
                              <p className="form-error">
                                {errors.start_date &&
                                  touched.start_date &&
                                  errors.start_date}
                              </p>
                            )}
                          </div>
                        </Col>
                        <Col lg={4} md={12} sm={12}>
                          <div className="form-row">
                            <label className="form-label">End Date:</label>
                            <div className="input-group">
                              <Input
                                type="date"
                                placeholder="End Date"
                                id="end_date"
                                name="end_date"
                                value={values.end_date}
                                min={values.start_date}
                                onChange={(event) => {
                                  setEndDate(
                                    moment(
                                      new Date(event.target.value)
                                    ).format("YYYY-MM-DD")
                                  );
                                  setFieldValueFunction(
                                    "end_date",
                                    moment(
                                      new Date(event.target.value)
                                    ).format("YYYY-MM-DD")
                                  );
                                }}
                                onBlur={(event) => {
                                  handleBlur(event);
                                }}
                                invalid={errors.end_date && touched.end_date}
                              />
                            </div>
                            {errors.end_date && touched.end_date && (
                              <p className="form-error">
                                {errors.end_date &&
                                  touched.end_date &&
                                  errors.end_date}
                              </p>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                          <Col>
                            <Button
                              className=""
                              onClick={() =>
                                setdayWiseButton(!dayWiseButton)
                              }
                            >
                              Day Wise
                              <span>
                                {" "}
                                <i
                                  className={`${
                                    dayWiseButton === false
                                      ? " ri-checkbox-blank-line"
                                      : " ri-checkbox-line"
                                  } mx-2 font-size-14`}
                                ></i>
                              </span>
                            </Button>
                            {dayWiseButton === true ?( <ul>
        {dayWiseList.map((ele, index) => {
          return (
            <th key={index}>
              <div>
                <div>
                  <input
                    type="checkbox"
                    id={`custom-checkbox-${index}`}
                    name={ele.name}
                    value={ele.name}
                    checked={ele.value}
                    onChange={(e) => {
                     changeDay(e,index,ele)
                    }}
                  /> {ele.name}
                </div>
              </div>
            </th>
          );
        })}
      </ul>) : null}
                          </Col>
                        </Row>
                      <Row>
                        {user.user_id !== 1685633729789 && (<Col lg={12}> {
                          (isEdit && isEdit === true && offerStatus === "UNPUBLISH") ? <CardTitle className="text-danger float-end">
                          Offer is unpublish!
                        </CardTitle> : (
                          <div className="d-flex align-items-end justify-content-end">
                            {loading === true ? (
                              <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                            ) : isEdit && isEdit === true ? (
                              <>
                                <Button
                                  className="btn-warning mt-3 me-1"
                                  disabled={loading}
                                  onClick={() => {
                                    updateValues(values);
                                  }}
                                >
                                  Update
                                </Button>
                                {isEdit && (
                                  <Button
                                    className="btn-warning mt-3"
                                    disabled={loading}
                                    onClick={() => {
                                      updateStatus(
                                        (offerStatus === "DRAFT")
                                              ? "PUBLISH"
                                              : (offerStatus === "PUBLISH" ? "UNPUBLISH" : "Save as Draft")
                                      );
                                    }}
                                  >
                                    {(offerStatus === "DRAFT")
                                              ? "PUBLISH"
                                              : (offerStatus === "PUBLISH" ? "UNPUBLISH" : "Save as Draft")}
                                  </Button>
                                )}
                              </>
                            ) : (
                              <>
                                <Button
                                  className="btn-warning mt-3 me-1"
                                  disabled={loading}
                                  onClick={() => {
                                    setRadioValue("DRAFT");
                                    submitValuetoApi(values,"DRAFT");
                                  }}
                                >
                                  Save as draft
                                </Button>
                                <Button
                                  className="btn-warning mt-3 me-1"
                                  disabled={loading}
                                  onClick={() => {
                                    console.log("test-----")
                                    setRadioValue("PUBLISH");
                                    submitValuetoApi(values,"PUBLISH");
                                  }}
                                >
                                  Publish
                                </Button>
                              </>
                            )}
                          </div>
                          )
                        }
                        </Col>)}
                      </Row>
                    </React.Fragment>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  </React.Fragment>
);
}

export default FocScheme;
