import React from "react";

// Redux
import { useHistory } from "react-router-dom";
import { Row, Col, CardTitle, Card, CardHeader, CardBody } from "reactstrap";

// import images

import { useState } from "react";
import { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";

import "./GamificationConsumptionPayout.scss";

function GamificationSelectProgramType(props) {
  const PROGRAM_SCHEME = [
    {
      type: "PROGRAM_SETUP",
      backTo: null,
      title: "SELECT PROGRAM",
    },
    {
      type: "POINT_BONUS",
      backTo: "PROGRAM_SETUP",
      title: "SELECT BONUS TYPE",
    },
    {
      type: "SCHEME_SETUP",
      backTo: "POINT_BONUS",
      title: "Setup Consumption based Payouts",
    },
    {
      type: "SCHEME_GEOGRAPHY",
      backTo: "SCHEME_SETUP",
      title: "Select Geography",
    }
  ];

  const history = useHistory();
  const [consumptionScheme, setConsumptionScheme] = useState(PROGRAM_SCHEME[0]);

  const handleSelectionRoute = (val) => {
    history.push(val);
  };

  const handleSelection = (val) => {
    const _selection = PROGRAM_SCHEME.find((item) => {
      return item.type === val;
    });

    setConsumptionScheme(_selection);
  };

  useEffect(() => {
    console.log(consumptionScheme, "consumptionScheme");
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="g-0 d-flex justify-content-around">
            <Col lg={12}>
              {consumptionScheme.type !== "PROGRAM_SETUP" && (
                <button
                  className="btn btn-secondary float-left mx-1"
                  type="submit"
                  onClick={() => {
                    handleSelection(consumptionScheme.backTo);
                  }}
                  color="primary"
                >
                  Back
                </button>
              )}
              <Card className="m-2">
                <CardHeader className="d-flex ">
                  <CardTitle className="font-size-20 text-uppercase">
                    {consumptionScheme.title}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row className="g-0 d-flex justify-content-center">
                    <Col sm={12}>
                      {consumptionScheme.type === "PROGRAM_SETUP" && (
                        <React.Fragment>
                          <div className="box-selection-wrap">
                            <div
                              className="box-selection-item"
                              onClick={() => {
                                handleSelectionRoute("/gamification-listing");
                              }}
                            >
                              <p className="box-inner-title">ULTIMATE BONANZA</p>
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default GamificationSelectProgramType;
