import React, { useEffect, useState } from "react";
import DashboardService from "../../services/rlpDashboard.service";
import { CardTitle } from "reactstrap";
import "./ProgramOverView.css";
import Spinner from "../Loader/Spinner";
import IndiaMap from "../Charts/DbtRedemtionChart";
import moment from "moment";

const RedemptionOverview = (props) => {
  const data = props.data;
  const redemptionOverview = props.redemptionOverview;

  const [section, setSection] = useState("");
  const [count, setCount] = useState(null);

  const [loader, setLoader] = useState(false);
  const [mapHide, setMapHide] = useState(true);
  const [rewardCashMap, setRewardCashMap] = useState(null);
  const [rewardCountMap, setRewardCountMap] = useState(null);
  const [rewardVoucherMap, setRewardVoucherMap] = useState(null);
  const [rewardVoucherCountMap, setRewardVoucherCountMap] = useState(null);

  const body = {
    section: section,
    start_date: data.start_date,
    end_date: data.end_date,
    user_id: data.user_id,
  };
  const centerTextStyle = {
    textAlign: "center",
  };

  const handleCardClick = async (section) => {
    setLoader(true);
    setMapHide(false);
    setSection(section);
    body.section = section;
    await DashboardService.GetRedemptionOverviewData(body).then((res) => {
      setRewardCashMap(res.data?.reward.map_data);
      setRewardCountMap(res.data?.reward.points_map);
      setRewardVoucherMap(res.data?.voucher.map_data);
      setRewardVoucherCountMap(res.data?.voucher.points_map);
      setLoader(false);
    });
  };
  const handleClose = () => {
    setMapHide(true);
  };
  return (
    <div className="mt-3">
      <CardTitle className="text-black-50 font-size-18">
        Reward ({moment(body.start_date).format("DD-MM-YYYY")} -{" "}
        {moment(body.end_date).format("DD-MM-YYYY")})
      </CardTitle>

      <div className="row">
        <div className="col-md-3">
          <div
            className={`${
              section === "total_count" ? "cd-border" : ""
            } card pe`}
            onClick={() => handleCardClick("total_count")}
          >
            <CardTitle className="p-2" style={centerTextStyle}>
              Total Count
            </CardTitle>
            <div className="d-flex" style={centerTextStyle}>
              <div
                className={` card-body vertical-line `}
                style={centerTextStyle}
              >
                <p className="d-flex justify-content-center">
                  <span
                    className="card-text"
                    title={`Reward Requests ${redemptionOverview?.total_count.reward.count}`}
                  >
                    {redemptionOverview?.total_count.reward.count}
                  </span>
                </p>
                <p>Physical Reward</p>
                <p className="d-flex justify-content-center">
                  <span
                    className="card-text"
                    title={`Reward Points ${
                      redemptionOverview?.total_count?.reward.points_used ===
                      undefined
                        ? "-"
                        : redemptionOverview?.total_count?.reward.points_used
                    }`}
                  >{`${
                    redemptionOverview?.total_count?.reward.points_used ===
                    undefined
                      ? "-"
                      : redemptionOverview?.total_count?.reward.points_used
                  }`}</span>
                </p>
              </div>
              <div className={` card-body`}>
                <p
                  className="d-flex justify-content-center"
                  style={centerTextStyle}
                  title={`Voucher Requests ${redemptionOverview?.total_count.voucher.count}`}
                >
                  {redemptionOverview?.total_count.voucher.count}
                </p>
                <p>Voucher</p>
                <p className="d-flex justify-content-center">
                  <span
                    className="card-text"
                    title={`Voucher Points ${
                      redemptionOverview?.total_count?.voucher.points_used ===
                      undefined
                        ? "-"
                        : redemptionOverview?.total_count?.voucher.points_used
                    }`}
                  >{`${
                    redemptionOverview?.total_count?.voucher.points_used ===
                    undefined
                      ? "-"
                      : redemptionOverview?.total_count?.voucher.points_used
                  }`}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div
            className={`${
              section === "verification_pending" ? "cd-border" : ""
            } card pe`}
            onClick={() => handleCardClick("verification_pending")}
          >
            <CardTitle className="p-2" style={centerTextStyle}>
              Verification Pending
            </CardTitle>
            <div className="d-flex" style={centerTextStyle}>
              <div
                className={` card-body vertical-line `}
                style={centerTextStyle}
              >
                <p className="d-flex justify-content-center">
                  <span
                    className="card-text"
                    title={`Reward Requests ${redemptionOverview?.verification_pending.reward.count}`}
                  >
                    {redemptionOverview?.verification_pending.reward.count}
                  </span>
                </p>
                <p>Physical Reward</p>
                <p className="d-flex justify-content-center">
                  <span
                    className="card-text"
                    title={`Reward Points ${
                      redemptionOverview?.verification_pending?.reward
                        .points_used === undefined
                        ? "-"
                        : redemptionOverview?.verification_pending?.reward
                            .points_used
                    }`}
                  >{`${
                    redemptionOverview?.verification_pending?.reward
                      .points_used === undefined
                      ? "-"
                      : redemptionOverview?.verification_pending?.reward
                          .points_used
                  }`}</span>
                </p>
              </div>
              <div className={` card-body`}>
                <p
                  className="d-flex justify-content-center"
                  style={centerTextStyle}
                  title={`Voucher Requests ${redemptionOverview?.verification_pending.voucher.count}`}
                >
                  {redemptionOverview?.verification_pending.voucher.count}
                </p>
                <p>Voucher</p>
                <p className="d-flex justify-content-center">
                  <span
                    className="card-text"
                    title={`Voucher Points ${
                      redemptionOverview?.verification_pending?.voucher
                        .points_used === undefined
                        ? "-"
                        : redemptionOverview?.verification_pending?.voucher
                            .points_used
                    }`}
                  >{`${
                    redemptionOverview?.verification_pending?.voucher
                      .points_used === undefined
                      ? "-"
                      : redemptionOverview?.verification_pending?.voucher
                          .points_used
                  }`}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div
            className={`${section === "in_process" ? "cd-border" : ""} card pe`}
            onClick={() => handleCardClick("in_process")}
          >
            <CardTitle className="p-2" style={centerTextStyle}>
              In Process
            </CardTitle>
            <div className="d-flex" style={centerTextStyle}>
              <div
                className={` card-body vertical-line `}
                style={centerTextStyle}
              >
                <p className="d-flex justify-content-center">
                  <span
                    className="card-text"
                    title={`Reward Requests ${redemptionOverview?.in_process.reward.count}`}
                  >
                    {redemptionOverview?.in_process.reward.count}
                  </span>
                </p>
                <p>Physical Reward</p>
                <p className="d-flex justify-content-center">
                  <span
                    className="card-text"
                    title={`Reward Points ${
                      redemptionOverview?.in_process?.reward.points_used ===
                      undefined
                        ? "-"
                        : redemptionOverview?.in_process?.reward.points_used
                    }`}
                  >{`${
                    redemptionOverview?.in_process?.reward.points_used ===
                    undefined
                      ? "-"
                      : redemptionOverview?.in_process?.reward.points_used
                  }`}</span>
                </p>
              </div>
              <div className={` card-body`}>
                <p
                  className="d-flex justify-content-center"
                  style={centerTextStyle}
                  title={`Voucher Requests ${redemptionOverview?.in_process.voucher.count}`}
                >
                  {redemptionOverview?.in_process.voucher.count}
                </p>
                <p>Voucher</p>
                <p className="d-flex justify-content-center">
                  <span
                    className="card-text"
                    title={`Voucher Points ${
                      redemptionOverview?.in_process?.voucher.points_used ===
                      undefined
                        ? "-"
                        : redemptionOverview?.in_process?.voucher.points_used
                    }`}
                  >{`${
                    redemptionOverview?.in_process?.voucher.points_used ===
                    undefined
                      ? "-"
                      : redemptionOverview?.in_process?.voucher.points_used
                  }`}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div
            className={`${section === "dispatched" ? "cd-border" : ""} card pe`}
            onClick={() => handleCardClick("dispatched")}
          >
            <CardTitle className="p-2" style={centerTextStyle}>
              Dispatched
            </CardTitle>
            <div className="d-flex" style={centerTextStyle}>
              <div
                className={` card-body vertical-line `}
                style={centerTextStyle}
              >
                <p className="d-flex justify-content-center">
                  <span
                    className="card-text"
                    title={`Reward Requests ${redemptionOverview?.dispatched.reward.count}`}
                  >
                    {redemptionOverview?.dispatched.reward.count}
                  </span>
                </p>
                <p>Physical Reward</p>
                <p className="d-flex justify-content-center">
                  <span
                    className="card-text"
                    title={`Reward Points ${
                      redemptionOverview?.dispatched?.reward.points_used ===
                      undefined
                        ? "-"
                        : redemptionOverview?.dispatched?.reward.points_used
                    }`}
                  >{`${
                    redemptionOverview?.dispatched?.reward.points_used ===
                    undefined
                      ? "-"
                      : redemptionOverview?.dispatched?.reward.points_used
                  }`}</span>
                </p>
              </div>
              <div className={`card-body`}>
                <p
                  className="d-flex justify-content-center"
                  style={centerTextStyle}
                  title={`Voucher Requests ${redemptionOverview?.dispatched.voucher.count}`}
                >
                  {redemptionOverview?.dispatched.voucher.count}
                </p>
                <p>Voucher</p>
                <p className="d-flex justify-content-center">
                  <span
                    className="card-text"
                    title={`Voucher Points ${
                      redemptionOverview?.dispatched?.voucher.points_used ===
                      undefined
                        ? "-"
                        : redemptionOverview?.dispatched?.voucher.points_used
                    }`}
                  >{`${
                    redemptionOverview?.dispatched?.voucher.points_used ===
                    undefined
                      ? "-"
                      : redemptionOverview?.dispatched?.voucher.points_used
                  }`}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {mapHide === false &&
      (section === "total_count" ||
        section === "verification_pending" ||
        section === "in_process" ||
        section === "dispatched") ? (
        <>
          <div className="row mt-3 d-flex">
            <div className="col-md-12 col-md-12">
              <button
                className="btn btn-danger w-auto float-end"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="card pe p-3" style={{ height: "500px" }}>
                <CardTitle className="p-1 text-center font-size-18">
                  India <i className="mdi mdi-chevron-right"></i> State Wise
                  Reward Counts
                </CardTitle>
                {rewardCashMap === null || loader === true ? (
                  <Spinner />
                ) : (
                  <IndiaMap data={rewardCashMap} />
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="card pe p-3" style={{ height: "500px" }}>
                <CardTitle className="p-1 text-center font-size-18">
                  India <i className="mdi mdi-chevron-right"></i> State Wise
                  Reward Voucher Requests 
                </CardTitle>
                {rewardVoucherMap === null || loader === true ? (
                  <Spinner />
                ) : (
                  <IndiaMap data={rewardVoucherMap} />
                )}
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="card pe p-3" style={{ height: "500px" }}>
                <CardTitle className="p-1 text-center font-size-18">
                  India <i className="mdi mdi-chevron-right"></i> State Wise
                  Reward Points
                </CardTitle>
                {rewardCountMap === null || loader === true ? (
                  <Spinner />
                ) : (
                  <IndiaMap data={rewardCountMap} />
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="card pe p-3" style={{ height: "500px" }}>
                <CardTitle className="p-1 text-center font-size-18">
                  India <i className="mdi mdi-chevron-right"></i> State Wise
                  Reward Voucher Points
                </CardTitle>
                {rewardVoucherCountMap === null || loader === true ? (
                  <Spinner />
                ) : (
                  <IndiaMap data={rewardVoucherCountMap} />
                )}
              </div>
            </div>
          </div>
        </>
      ) : null}
      <div className="row mt-3">
        <div className="col-md-3">
          <div
            className={`${section === "delivered" ? "cd-border" : ""} card pe`}
            onClick={() => handleCardClick("delivered")}
          >
            <CardTitle className="p-2" style={centerTextStyle}>
              Delivered
            </CardTitle>
            <div className="d-flex" style={centerTextStyle}>
              <div
                className={`card-body vertical-line `}
                style={centerTextStyle}
              >
                <p className="d-flex justify-content-center">
                  <span
                    className="card-text"
                    title={`Reward Requests ${redemptionOverview?.delivered.reward.count}`}
                  >
                    {redemptionOverview?.delivered.reward.count}
                  </span>
                </p>
                <p>Physical Reward</p>
                <p className="d-flex justify-content-center">
                  <span
                    className="card-text"
                    title={`Reward Points ${
                      redemptionOverview?.delivered?.reward.points_used ===
                      undefined
                        ? "-"
                        : redemptionOverview?.delivered?.reward.points_used
                    }`}
                  >{`${
                    redemptionOverview?.delivered?.reward.points_used ===
                    undefined
                      ? "-"
                      : redemptionOverview?.delivered?.reward.points_used
                  }`}</span>
                </p>
              </div>
              <div className={` card-body`}>
                <p
                  className="d-flex justify-content-center"
                  style={centerTextStyle}
                  title={`Voucher Requests ${redemptionOverview?.delivered.voucher.count}`}
                >
                  {redemptionOverview?.delivered.voucher.count}
                </p>
                <p>Voucher</p>
                <p className="d-flex justify-content-center">
                  <span
                    className="card-text"
                    title={`Voucher Points ${
                      redemptionOverview?.delivered?.voucher.points_used ===
                      undefined
                        ? "-"
                        : redemptionOverview?.delivered?.voucher.points_used
                    }`}
                  >{`${
                    redemptionOverview?.delivered?.voucher.points_used ===
                    undefined
                      ? "-"
                      : redemptionOverview?.delivered?.voucher.points_used
                  }`}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div
            className={`${
              section === "delivery_confirmed" ? "cd-border" : ""
            } card pe`}
            onClick={() => handleCardClick("delivery_confirmed")}
          >
            <CardTitle className="p-2" style={centerTextStyle}>
              Delivery Confirmed
            </CardTitle>
            <div className="d-flex" style={centerTextStyle}>
              <div
                className={` card-body vertical-line `}
                style={centerTextStyle}
              >
                <p className="d-flex justify-content-center">
                  <span
                    className="card-text"
                    title={`Reward Requests ${redemptionOverview?.delivery_confirmed.reward.count}`}
                  >
                    {redemptionOverview?.delivery_confirmed.reward.count}
                  </span>
                </p>
                <p>Physical Reward</p>
                <p className="d-flex justify-content-center">
                  <span
                    className="card-text"
                    title={`Reward Points ${
                      redemptionOverview?.delivery_confirmed?.reward
                        .points_used === undefined
                        ? "-"
                        : redemptionOverview?.delivery_confirmed?.reward
                            .points_used
                    }`}
                  >{`${
                    redemptionOverview?.delivery_confirmed?.reward
                      .points_used === undefined
                      ? "-"
                      : redemptionOverview?.delivery_confirmed?.reward
                          .points_used
                  }`}</span>
                </p>
              </div>
              <div className={` card-body`}>
                <p
                  className="d-flex justify-content-center"
                  style={centerTextStyle}
                  title={`Voucher Requests  ${redemptionOverview?.delivery_confirmed.voucher.count}`}
                >
                  {redemptionOverview?.delivery_confirmed.voucher.count}
                </p>
                <p>Voucher</p>
                <p className="d-flex justify-content-center">
                  <span
                    className="card-text"
                    title={`Voucher Points ${
                      redemptionOverview?.delivery_confirmed?.voucher
                        .points_used === undefined
                        ? "-"
                        : redemptionOverview?.delivery_confirmed?.voucher
                            .points_used
                    }`}
                  >{`${
                    redemptionOverview?.delivery_confirmed?.voucher
                      .points_used === undefined
                      ? "-"
                      : redemptionOverview?.delivery_confirmed?.voucher
                          .points_used
                  }`}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div
            className={`${
              section === "app_request" ? "cd-border" : ""
            } card pe`}
            onClick={() => handleCardClick("app_request")}
          >
            <CardTitle className="p-2" style={centerTextStyle}>
              App Request
            </CardTitle>
            <div className="d-flex" style={centerTextStyle}>
              <div
                className={` card-body vertical-line `}
                style={centerTextStyle}
              >
                <p className="d-flex justify-content-center">
                  <span
                    className="card-text"
                    title={`Reward Requests ${redemptionOverview?.app_request.reward.count}`}
                  >
                    {redemptionOverview?.app_request.reward.count}
                  </span>
                </p>
                <p>Physical Reward</p>
                <p className="d-flex justify-content-center">
                  <span
                    className="card-text"
                    title={`Reward Points ${
                      redemptionOverview?.app_request?.reward.points_used ===
                      undefined
                        ? "-"
                        : redemptionOverview?.app_request?.reward.points_used
                    }`}
                  >{`${
                    redemptionOverview?.app_request?.reward.points_used ===
                    undefined
                      ? "-"
                      : redemptionOverview?.app_request?.reward.points_used
                  }`}</span>
                </p>
              </div>
              <div className={`card-body`}>
                <p
                  className="d-flex justify-content-center"
                  style={centerTextStyle}
                  title={`Voucher Requests  ${redemptionOverview?.app_request.voucher.count}`}
                >
                  {redemptionOverview?.app_request.voucher.count}
                </p>
                <p>Voucher</p>
                <p className="d-flex justify-content-center">
                  <span
                    className="card-text"
                    title={`Voucher Points ${
                      redemptionOverview?.app_request?.voucher.points_used ===
                      undefined
                        ? "-"
                        : redemptionOverview?.app_request?.voucher.points_used
                    }`}
                  >{`${
                    redemptionOverview?.app_request?.voucher.points_used ===
                    undefined
                      ? "-"
                      : redemptionOverview?.app_request?.voucher.points_used
                  }`}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div
            className={`${
              section === "ccms_request" ? "cd-border" : ""
            } card pe`}
            onClick={() => handleCardClick("ccms_request")}
          >
            <CardTitle className="p-2" style={centerTextStyle}>
              CCMS Request
            </CardTitle>
            <div className="d-flex" style={centerTextStyle}>
              <div
                className={`card-body vertical-line `}
                style={centerTextStyle}
              >
                <p className="d-flex justify-content-center">
                  <span
                    className="card-text"
                    title={`Reward Requests ${redemptionOverview?.ccms_request.reward.count}`}
                  >
                    {redemptionOverview?.ccms_request.reward.count}
                  </span>
                </p>
                <p>Physical Reward</p>
                <p className="d-flex justify-content-center">
                  <span
                    className="card-text"
                    title={`Reward Points ${
                      redemptionOverview?.ccms_request?.reward.points_used ===
                      undefined
                        ? "-"
                        : redemptionOverview?.ccms_request?.reward.points_used
                    }`}
                  >{`${
                    redemptionOverview?.ccms_request?.reward.points_used ===
                    undefined
                      ? "-"
                      : redemptionOverview?.ccms_request?.reward.points_used
                  }`}</span>
                </p>
              </div>
              <div className={`card-body`}>
                <p
                  className="d-flex justify-content-center"
                  style={centerTextStyle}
                  title={`Voucher Requests  ${redemptionOverview?.ccms_request.voucher.count}`}
                >
                  {redemptionOverview?.ccms_request.voucher.count}
                </p>
                <p>Voucher</p>
                <p className="d-flex justify-content-center">
                  <span
                    className="card-text"
                    title={`Voucher Points ${
                      redemptionOverview?.ccms_request?.voucher.points_used ===
                      undefined
                        ? "-"
                        : redemptionOverview?.ccms_request?.voucher.points_used
                    }`}
                  >{`${
                    redemptionOverview?.ccms_request?.voucher.points_used ===
                    undefined
                      ? "-"
                      : redemptionOverview?.ccms_request?.voucher.points_used
                  }`}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {mapHide === false &&
      (section === "delivered" ||
        section === "delivery_confirmed" ||
        section === "app_request" ||
        section === "ccms_request") ? (
        <>
          <div className="row mt-3 d-flex">
            <div className="col-md-12 col-md-12">
              <button
                className="btn btn-danger w-auto float-end"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="card pe p-3" style={{ height: "500px" }}>
                <CardTitle className="p-1 text-center font-size-18">
                  India <i className="mdi mdi-chevron-right"></i> State Wise
                  Physical Redemption Requests
                </CardTitle>
                {rewardCashMap === null || loader === true ? (
                  <Spinner />
                ) : (
                  <IndiaMap data={rewardCashMap} />
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="card pe p-3" style={{ height: "500px" }}>
                <CardTitle className="p-1 text-center font-size-18">
                  India <i className="mdi mdi-chevron-right"></i> State Wise
                  Voucher Requests 
                </CardTitle>
                {rewardVoucherMap === null || loader === true ? (
                  <Spinner />
                ) : (
                  <IndiaMap data={rewardVoucherMap} />
                )}
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="card pe p-3" style={{ height: "500px" }}>
                <CardTitle className="p-1 text-center font-size-18">
                  India <i className="mdi mdi-chevron-right"></i> State Wise
                  Physical Reward Points
                </CardTitle>
                {rewardCountMap === null ? (
                  <Spinner />
                ) : (
                  <IndiaMap data={rewardCountMap} />
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="card pe p-3" style={{ height: "500px" }}>
                <CardTitle className="p-1 text-center font-size-18">
                  India <i className="mdi mdi-chevron-right"></i> State Wise
                  Voucher Points
                </CardTitle>
                {rewardVoucherCountMap === null || loader === true ? (
                  <Spinner />
                ) : (
                  <IndiaMap data={rewardVoucherCountMap} />
                )}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default RedemptionOverview;
