import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Input,
  Button,
  Label,
} from "reactstrap";
import Spinner from "../../components/Loader/Spinner";
import { Formik } from "formik";
import Select from "react-select";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function AddEditAudience(props) {
  const history = useHistory();
  const handleClose = () => {
    history.goBack();
  };
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [stateOptions, setstateOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [segmentOption, setSegmentOptions] = useState([]);
  const [offerOptions, setOfferOptions] = useState([]);
  const [seriesOptions, setSeriesOptions] = useState([]);
  const [dbtOptions, setDbtOptions] = useState([]);
  const [value, setValue] = useState(new Date().toISOString());
  const [mobile, setMobile] = useState([]);
  const [inputMobile, setInputMobile] = useState("");
  const [csvFile, setCSVFile] = useState(null);
  let setFieldValueFunction = null;
  const [formType, setFormTYpe] = useState({ value: "bulk", label: "Bulk" });
  const handleSelectFieldObject = (setFieldValue) => {
    setFieldValueFunction = setFieldValue;
  };

  const handleFormChange = async (optioon) => {
    setFormTYpe(optioon);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setCSVFile(file);
  };

  const handleStateChange = async (selectedOption) => {};
  const handleCategory = async (event) => {};
  const handleSegment = async (event) => {};
  const handleOffers = async (event) => {};
  const handleSeries = async (event) => {};
  const handleDbtStatus = async (event) => {};
  const handleDateChange = async (value) => {
    console.log("values are ", typeof value, value);
    setValue(value);
  };
  const createOption = (label) => ({
    label,
    value: label,
  });
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const value = event.target.value;
      const values = value.split(",").map((val) => val.trim());

      // Filter out values that don't have exactly 10 digits
      const validMobiles = values.filter((val) => /^[6-9]\d{9}$/.test(val));

      if (validMobiles.length > 10) {
        toast.error("You can only add a maximum of 10 mobile numbers.");
      }

      // Check for duplicates
      const duplicates = validMobiles.filter(
        (val, index, self) => self.indexOf(val) !== index
      );

      const duplicateArray = mobile.some((item) => item.value === value);

      if (duplicates.length > 0 || duplicateArray) {
        toast.error("Duplicate mobile numbers are not allowed.");
      } else {
        // Update the state with the valid mobile numbers
        setMobile((prev) => [...prev, ...validMobiles.map(createOption)]);
        setInputMobile("");
      }
    } else if (
      !/^[0-9,]*$/.test(event.key) ||
      !event.ctrlKey ||
      !event.metaKey
    ) {
      return true;
    } else {
      event.preventDefault();
      toast.error("Invalid input. Only digits and commas are allowed.");
    }
  };
  let inputProps = {
    placeholder: "Schedule Notification",
  };
  useEffect(async () => {
    // campaignService.getAllStates().then((res) => {
    //     console.log("getAllStates res ", res);
    // })
    // campaignService.getDbtList().then((res) => {
    //     console.log("getDbtList res ", res);
    // })
    // campaignService.getSkuFiterList().then((res) => {
    //     console.log("getSkuFiterList res ", res);
    // })
  });
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="g-0 d-flex justify-content-around">
            <Col lg={12}>
              <Card className="m-2">
                <CardHeader className="d-flex align-items-center">
                  <button
                    className="btn btn-secondary float-left"
                    type="submit"
                    onClick={() => {
                      handleClose();
                    }}
                    color="primary"
                  >
                    Back
                  </button>
                  <CardTitle className="font-size-20 mx-3 m-0 text-uppercase">
                    Add Audience
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row className="mt-3">
                    <Col lg={6} md={6} sm={12}>
                      <div className="form-row">
                        <label className="form-label">Form Type:</label>
                        <div className={`input-group select-group`}>
                          <Select
                            className="w-100"
                            closeMenuOnSelect={false}
                            isSearchable={true}
                            options={[
                              { value: "bulk", label: "Bulk" },
                              { value: "mobile", label: "Mobile" },
                            ]}
                            value={formType}
                            blurInputOnSelect={true}
                            id="form_type"
                            name="form_type"
                            onChange={(event) => {
                              handleFormChange(event);
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <Formik
                      initialValues={{
                        audience_title: "",
                        state: "",
                        district: "",
                        category: "",
                        segment: "",
                        offer: "",
                        series: "",
                        dbt_status: "",
                        notification_date: "",
                        mobile: [],
                        file: "",
                      }}
                      validate={(values) => {
                        const errors = {};
                        if (!values.audience_title) {
                          errors.audience_title = "Required";
                        }
                        return errors;
                      }}
                      onSubmit={(values) => {
                        console.log("submit Value", values);
                      }}
                    >
                      {({
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        values,
                        errors,
                        touched,
                      }) => (
                        <React.Fragment>
                          {handleSelectFieldObject(setFieldValue)}
                          <Row className="mt-3">
                            <Col lg={6} md={12} sm={12}>
                              <div className="form-row">
                                <label className="form-label">
                                  Audiene Tite
                                </label>
                                <div className="input-group">
                                  <Input
                                    type="text"
                                    placeholder="Title"
                                    id="audience_title"
                                    name="audience_title"
                                    value={values.audience_title}
                                    onChange={(event) => {
                                      handleChange(event);
                                    }}
                                    onBlur={(event) => {
                                      handleBlur(event);
                                    }}
                                    invalid={
                                      errors.audience_title &&
                                      touched.audience_title
                                    }
                                  />
                                </div>
                                {errors.audience_title &&
                                  touched.audience_title && (
                                    <p className="form-error">
                                      {errors.audience_title &&
                                        touched.audience_title &&
                                        errors.defaultPayoutRs}
                                    </p>
                                  )}
                              </div>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                              <div className="form-row">
                                <label className="form-label">
                                  Schedule Notifications:
                                </label>
                                <div
                                  className={`input-group select-group ${
                                    errors.notification_date &&
                                    touched.notification_date
                                      ? "input-error"
                                      : ""
                                  }`}
                                >
                                  <Datetime
                                    // inputProps={ inputProps }
                                    // value={new Date()}
                                    // dateFormat="YYYY-MM-DD"
                                    // timeFormat="HH:mm"
                                    inputProps={inputProps}
                                    className="w-100"
                                    id="notification_date"
                                    name="notification_date"
                                    value={values.notification_date}
                                    dateFormat={true}
                                    timeFormat={true}
                                    input={true}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          {formType.value === "bulk" && (
                            <Row className="mt-3">
                              <Col lg={6} md={6} sm={12}>
                                <div className="form-row">
                                  <label className="form-label">
                                    Select State:
                                  </label>
                                  <div
                                    className={`input-group select-group ${
                                      errors.state && touched.state
                                        ? "input-error"
                                        : ""
                                    }`}
                                  >
                                    <Select
                                      className="w-100"
                                      closeMenuOnSelect={false}
                                      isSearchable={true}
                                      options={stateOptions}
                                      value={values.state}
                                      blurInputOnSelect={true}
                                      id="state"
                                      name="state"
                                      onChange={(event) => {
                                        handleStateChange(event);
                                      }}
                                      onBlur={(event) => {
                                        handleBlur(event);
                                      }}
                                    />
                                  </div>
                                  {errors.state && touched.state && (
                                    <p className="form-error">
                                      {errors.state &&
                                        touched.state &&
                                        errors.state}
                                    </p>
                                  )}
                                </div>
                              </Col>
                              <Col lg={6} md={6} sm={12}>
                                <div className="form-row">
                                  <label className="form-label">
                                    Select District:
                                  </label>
                                  <div
                                    className={`input-group select-group ${
                                      errors.district && touched.district
                                        ? "input-error"
                                        : ""
                                    }`}
                                  >
                                    <Select
                                      className="w-100"
                                      closeMenuOnSelect={false}
                                      isSearchable={true}
                                      options={districtOptions}
                                      blurInputOnSelect={true}
                                      value={values.district}
                                      id="district"
                                      name="district"
                                      onBlur={(event) => {
                                        handleBlur(event);
                                      }}
                                    />
                                  </div>
                                  {errors.district && touched.district && (
                                    <p className="form-error">
                                      {errors.district &&
                                        touched.district &&
                                        errors.district}
                                    </p>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          )}
                          {formType.value === "bulk" && (
                            <Row className="mt-3">
                              <Col lg={4} md={6} sm={12}>
                                <div className="form-row">
                                  <label className="form-label">
                                    Select category:
                                  </label>
                                  <div
                                    className={`input-group select-group ${
                                      errors.category && touched.category
                                        ? "input-error"
                                        : ""
                                    }`}
                                  >
                                    <Select
                                      className="w-100"
                                      closeMenuOnSelect={false}
                                      isSearchable={false}
                                      options={categoryOptions}
                                      blurInputOnSelect={true}
                                      value={values.category}
                                      id="category"
                                      name="category"
                                      onChange={(event) => {
                                        handleCategory(event);
                                      }}
                                      onBlur={(event) => {
                                        handleBlur(event);
                                      }}
                                    />
                                  </div>
                                  {errors.category && touched.category && (
                                    <p className="form-error">
                                      {errors.category &&
                                        touched.category &&
                                        errors.category}
                                    </p>
                                  )}
                                </div>
                              </Col>
                              <Col lg={4} md={6} sm={12}>
                                <div className="form-row">
                                  <label className="form-label">
                                    Select Product Segment:
                                  </label>
                                  <div
                                    className={`input-group select-group ${
                                      errors.segment && touched.segment
                                        ? "input-error"
                                        : ""
                                    }`}
                                  >
                                    <Select
                                      className="w-100"
                                      closeMenuOnSelect={false}
                                      isSearchable={false}
                                      options={segmentOption}
                                      blurInputOnSelect={true}
                                      value={values.segment}
                                      id="segment"
                                      name="segment"
                                      onChange={(event) => {
                                        handleSegment(event);
                                      }}
                                      onBlur={(event) => {
                                        handleBlur(event);
                                      }}
                                    />
                                  </div>
                                  {errors.segment && touched.segment && (
                                    <p className="form-error">
                                      {errors.segment &&
                                        touched.segment &&
                                        errors.segment}
                                    </p>
                                  )}
                                </div>
                              </Col>
                              <Col lg={4} md={6} sm={12}>
                                <div className="form-row">
                                  <label className="form-label">
                                    Select offers:
                                  </label>
                                  <div
                                    className={`input-group select-group ${
                                      errors.offer && touched.offer
                                        ? "input-error"
                                        : ""
                                    }`}
                                  >
                                    <Select
                                      className="w-100"
                                      closeMenuOnSelect={false}
                                      isSearchable={false}
                                      options={offerOptions}
                                      blurInputOnSelect={true}
                                      value={values.offer}
                                      id="offer"
                                      name="offer"
                                      onChange={(event) => {
                                        handleOffers(event);
                                      }}
                                      onBlur={(event) => {
                                        handleBlur(event);
                                      }}
                                    />
                                  </div>
                                  {errors.offer && touched.offer && (
                                    <p className="form-error">
                                      {errors.offer &&
                                        touched.offer &&
                                        errors.offer}
                                    </p>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          )}
                          {formType.value === "bulk" && (
                            <Row className="mt-3">
                              <Col lg={4} md={6} sm={12}>
                                <div className="form-row">
                                  <label className="form-label">
                                    Select Product Series:
                                  </label>
                                  <div
                                    className={`input-group select-group ${
                                      errors.series && touched.series
                                        ? "input-error"
                                        : ""
                                    }`}
                                  >
                                    <Select
                                      className="w-100"
                                      closeMenuOnSelect={false}
                                      isMulti
                                      options={seriesOptions}
                                      value={values.series}
                                      id="series"
                                      name="series"
                                      blurInputOnSelect={true}
                                      onChange={(event) => {
                                        handleSeries(event);
                                      }}
                                      onBlur={(event) => {
                                        handleBlur(event);
                                      }}
                                    />
                                  </div>
                                  {errors.series && touched.series && (
                                    <p className="form-error">
                                      {errors.series &&
                                        touched.series &&
                                        errors.series}
                                    </p>
                                  )}
                                </div>
                              </Col>
                              <Col lg={4} md={6} sm={12}>
                                <div className="form-row">
                                  <label className="form-label">
                                    Select Dbt Status:
                                  </label>
                                  <div
                                    className={`input-group select-group ${
                                      errors.dbt_status && touched.dbt_status
                                        ? "input-error"
                                        : ""
                                    }`}
                                  >
                                    <Select
                                      className="w-100"
                                      closeMenuOnSelect={false}
                                      isSearchable={false}
                                      options={dbtOptions}
                                      blurInputOnSelect={true}
                                      value={values.dbt_status}
                                      id="dbt_status"
                                      name="dbt_status"
                                      onChange={(event) => {
                                        handleDbtStatus(event);
                                      }}
                                      onBlur={(event) => {
                                        handleBlur(event);
                                      }}
                                    />
                                  </div>
                                  {errors.dbt_status && touched.dbt_status && (
                                    <p className="form-error">
                                      {errors.dbt_status &&
                                        touched.dbt_status &&
                                        errors.dbt_status}
                                    </p>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          )}
                          {formType.value == "mobile" && (
                            <Row className="mt-3">
                              <Col lg={8}>
                                <div className="form-row">
                                  <Label
                                    className="form-label"
                                    htmlFor="mobile"
                                  >
                                    Mobile Number:
                                  </Label>
                                  <div
                                    className={`input-group w-100 select-group`}
                                  >
                                    <CreatableSelect
                                      className="w-100"
                                      isClearable
                                      isMulti
                                      menuIsOpen={false}
                                      onChange={(newValue) => {
                                        setMobile(newValue);
                                      }}
                                      onBlur={(event) => {
                                        handleBlur(event);
                                      }}
                                      onInputChange={(newValue) =>
                                        setInputMobile(newValue)
                                      }
                                      onKeyDown={handleKeyDown}
                                      placeholder="Type single mobile number or type comma separated mobile numbers and press enter..."
                                      value={values.mobile}
                                      name="mobile"
                                      id="mobile"
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col lg={4}>
                                <div className="form-row">
                                  <Label
                                    className="form-label"
                                    htmlFor="upload"
                                  >
                                    Or Upload CSV File{" "}
                                  </Label>
                                  <div className="input-group">
                                    <input
                                      id="upload"
                                      name="upload"
                                      type="file"
                                      accept=".csv"
                                      onChange={handleFileChange}
                                      className="form-control"
                                    />
                                  </div>
                                  {errors.file && (
                                    <p className="form-error">
                                      {errors.mobile && errors.file}
                                    </p>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          )}
                          <Row>
                            <Col lg={12}>
                              <div className="d-flex align-items-end justify-content-end">
                                {!loading && (
                                  <Button
                                    className="btn-warning mt-3 me-1"
                                    disabled={loading}
                                    onClick={() => {
                                      handleSubmit();
                                    }}
                                  >
                                    {isEdit ? "Update" : "Submit"}
                                  </Button>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </Formik>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddEditAudience;
