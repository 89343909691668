import React, { useState, useEffect } from "react";
import {
  // ToastContainer,
  toast,
} from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  FormGroup,
  Input,
  CardTitle,
} from "reactstrap";
import "./ViewExpiry.css";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ViewExpiryTable from "../../components/Table/ExpiryTable";
import viewExpiryService from "../../services/viewExpiry.service";
import viewTransactionService from "../../services/viewTransaction.service";
import ReportModal from "../../components/Modal/ReportModal";
import { showLoader, hideLoader } from "../../actions/loading";
import { useDispatch, useSelector } from "react-redux";
import Spinner, { SpinnerSmall } from "../../components/Loader/Spinner";
import moment from "moment";
import { DateRange, DateRangePicker } from "react-date-range";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
const ViewExpiry = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const role = user ? user.role_id : null;
  console.log("role", role);
  const loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isOpenfirstMonth, setIsOpenFirstmonth] = useState(false);
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  const initialDateRange = [
    // {
    //   startDate: new Date(moment().format("YYYY-MM-DD")),
    //   endDate: new Date(moment().format("YYYY-MM-DD")),
    //   key: "selection",
    // },
    {
      startDate: new Date(moment(currentDate)),
      endDate: new Date(moment(currentDate)),
      key: "selection",
    },
  ];

  const [disableDownload, setDisableDownload] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [totalCount, setTotalCount] = useState(null);
  const [nameMobile, setNameMobile] = useState("");
  const [reportName, setReportName] = useState("");
  const [dateRange, setDateRange] = useState(initialDateRange);
  // const [dateRange, setDateRange] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [state, setState] = useState("");
  const [stateOptions, setstateOptions] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [district, setDistrict] = useState("");
  const [districtOptions, setDistrictOptions] = useState([]);
  const [districtId, setDistrictId] = useState([]);
  const [dateFilters, setDateFilters] = useState({
    start_date: "",
    end_date: "",
  });

  const [modal, setModal] = useState(false);

  //modified Body data
  let bodyFilters = {
    // start_date: moment(dateRange[0].startDate).format("YYYY-MM-DD"),
    // end_date: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
    limit: pageSize,
    offset: currentPage,
  };

  if (nameMobile.length > 0) {
    if (parseInt(nameMobile) > 0) {
      bodyFilters.mobile = nameMobile;
    } else {
      bodyFilters.name = nameMobile;
    }
  }

  if (state.length > 0) {
    bodyFilters.state_id = stateId;
  }
  if (district.length > 0) {
    bodyFilters.district_id = districtId;
  }
  if (dateFilters.start_date.length > 0 && dateFilters.end_date.length > 0) {
    bodyFilters.start_date = dateFilters.start_date;
    bodyFilters.end_date = dateFilters.end_date;
  }

  //handleFunction
  const handledateRange = (item) => {
    setDateRange([item.selection]);
    setDateFilters((prevFilters) => ({
      // ...prevFilters,
      start_date: moment(item.selection.startDate).format("YYYY-MM-DD"),
      end_date: moment(item.selection.endDate).format("YYYY-MM-DD"),
    }));
  };

  const handleFirstMonthButtonState = () => {
    setIsOpenFirstmonth(!isOpenfirstMonth);
  };

  const handleDateRangeClose = () => {
    setIsOpenFirstmonth(false);
    // perform some action here
  };

  const handleExportExpiry = async () => {
    setModal(false);
    setDisableDownload(true);
    bodyFilters.report_name = reportName;
    setReportName("");
    const mechanicList = await viewExpiryService.exportViewExpiry(bodyFilters);

    if (mechanicList.code === 200) {
      setDisableDownload(false);
      toast.success(mechanicList.message, {
        autoClose: 2000,
      });
    } else {
      setDisableDownload(false);
      toast.error(mechanicList.message, {
        autoClose: 2000,
      });
    }
  };

  const handleStateChanges = async (e) => {
    const selectedState = stateOptions.find(
      (option) => option.value === e.target.value
    );
    if (selectedState) {
      setState(selectedState.value);
      setStateId(selectedState.id);
      setDistrict("");
      setDistrictId("");
      setDistrictOptions([]);
    } else {
      setState(""); // set state to empty string if "Select State" is selected
      setStateId("");
      setDistrict("");
      setDistrictId("");
      setDistrictOptions([]);
    }
  };
  const handleDistrictChanges = async (e) => {
    const selectedDistrict = districtOptions.find(
      (option) => option.value === e.target.value
    );
    if (selectedDistrict) {
      setDistrict(selectedDistrict.value);
      setDistrictId(selectedDistrict.district_id);
    } else {
      setDistrict("");
      setDistrictId("");
    }
  };
  const handleSubmit = async () => {
    setCurrentPage(1);
    setIsOpenFirstmonth(false);
    fetchViewExpiryDataAndCount();
  };
  const handleReset = async () => {
    setNameMobile("");
    setDistrict("");
    setDistrictId([]);
    setDistrictOptions([]);
    setState("");
    setstateOptions([]);
    setStateId([]);
    setDateFilters({
      start_date: "",
      end_date: "",
    });
    bodyFilters = {
      limit: pageSize,
      offset: currentPage,
    };
    setDateRange(initialDateRange);
    handleSubmit();
  };

  const toggleModal = async () => {
    setModal(!modal);
  };

  //calling API
  const fetchViewExpiryDataAndCount = async () => {
    dispatch(showLoader());

    const mechanicList = await viewExpiryService.GetExpiryList(bodyFilters);

    if (mechanicList.status === true) {
      setData(mechanicList.data.data);
      if (mechanicList.data.count > 0) {
        setTotalPages(mechanicList.data.pagination.totalPages);
        setTotalCount(mechanicList.data.count);
      } else {
        setTotalPages(0);
        setTotalCount(0);
      }
    } else if (mechanicList.status === false) {
      setData([]);
      setTotalPages(0);
      setTotalCount(0);
    }
    dispatch(hideLoader());
  };

  const fetchGetStates = async () => {
    const stateData = await viewTransactionService.getStates();
    const options = stateData.data.map((state) => ({
      value: state.name,
      label: state.name,
      id: state.id,
    }));
    setstateOptions(options);
  };
  const fetchGetDistricts = async () => {
    if (stateId > 0) {
      const params = {
        state_id: [stateId],
      };
      const districtData = await viewTransactionService.getDistricts(params);
      // if (districtData.status === true) {
      //   setData(transactionList.data.data);
      // } else if (transactionList.status === false) {
      //   setData([]);
      // }
      if (districtData.status === true) {
        const options = districtData.data.map((district) => ({
          district_id: district.id,
          value: district.name,
          label: district.name,
        }));
        setDistrictOptions(options);
      } else if (districtData.status === false) {
        toast.error("No district found.", {
          autoClose: 2000,
        });
      }
    } else {
      toast.error("please select state first. ", {
        autoClose: 2000,
      });
    }
  };

  const handlePage = (event) => {
    const buttonElement = event.target; // Your event target
    if (buttonElement.tagName === "BUTTON") {
      const pageNumber = buttonElement.textContent;
      console.log(pageNumber); // Output: "2"
      setCurrentPage(pageNumber);
    }
  };
  useEffect(() => {
    fetchViewExpiryDataAndCount();
  }, [currentPage]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="mb-3">
                  <div className="row table_top">
                    <Col lg={4} md={4} sm={5} xs={6}>
                      <CardTitle className="font-size-18">
                        View Expiry
                      </CardTitle>
                    </Col>
                    <Col
                      lg={7}
                      md={7}
                      sm={6}
                      xs={5}
                      className="total-count-class"
                    >
                      <div className="d-flex justify-content-end">
                        <span style={{ float: "left", paddingRight: "5px" }}>
                          Total count:
                        </span>
                        {totalCount === null ? (
                          <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                        ) : (
                          totalCount
                        )}
                      </div>
                    </Col>
                    {role === 16 || role === 22 ? null : (
                      <Col lg={1} md={1} sm={1}>
                        {" "}
                        <div
                          className="download-excel"
                          style={{ opacity: disableDownload ? 0.5 : 1 }}
                        >
                          <i
                            title={
                              disableDownload
                                ? "Preparing your file"
                                : "Export to Excel"
                            }
                            className="fa fa-download"
                            onClick={disableDownload ? null : toggleModal}
                            disabled={disableDownload}
                          />
                          <ReportModal
                            isOpen={modal}
                            toggle={toggleModal}
                            modalHeader="Expiry Report"
                            handleAPI={handleExportExpiry}
                            reportName={reportName}
                            setReportName={setReportName}
                          />
                        </div>
                      </Col>
                    )}
                  </div>
                  <div>
                    <Row className="mt-3">
                      <Col md={3}>
                        <h6>Search</h6>
                        <Input
                          type="search"
                          placeholder="Phone no. / Name"
                          id="search_name_mobile"
                          value={nameMobile}
                          onChange={(e) => {
                            setNameMobile(e.target.value);
                          }}
                        />
                      </Col>
                      <Col md={3}>
                        <h6>State Name</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={state}
                            onFocusCapture={fetchGetStates}
                            onChange={handleStateChanges}
                          >
                            <option value="">Select State</option>
                            {stateOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <h6>District Name</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={district}
                            onFocusCapture={fetchGetDistricts}
                            onChange={handleDistrictChanges}
                          >
                            <option value="">Select District</option>
                            {districtOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col lg={3} className="w-100 flex-1 mt-4">
                        <Button
                          className="btn btn-danger btn-secondary float-end"
                          onClick={handleReset}
                          // style={{ width: "74px" }}
                        >
                          Reset
                        </Button>{" "}
                        <Button
                          className="btn-warning float-end mx-2"
                          onClick={disableSubmit ? null : handleSubmit}
                          disabled={disableSubmit}
                        >
                          Search
                        </Button>{" "}
                      </Col>
                    </Row>
                    <Row className="mt-3 mb-2">
                      {/* <Col md={4}>
                        {isOpenfirstMonth === false ? (
                          <div>
                            <h6>Select Dates</h6>
                            <button
                              className="form-select"
                              onClick={handleFirstMonthButtonState}
                            >
                              {dateFilters.start_date && dateFilters.end_date
                                ? `${new Date(
                                    dateFilters.start_date
                                  ).toLocaleString("default", {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                  })} - ${new Date(
                                    dateFilters.end_date
                                  ).toLocaleString("default", {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                  })}`
                                : "select date range"}
                            </button>
                          </div>
                        ) : (
                          <div className="">
                            <label className="form-label">Select Dates: </label>
                            <div className="customDateOverlay">
                              <DateRange
                                editableDateInputs={true}
                                onChange={handledateRange}
                                moveRangeOnFirstSelection={false}
                                ranges={dateRange}
                                rangeColors={["#FCB92C", "#FCB92C", "#FCB92C"]}
                                showSelectionPreview={true}
                                shouldCloseOnSelect={true}
                                maxDate={currentDate}
                                minDate={new Date(moment("2021-01-01"))}
                                onClose={handleDateRangeClose}
                              />
                              <Button
                                className="btn-danger"
                                onClick={handleFirstMonthButtonState}
                              >
                                Close
                              </Button>
                              {/* <Button
                                className="btn-warning mx-2"
                                onClick={handleSubmit}
                              >
                                Search
                              </Button> */}
                      {/* </div> */}
                      {/* </div> */}
                      {/* )} */}
                      {/* </Col> */}
                      {/* </Row>
                    <Row className="mt-3 float-end"> */}
                      {/* <Col lg={3} className="w-100"> */}
                      {/* <Col lg={3} className="w-100 flex-1 mt-4">
                        <Button
                          className="btn btn-danger btn-secondary float-end"
                          onClick={handleReset}
                          // style={{ width: "74px" }}
                        >
                          Reset
                        </Button>{" "}
                        <Button
                          className="btn-warning float-end mx-2"
                          onClick={disableSubmit ? null : handleSubmit}
                          disabled={disableSubmit}
                        >
                          Search
                        </Button>{" "}
                      </Col> */}
                    </Row>
                  </div>
                </CardHeader>
                <CardBody className="">
                  <div className="">
                    <div className="row">
                      {loading === true ? (
                        <Spinner />
                      ) : (
                        <ViewExpiryTable data={data} />
                      )}

                      {totalCount === null ? (
                        <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                      ) : (
                        <>
                          {totalCount === 0 ? null : (
                            <div className="d-flex justify-content-center ">
                              <Stack spacing={2}>
                                <Pagination
                                  count={totalPages}
                                  hidePrevButton
                                  hideNextButton
                                  onClick={handlePage}
                                />
                              </Stack>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>{" "}
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ViewExpiry;
