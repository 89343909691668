import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  CardTitle,
  FormGroup,
  Input,
} from "reactstrap";
//Import Breadcrumb
import kycVerification from "../../services/kycVerification.service";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import { useSelector, useDispatch } from "react-redux";
import { DateRange } from "react-date-range";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import CustomModal1 from "../../components/Modal/kycVerificationModalRlpSo";
import { showLoader, hideLoader } from "../../actions/loading";
import {
  // ToastContainer,
  toast,
} from "react-toastify";
import moment from "moment";
import { SpinnerSmall } from "../../components/Loader/Spinner";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const KycVerificationSo = () => {
  const [data, setData] = useState([]);
  const [isOpenfirstMonth, setIsOpenFirstmonth] = useState(false);
  const loading = useSelector((state) => state.loading.loading);
  const [profileModal, setProfileModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [modalData, setModalData] = useState([]);
  const currentDate = new Date(); // current date
  const initialStartDate = moment(currentDate).subtract(29, "days").toDate(); // subtract 30 days from current date
  const initialDateRange = [
    {
      startDate: new Date(moment(initialStartDate)),
      endDate: new Date(moment(currentDate)),
      key: "selection",
    },
  ];

  const [dateFilters, setDateFilters] = useState({
    start_date: "",
    end_date: "",
  });

  const [dateRange, setDateRange] = useState(initialDateRange);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(null);
  const pageSize = 10;
  const [nameMobile, setNameMobile] = useState("");
  const [kycNumber, setKycNumber] = useState("");
  const [appStatus, setAppStatus] = useState("");
  const [state, setState] = useState("");
  const [stateOptions, setstateOptions] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [district, setDistrict] = useState("");
  const [districtOptions, setDistrictOptions] = useState([]);
  const [districtId, setDistrictId] = useState([]);
  const dispatch = useDispatch();
  let final_status = false;
  let body = {
    skip: currentPage,
    limit: pageSize,
  };
  const [appOptions, setAppOptions] = useState([
    { value: "", label: "All" },
    { value: "1", label: "KYC Verification Pending" },
    { value: "131", label: "Kyc reviewed by SO" },
    { value: "2", label: "KYC Verified" },
    { value: "3", label: "KYC Rejected" },
  ]);

  const handledateRange = (item) => {
    setDateRange([item.selection]);
    setDateFilters((prevFilters) => ({
      start_date: moment(item.selection.startDate).format("YYYY-MM-DD"),
      end_date: moment(item.selection.endDate).format("YYYY-MM-DD"),
    }));
  };

  if (nameMobile.length > 0) {
    if (parseInt(nameMobile) > 0) {
      body.mobile = nameMobile;
    }
  }
  if (kycNumber.length > 0) {
    body.kyc_number = kycNumber;
  }
  if (appStatus.length > 0) {
    body.status_id = appStatus;
  }
  if (state.length > 0) {
    body.state_id = stateId;
  }
  if (district.length > 0) {
    body.district_id = districtId;
  }
  if (dateFilters.start_date.length > 0 && dateFilters.end_date.length > 0) {
    body.start_date = dateFilters.start_date;
    body.end_date = dateFilters.end_date;
  }
  const submitDates = async () => {
    // setLoading(true);
    setModalData([]);
    setIsOpenFirstmonth(false);
    setCurrentPage(0);
    fetchViewKycData();

    // setLoading(false);
  };
  const fetchViewKycData = async () => {
    dispatch(showLoader());
    const redemption_data = await kycVerification.GetKycVerificationSO(body);
    if (redemption_data.status === true) {
      redemption_data.data.data.kyc.map((ele) => {
        var maxLength = 15;
        if (ele.sale_officer_remarks.length > maxLength) {
          ele.sale_officer_remarks1 =
            ele.sale_officer_remarks.substring(0, maxLength) + "...";
          ele.read = 1;
        } else {
          ele.sale_officer_remarks1 = ele.sale_officer_remarks;
          ele.read = 0;
        }
      });

      setData(redemption_data.data.data.kyc);
      setTotalCount(redemption_data.data.data.count);
      let tp = Math.ceil(redemption_data.data.data.count / pageSize);
      setTotalPages(tp);
    }
    dispatch(hideLoader());
  };

  const fetchGetStates = async () => {
    const stateData = await kycVerification.getStates();

    const options = stateData.data.state.map((state) => ({
      value: state.state_name,
      label: state.state_name,
      id: state.state_id,
    }));
    setstateOptions(options);
  };
  const fetchGetDistricts = async () => {
    if (stateId > 0) {
      const params = {
        state_id: [stateId],
      };
      const districtData = await kycVerification.getDistricts(params);
      if (districtData.status === true) {
        const options = districtData.data.data.map((district) => ({
          district_id: district.id,
          value: district.name,
          label: district.name,
        }));
        setDistrictOptions(options);
      } else if (districtData.status === false) {
        toast.error("No district found.", {
          autoClose: 2000,
        });
      }
    } else {
      toast.error("please select state first. ", {
        autoClose: 2000,
      });
    }
  };
  const profileToggleModal = () => {
    setProfileModal(!profileModal);
  };

  const fetchProfile = async (data) => {
    setModalData([]);
    if (
      data.status_id === 129 ||
      data.status_id === 131 ||
      data.status_id === 130
    ) {
      final_status = true;
    } else {
      final_status = false;
    }
    setModalData(data);
  };

  const Reset = () => {
    setDateRange(initialDateRange);
    setCurrentPage(0);
    setNameMobile("");
    setKycNumber("");
    setAppStatus("");
    setModalData([]);
    setDateFilters({
      start_date: "",
      end_date: "",
    });
    body = {
      limit: pageSize,
      skip: currentPage,
    };
    submitDates();
  };

  const handleFirstMonthButtonState = () => {
    setIsOpenFirstmonth(!isOpenfirstMonth);
  };

  const handleDateRangeClose = () => {
    setIsOpenFirstmonth(false);
  };
  const handleStateChanges = async (e) => {
    const selectedState = stateOptions.find(
      (option) => option.value === e.target.value
    );
    if (selectedState) {
      setState(selectedState.value);
      setStateId(selectedState.id);
      setDistrict("");
      setDistrictId("");
      setDistrictOptions([]);
    } else {
      setState(""); // set state to empty string if "Select State" is selected
      setStateId("");
      setDistrict("");
      setDistrictId("");
      setDistrictOptions([]);
    }
  };
  const handleDistrictChanges = async (e) => {
    const selectedDistrict = districtOptions.find(
      (option) => option.value === e.target.value
    );
    if (selectedDistrict) {
      setDistrict(selectedDistrict.value);
      setDistrictId(selectedDistrict.district_id);
    } else {
      setDistrict("");
      setDistrictId("");
    }
  };

  useEffect(() => {
    fetchViewKycData();
  }, [currentPage]);

  const handlePage = (event) => {
    const buttonElement = event.target; // Your event target
    if (buttonElement.tagName === "BUTTON") {
      const pageNumber = buttonElement.textContent;
      console.log(pageNumber); // Output: "2"
      setCurrentPage(pageNumber);
    }
  };
  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Row>
              <Col md={12}>
                <Card>
                  <CardHeader className="mb-3">
                    <div className="row table_top">
                      <Col lg={4} md={4} sm={5} xs={6}>
                        <CardTitle className="font-size-18">
                          VIEW KYC DETAILS
                        </CardTitle>
                      </Col>
                      <Col
                        lg={7}
                        md={7}
                        sm={6}
                        xs={5}
                        className="total-count-class"
                      >
                        <div className="d-flex justify-content-end">
                          <span style={{ float: "left", paddingRight: "5px" }}>
                            Total count:
                          </span>
                          {totalCount === null ? (
                            <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                          ) : (
                            totalCount
                          )}
                        </div>
                      </Col>
                    </div>
                    <div>
                      <Row className="mt-3">
                        <Col md={3}>
                          <h6>Status</h6>
                          <FormGroup className="mb-0">
                            <select
                              className="form-select"
                              value={appStatus}
                              onChange={(e) => setAppStatus(e.target.value)}
                            >
                              {appOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md={3}>
                          <h6>Search</h6>
                          <Input
                            type="search"
                            placeholder="Phone no."
                            id="search_name_mobile"
                            value={nameMobile}
                            onChange={(e) => {
                              setNameMobile(e.target.value);
                            }}
                          />
                        </Col>
                        <Col md={3}>
                          <h6>Kyc Number</h6>
                          <Input
                            type="search"
                            placeholder="Kyc Number"
                            id="search_name_mobile"
                            value={kycNumber}
                            onChange={(e) => {
                              setKycNumber(e.target.value);
                            }}
                          />
                        </Col>
                        <Col md={3}>
                          <h6>State Name</h6>
                          <FormGroup className="mb-0">
                            <select
                              className="form-select"
                              value={state}
                              onFocusCapture={fetchGetStates}
                              onChange={handleStateChanges}
                            >
                              <option value="">Select State</option>
                              {stateOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md={3}>
                          <h6>District Name</h6>
                          <FormGroup className="mb-0">
                            <select
                              className="form-select"
                              value={district}
                              onFocusCapture={fetchGetDistricts}
                              onChange={handleDistrictChanges}
                            >
                              <option value="">Select District</option>
                              {districtOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          {isOpenfirstMonth === false ? (
                            <div>
                              <h6>Select Dates</h6>
                              <button
                                className="form-select"
                                onClick={handleFirstMonthButtonState}
                              >
                                {dateFilters.start_date && dateFilters.end_date
                                  ? `${new Date(
                                      dateFilters.start_date
                                    ).toLocaleString("default", {
                                      day: "numeric",
                                      month: "short",
                                      year: "numeric",
                                    })} - ${new Date(
                                      dateFilters.end_date
                                    ).toLocaleString("default", {
                                      day: "numeric",
                                      month: "short",
                                      year: "numeric",
                                    })}`
                                  : "select date range"}
                              </button>
                            </div>
                          ) : (
                            <div className="customDateOverlay">
                              <label className="form-label">
                                Select Dates:{" "}
                              </label>
                              <div className="">
                                <DateRange
                                  editableDateInputs={true}
                                  onChange={handledateRange}
                                  moveRangeOnFirstSelection={false}
                                  ranges={dateRange}
                                  rangeColors={[
                                    "#FCB92C",
                                    "#FCB92C",
                                    "#FCB92C",
                                  ]}
                                  showSelectionPreview={true}
                                  shouldCloseOnSelect={true}
                                  maxDate={currentDate}
                                  minDate={new Date(moment("2016-01-01"))}
                                  onClose={handleDateRangeClose}
                                />
                                <Button
                                  className="btn-danger float-end mt-1"
                                  onClick={handleFirstMonthButtonState}
                                >
                                  Close
                                </Button>
                                <Button
                                  className="btn-warning float-end me-2 mt-1"
                                  onClick={submitDates}
                                >
                                  Search
                                </Button>
                              </div>
                            </div>
                          )}
                        </Col>
                        <Col md={3} className="mt-4">
                          <Button
                            className="btn btn-danger btn-secondary float-end"
                            onClick={Reset}
                            // style={{ width: "74px" }}
                          >
                            Reset
                          </Button>{" "}
                          <Button
                            className="btn-warning float-end mx-2"
                            onClick={submitDates}
                            disabled={loading === true ? true : false}
                          >
                            Search
                          </Button>{" "}
                        </Col>
                      </Row>
                      {loading === true ? (
                        <SpinnerSmall />
                      ) : (
                        <Row className="mt-3 float-end"></Row>
                      )}
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="row">
                      {/* <div className="row"> */}
                      {data.map((val, idx) => (
                        // <div className="col-md-2">
                        <div className="col-md-4">
                          <div className="projects-grid">
                            <Card shadow={1} style={{ minWidth: "400" }}>
                              <CardBody>
                                <div className="col-12">
                                  <div className="row">
                                    <div className="col-11">
                                      {val.status_id === 2 ? (
                                        <i
                                          className="ri-shield-star-fill icon-green d-flex justify-content-end"
                                          style={{ fontSize: "20px" }}
                                        ></i>
                                      ) : null}
                                      {val.status_id === 3 ? (
                                        <i
                                          className="ri-shield-cross-fill icon-red d-flex justify-content-end"
                                          style={{ fontSize: "20px" }}
                                        ></i>
                                      ) : null}
                                      {val.status_id === 6 ? (
                                        <i
                                          className="ri-scan-2-line align-middle mx-2 icon-blue d-flex justify-content-end"
                                          style={{ fontSize: "20px" }}
                                        ></i>
                                      ) : null}
                                    </div>
                                    <div className="col-1">
                                      <i
                                        className="ri-edit-box-fill align-middle mx-2 icon-red d-flex justify-content-end"
                                        style={{ fontSize: "20px" }}
                                        onClick={async () => {
                                          await fetchProfile(val);
                                          profileToggleModal();
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <p>
                                    <b style={{ fontSize: "16px" }}>Name: </b>{" "}
                                    {val.kyc_name}
                                  </p>
                                  <hr className="mb-1"></hr>
                                  <p>
                                    <b style={{ fontSize: "16px" }}>
                                      Mobile No:
                                    </b>{" "}
                                    {val.mobile}
                                  </p>
                                  <hr className="mb-1"></hr>
                                  <p>
                                    <b style={{ fontSize: "16px" }}>
                                      KYC Type:
                                    </b>{" "}
                                    {val.kyc_type}
                                  </p>
                                  <hr className="mb-1"></hr>
                                  <p>
                                    <b style={{ fontSize: "16px" }}>
                                      KYC Number:
                                    </b>{" "}
                                    {val.kyc_number}
                                  </p>
                                  <hr className="mb-1"></hr>
                                  <p>
                                    <b style={{ fontSize: "16px" }}>State:</b>{" "}
                                    {val.state_name}
                                  </p>
                                  <hr className="mb-1"></hr>
                                  <p>
                                    <b style={{ fontSize: "16px" }}>
                                      District:
                                    </b>{" "}
                                    {val.district_name}
                                  </p>
                                  <hr className="mb-1"></hr>
                                  <p>
                                    <b style={{ fontSize: "16px" }}>City:</b>{" "}
                                    {val.city_name}
                                  </p>
                                  <hr className="mb-1"></hr>
                                  <p>
                                    <b style={{ fontSize: "16px" }}>
                                      Distributer Name:
                                    </b>{" "}
                                    {val.distributor_name}
                                  </p>
                                  <hr className="mb-1"></hr>
                                  <p>
                                    <b style={{ fontSize: "16px" }}>
                                      Shop Name:
                                    </b>{" "}
                                    {val.shop_name}
                                  </p>
                                  <hr className="mb-1"></hr>
                                  <p>
                                    <b style={{ fontSize: "16px" }}>
                                      Point Balance:
                                    </b>{" "}
                                    {val.balance_points}
                                  </p>
                                  <hr className="mb-1"></hr>
                                  <p>
                                    <b style={{ fontSize: "16px" }}>
                                      Current Status:
                                    </b>{" "}
                                    {val.display_status}
                                  </p>
                                  <hr className="mb-1"></hr>
                                  <p>
                                    <b style={{ fontSize: "16px" }}>
                                      Sales Officer Status:
                                    </b>{" "}
                                    {val.so_status_dashboard_display_status}
                                  </p>
                                  <hr className="mb-1"></hr>
                                  <p>
                                    <b style={{ fontSize: "16px" }}>
                                      Sales Officer Remark:
                                    </b>{" "}
                                    {val.sale_officer_remarks1}.{" "}
                                    {val.read === 1 ? (
                                      <Popup
                                        trigger={
                                          <a
                                            class="read_more"
                                            data-toggle="modal"
                                          >
                                            Read More
                                          </a>
                                        }
                                        position="right center"
                                      >
                                        <div>{val.sale_officer_remarks}</div>
                                      </Popup>
                                    ) : null}
                                  </p>
                                  <hr></hr>
                                  <p>
                                    <b>Kyc Upload Date:</b>
                                    {val.date}
                                  </p>
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                        </div>
                        // </div>
                      ))}
                      {/* </div> */}
                      {totalCount === null ? (
                        <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                      ) : (
                        <>
                          <div className="d-flex justify-content-center ">
                            <Stack spacing={2}>
                              <Pagination
                                count={totalPages}
                                hidePrevButton
                                hideNextButton
                                onClick={handlePage}
                              />
                            </Stack>
                          </div>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={4} xl={3}>
                <CustomModal1
                  isOpen={profileModal}
                  toggle={profileToggleModal}
                  user_id={userId}
                  onConfirm={() => console.log("Confirmed!")}
                  confirmLabel="Confirm"
                  data={modalData}
                  final_status={final_status}
                ></CustomModal1>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};
export default KycVerificationSo;
