/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

// Redux
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Label,
  CardTitle,
  Card,
  CardHeader,
  CardBody,
  Input,
  Table
} from "reactstrap";

import { Formik } from "formik";

import { toast } from "react-toastify";
import Select from "react-select";
import moment from "moment";
import campaignSer from "../../../../services/campaignService";
import ExcelJS from "exceljs";
// import images
import "react-toastify/dist/ReactToastify.css";

// import css
import "../../ConsumptionPayout.scss";
import { campaign_programs} from "../../../../Config/config";
import { SpinnerSmall } from "../../../../components/Loader/Spinner";

function GeographyWisePincodeScheme(props) {
  const routeParams = props.match.params;
  const program_id = routeParams.programId;
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();
  if (
    !routeParams ||
    campaign_programs.includes(routeParams.programId) == false
  ) {
    history.goBack();
  }
  const [budgetUsed, setBudgetUsed] = useState(0);
  const [frequencyButton, setFrequencyButton] = useState(false);
  const [dayWiseButton, setdayWiseButton] = useState(false);
  const [buttonDisabled, setButtonDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [csvFile, setCSVFile] = useState(null);
  const [isEdit, setIsEdit] = useState(routeParams.id ? true : false);
  const [editDetails, setEditVal] = useState({});

  const [pincode, setPincode] = useState([]);
  const [inputPincode, setInputPincode] = useState("");
  const [segment, setSegment] = useState("ALL");
  const [segmentOption, setSegmentOption] = useState([
    { value: "Select", label: "Select" },
  ]);
  const [offerStatus, setOfferStatus] = useState("");
  const [defaultPayoutRs, setDefaultPayoutRs] = useState("0");
  const [defaultPayoutPoints, setDefaultPayoutPoints] = useState("0");
  const [bonusPayoutRs, setBonusPayoutRs] = useState("");
  const [bonusPayoutPoints, setBonusPayoutPoints] = useState("");
  const [numberOfCoupon, setNumberOfCoupon] = useState("");
  const [numberOfTimesCouponAvail, setNumberOfTimesCouponAvail] = useState("");
  const [tempSegment, setTempSegment] = useState("");
  const [series, setSeries] = useState([]);
  const [seriesOption, setSeriesOptions] = useState([]);
  const [budget, setBudget] = useState("");
  const [totalRupees, setTotalRupees] = useState("");
  const [totalPoints, setTotalPoints] = useState("");
  const [existData, setExistData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const stackingOption = [
    { value: 1, label: "ON" },
    { value: 0, label: "OFF" },
  ];
  const [stacking, setStacking] = useState(stackingOption[0]);

  const restrictionOption = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  const vehicleSegmentOption = [
    { value: 1, label: "BIKE AND SCOOTER" },
    { value: 2, label: "AUTO" },
    { value: 3, label: "CAR" },
    { value: 5, label: "TRUCK" },
    { value: 6, label: "TRACTOR" },
  ];
  const [vehicleSegment, setVehicleSegment] = useState("");
  const [restriction, setRestriction] = useState(restrictionOption[1]);
  const [bonusOnceADay, setBonusOnceADay] = useState(restrictionOption[1]);
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [tempStartDate, setTempStartDate] = useState(
    moment(new Date()).add(1, "days").format("YYYY-MM-DD")
  );
  // const [endDate, setEndDate] = useState(
  //   moment(new Date()).add(1, "days").format("YYYY-MM-DD")
  // );
  const [tempEndDate, setTempEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [limitPack, setLimitPack] = useState("");
  const [tempPack, setTempPack] = useState("");
  const [radioValue, setRadioValue] = useState("DRAFT");
  const [pincodeNumbers, setPincodeNumbers] = useState([]);
  const [pincodeInput, setPincodeInput] = useState("");
  const [error, setError] = useState("");
  const [limitBonus, setLimitBonus] = useState();
  const [dayWiseList, setdayWiseList]=useState([
    {
      id: 0,
      name: "Sunday",
      value:false
    },
    {
      id: 1,
      name: "Monday",
      value:false
    },
    {
      id: 2,
      name: "Tuesday",
      value:false
    },
    {
      id: 3,
      name: "Wednesday",
      value:false
    },
    {
      id: 4,
      name: "Thursday",
      value:false
    },
    {
      id: 5,
      name: "Friday",
      value:false
    },
    {
      id: 6,
      name: "Saturday",
      value:false
    },
  ])
  const [dayWise, setdayWise] = useState({"Sunday":false,"Monday":false,"Tuesday":false,"Wednesday":false,"Thursday":false,"Friday":false,"Saturday":false});
  const multiplyPointOption = [
    { value: 0, label: "Select" },
    { value: 1, label: "1X" },
    { value: 2, label: "2X" },
    { value: 3, label: "3X" },
    { value: 4, label: "4X" },
    { value: 5, label: "5X" },
    { value: 6, label: "6X" },
    { value: 7, label: "7X" },
    { value: 8, label: "8X" },
    { value: 9, label: "9X" },
    { value: 10, label: "10X" },
  ];
  const multiplyRupeesOption = [
    { value: 0, label: "Select" },
    { value: 1, label: "1X" },
    { value: 2, label: "2X" },
    { value: 3, label: "3X" },
    { value: 4, label: "4X" },
    { value: 5, label: "5X" },
    { value: 6, label: "6X" },
    { value: 7, label: "7X" },
    { value: 8, label: "8X" },
    { value: 9, label: "9X" },
    { value: 10, label: "10X" },
  ];
  const [multiplyPoint, setmultiplyPoint] = useState(multiplyRupeesOption[0]);
  const [multiplyRupees, setmultiplyRupees] = useState(multiplyPointOption[0]);
  let setFieldValueFunction = null;

  const handleSelectFieldObject = (setFieldValue) => {
    setFieldValueFunction = setFieldValue;
  };
  const downloadSampleXLSX = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sample Data");
    worksheet.columns = [{ header: "pincode", key: "pincode" }];

    // Add sample data
    worksheet.addRow({ pincode: "400001" });

    // Generate XLSX file
    const blob = await workbook.xlsx.writeBuffer();

    // Save and trigger the download
    const blobUrl = window.URL.createObjectURL(
      new Blob([blob], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = "data.xlsx";
    a.click();

    // Clean up resources
    window.URL.revokeObjectURL(blobUrl);
  };
  const updateValues = async (data) => {
    let bonusPoints = parseInt(bonusPayoutPoints * 0.5);
    let minimumBudget = parseInt(bonusPayoutRs, 10) + parseInt(bonusPoints, 10);
    if (data.budget < minimumBudget) {
      toast.error(`Minimum budget must be greater than ${minimumBudget}`);
      return;
    }
    if (limitBonus !== "" && limitBonus == 0) {
      toast.error("Limit bonus per day must be greater than zero");
      return;
    }
    if (limitBonus && numberOfTimesCouponAvail) {
      if (limitBonus > numberOfTimesCouponAvail) {
        toast.error(
          "Limit bonus per day must be less than number of time mechanic can avail coupon"
        );
        return;
      }
    }
    const startMoment = moment(data.start_date, "YYYY-MM-DD");
    const endMoment = moment(data.end_date, "YYYY-MM-DD");
    // if (
    //   startMoment.isBefore(
    //     moment(new Date()).add(1, "days").format("YYYY-MM-DD")
    //   ) ||
    //   endMoment.isBefore(moment(new Date()).add(1, "days").format("YYYY-MM-DD"))
    // ) {
    //   toast.error(
    //     `Please select date greater than ${moment().format("YYYY-MM-DD")}`
    //   );
    //   return;
    // }
    // Compare the dates
    if (endMoment.isBefore(startMoment)) {
      // Raise an error or handle the error as needed
      toast.error("End date is before the start date");
      return;
    }
    if (pincodeNumbers.length == 0 && !csvFile) {
      toast.error("Please enter pincode number or upload a file", {
        autoClose: 2000,
      });
      return;
    }
    if (budgetUsed > budget) {
      toast.error("Budget must be greater then budget used!");
      return;
    }
    const tempstartdate = moment(data.start_date, "YYYY-MM-DD").unix();
    const tempenddate = moment(data.end_date, "YYYY-MM-DD").unix();
    setLoading(true);
    let pack;
    if (routeParams.programId == 1) {
      const d2 =
        parseInt(bonusPayoutPoints, 10) * 0.5 + parseInt(bonusPayoutRs, 10);
      const denominator = d2;
      pack = parseInt(budget, 10) / denominator;
      console.log(bonusPayoutPoints, bonusPayoutRs, d2, pack);
      setLimitPack(pack);
    } else {
      const d2 = parseInt(bonusPayoutPoints, 10) * 0.5;
      const denominator = d2;
      pack = parseInt(budget, 10) / denominator;
    }
    let formData = new FormData();
    // formData.append("pack", limitPack);
    formData.append("title", title);
    formData.append("limitBonus", data.limitBonus);
    formData.append("stacking", data.stacking.value);
    formData.append("program_id", routeParams.programId);
    formData.append(
      "start_date",
      moment.unix(tempstartdate).utcOffset("+05:30").unix()
    );
    formData.append(
      "end_date",
      moment.unix(tempenddate).utcOffset("+05:30").unix()
    );
    formData.append("defaultPayoutRs", data.defaultPayoutRs);
    formData.append("defaultPayoutPoints", data.defaultPayoutPoints);
    formData.append("bonusPayoutRs", data.bonusPayoutRs);
    formData.append("bonusPayoutPoints", data.bonusPayoutPoints);
    formData.append("cash_multiplier", data.multiplyRupees.value);
    formData.append("points_multiplier", data.multiplyPoint.value);
    formData.append("numberOfCoupon", data.numberOfCoupon);
    formData.append("numberOfTimesCouponAvail", data.numberOfTimesCouponAvail);
    formData.append("restriction", data.restriction.value.toLowerCase());
    formData.append("limitPack", pack);
    formData.append("segment", data.segment.id);
    formData.append("offer_id", routeParams.id);
    formData.append("status", offerStatus);
    formData.append("frequency", frequencyButton === true ? "1" : "0");
    formData.append("campaign_budget", parseInt(data.budget));
    formData.append("dayWise",dayWiseButton === true ? JSON.stringify(dayWise) : '');
    const bonus_a_day =
      data.bonusOnceADay?.value.toLowerCase() === "yes" ? "1" : "0";
    formData.append("bonusOnceADay", bonus_a_day);
    let series = [];
    for (let i = 0; i < data.product_series.length; i++) {
      series.push(
        data.product_series[i].series_id
          ? data.product_series[i].series_id
          : data.product_series[i].value
      );
    }
    formData.append("series", JSON.stringify(series));
    formData.append("bonusType", routeParams.bonusType);
    if (csvFile) {
      formData.append("excel", csvFile);
    } else {
      let pincodes = [];
      for (let i = 0; i < pincodeNumbers.length; i++) {
        pincodes.push({ pincode: pincodeNumbers[i] });
      }
      formData.append("pincodes", JSON.stringify(pincodes));
    }
    setLoading(true);
    await campaignSer.updateCampaignPincode(formData).then((response) => {
      setLoading(false);
      if (response.status) {
        setLoading(false);
        toast.success(
          !isEdit
            ? "Offer created successfully."
            : "Offer updated successfully."
        );
        if (routeParams.programId == 1) {
          history.push("/eni-club-listing");
        } else {
          history.push("/eni-vistaar-listing");
        }
        setLoading(false);
      }
      if (response?.status === false && response?.code === 409) {
        setLoading(false);
        setExistData(response.data);
        setShowTable(true);
      }
    });
    setLoading(false);
  };
  const submitValuetoApi = async (data) => {
    let bonusPoints = parseInt(bonusPayoutPoints * 0.5);
    let minimumBudget = parseInt(bonusPayoutRs, 10) + parseInt(bonusPoints, 10);
    if (data.budget < minimumBudget) {
      toast.error(`Minimum budget must be greater than ${minimumBudget}`);
      return;
    }
    if (limitBonus !== "" && limitBonus == 0) {
      toast.error("Limit bonus per day must be greater than zero");
      return;
    }
    if (
      numberOfTimesCouponAvail.trim() !== "" &&
      numberOfTimesCouponAvail == 0
    ) {
      toast.error(
        "Number of times mechanic can avail coupon must be greater than zero if selected"
      );
      return;
    }
    if (limitBonus && numberOfTimesCouponAvail) {
      if (limitBonus > numberOfTimesCouponAvail) {
        toast.error(
          "Limit bonus per day must be less than number of time mechanic can avail coupon"
        );
        return;
      }
    }
    const startMoment = moment(data.start_date, "YYYY-MM-DD");
    const endMoment = moment(data.end_date, "YYYY-MM-DD");
    // Compare the dates
    if (
      startMoment.isBefore(
        moment(new Date()).add(1, "days").format("YYYY-MM-DD")
      ) ||
      endMoment.isBefore(moment(new Date()).add(1, "days").format("YYYY-MM-DD"))
    ) {
      toast.error(
        `Please select date greater than ${moment().format("YYYY-MM-DD")}`
      );
      return;
    }
    if (endMoment.isBefore(startMoment)) {
      // Raise an error or handle the error as needed
      toast.error("End date is before the start date");
      return;
    }
    if (pincodeNumbers.length == 0 && !csvFile) {
      toast.error("Please enter pincode number or upload a file", {
        autoClose: 2000,
      });
      return;
    }
    const tempstartdate = moment(data.start_date, "YYYY-MM-DD").unix();
    const tempenddate = moment(data.end_date, "YYYY-MM-DD").unix();
    setLoading(true);
    let formData = new FormData();
    formData.append("pack", limitPack);
    formData.append("title", title);
    if (vehicleSegment.length !== 0) {
      let vehicle_id = [];
      for (let i = 0; i < data.vehicleSegment.length; i++) {
        vehicle_id.push(data.vehicleSegment[i].value);
      }
      formData.append("vehicle_segment", vehicle_id);
    }

    // if (vehicleSegment) {
    //   formData.append("vehicle_segment", data.vehicleSegment.value);
    // }
    formData.append("status", radioValue);
    formData.append("form_type", "pincode_wise");
    formData.append("campaign_budget", parseInt(data.budget));
    formData.append("stacking", data.stacking.value);
    formData.append("program_id", routeParams.programId);
    formData.append(
      "start_date",
      moment.unix(tempstartdate).utcOffset("+05:30").unix()
    );
    formData.append(
      "end_date",
      moment.unix(tempenddate).utcOffset("+05:30").unix()
    );
    formData.append("defaultPayoutRs", data.defaultPayoutRs);
    formData.append("defaultPayoutPoints", data.defaultPayoutPoints);
    formData.append("bonusPayoutRs", data.bonusPayoutRs);
    formData.append("bonusPayoutPoints", data.bonusPayoutPoints);
    formData.append("numberOfCoupon", data.numberOfCoupon);
    formData.append("cash_multiplier", data.multiplyRupees.value);
    formData.append("points_multiplier", data.multiplyPoint.value);
    formData.append("numberOfTimesCouponAvail", data.numberOfTimesCouponAvail);
    formData.append("restriction", data.restriction.value.toLowerCase());
    const bonus_a_day =
      data.bonusOnceADay?.value.toLowerCase() === "yes" ? "1" : "0";
    formData.append("bonusOnceADay", bonus_a_day);
    formData.append("limitBonus", data.limitBonus);
    formData.append("limitPack", limitPack);
    formData.append("segment", data.segment.id);
    formData.append("dayWise",dayWiseButton === true ? JSON.stringify(dayWise) : '');
    let series = [];
    for (let i = 0; i < data.product_series.length; i++) {
      series.push(data.product_series[i].series_id);
    }
    formData.append("frequency", frequencyButton === true ? "1" : "0");
    formData.append("series", JSON.stringify(series));

    formData.append("bonusType", routeParams.bonusType);
    if (csvFile) {
      formData.append("excel", csvFile);
    } else {
      let pincodes = [];
      for (let i = 0; i < pincodeNumbers.length; i++) {
        pincodes.push({ pincode: pincodeNumbers[i] });
      }
      formData.append("pincodes", JSON.stringify(pincodes));
    }
    setLoading(true);
    await campaignSer.savePinCodeWiseCampaign(formData).then((response) => {
      if (response?.status) {
        setLoading(false);
        toast.success(
          !isEdit
            ? "Offer created successfully."
            : "Offer updated successfully."
        );
        if (routeParams.programId == 1) {
          history.push("/eni-club-listing");
        } else {
          history.push("/eni-vistaar-listing");
        }
      }
      if (response?.status === false && response?.code === 409) {
        setLoading(false);
        setExistData(response.data);
        setShowTable(true);
      }
    });
    setLoading(false);
  };

  const handleSegment = async (event) => {
    setSeries([]);
    setFieldValueFunction("product_series", []);
    setFieldValueFunction("segment", event);
    setTempSegment(event.id);
    const options = await campaignSer.GetSegmentSeriesList({
      id: event.id,
      program_id: program_id,
    });
    if (options.status) {
      setSeriesOptions(options?.data);
    }
  };

  const handleSeries = async (event) => {
    if (event.length === 0 && isEdit==true) {
      const options = await campaignSer.GetSegmentSeriesList({
        id: segment.id,
        program_id: routeParams.programId,
      });
      if (options.status) {
        setSeriesOptions(options?.data);
      }
    }
    // if (event.length === 1) {
    //   const filteredArr = seriesOption.filter(
    //     (item) =>           
    //       item.points === event[0].points && item.rupees === event[0].rupees
    //   );
    //   setSeriesOptions(filteredArr);
    //   setDefaultPayoutRs(parseInt(event[0].rupees));
    //   setFieldValueFunction("defaultPayoutRs", parseInt(event[0].rupees));
    //   setDefaultPayoutPoints(parseInt(event[0].points));
    //   setFieldValueFunction("defaultPayoutPoints", parseInt(event[0].points));
    // } else 
    if (event.length === 0) {
      setDefaultPayoutRs("0");
      setFieldValueFunction("defaultPayoutRs", "0");
      setDefaultPayoutPoints("0");
      setFieldValueFunction("defaultPayoutPoints", "0");
      // await campaignSer
      //   .GetSegmentSeriesList({
      //     id: tempSegment,
      //     program_id: program_id,
      //   })
      //   .then((response) => {
      //     if (response.status === true) {
      //       setSeriesOptions(response?.data);
      //     }
      //   });
    }
    setFieldValueFunction("product_series", event);
    setSeries(event);
    await setPayoutData(event);
  };

  const setPayoutData = async (event) => {
    console.log("series",event);
    let ids = event.map((obj) => obj.points);
    let data = ids.length !== 0 && new Set(ids).size !== 1;
    console.log("data---",data);
    if(event.length > 0) {
      if(data == false) {
        setDefaultPayoutRs(parseInt(event[0].rupees));
        setFieldValueFunction("defaultPayoutRs", parseInt(event[0].rupees));
        setDefaultPayoutPoints(parseInt(event[0].points));
        setFieldValueFunction("defaultPayoutPoints", parseInt(event[0].points));
      } else {
        setDefaultPayoutRs("0");
        setFieldValueFunction("defaultPayoutRs", "0");
        setDefaultPayoutPoints("0");
        setFieldValueFunction("defaultPayoutPoints", "0");
      }
    }
  }
  const handleStacking = (event) => {
    setStacking(event.value);
    setFieldValueFunction("stacking", event);
  };
  const handleRestriction = (event) => {
    setRestriction(event);
    setFieldValueFunction("restriction", event);
  };
  const handleBonusOnceADay = (event) => {
    setBonusOnceADay(event.value);
    setFieldValueFunction("bonusOnceADay", event);
  };
  const updateStatus = async (action) => {
    let response = await campaignSer.updateCampaign(action, routeParams.id);
    if (response.status) {
      toast.success(
        action === "INACTIVE"
          ? "Campaign inactivated successfully."
          : "Campaign Published Successfully."
      );
      if (program_id == 1) {
        history.push("/eni-club-listing");
      } else {
        history.push("/eni-vistaar-listing");
      }
    } else {
      toast.error("Something went wrong.");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setCSVFile(file);
  };

  const addPincode = () => {
    if (pincodeInput) {
      const numbers = pincodeInput.split(",").map((number) => number.trim());
      // Validate mobile numbers
      if (numbers.length > 10) {
        setError("You can only add a maximum of 10 pincode numbers.");
      }
      //  else if (numbers.some((number) => !/^[1-9]\d{6}$/.test(number))) {
      //   toast.error("Please enter valid pincode.");
      // }
      else {
        const newNumbers = [...new Set([...pincodeNumbers, ...numbers])];
        if (newNumbers.length > 10) {
          toast.error("Only 10 pincode numbers are allowed");
          return;
        }
        setPincodeNumbers(newNumbers);
        setError("");
      }
    }
  };

  const handleNumber = (index) => {
    const updatedMobileNumbers = [...pincodeNumbers];
    updatedMobileNumbers.splice(index, 1);
    setPincodeNumbers(updatedMobileNumbers);
    setInputPincode("");
  };
  const removeFile = () => {
    setCSVFile(null);
    const inputElement = document.getElementById("upload");
    if (inputElement) {
      inputElement.value = ""; // Clear the file input value
    }
  };

  const handleVehicleSegment = async (event) => {
    setFieldValueFunction("vehicleSegment", event);
    setVehicleSegment(event);
  };
  const changeDay = async  (event,index,ele) => {
    console.log("dayWise--",dayWise);
   console.log("event---",event.target.value,index,event.target.checked);
   console.log("data---",dayWise[ele.name]);
   dayWise[ele.name] = event.target.checked;
   dayWiseList[index]['value'] = event.target.checked;
   setdayWise(dayWise);
   setdayWiseList(dayWiseList);
   setFieldValueFunction("dayWiseList",dayWiseList);
  };

  const handleMultiplyRupees = (event) => {
    // setStacking(event.value);
    setFieldValueFunction("multiplyRupees", event);
  };
  const handleMultiplyPoint = (event) => {
    // setStacking(event.value);
    setFieldValueFunction("multiplyPoint", event);
  };
  useEffect(async () => {
    const segmentoptions = await campaignSer.GetSegmentList();
    if (segmentoptions?.status) {
      setSegmentOption(segmentoptions?.data);
    }
    if (isEdit) {
      let params = {
        program_id: routeParams.programId,
        offer_id: routeParams.id,
      };
      let details = await campaignSer.GetOfferDetails(params);
      let editDetails = details.data.offerDetails;
      let selectSegment = segmentoptions.data.find(
        (item) => item.id === editDetails.segment
      );
      const options = await campaignSer.GetSegmentSeriesList({
        id: editDetails.segment,
        program_id: routeParams.programId
      });
      if (options.status) {
        setSeriesOptions(options?.data);
      }
      setEditVal(editDetails);
      setSegment(selectSegment);
      let multiplyCash = multiplyRupeesOption.find(
        (item) => item.value === editDetails.cash_multiplier
      );
      let multiplypoint = multiplyPointOption.find(
        (item) => item.value === editDetails.points_multiplier
      );
      setmultiplyRupees(multiplyCash);
      setFieldValueFunction(
        "multiplyRupees",
        multiplyCash
      );
      setmultiplyPoint(multiplypoint);
      setFieldValueFunction(
        "multiplyPoint",
        multiplypoint
      );
      if(editDetails.dayWise) {
        setdayWiseButton(true);
        // setFieldValueFunction("dayWiseButton",true);
        console.log("dayWiseButton----",dayWiseButton);
        let dayWiseData = JSON.parse(editDetails.dayWise);
        console.log("dayWise---",dayWiseData);
        setdayWise(dayWiseData);
        setFieldValueFunction("dayWise",dayWiseData);
        console.log("true data,",dayWiseData['Sunday']);
        await Promise.all(dayWiseList.map(ele => {
          console.log("ele--", ele);
          console.log("data===",ele.value,typeof ele.name,dayWiseData[ele.name]);
          ele.value = dayWiseData[ele.name];
        }))
        setFieldValueFunction("dayWiseList",dayWiseList);
        setdayWiseList(dayWiseList);
        console.log("dayWise List",dayWiseList);
        console.log("dayWise---",dayWise);
      }
      if (details.data.file_path) {
      } else {
        setPincode(details.data.pinodeData);
        setPincodeNumbers(details.data.pinodeData?.map((obj) => obj.value));
        setInputPincode("");
        setFieldValueFunction("pincode", details.data.pinodeData);
      }
      setTempPack(editDetails.pack);
      setDefaultPayoutRs(editDetails.defaultPayoutRs);
      setFieldValueFunction("defaultPayoutRs", editDetails.defaultPayoutRs);
      setDefaultPayoutPoints(editDetails.defaultPayoutPoints);
      setFieldValueFunction(
        "defaultPayoutPoints",
        editDetails.defaultPayoutPoints
      );
      setBonusPayoutPoints(editDetails.bonusPayoutPoints);
      setFieldValueFunction("bonusPayoutPoints", editDetails.bonusPayoutPoints);
      setBonusPayoutRs(Number(editDetails.bonusPayoutRs));
      setFieldValueFunction("bonusPayoutRs", Number(editDetails.bonusPayoutRs));
      setBudget(editDetails.budget);
      setFieldValueFunction("budget", editDetails.budget);
      setFieldValueFunction("numberOfCoupon", editDetails.numberOfCoupon);
      setNumberOfCoupon(editDetails.numberOfCoupon);
      setNumberOfTimesCouponAvail(editDetails.numberOfTimesCouponAvail);
      setFieldValueFunction(
        "numberOfTimesCouponAvail",
        editDetails.numberOfTimesCouponAvail
      );
      setLimitBonus(editDetails.limitBonus);
      setFieldValueFunction("limitBonus", editDetails.limitBonus);
      setRestriction(
        editDetails.restriction === 1
          ? { value: "Yes", label: "Yes" }
          : { value: "No", label: "No" }
      );
      setFieldValueFunction(
        "restriction",
        editDetails.restriction === 1
          ? { value: "Yes", label: "Yes" }
          : { value: "No", label: "No" }
      );
      setLimitPack(editDetails.limitPack);

      setFieldValueFunction("limitPack", editDetails.limitPack);
      setStacking(
        editDetails.stacking == 1
          ? { value: "1", label: "ON" }
          : { value: "0", label: "OFF" }
      );
      setFieldValueFunction(
        "stacking",
        editDetails.stacking == 1
          ? { value: "1", label: "ON" }
          : { value: "0", label: "OFF" }
      );
      setStartDate(moment(editDetails.start_date).format("YYYY-MM-DD"));
      setTempStartDate(moment(editDetails.start_date).format("YYYY-MM-DD"));
      setFieldValueFunction(
        "start_date",
        moment(editDetails.start_date).format("YYYY-MM-DD")
      );
      setEndDate(moment(editDetails.end_date).format("YYYY-MM-DD"));
      setTempEndDate(moment(editDetails.end_date).format("YYYY-MM-DD"));
      setFieldValueFunction(
        "end_date",
        moment(editDetails.end_date).format("YYYY-MM-DD")
      );
      setBudgetUsed(editDetails.budget_used);
      setSeries(details.data.series);
      setFieldValueFunction("product_series", details.data.series);
      setFieldValueFunction("segment", selectSegment);
      setSegment(selectSegment);
      setOfferStatus(editDetails.status);
      const total_rupees =
        parseInt(editDetails.defaultPayoutRs) +
        parseInt(editDetails.bonusPayoutRs);
      const total_points =
        parseInt(editDetails.defaultPayoutPoints) +
        parseInt(editDetails.bonusPayoutPoints);
      setTotalPoints(total_points);
      setTotalRupees(total_rupees);
      if (editDetails.frequency == "1") {
        setFrequencyButton(true);
      } else {
        setButtonDisable(true);
      }
      setRestriction(
        editDetails.restriction == "1"
          ? restrictionOption[0]
          : restrictionOption[1]
      );
      setFieldValueFunction(
        "restriction",
        editDetails.restriction == "1"
          ? restrictionOption[0]
          : restrictionOption[1]
      );
      setBonusOnceADay(
        editDetails.bonusOnceADay == "1"
          ? restrictionOption[0]
          : restrictionOption[1]
      );
      setFieldValueFunction(
        "bonusOnceADay",
        editDetails.bonusOnceADay == "1"
          ? restrictionOption[0]
          : restrictionOption[1]
      );
      setTitle(editDetails.title);
      setFieldValueFunction("title", editDetails.title);
      setSeries(details.data.trading);
      setFieldValueFunction("vehicleSegment", details.data.trading);
      const budget_diff = editDetails.budget - editDetails.budget_used;
      const denominator =
        editDetails.bonusPayoutRs + editDetails.bonusPayoutPoints / 2;
      if (editDetails.budget_used != "" && budget_diff < denominator) {
        setTempPack(true);
      }
    }
  }, []);

  useEffect(() => {
    if (pincode?.length <= 10) {
      setFieldValueFunction("pincode", pincode);
    } else {
      toast.error("Maximum 10 pincode numbers are allowed");
    }
  }, [pincode]);
  useEffect(() => {
    if (routeParams.programId == 5) {
      const d2 = bonusPayoutPoints * 0.5;
      const denominator = d2;
      const pack = parseInt(budget, 10) / denominator;
      const total_points =
        parseInt(defaultPayoutPoints) + parseInt(bonusPayoutPoints);
      setLimitPack(parseInt(pack));
      setTotalPoints(total_points);
    } else {
      const d2 = bonusPayoutPoints * 0.5 + parseInt(bonusPayoutRs, 10);
      const denominator = d2;
      const pack = parseInt(budget, 10) / denominator;
      const total_rupees = parseInt(defaultPayoutRs) + parseInt(bonusPayoutRs);
      setTotalRupees(total_rupees);
      const total_points =
        parseInt(defaultPayoutPoints) + parseInt(bonusPayoutPoints);
      setLimitPack(parseInt(pack));
      setTotalPoints(total_points);
    }
  }, [budget, bonusPayoutPoints, bonusPayoutRs]);
  useEffect(() => {
    if (routeParams.programId == 5) {
      const d2 = parseInt(bonusPayoutPoints, 10) * 0.5;
      const denominator = d2;
      const pack = parseInt(budget, 10) / denominator;
      const total_points =
        parseInt(defaultPayoutPoints) + parseInt(bonusPayoutPoints);
      setLimitPack(parseInt(pack));
      setTotalPoints(total_points);
    } else {
      const d2 =
        parseInt(bonusPayoutPoints, 10) * 0.5 + parseInt(bonusPayoutRs, 10);
      const denominator = d2;
      const pack = parseInt(budget, 10) / denominator;
      const total_rupees = parseInt(defaultPayoutRs) + parseInt(bonusPayoutRs);
      setTotalRupees(total_rupees);
      const total_points =
        parseInt(defaultPayoutPoints) + parseInt(bonusPayoutPoints);
      setLimitPack(parseInt(pack));
      console.log(limitPack, pack);
      setTotalPoints(total_points);
    }
  }, [budget, bonusPayoutPoints, bonusPayoutRs]);
  const handleClose = () => {
    history.goBack();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="g-0 d-flex justify-content-around">
            <Col lg={12}>
              <Card className="m-2">
                <CardHeader className="d-flex align-items-center">
                  <button
                    className="btn btn-secondary float-left"
                    type="submit"
                    onClick={() => {
                      handleClose();
                    }}
                    color="primary"
                  >
                    Back
                  </button>
                  <CardTitle className="font-size-20 mx-3 m-0 text-uppercase">
                    Geography Wise Pincode Scheme
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Formik
                    initialValues={{
                      vehicleSegment: vehicleSegment,
                      pincode: inputPincode,
                      segment: segment,
                      file: null,
                      product_series: series,
                      stacking: stacking,
                      defaultPayoutRs: defaultPayoutRs,
                      defaultPayoutPoints: defaultPayoutPoints,
                      bonusPayoutRs: bonusPayoutRs,
                      bonusPayoutPoints: bonusPayoutPoints,
                      multiplyRupees: multiplyRupees,
                      multiplyPoint: multiplyPoint,
                      restriction: restriction,
                      bonusOnceADay: bonusOnceADay,
                      numberOfCoupon: numberOfCoupon,
                      numberOfTimesCouponAvail: numberOfTimesCouponAvail,
                      start_date: startDate,
                      end_date: endDate,
                      limitPack: limitPack,
                      title: title,
                      budget: budget,
                      limitBonus: limitBonus,
                    }}
                    validate={(values) => {
                      const errors = {};
                      if (!values.limitBonus) {
                        errors.limitBonus = "Required";
                      }
                      if (!values.title) {
                        errors.title = "Required";
                      }
                      if (!values.segment) {
                        errors.segment = "Required";
                      }
                      // if (values.product_series.length === 0) {
                      //   errors.product_series = "Required";
                      // }
                      if (!values.stacking) {
                        errors.stacking = "Required";
                      }
                      if (!values.budget) {
                        errors.budget = "Required";
                      }
                      // if (!values.defaultPayoutPoints) {
                      //   errors.defaultPayoutPoints = "Required";
                      // }
                      if (isEdit === false) {
                        if (routeParams.programId == 1) {
                          if (!values.bonusPayoutRs) {
                            errors.bonusPayoutRs = "Required";
                          }
                        }
                        if (!values.bonusPayoutPoints) {
                          errors.bonusPayoutPoints = "Required";
                        }
                      }
                      if (frequencyButton === true) {
                        if (!values.restriction) {
                          errors.restriction = "Required";
                        }

                        if (!values.numberOfCoupon) {
                          errors.numberOfCoupon = "Required";
                        }

                        // if (!values.bonusOnceADay) {
                        //   errors.bonusOnceADay = "Required";
                        // }
                      }
                      if (!values.start_date) {
                        errors.start_date = "Required";
                      }
                      if (!values.end_date) {
                        errors.end_date = "Required";
                      }

                      return errors;
                    }}
                    onSubmit={(values) => {
                      if (isEdit) {
                        updateValues(values);
                      } else {
                        submitValuetoApi(values);
                      }
                    }}
                  >
                    {({
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                      values,
                      errors,
                      touched,
                    }) => (
                      <React.Fragment>
                        {handleSelectFieldObject(setFieldValue)}
                        {showTable === true ? (
                          <Row>
                            <Col>
                              <div>
                                <CardTitle className="text-danger">
                                  Given pincodes are already mapped!
                                </CardTitle>
                              </div>
                              <table
                                border="1"
                                className="table table-bordered bg-danger"
                              >
                                <thead className="bg-danger-subtle">
                                  <tr className="bg-danger-subtle">
                                    <th>Offer Id</th>
                                    <th>Pincode</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                  </tr>
                                </thead>
                                <tbody className="bg-danger-subtle">
                                  {existData.map((item, index) => (
                                    <tr key={index}>
                                      <td
                                        className="bg-danger-subtle"
                                        // onClick={() =>
                                        //   handleTdClick(item.series_offer_id)
                                        // }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item.offer_id}
                                      </td>
                                      <td className="bg-danger-subtle">
                                        {item.pincode}
                                      </td>
                                      <td className="bg-danger-subtle">
                                        {moment(item.start_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </td>
                                      <td className="bg-danger-subtle">
                                        {moment(item.end_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                        ) : null}
                        <Row>
                          <Col lg={12}>
                            <button
                              className="btn btn-warning float-end "
                              onClick={downloadSampleXLSX}
                            >
                              <div className="d-flex mt-1">
                                <CardTitle>Sample Excel</CardTitle>
                                <i className="ri-arrow-down-line align middle mx-1"></i>
                              </div>
                            </button>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12} md={6} sm={12}>
                            <div className="form-row">
                              <label className="form-label">Offer Title</label>
                              <div className="input-group">
                                <Input
                                  type="search"
                                  placeholder="Type offer title"
                                  id="title"
                                  name="title"
                                  value={values.title}
                                  disabled={isEdit ? true : false}
                                  onChange={(e) => {
                                    setTitle(e.target.value);
                                    setFieldValueFunction(
                                      "title",
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                              {errors.title && touched.title && (
                                <p className="form-error">
                                  {errors.title &&
                                    touched.title &&
                                    errors.title}
                                </p>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg={8}>
                            <div className="form-row">
                              <label className="form-label" htmlFor="pincode">
                                Pincode:
                              </label>
                              <div className="input-group d-flex">
                                <Input
                                  type="search"
                                  className="w-75"
                                  placeholder="Type single pincode number or type comma-separated pincode numbers and submit..."
                                  value={pincodeInput}
                                  name="pincode"
                                  id="pincode"
                                  onChange={(e) =>
                                    setPincodeInput(e.target.value)
                                  }
                                />
                                <Button
                                  className="btn-primary"
                                  onClick={addPincode}
                                >
                                  Add
                                </Button>
                              </div>
                              <div
                                className="d-flex mt-2 flex-wrap "
                                style={{
                                  maxHeight: "100px",
                                  overflowY: "auto",
                                }}
                              >
                                {pincodeNumbers.map((number, index) => (
                                  <div
                                    className="badge-soft-dark p-2"
                                    key={index}
                                  >
                                    {number}
                                    <button
                                      className="badge-soft-danger border-0 form-select-sm align-middle  mx-2"
                                      onClick={() => handleNumber(index)}
                                    >
                                      <i className="ri-close-line align-middle"></i>
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Col>

                          <Col lg={4}>
                            <div className="form-row">
                              <Label className="form-label" htmlFor="upload">
                                Or Upload CSV File{" "}
                              </Label>
                              <div className="input-group">
                                <input
                                  id="upload"
                                  name="upload"
                                  type="file"
                                  accept=".xlsx"
                                  onChange={handleFileChange}
                                  className="form-control"
                                />
                                <div className="input-group-prepend">
                                  <div className="input-group-text ">
                                    <button
                                      className="btn btn-close"
                                      onClick={() => removeFile()}
                                    ></button>
                                  </div>
                                </div>
                              </div>
                              {errors.file && (
                                <p className="form-error">
                                  {errors.mobile && errors.file}
                                </p>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg={4} md={6} sm={12}>
                            <div className="form-row">
                              <label className="form-label">
                                Select Product Segment:
                              </label>
                              <div
                                className={`input-group select-group ${
                                  errors.segment && touched.segment
                                    ? "input-error"
                                    : ""
                                }`}
                              >
                                <Select
                                  // isDisabled={isEdit ? true : false}
                                  className="w-100"
                                  closeMenuOnSelect={true}
                                  value={values.segment}
                                  isSearchable={false}
                                  options={segmentOption}
                                  blurInputOnSelect={true}
                                  id="segment"
                                  name="segment"
                                  onChange={(event) => {
                                    handleSegment(event);
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                />
                              </div>
                              {errors.segment && touched.segment && (
                                <p className="form-error">
                                  {errors.segment &&
                                    touched.segment &&
                                    errors.segment}
                                </p>
                              )}
                            </div>
                          </Col>
                          <Col lg={4} md={6} sm={12}>
                            <div className="form-row">
                              <label className="form-label">
                                Choose Product Series:
                              </label>
                              <div
                                className={`input-group select-group ${
                                  errors.product_series &&
                                  touched.product_series
                                    ? "input-error"
                                    : ""
                                }`}
                              >
                                <Select
                                  // isDisabled={isEdit ? true : false}
                                  className="w-100"
                                  closeMenuOnSelect={false}
                                  value={values.product_series}
                                  isMulti
                                  options={seriesOption}
                                  id="product_series"
                                  name="product_series"
                                  onChange={(event) => {
                                    handleSeries(event);
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                />
                              </div>
                              {/* {errors.product_series &&
                                touched.product_series && (
                                  <p className="form-error">
                                    {errors.product_series &&
                                      touched.product_series &&
                                      errors.product_series}
                                  </p>
                                )} */}
                            </div>
                          </Col>
                          {routeParams.programId == 1 ? (
                            <Col lg={4} md={6} sm={12}>
                              <div className="form-row">
                                <label className="form-label">
                                  Existing Stacking Logic:
                                </label>
                                <div
                                  className={`input-group select-group ${
                                    errors.stacking && touched.stacking
                                      ? "input-error"
                                      : ""
                                  }`}
                                >
                                  <Select
                                    isDisabled={
                                      routeParams.programId == 5 ? true : false
                                    }
                                    className="w-100"
                                    closeMenuOnSelect={true}
                                    value={values.stacking}
                                    isSearchable={false}
                                    options={stackingOption}
                                    blurInputOnSelect={true}
                                    id="stacking"
                                    name="stacking"
                                    onChange={(event) => {
                                      handleStacking(event);
                                    }}
                                    onBlur={(event) => {
                                      handleBlur(event);
                                    }}
                                  />
                                </div>
                                {errors.stacking && touched.stacking && (
                                  <p className="form-error">
                                    {errors.stacking &&
                                      touched.stacking &&
                                      errors.stacking}
                                  </p>
                                )}
                              </div>
                            </Col>
                          ) : null}

                          <Col
                            lg={4}
                            md={6}
                            sm={12}
                            className={`${routeParams.productId} == 1 ? "mt-2": ""`}
                          >
                            <div className="form-row">
                              <label className="form-label">
                                Choose vehicle Segment:
                              </label>
                              <div className={`input-group select-group `}>
                                <Select
                                  isMulti
                                  isDisabled={isEdit ? true : false}
                                  className="w-100"
                                  closeMenuOnSelect={true}
                                  value={values.vehicleSegment}
                                  options={vehicleSegmentOption}
                                  id="vehicleSegment"
                                  name="vehicleSegment"
                                  onChange={(event) => {
                                    handleVehicleSegment(event);
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row className="mt-3">
                          {routeParams.programId == 1 ? (
                            <Col lg={4} md={6} sm={12}>
                              <div className="form-row">
                                <label className="form-label">
                                  Default Payout(Cash)
                                </label>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      Rs.
                                    </span>
                                  </div>
                                  <Input
                                    type="search"
                                    placeholder="Default Payout (Rs.)"
                                    id="defaultPayoutRs"
                                    name="defaultPayoutRs"
                                    value={values.defaultPayoutRs}
                                    disabled={true}
                                    onChange={(e) => {
                                      const input = e.target.value;

                                      // Use a regular expression to remove non-numeric characters
                                      const numericInput = input.replace(
                                        /[^0-9]/g,
                                        ""
                                      );
                                      setDefaultPayoutRs(numericInput);
                                      setFieldValueFunction(
                                        "defaultPayoutRs",
                                        numericInput
                                      );
                                    }}
                                    onBlur={(event) => {
                                      handleBlur(event);
                                    }}
                                  />
                                  {/* <div className="input-group-append">
                                <span className="input-group-text">.00</span>
                              </div> */}
                                </div>
                                {errors.defaultPayoutRs &&
                                  touched.defaultPayoutRs && (
                                    <p className="form-error">
                                      {errors.defaultPayoutRs &&
                                        touched.defaultPayoutRs &&
                                        errors.defaultPayoutRs}
                                    </p>
                                  )}
                              </div>
                            </Col>
                          ) : null}
                          <Col lg={4} md={6} sm={12}>
                            <div className="form-row">
                              <label className="form-label">
                                Default Payout (Points)
                              </label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    Points
                                  </span>
                                </div>
                                <Input
                                  type="search"
                                  disabled={true}
                                  placeholder="Default Payout (Points)"
                                  id="defaultPayoutPoints"
                                  name="defaultPayoutPoints"
                                  value={values.defaultPayoutPoints}
                                  onChange={(e) => {
                                    const input = e.target.value;

                                    // Use a regular expression to remove non-numeric characters
                                    const numericInput = input.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    setDefaultPayoutPoints(numericInput);
                                    setFieldValueFunction(
                                      "defaultPayoutPoints",
                                      numericInput
                                    );
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                  invalid={
                                    errors.defaultPayoutPoints &&
                                    touched.defaultPayoutPoints
                                  }
                                />
                              </div>
                              {errors.defaultPayoutPoints &&
                                touched.defaultPayoutPoints && (
                                  <p className="form-error">
                                    {errors.defaultPayoutPoints &&
                                      touched.defaultPayoutPoints &&
                                      errors.defaultPayoutPoints}
                                  </p>
                                )}
                            </div>
                          </Col>
                        </Row>
                         {/* Multiplier Bonus */}
                         <Row className="mt-3">
                          <Table className="m-3" style={{border:"1px solid black",width:"800px",margin:"5px"}}>
                            <thead>
                            <div className="form-row">
                              <h5>
                                Multiplier Bonus
                              </h5>
                            </div>
                            </thead>
                            <tbody>
                              <td>
                                <Col>
                              <div className="form-row">
                                <label className="form-label">
                                Bonus Cash
                                </label>
                                <div className="input-group">
                                <Select
                                    className="w-100"
                                    closeMenuOnSelect={true}
                                    value={values.multiplyRupees}
                                    isSearchable={false}
                                    options={multiplyRupeesOption}
                                    blurInputOnSelect={true}
                                    id="multiplyRupees"
                                    name="multiplyRupees"
                                    onChange={(event) => {
                                      handleMultiplyRupees(event);
                                    }}
                                    onBlur={(event) => {
                                      handleBlur(event);
                                    }}
                                  />
                                  {/* <div className="input-group-append">
                                <span className="input-group-text">.00</span>
                              </div> */}
                                </div>
                                {/* {errors.defaultPayoutRs &&
                                  touched.defaultPayoutRs && (
                                    <p className="form-error">
                                      {errors.defaultPayoutRs &&
                                        touched.defaultPayoutRs &&
                                        errors.defaultPayoutRs}
                                    </p>
                                  )} */}
                              </div>
                                </Col>
                              </td>
                              <td>
                                <Col>
                            <div className="form-row">
                              <label className="form-label">
                              Bonus Points
                              </label>
                              <div className="input-group">
                                <Select
                                    className="w-100"
                                    closeMenuOnSelect={true}
                                    value={values.multiplyPoint}
                                    isSearchable={false}
                                    options={multiplyPointOption}
                                    blurInputOnSelect={true}
                                    id="multiplyPoint"
                                    name="multiplyPoint"
                                    onChange={(event) => {
                                      handleMultiplyPoint(event);
                                    }}
                                    onBlur={(event) => {
                                      handleBlur(event);
                                    }}
                                    />
                              </div>
                              {errors.defaultPayoutPoints &&
                                touched.defaultPayoutPoints && (
                                  <p className="form-error">
                                    {errors.defaultPayoutPoints &&
                                      touched.defaultPayoutPoints &&
                                      errors.defaultPayoutPoints}
                                  </p>
                                )}
                            </div>
                                </Col>
                              </td>
                            </tbody>
                          </Table>
                        </Row>
                        {/*  */}
                      {/* Flat Bonus */}
                      <Row className="mt-3">
                          <Table className="m-3" style={{border:"1px solid black",width:"800px",margin:"5px"}}>
                              <h5 className="m-2">
                                Flat Bonus
                              </h5>
                            <thead>
                            </thead>
                            <tbody>
                            {routeParams.programId == 1 ? (
                               <td>
                                <Col>
                                  <div className="form-row">
                                    <label className="form-label">
                                      Bonus Cash
                                    </label>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Rs.
                                        </span>
                                      </div>
                                      <Input
                                        type="search"
                                        placeholder="Bonus Payout (Rs.)"
                                        id="bonusPayoutRs"
                                        name="bonusPayoutRs"
                                        value={values.bonusPayoutRs}
                                        onChange={(e) => {
                                          const input = e.target.value;

                                          // Use a regular expression to remove non-numeric characters
                                          const numericInput = input.replace(
                                            /[^0-9]/g,
                                            ""
                                          );
                                          setBonusPayoutRs(numericInput);
                                          setFieldValueFunction(
                                            "bonusPayoutRs",
                                            numericInput
                                          );
                                        }}
                                        onBlur={(event) => {
                                          handleBlur(event);
                                        }}
                                      />
                                    </div>
                                    {errors.bonusPayoutRs &&
                                      touched.bonusPayoutRs && (
                                        <p className="form-error">
                                          {errors.bonusPayoutRs &&
                                            touched.bonusPayoutRs &&
                                            errors.bonusPayoutRs}
                                        </p>
                                      )}
                                  </div>
                                </Col>
                                </td>
                              ) : null}
                              <td>
                              <Col>
                            <div className="form-row">
                              <label className="form-label">
                                Bonus Points
                              </label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    Points
                                  </span>
                                </div>
                                <Input
                                  type="search"
                                  placeholder="Bonus Payout (Points)"
                                  id="bonusPayoutPoints"
                                  name="bonusPayoutPoints"
                                  value={bonusPayoutPoints}
                                  onChange={(e) => {
                                    const input = e.target.value;

                                    // Use a regular expression to remove non-numeric characters
                                    const numericInput = input.replace(
                                      /[^0-9]/g,
                                      ""
                                    );

                                    setBonusPayoutPoints(numericInput);
                                    setFieldValueFunction(
                                      "bonusPayoutPoints",
                                      numericInput
                                    );
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                  invalid={
                                    errors.bonusPayoutPoints &&
                                    touched.bonusPayoutPoints
                                  }
                                />
                              </div>
                              {errors.bonusPayoutPoints &&
                                touched.bonusPayoutPoints && (
                                  <p className="form-error">
                                    {errors.bonusPayoutPoints &&
                                      touched.bonusPayoutPoints &&
                                      errors.bonusPayoutPoints}
                                  </p>
                                )}
                            </div>
                          </Col>
                              </td>
                            </tbody>
                          </Table>
                        </Row>
                        {/*  */}
                        <Row className="mt-3">
                          {routeParams.programId == 1 ? (
                            <Col lg={4} md={6} sm={12}>
                              <div className="form-row">
                                <label className="form-label">
                                  Total Rupees
                                </label>
                                <div className="form-control">
                                  {isNaN(totalRupees)
                                    ? "Total Rupees"
                                    : totalRupees}
                                </div>
                              </div>
                            </Col>
                          ) : null}

                          <Col lg={4} md={6} sm={12}>
                            <div className="form-row">
                              <label className="form-label">Total Points</label>
                              <div className="form-control">
                                {isNaN(totalPoints)
                                  ? "Total Points"
                                  : totalPoints}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg={4} md={6} sm={12}>
                            <div className="form-row">
                              <label className="form-label">
                                Campaign Budget
                              </label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">Rs.</span>
                                </div>
                                <Input
                                  disabled={tempPack}
                                  type="search"
                                  placeholder="Budget(Rs.)"
                                  id="budget"
                                  name="budget"
                                  value={values.budget}
                                  onChange={(e) => {
                                    const input = e.target.value;
                                    // Use a regular expression to remove non-numeric characters
                                    const numericInput = input.replace(
                                      /[^0-9]/g,
                                      ""
                                    );

                                    setBudget(input);
                                    setFieldValueFunction(
                                      "budget",
                                      numericInput
                                    );
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                />
                                {/* <div className="input-group-append">
                                <span className="input-group-text">.00</span>
                              </div> */}
                              </div>
                              {errors.budget && touched.budget && (
                                <p className="form-error">
                                  {errors.budget &&
                                    touched.budget &&
                                    errors.budget}
                                </p>
                              )}
                            </div>
                          </Col>
                          {isEdit ? (
                            <Col lg={4} md={6} sm={12}>
                              <div className="form-row">
                                <label className="form-label">
                                  Campaign Budget Used
                                </label>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      Rs.
                                    </span>
                                  </div>
                                  <Input
                                    type="search"
                                    disabled={true}
                                    placeholder="Budget(Rs.)"
                                    id="budget"
                                    name="budget"
                                    value={budgetUsed}
                                  />
                                </div>
                              </div>
                            </Col>
                          ) : null}
                          {/* <Col lg={4} md={12} sm={12}>
                            <div className="form-row">
                              <label className="form-label">
                                Limit No of Packs
                              </label>
                              <div className="input-group">
                                <Input
                                  type="search"
                                  placeholder="Max No.of pack"
                                  id="limitPack"
                                  disabled={true}
                                  name="limitPack"
                                  value={isNaN(limitPack) ? "" : limitPack}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                  invalid={
                                    errors.limitPack && touched.limitPack
                                  }
                                />
                              </div>
                              {errors.limitPack && touched.limitPack && (
                                <p className="form-error">
                                  {errors.limitPack &&
                                    touched.limitPack &&
                                    errors.limitPack}
                                </p>
                              )}
                            </div>
                          </Col> */}
                        </Row>
                        <Row className="mt-3">
                          <Col lg={4} md={6} sm={12} className="">
                            <div className="form-row">
                              <label className="form-label">
                                Limit bonus per day:
                              </label>
                              <div className="input-group">
                                <Input
                                  // disabled={isEdit ? true : false}
                                  type="search"
                                  placeholder="Enter number"
                                  id="limitBonus"
                                  name="limitBonus"
                                  value={values.limitBonus}
                                  onChange={(e) => {
                                    const input = e.target.value;

                                    // Use a regular expression to remove non-numeric characters
                                    const numericInput = input.replace(
                                      /[^0-9]/g,
                                      ""
                                    );

                                    // Update the state with the numeric input
                                    setLimitBonus(numericInput);
                                    setFieldValueFunction(
                                      "limitBonus",
                                      numericInput
                                    );
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                  invalid={
                                    errors.limitBonus && touched.limitBonus
                                  }
                                />
                              </div>
                              {errors.limitBonus && touched.limitBonus && (
                                <p className="form-error">
                                  {errors.limitBonus &&
                                    touched.limitBonus &&
                                    errors.limitBonus}
                                </p>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <Button
                              className=""
                              disabled={isEdit}
                              onClick={() =>
                                setFrequencyButton(!frequencyButton)
                              }
                            >
                              Set Frequency
                              <span>
                                {" "}
                                <i
                                  className={`${
                                    frequencyButton === false
                                      ? " ri-checkbox-blank-line"
                                      : " ri-checkbox-line"
                                  } mx-2 font-size-14`}
                                ></i>
                              </span>
                            </Button>
                          </Col>
                        </Row>
                        {frequencyButton === true ? (
                          <Row className="mt-3">
                            <Col lg={4} md={6} sm={12}>
                              <div className="form-row">
                                <label className="form-label">
                                  Bonus On Every:
                                </label>
                                <div className="input-group">
                                  <Input
                                    disabled={isEdit ? true : false}
                                    type="search"
                                    placeholder="Coupon"
                                    id="numberOfCoupon"
                                    name="numberOfCoupon"
                                    value={values.numberOfCoupon}
                                    onChange={(e) => {
                                      const input = e.target.value;

                                      // Use a regular expression to remove non-numeric characters
                                      const numericInput = input.replace(
                                        /[^0-9]/g,
                                        ""
                                      );
                                      setNumberOfCoupon(numericInput);
                                      setFieldValueFunction(
                                        "numberOfCoupon",
                                        numericInput
                                      );
                                    }}
                                    onBlur={(event) => {
                                      handleBlur(event);
                                    }}
                                    invalid={
                                      errors.numberOfCoupon &&
                                      touched.numberOfCoupon
                                    }
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">
                                      Coupon
                                    </span>
                                  </div>
                                </div>
                                {errors.numberOfCoupon &&
                                  touched.numberOfCoupon && (
                                    <p className="form-error">
                                      {errors.numberOfCoupon &&
                                        touched.numberOfCoupon &&
                                        errors.numberOfCoupon}
                                    </p>
                                  )}
                              </div>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                              <div className="form-row">
                                <label className="form-label">
                                  Sameday Scanning Time Restriction:
                                </label>
                                <div
                                  className={`input-group select-group ${
                                    errors.restriction && touched.restriction
                                      ? "input-error"
                                      : ""
                                  }`}
                                >
                                  <Select
                                    isDisabled={isEdit ? true : false}
                                    className="w-100"
                                    closeMenuOnSelect={true}
                                    value={values.restriction}
                                    id="restriction"
                                    name="restriction"
                                    isSearchable={false}
                                    options={restrictionOption}
                                    blurInputOnSelect={false}
                                    onChange={(event) => {
                                      handleRestriction(event);
                                    }}
                                    onBlur={(event) => {
                                      handleBlur(event);
                                    }}
                                  />
                                </div>
                                {errors.restriction && touched.restriction && (
                                  <p className="form-error">
                                    {errors.restriction &&
                                      touched.restriction &&
                                      errors.restriction}
                                  </p>
                                )}
                              </div>
                            </Col>
                            {/* <Col lg={4} md={6} sm={12}>
                              <div className="form-row">
                                <label className="form-label">
                                  Is the bonus once a day?
                                </label>
                                <div
                                  className={`input-group select-group ${
                                    errors.bonusOnceADay &&
                                    touched.bonusOnceADay
                                      ? "input-error"
                                      : ""
                                  }`}
                                >
                                  <Select
                                    isDisabled={isEdit ? true : false}
                                    className="w-100"
                                    closeMenuOnSelect={true}
                                    value={values.bonusOnceADay}
                                    id="bonusOnceADay"
                                    name="bonusOnceADay"
                                    isSearchable={false}
                                    options={restrictionOption}
                                    blurInputOnSelect={false}
                                    onChange={(event) => {
                                      handleBonusOnceADay(event);
                                    }}
                                    onBlur={(event) => {
                                      handleBlur(event);
                                    }}
                                  />
                                </div>
                                {errors.bonusOnceADay &&
                                  touched.bonusOnceADay && (
                                    <p className="form-error">
                                      {errors.bonusOnceADay &&
                                        touched.bonusOnceADay &&
                                        errors.bonusOnceADay}
                                    </p>
                                  )}
                              </div>
                            </Col> */}
                            <Col lg={4} md={6} sm={12} className="">
                              <div className="form-row">
                                <label className="form-label">
                                  Number Of Times Mechanic Can Avail Bonus:
                                </label>
                                <div className="input-group">
                                  <Input
                                    disabled={isEdit ? true : false}
                                    type="search"
                                    placeholder="Enter number"
                                    id="numberOfTimesCouponAvail"
                                    name="numberOfTimesCouponAvail"
                                    value={values.numberOfTimesCouponAvail}
                                    onChange={(e) => {
                                      const input = e.target.value;

                                      // Use a regular expression to remove non-numeric characters
                                      const numericInput = input.replace(
                                        /[^0-9]/g,
                                        ""
                                      );

                                      // Update the state with the numeric input
                                      setNumberOfTimesCouponAvail(numericInput);
                                      setFieldValueFunction(
                                        "numberOfTimesCouponAvail",
                                        numericInput
                                      );
                                    }}
                                    onBlur={(event) => {
                                      handleBlur(event);
                                    }}
                                    invalid={
                                      errors.numberOfTimesCouponAvail &&
                                      touched.numberOfTimesCouponAvail
                                    }
                                  />
                                </div>
                                {errors.numberOfTimesCouponAvail &&
                                  touched.numberOfTimesCouponAvail && (
                                    <p className="form-error">
                                      {errors.numberOfTimesCouponAvail &&
                                        touched.numberOfTimesCouponAvail &&
                                        errors.numberOfTimesCouponAvail}
                                    </p>
                                  )}
                              </div>
                            </Col>
                          </Row>
                        ) : null}

                        <Row className="mt-3">
                          <Col lg={4} md={12} sm={12}>
                            <div className="form-row">
                              <label className="form-label">Start Date:</label>
                              <div className="input-group">
                                <Input
                                  type="date"
                                  placeholder="Start Date"
                                  id="start_date"
                                  name="start_date"
                                  disabled={isEdit ? true : false}
                                  value={values.start_date}
                                  min={moment().format("YYYY-MM-DD")}
                                  onChange={(event) => {
                                    setStartDate(
                                      moment(
                                        new Date(event.target.value)
                                      ).format("YYYY-MM-DD")
                                    );
                                    setFieldValueFunction(
                                      "start_date",
                                      moment(
                                        new Date(event.target.value)
                                      ).format("YYYY-MM-DD")
                                    );
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                  invalid={
                                    errors.start_date && touched.start_date
                                  }
                                />
                              </div>
                              {errors.start_date && touched.start_date && (
                                <p className="form-error">
                                  {errors.start_date &&
                                    touched.start_date &&
                                    errors.start_date}
                                </p>
                              )}
                            </div>
                          </Col>
                          <Col lg={4} md={12} sm={12}>
                            <div className="form-row">
                              <label className="form-label">End Date:</label>
                              <div className="input-group">
                                <Input
                                  type="date"
                                  placeholder="End Date"
                                  id="end_date"
                                  name="end_date"
                                  value={values.end_date}
                                  min={moment(new Date()).format("YYYY-MM-DD")}
                                  onChange={(event) => {
                                    setEndDate(
                                      moment(
                                        new Date(event.target.value)
                                      ).format("YYYY-MM-DD")
                                    );
                                    setFieldValueFunction(
                                      "end_date",
                                      moment(
                                        new Date(event.target.value)
                                      ).format("YYYY-MM-DD")
                                    );
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                  invalid={errors.end_date && touched.end_date}
                                />
                              </div>
                              {errors.end_date && touched.end_date && (
                                <p className="form-error">
                                  {errors.end_date &&
                                    touched.end_date &&
                                    errors.end_date}
                                </p>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <Button
                              className=""
                              onClick={() =>
                                setdayWiseButton(!dayWiseButton)
                              }
                            >
                              Day Wise
                              <span>
                                {" "}
                                <i
                                  className={`${
                                    dayWiseButton === false
                                      ? " ri-checkbox-blank-line"
                                      : " ri-checkbox-line"
                                  } mx-2 font-size-14`}
                                ></i>
                              </span>
                            </Button>
                            {dayWiseButton === true ?( <ul>
        {dayWiseList.map((ele, index) => {
          return (
            <th key={index}>
              <div>
                <div>
                  <input
                    type="checkbox"
                    id={`custom-checkbox-${index}`}
                    name={ele.name}
                    value={ele.name}
                    checked={ele.value}
                    onChange={(e) => {
                     changeDay(e,index,ele)
                    }}
                  /> {ele.name}
                </div>
              </div>
            </th>
          );
        })}
      </ul>) : null}
                          </Col>
                        </Row>
                        {moment().format("YYYY-MM-DD") > tempEndDate ||
                        offerStatus === "EXPIRED" ? (
                          <Row>
                            <Col>
                              <CardTitle className="text-danger float-end">
                                Offer is expired!
                              </CardTitle>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            {user.user_id !== 1685633729789 && (<Col lg={12}> {
                              (isEdit && isEdit === true && offerStatus === "UNPUBLISH") ? <CardTitle className="text-danger float-end">
                              Offer is unpublish!
                            </CardTitle> : (
                              <div className="d-flex align-items-end justify-content-end">
                                {loading === true ? (
                                  <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                                ) : isEdit && isEdit === true ? (
                                  <>
                                    <Button
                                      className="btn-warning mt-3 me-1"
                                      disabled={loading}
                                      onClick={() => {
                                        handleSubmit();
                                      }}
                                    >
                                      Update
                                    </Button>
                                    {isEdit && (
                                      <Button
                                        className="btn-warning mt-3"
                                        disabled={loading}
                                        onClick={() => {
                                          updateStatus(
                                            (offerStatus === "DRAFT")
                                              ? "PUBLISH"
                                              : (offerStatus === "PUBLISH" ? "UNPUBLISH" : "Save as Draft")
                                          );
                                        }}
                                      >
                                        {(offerStatus === "DRAFT")
                                              ? "PUBLISH"
                                              : (offerStatus === "PUBLISH" ? "UNPUBLISH" : "Save as Draft")}
                                      </Button>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      className="btn-warning mt-3 me-1"
                                      disabled={loading}
                                      onClick={() => {
                                        setRadioValue("DRAFT");
                                        handleSubmit();
                                      }}
                                    >
                                      Save as draft
                                    </Button>
                                    <Button
                                      className="btn-warning mt-3 me-1"
                                      disabled={loading}
                                      onClick={() => {
                                        setRadioValue("PUBLISH");
                                        handleSubmit();
                                      }}
                                    >
                                      Publish
                                    </Button>
                                  </>
                                )}
                              </div>
                              )
                            }
                            </Col>)}
                          </Row>
                        )}
                      </React.Fragment>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default GeographyWisePincodeScheme;
