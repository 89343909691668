import React, { useState, useEffect } from "react";
import { Row, Col, Modal, ModalBody, CardTitle, Button } from "reactstrap";
import "./CustomModal.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import Spinner from "../Loader/Spinner";

import "react-daterange-picker/dist/css/react-calendar.css";

import kycVerification from "../../services/kycVerification.service";
import {
  // ToastContainer,
  toast,
} from "react-toastify";

const CustomModal1 = ({
  isOpen,
  toggle,
  user_id,
  data,
  mechanic,
  start_date,
  end_date,
  currentSlab,
  prevSlab,
  userType,
  final_status,
}) => {
  console.log("isOpen", final_status, data);
  let KYC_IMAGE_URL = "https://mlpstorage.s3.ap-south-1.amazonaws.com/public";
  let uploadedFrontPic = null;
  let dataTrue = true;
  const [modalLoading, setModalLoading] = useState(false);
  const [nameMobile, setNameMobile] = useState("");

  if (
    data.status_id === 129 ||
    data.status_id === 131 ||
    data.status_id === 130
  ) {
    final_status = true;
  } else {
    final_status = false;
  }
  const onChangeSubmit = async (value, data) => {
    console.log("======INSIDE 7======>>>", value, data);
    dataTrue = true;
    // return true;
    let ade_status_id = value || data.ade_status_id;
    let kyc_name = data.name;
    let kyc_number = data.kyc_number;
    let area_development = nameMobile || data.state_head_remarks;
    const kycId = data.kyc_type_id;

    // if (value != 2) {
    if (!area_development) {
      dataTrue = false;
      toast.error("Enter a remark.", {
        autoClose: 2000,
      });
    }
    // }
    const formData = new FormData();
    formData.append("status_id", ade_status_id);
    formData.append("asm_status_id", ade_status_id);
    formData.append("source", "ASM");
    formData.append("kyc_type_id", kycId);
    formData.append("kyc_name", kyc_name);
    formData.append("kyc_number", kyc_number);
    formData.append("state_head_remarks", area_development);
    formData.append("user_id", data.user_id);
    for (var pair of formData.entries()) {
      console.log("data here --------------", `${pair[0]}: ${pair[1]}`);
    }
    console.log("formData-------", formData);
    if (uploadedFrontPic) {
      formData.append(
        `kyc-kyc_selfie_with_visitor_painter-${data.user_id}`,
        uploadedFrontPic
      );
    }
    if (!dataTrue) {
      return 0;
    } else {
      console.log("come here");
      // this.showLoader = true;
      // this.showDocuments = false;
      // this.submitClick = false;
      // document.getElementById('closeid').click();
    }
    if (area_development) {
      const redemption_data = await kycVerification.updateKycDetails_rlp(
        data.user_id,
        formData
      );
      console.log("redemption data=======", redemption_data);
      if (redemption_data.status === true) {
        toast.success("Data Updated", {
          autoClose: 2000,
        });
        goBack();
      }
    }
  };

  const goBack = () => {
    window.location.reload();
  };
  useEffect(() => {
    if (data.state_head_remarks) {
      setNameMobile(data.state_head_remarks);
    }
  });
  return (
    <Row>
      <Col sm={6} md={4} xl={6} className="mt-4">
        <Modal isOpen={isOpen} toggle={toggle} size="lg" centered={true}>
          <Row className=" ">
            <div>
              <i
                onClick={toggle}
                className="ri-close-line align-middle icon mx-2 float-end"
              ></i>
            </div>
            <div className="d-flex justify-content-left">
              <CardTitle className="float-start mx-3 mb-n2 mt-n2 font-size-20 text-dark">
                {data?.kyc_name}
              </CardTitle>
            </div>
          </Row>
          {modalLoading === true ? (
            <Spinner />
          ) : (
            <>
              <ModalBody>
                <div className="row d-flex justify-content-around">
                  <div className="col-sm-6 d-flex flex-row mt-1">
                    <div className="d-flex flex-column w-100">
                      <div className="input-group input-group-sm flex-nowrap mt-1">
                        <a
                          href={`tel:${data?.mobile}`}
                          className="input-group-text input-group-text-1"
                        >
                          <span id="inputGroup-sizing-sm">Mobile</span>
                        </a>

                        <input
                          type="text"
                          className="form-control bg-white"
                          placeholder="Username"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                          value={data?.mobile}
                          disabled
                        />
                      </div>
                      <div className="input-group input-group-sm flex-nowrap mt-1">
                        <span
                          className="input-group-text input-group-text-1"
                          id="inputGroup-sizing-sm"
                        >
                          Distributor
                        </span>
                        <input
                          type="text"
                          className="form-control bg-white"
                          placeholder="Username"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                          value={data?.distributor_name}
                          disabled
                        />
                      </div>
                      <div className="input-group input-group-sm flex-nowrap mt-1">
                        <span
                          className="input-group-text input-group-text-1"
                          id="inputGroup-sizing-sm"
                        >
                          KYC No
                        </span>
                        <input
                          type="text"
                          className="form-control bg-white"
                          placeholder="Username"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                          value={data?.kyc_number}
                          disabled
                        />
                      </div>
                      <div className="input-group input-group-sm flex-nowrap mt-1">
                        <span
                          className="input-group-text input-group-text-1"
                          id="inputGroup-sizing-sm"
                        >
                          KYC Type
                        </span>
                        <input
                          type="text"
                          className="form-control bg-white"
                          placeholder="Username"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                          value={data?.kyc_type}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 d-flex flex-row mt-1">
                    <div className="d-flex flex-column w-100">
                      <div className="input-group input-group-sm flex-nowrap mt-1">
                        <a
                          href={`tel:${data?.shop_name}`}
                          className="input-group-text input-group-text-1"
                        >
                          <span id="inputGroup-sizing-sm">Shop Name</span>
                        </a>

                        <input
                          type="text"
                          className="form-control bg-white"
                          placeholder="Username"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                          value={data?.shop_name}
                          disabled
                        />
                      </div>
                      <div className="input-group input-group-sm flex-nowrap mt-1">
                        <span
                          className="input-group-text input-group-text-1"
                          id="inputGroup-sizing-sm"
                        >
                          State
                        </span>
                        <input
                          type="text"
                          className="form-control bg-white"
                          placeholder="Username"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                          value={data?.state_name}
                          disabled
                        />
                      </div>
                      <div className="input-group input-group-sm flex-nowrap mt-1">
                        <span
                          className="input-group-text input-group-text-1"
                          id="inputGroup-sizing-sm"
                        >
                          District
                        </span>
                        <input
                          type="text"
                          className="form-control bg-white"
                          placeholder="Username"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                          value={data?.district_name}
                          disabled
                        />
                      </div>
                      <div className="input-group input-group-sm flex-nowrap mt-1">
                        <span
                          className="input-group-text input-group-text-1"
                          id="inputGroup-sizing-sm"
                        >
                          City
                        </span>
                        <input
                          type="text"
                          className="form-control bg-white"
                          placeholder="Username"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                          value={data?.city_name}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Row>
                  <div className="col-sm-4 mt-1">
                    <div className="align-self-center mt-1">
                      <h6 className="p-4">KYC front Image</h6>
                      <div className="">
                        {data.kyc_front_pic ? (
                          <img
                            className="img-modal"
                            src={`${KYC_IMAGE_URL}` + data.kyc_front_pic}
                            alt={`${KYC_IMAGE_URL}` + data.kyc_front_pic}
                            height="20"
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 mt-1">
                    <div className="align-self-center mt-1">
                      <h6 className="p-4">KYC back Image</h6>
                      <div className="">
                        {data.kyc_back_pic ? (
                          <img
                            className="img-modal"
                            src={`${KYC_IMAGE_URL}` + data.kyc_back_pic}
                            alt={`${KYC_IMAGE_URL}` + data.kyc_back_pic}
                            height="20"
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 mt-1">
                    <div className="align-self-center mt-1">
                      <h6 className="p-4">SO uploaded image</h6>
                      <div className="">
                        {data.kyc_selfie_with_visitor_painter ? (
                          <img
                            className="img-modal"
                            src={
                              `${KYC_IMAGE_URL}` +
                              data.kyc_selfie_with_visitor_painter
                            }
                            alt={
                              `${KYC_IMAGE_URL}` +
                              data.kyc_selfie_with_visitor_painter
                            }
                            height="20"
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 mt-4">
                    <label>AREA STATE HEAD REMARKS:</label>
                    <textarea
                      className="form-control"
                      value={nameMobile}
                      disabled={!final_status}
                      placeholder="Enter Remark"
                      rows="5"
                      maxLength="100"
                      onChange={(e) => {
                        setNameMobile(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  <div className="col-sm-12 mt-4">
                    <div class="form-group">
                      {final_status === true ? (
                        <Button
                          className="btn btn-danger btn-secondary float-end"
                          disabled={!final_status}
                          onClick={async () => {
                            await onChangeSubmit("3", data);
                          }}
                        >
                          REJECT
                        </Button>
                      ) : null}

                      {final_status === true ? (
                        <Button
                          disabled={!final_status}
                          onClick={async () => {
                            await onChangeSubmit("2", data);
                          }}
                          className="btn-warning float-end mx-2"
                          type="submit"
                        >
                          APPROVE
                        </Button>
                      ) : null}
                    </div>
                  </div>
                </Row>
              </ModalBody>
            </>
          )}
        </Modal>
      </Col>
    </Row>
  );
};

export default CustomModal1;
