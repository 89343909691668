import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/NewLogin";
import Logout from "../pages/Authentication/Logout";

import SegmentVolume from "../pages/Segment/SegmentVolume";
import Segment from "../pages/Segment/Segment";
import ZonePage from "../pages/Master/Zone/ZonePage";
import ReginalPage from "../pages/Master/Reginal/ReginalPage";
import StatePage from "../pages/Master/State/StatePage";
import SalesOfficerPage from "../pages/Master/Sales/SalesOfficerPage";
import Error404 from "../pages/utility/Error404";
import Error500 from "../pages/utility/Error500";
import Redemption from "../pages/Redemption/Redemption";
import Profile from "../pages/Profile";

import CloneReginal from "../pages/Master/Reginal/CloneReginal";

import cloneSO from "../pages/Master/Sales/CloneSalesOfficer";

import cloneSH from "../pages/Master/State/CloneState";

import cloneZH from "../pages/Master/Zone/CloneZone";
import viewTransaction from "../pages/ViewTransaction/ViewTransactions";
import ZoneList from "../pages/Master/Zone/ZoneList";
import RegionalList from "../pages/Master/Reginal/RegionalList";
import StateHeadList from "../pages/Master/State/StateList";
import SOList from "../pages/Master/Sales/SOList";
import EditSO from "../pages/Master/Sales/EditSO";
import EditStateHead from "../pages/Master/State/EditStateHead";
import EditZoneHead from "../pages/Master/Zone/EditZoneHead";
import AddZoneHead from "../pages/Master/Zone/AddZoneHead";
import AddSO from "../pages/Master/Sales/AddSO";
import AddStateHead from "../pages/Master/State/AddStateHead";
import EditRegionalHead from "../pages/Master/Reginal/EditRegional";
import viewMechanic from "../pages/ViewMechanic/ViewMechanic";
import viewRetailer from "../pages/ViewRetailer/ViewRetailer";
import viewGameActivity from "../pages/ViewGameActivity/ViewGameActivity";
import eTransfer from "../pages/Etransfer/Etransfer";
import viewExpiry from "../pages/ViewExpiry/ViewExpiry";
import AddRegionalHead from "../pages/Master/Reginal/AddRegional";
import Dashboard from "../pages/Dashboard/index";
import rlpDashboard from "../pages/Dashboard/rlpIndex";
import KycVerificationSH from "../pages/kyc-verification/Redemption";
import KycVerificationSO from "../pages/kyc-verification/KycVerificationSo";
import ViewRedemption from "../pages/Redemption/ViewRedemption";
import UserActivity from "../pages/UserActivity";
import Support from "../pages/Support/Support";
import ProductInsights from "../pages/Segment/ProductInsights";
import ProductSegment from "../pages/Segment/ProductSegment";
import Tnc from "../pages/TnC/Tnc";
// import MechanicScheme from "../pages/MechanicScheme";
import SelectProgramType from "../pages/ConsumptionPayout/SelectProgramType";
import MechanicScheme from "../pages/ConsumptionPayout/Retailer/MechanicWiseScheme";
import GeographyWisePincodeScheme from "../pages/ConsumptionPayout/Retailer/GeogrpahyWiseScheme/GeographyWisePincodeScheme";
import GeographyWiseStateScheme from "../pages/ConsumptionPayout/Retailer/GeogrpahyWiseScheme/GeographyWiseStateScheme";
import VehicleWiseScheme from "../pages/ConsumptionPayout/Retailer/VehicleWiseScheme";
import EniClubListing from "../pages/ConsumptionPayout/EniClub/Listing";
import EniVistaarListing from "../pages/ConsumptionPayout/EniVistaar/Listing";
import EniClubSchemeSetup from "../pages/ConsumptionPayout/EniClub/EniClubSchemeSetup";
import EniVistaarSchemeSetup from "../pages/ConsumptionPayout/EniVistaar/EniVistaarSchemeSetup";
import FocScheme from "../pages/ConsumptionPayout/Retailer/FocScheme";
import ListingAudience from "../pages/Audience/Listing";
import AddEditAudience from "../pages/Audience/AddEdtAudience";
import SelectAudienceProgram from "../pages/Audience/SeletProgramType";
import BannerList from "../pages/Banner/ViewBanners";
import AddBanner from "../pages/Banner/AddBanner";
import EditBanner from "../pages/Banner/EditBanner";
import FocRetailer from "../pages/ConsumptionPayout/Retailer/FocRetailer";
import rewardsCatlogue from "../pages/Catlogue/rewardsCatlogue";
import ProductWisePackSize from "../pages/ProductWisePackSize/ProductWisePackSize";
import GamificationSelectProgramType from "../pages/Gamification/GamificationSelectProgramType";
import GamificationListing from "../pages/Gamification/EniVistaar/GamificationListing";
import GamificationEniVistaarSchemeSetup from "../pages/Gamification/EniVistaar/GamificationEniVistaarSchemeSetup"
import RetailerScheme from "../pages/Gamification/Retailer/RetailerWiseScheme";

const authProtectedRoutes = [
  { path: "/zone-list", component: ZoneList },
  { path: "/regional-list", component: RegionalList },
  { path: "/state-head-list", component: StateHeadList },
  { path: "/sales-officer-list", component: SOList },

  { path: "/edit-zone-head/:user_id", component: EditZoneHead },
  { path: "/edit-regional-head/:user_id", component: EditRegionalHead },
  { path: "/edit-state-head/:user_id", component: EditStateHead },
  { path: "/edit-sales-officer/:user_id", component: EditSO },

  { path: "/add-zone-head", component: AddZoneHead },
  { path: "/add-regional-head", component: AddRegionalHead },
  { path: "/add-state-head", component: AddStateHead },
  { path: "/add-sales-officer", component: AddSO },

  { path: "/state-head", component: StatePage },
  { path: "/sales-officer", component: SalesOfficerPage },
  { path: "/zone-head", component: ZonePage },
  { path: "/regional-head", component: ReginalPage },
  { path: "/gamification", component: GamificationSelectProgramType },
  { path: "/gamification-listing", component: GamificationListing },
  { path: "/gamification-eni-vistaar-scheme", component: GamificationEniVistaarSchemeSetup },
  {
    path: "/retailer-wise-scheme/:programId/:bonusType/:id?",
    component: RetailerScheme,
  },

  // { path: "/add-zone-head", component: addZoneHead },

  // { path: "/edit-state-head/:user_id", component: EditState },
  // { path: "/edit-regional", component: EditReginal },
  // { path: "/edit-zone-head/:user_id", component: editzone },
  // { path: "/edit-sales-officer/:user_id", component: editSales },

  { path: "/clone-sales-officer/:user_id", component: cloneSO },
  { path: "/clone-state-head/:user_id", component: cloneSH },
  { path: "/clone-zone-head/:user_id", component: cloneZH },
  { path: "/clone-regional/:user_id", component: CloneReginal },

  { path: "/view-transaction", component: viewTransaction },
  { path: "/view-mechanic", component: viewMechanic },
  { path: "/view-retailer", component: viewRetailer },
  { path: "/view-game-activity", component: viewGameActivity },
  { path: "/e-transfer", component: eTransfer },
  { path: "/view-expiry", component: viewExpiry },
  { path: "/ProductWisePackSize", component: ProductWisePackSize},

  { path: "/segment-volume", component: SegmentVolume },
  { path: "/redemption", component: Redemption },
  { path: "/view-redemption", component: ViewRedemption },
  { path: "/segment", component: Segment },
  { path: "/profile", component: Profile },
  { path: "/dashboard", component: Dashboard },
  { path: "/rlp-dashboard", component:  rlpDashboard},
  { path: "/kyc-verification-sh", component: KycVerificationSH },
  { path: "/kyc-verification-so", component: KycVerificationSO },
  { path: "/user-activity", component: UserActivity },
  { path: "/product-insights", component: ProductInsights },
  { path: "/product-segment", component: ProductSegment },
  { path: "/reward-catlogue", component: rewardsCatlogue },
  // { path: "/mechanic-scheme", component: MechanicScheme },
  { path: "/mechanic-scheme", component: SelectProgramType },
  { path: "/eni-club-listing/", component: EniClubListing },
  { path: "/eni-vistaar-listing/", component: EniVistaarListing },
  { path: "/eni-club-scheme", component: EniClubSchemeSetup },
  { path: "/eni-vistaar-scheme", component: EniVistaarSchemeSetup },
  // { path: "/select-bonus-type", component: BonusTypeSelection },
  // { path: "/setup-scheme", component: SchemeSetup },
  {
    path: "/mechanic-wise-scheme/:programId/:bonusType/:id?",
    component: MechanicScheme,
  },
  // { path: "/geography-type-scheme", component: GeographySchemeSetup },
  {
    path: "/geography-wise-pincode-scheme/:programId/:bonusType/:id?",
    component: GeographyWisePincodeScheme,
  },
  {
    path: "/geography-wise-state-scheme/:programId/:bonusType/:id?",
    component: GeographyWiseStateScheme,
  },
  {
    path: "/vehicle-wise-scheme/:programId/:bonusType/:id?",
    component: VehicleWiseScheme,
  },
  { path: "/foc-scheme/:programId/:bonusType/:id?", component: FocScheme },
  { path: "/foc-retailer/:programId/:bonusType/:id?", component: FocRetailer },
  { path: "/audience", component: ListingAudience },
  { path: "/add-audience/:programId", component: AddEditAudience },
  { path: "/edit-audience/:programId/:id", component: AddEditAudience },
  { path: "/audience-type", component: SelectAudienceProgram },
  { path: "/banner-list", component: BannerList },
  { path: "/add-banner", component: AddBanner },
  { path: "/edit-banner/:id", component: EditBanner },
  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/segment-volume" />,
  },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/404", component: Error404 },
  { path: "/500", component: Error500 },
  { path: "/support", component: Support },
  { path: "/tnc", component: Tnc },
  // { path: "/forgot-password", component: ForgetPwd },
  // { path: "/register", component: Register },
  // { path: "/lock-screen", component: AuthLockScreen },

  // Authentication Inner
  // { path: "/auth-login", component: Login },
  // { path: "/auth-register", component: Register1 },
  // { path: "/auth-recoverpw", component: ForgetPwd1 },
];

export { authProtectedRoutes, publicRoutes };
