import axios from "axios";
import { API_URL, CAMPAIGN_URL } from "../Config/config";
import authService from "./auth.service";
import { toast } from "react-toastify";
const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
const updateCampaign = async (action, offer_id) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  let api_url = CAMPAIGN_URL + "campaign/update-status";
  let params = { action, offer_id };
  console.log("updating");
  return axiosApi
    .post(api_url, params)
    .then((response) => {
      console.log(`Success`, response);
      if (response.status === 200) {
        return { status: true, data: response.data };
      }
    })
    .catch((err) => {
      console.log(`Success`, err);
      return { status: false, message: err.message, invalid: true };
    });
};
const getAllStates = async () => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .get(CAMPAIGN_URL + "areas/allstate")
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const getDbtList = async () => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .get(CAMPAIGN_URL + "dbt/getDbtFilters/list")
    .then((response) => {
      if (response.data.success) {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const getSkuFiterList = async () => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .get(CAMPAIGN_URL + "sku/filter_list")
    .then((response) => {
      if (response.data.success) {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const GetSegmentList = async () => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  let api_url = CAMPAIGN_URL;
  return axiosApi
    .get(api_url + "campaign/get-segment-list")
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const GetProductList = async () => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  let api_url = CAMPAIGN_URL;
  return axiosApi
    .get(api_url + "campaign/getProductList")
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};
const GetPackSize = async (params) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .post(`${CAMPAIGN_URL}campaign/get-pack-size`, params)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};
const GetSegmentSeriesList = async (params) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  let api_url = CAMPAIGN_URL;
  return axiosApi
    .get(
      api_url +
        `campaign/get-segment-series-list?segment_id=${params.id}&program_id=${params.program_id}`
    )
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};
const GetSegmentSeriesListFoc = async (params) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .post(`${CAMPAIGN_URL}campaign/get-segment-series-list-foc`, params)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const getStateList = async () => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .get(CAMPAIGN_URL + "campaign/state-list")
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};
const GetAreaList = async (body) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .post(`${CAMPAIGN_URL}campaign/get-area-list`, body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};
const getDistrictList = async (body) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .post(CAMPAIGN_URL + `campaign/district-list`, body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};
const GetOfferDetails = async (params) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  let api_url = `${CAMPAIGN_URL}campaign/get-offer-details?offer_id=${params.offer_id}&program_id=${params.program_id}`;
  return axiosApi
    .get(api_url)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      } else {
        return { status: false };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const GetOffersList = async (params) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  let api_url = CAMPAIGN_URL;
  // let queryParams = `?program_id=${params.program_id}`;
  // if (Number(params.is_active) === 1 || Number(params.is_active) === 0) {
  //   queryParams += `&is_active=${params.is_active}`;
  // }
  // if (params.offset) {
  //   queryParams += `&offset=${params.offset}`;
  // }
  return axiosApi
    .post(api_url + "campaign/get-offer-list", params)
    .then((response) => {
      if (response.data.status === "success") {
        return {
          status: true,
          data: response.data.data,
          total: response.data.total,
        };
      } else {
        return { status: false };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

// segment
const updateCampaignSegment = async (params) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  let api_url = CAMPAIGN_URL + "campaign/update-segment-campaign";
  return axiosApi
    .post(api_url, params)
    .then((response) => {
      if (response.status === 200) {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      return { status: false, message: err.message, invalid: true };
    });
};
const saveVehicleWiseCampaign = async (params) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  let api_url = CAMPAIGN_URL + "campaign/save-segment-campaign";
  return axiosApi
    .post(api_url, params)
    .then((response) => {
      console.log("response is ", response);
      if (response.status === 200) {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      return { status: false, message: err.message, invalid: true };
    });
};

// mechanic
const saveMechanicWiseCampaign = async (formData) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;

  let api_url = CAMPAIGN_URL + "campaign/save-mechanic-wise-data";
  return axiosApi
    .post(api_url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (res.data.status === "success") {
        return { status: true, offer_id: res.data.data.id };
      } else {
        return { status: false };
      }
    })
    .catch((err) => {
      console.log(err.response.data.code, "/////////");
      if (err?.response?.data.code === 409) {
        console.log(err.response.data, "/////////");
        toast.error(err.response.data.message);
      }
      if (err?.response?.data.code === 404) {
        console.log(err.response.data, "/////////");
        toast.error(err.response.data.message);
      }
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
      return {
        code: err.response.data.code,
        status: false,
        message: err.message,
        invalid: true,
        data:
          err.response.data.code === 409
            ? err.response.data.data?.filteredResult
            : [],
      };
    });
};
const saveBonanza = async (formData) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;

  let api_url = CAMPAIGN_URL + "campaign/save-bonanza";
  return axiosApi
    .post(api_url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (res.data.status === "success") {
        return { status: true, offer_id: res.data.data.id };
      } else {
        return { status: false };
      }
    })
    .catch((err) => {
      console.log(err.response.data.code, "/////////");
      if (err?.response?.data.code === 409) {
        console.log(err.response.data, "/////////");
        toast.error(err.response.data.message);
      }
      if (err?.response?.data.code === 404) {
        console.log(err.response.data, "/////////");
        toast.error(err.response.data.message);
      }
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
      return {
        code: err.response.data.code,
        status: false,
        message: err.message,
        invalid: true,
        data:
          err.response.data.code === 409
            ? err.response.data.data?.filteredResult
            : [],
      };
    });
};
const updateCampaignMehanic = async (params) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  let api_url = CAMPAIGN_URL + "campaign/update-mechanic-wise-data";
  return axiosApi
    .post(api_url, params)
    .then((response) => {
      if (response.status === 200) {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err.response.data.code, "/////////");
      if (err?.response?.data.code === 409) {
        console.log(err.response.data.message, "/////////");
        toast.error(err.response.data.message);
      }
      if (err?.response?.data.code === 404) {
        console.log(err.response.data.message, "/////////");
        toast.error(err.response.data.message);
      }
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
      return {
        code: err.response.data.code,
        status: false,
        message: err.message,
        invalid: true,
        data:
          err.response.data.code === 409
            ? err.response.data.data?.filteredResult
            : [],
      };
    });
};

// Pincode
const savePinCodeWiseCampaign = async (formData) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  let api_url = CAMPAIGN_URL + "campaign/save-campaign-pincode-wise";
  return axiosApi
    .post(api_url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "multipart/form-data",
      },
    })
    .then((res) => {
      console.log(`Success`, res.data);
      if (res.data.status === "success") {
        return { status: true, offer_id: res.data.data.id };
      } else {
        return { status: false };
      }
    })
    .catch((err) => {
      console.log(err.response?.data.data, "--------------");
      if (err?.response?.data.code === 409) {
        // console.log(err.response.data, "/////////");
        toast.error(err.response?.data?.message);
      }
      if (err?.response?.data.code === 404) {
        // console.log(err.response.data, "/////////");
        toast.error(err.response?.data?.message);
      }
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
      return {
        code: err.response.data.code,
        status: false,
        message: err.message,
        invalid: true,
        data: err.response.data.code === 409 ? err.response?.data?.data : [],
      };
    });
};
const updateCampaignPincode = async (params) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  let api_url = CAMPAIGN_URL + "campaign/update-campaign-pincode-wise";
  return axiosApi
    .post(api_url, params)
    .then((response) => {
      if (response.status === 200) {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err.response.data.code, "/////////");
      if (err?.response?.data.code === 409) {
        console.log(err.response.data, "/////////");
        toast.error(err.response.data.message);
      }
      if (err?.response?.data.code === 404) {
        console.log(err.response.data, "/////////");
        toast.error(err.response.data.message);
      }
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
      return {
        code: err.response.data.code,
        status: false,
        message: err.message,
        invalid: true,
        data: err.response.data.code === 409 ? err.response?.data?.data : [],
      };
    });
};

// statewise
const saveStateWiseCampaign = async (params) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  let api_url = CAMPAIGN_URL + "campaign/save-campaign-state-wise";
  return axiosApi
    .post(api_url, params)
    .then((response) => {
      console.log("response is ", response);
      if (response.status === 200) {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      return { status: false, message: err.message, invalid: true };
    });
};

const updateCampaignState = async (params) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  let api_url = CAMPAIGN_URL + "campaign/update-campaign-state-wise";
  return axiosApi
    .post(api_url, params)
    .then((response) => {
      if (response.status === 200) {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      return { status: false, message: err.message, invalid: true };
    });
};

const updateFOCCampaign = async (params) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  let api_url = CAMPAIGN_URL + "campaign/update-foc-campaign";
  return axiosApi
  .post(api_url, params)
  .then((response) => {
    if (response.status === 200) {
      return { status: true, data: response.data.data };
    }
  })
  .catch((err) => {
    console.log(err.response.data.code, "/////////");
    if (err?.response?.data.code === 409) {
      console.log(err.response.data.message, "/////////");
      toast.error(err.response.data.message);
    }
    if (err?.response?.data.code === 404) {
      console.log(err.response.data.message, "/////////");
      toast.error(err.response.data.message);
    }
    if (err?.response?.status === 403) {
      authService.autoLogout();
      window.location.href = "/login";
    }
    return {
      code: err.response.data.code,
      status: false,
      message: err.message,
      invalid: true,
      data:
        err.response.data.code === 409
          ? err.response.data.data?.filteredResult
          : [],
    };
  });
};

const saveFOCCampaign = async (formData) => {
  console.log(formData);
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const token = user.token;

  const api_url = CAMPAIGN_URL + "campaign/save-foc-campaign";
  return axiosApi
    .post(api_url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (res.data.status === "success") {
        return { status: true, offer_id: res.data.data.id };
      } else {
        return { status: false };
      }
    })
    .catch((err) => {
      console.log(err.response.data.code, "/////////");
      if (err?.response?.data.code === 409) {
        console.log(err.response.data, "/////////");
        toast.error(err.response.data.message);
      }
      if (err?.response?.data.code === 404) {
        console.log(err.response.data, "/////////");
        toast.error(err.response.data.message);
      }
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
      return {
        code: err.response.data.code,
        status: false,
        message: err.message,
        invalid: true,
        data:
          err.response.data.code === 409
            ? err.response.data.data?.filteredResult
            : [],
      };
    });
};
const saveFOCStateWiseCampaign = async (params) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  let api_url = CAMPAIGN_URL + "campaign/save-foc-campaign-geography";
  return axiosApi
    .post(api_url, params)
    .then((response) => {
      console.log("response is ", response);
      if (response.status === 200) {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      return { status: false, message: err.message, invalid: true };
    });
};
const updateFOCStateWiseCampaign = async (params) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  let api_url = CAMPAIGN_URL + "campaign/update-foc-campaign-geography";
  return axiosApi
    .post(api_url, params)
    .then((response) => {
      if (response.status === 200) {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      return { status: false, message: err.message, invalid: true };
    });
};
export default {
  getAllStates,
  getSkuFiterList,
  getDbtList,
  GetSegmentList,
  GetProductList,
  GetSegmentSeriesList,
  GetSegmentSeriesListFoc,
  GetAreaList,
  GetOffersList,
  GetOfferDetails,
  updateCampaign,
  saveMechanicWiseCampaign,
  savePinCodeWiseCampaign,
  saveStateWiseCampaign,
  saveVehicleWiseCampaign,
  saveFOCCampaign,
  updateCampaignMehanic,
  updateCampaignPincode,
  updateCampaignState,
  updateCampaignSegment,
  updateFOCCampaign,
  getDistrictList,
  getStateList,
  GetPackSize,
  saveFOCStateWiseCampaign,
  updateFOCStateWiseCampaign,
  saveBonanza
};
