import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Row, Button } from "reactstrap";
//Import Breadcrumb
import RedemptionAreaTable from "../../components/Table/RedemptionAreaTable";
import redemptionService from "../../services/redemption.service";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import { useSelector } from "react-redux";
import Spinner from "../../components/Loader/Spinner";
import moment from "moment";
import { DateRange } from "react-date-range";
import Pagination from "../../components/Table/Pagination";
import RedemptionSoDbt from "./RedemptionSoDbt";

const Redemption = () => {
  // const loading = useSelector((state) => state.loading.loading);
  const [loading, setLoading] = useState(false);
  const master = useSelector((state) => state.master.setList.body);
  const [data, setData] = useState([]);
  const [isOpenfirstMonth, setIsOpenFirstmonth] = useState(false);
  const currentDate = new Date(); // current date
  const initialStartDate = moment(currentDate).subtract(29, "days").toDate(); // subtract 30 days from current date
  const initialDateRange = [
    {
      startDate: new Date(moment(initialStartDate)),
      endDate: new Date(moment(currentDate)),
      key: "selection",
    },
  ];

  const [dateRange, setDateRange] = useState(initialDateRange);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;

  let body = {
    start_date: moment(dateRange[0].startDate).format("YYYY-MM-DD"),
    end_date: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
    offset: currentPage,
    limit: pageSize,
    regional_mapping: master ? master.regional_mapping : null,
  };

  const handledateRange = (item) => {
    setDateRange([item.selection]);
    body.start_date = moment(item.selection.startDate).format("YYYY-MM-DD");
    body.end_date = moment(item.selection.endDate).format("YYYY-MM-DD");
  };

  const submitDates = async () => {
    setLoading(true);
    setIsOpenFirstmonth(false);
    const redemption_data = await redemptionService.GetRedemption(body);
    if (redemption_data.status === true) {
      setTotalPages(redemption_data.data.pagination.totalPages);
      setData(redemption_data.data.data);
      setLoading(false);
    }
    setLoading(false);
  };

  const Reset = () => {
    body.start_date = moment(initialDateRange[0].startDate).format(
      "YYYY-MM-DD"
    );
    body.end_date = moment(initialDateRange[0].endDate).format("YYYY-MM-DD");
    setDateRange(initialDateRange);
    setCurrentPage(1);
    submitDates();
  };

  const handleFirstMonthButtonState = () => {
    setIsOpenFirstmonth(!isOpenfirstMonth);
  };

  const handleDateRangeClose = () => {
    setIsOpenFirstmonth(false);
  };

  useEffect(() => {
    submitDates();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        {isOpenfirstMonth === false ? (
                          <div>
                            <label className="form-label">Select Dates: </label>
                            <button
                              className="form-select"
                              onClick={handleFirstMonthButtonState}
                            >
                              {`${new Date(body.start_date).toLocaleString(
                                "default",
                                {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                }
                              )} - ${new Date(body.end_date).toLocaleString(
                                "default",
                                {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                }
                              )}`}
                            </button>
                          </div>
                        ) : (
                          <div className="">
                            <label className="form-label">Select Dates: </label>
                            <div className="">
                              <DateRange
                                editableDateInputs={true}
                                // onChange={(item) => handledateRange(item.selection.startDate,item.selection.endDate)}
                                onChange={handledateRange}
                                moveRangeOnFirstSelection={false}
                                ranges={dateRange}
                                rangeColors={["#FCB92C", "#FCB92C", "#FCB92C"]}
                                shouldCloseOnSelect={true}
                                maxDate={new Date()}
                                minDate={new Date(moment("2021-01-01"))}
                                onClose={handleDateRangeClose}
                              />
                              <Button
                                className="btn-danger"
                                onClick={handleFirstMonthButtonState}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-2 col-md-6 col-sm-12">
                        <div className="margin-top d-flex mx-3">
                          <Button
                            className="btn-warning"
                            onClick={() => submitDates()}
                          >
                            Search
                          </Button>
                          <Button
                            className="btn btn-danger btn-secondary margin-right"
                            onClick={() => Reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="container-fluid">
                    <div className="row">
                      {loading === true ? (
                        <Spinner />
                      ) : (
                        <>
                          <h4 className="card-title pt-4">
                            {`Cash Earned by Mechanics in a GEO between ${moment(
                              body.start_date
                            ).format("MMM DD, YYYY")} - ${moment(
                              body.end_date
                            ).format("MMM DD, YYYY")}`}
                          </h4>
                          <RedemptionAreaTable data={data} loading={loading} />
                          <div className="d-flex justify-content-center ">
                            <Pagination
                              page={currentPage}
                              setPage={setCurrentPage}
                              totalPages={totalPages}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <RedemptionSoDbt />
    </React.Fragment>
  );
};
export default Redemption;
