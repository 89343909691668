import React from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, CardTitle, Card, CardHeader, CardBody } from "reactstrap";

function SelectAudienceProgram(props) {
  const history = useHistory();

  const handleSelectionRoute = (val) => {
    history.push(val);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="g-0 d-flex justify-content-around">
            <Col lg={12}>
              <button
                className="btn btn-secondary float-left mx-1"
                type="submit"
                onClick={() => {
                  handleSelectionRoute("/audience");
                }}
                color="primary"
              >
                Back
              </button>
              <Card className="m-2">
                <CardHeader className="d-flex ">
                  <CardTitle className="font-size-20 text-uppercase">
                    Select Program
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row className="g-0 d-flex justify-content-center">
                    <Col sm={12}>
                      <React.Fragment>
                        <div className="box-selection-wrap">
                          <div
                            className="box-selection-item"
                            onClick={() => {
                              handleSelectionRoute("/add-audience/1");
                            }}
                          >
                            <p className="box-inner-title">ENI CLUB</p>
                          </div>
                          <div
                            className="box-selection-item"
                            onClick={() => {
                              handleSelectionRoute("/add-audience/5");
                            }}
                          >
                            <p className="box-inner-title">ENI VISTAAR</p>
                          </div>
                        </div>
                      </React.Fragment>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SelectAudienceProgram;
