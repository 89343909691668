import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { CAMPAIGN_URL } from "../../Config/config";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import bannerService from "../../services/banner.service";
import Spinner from "../../components/Loader/Spinner";
import moment from "moment";
const AddBanner = () => {
  const history = useHistory();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [bannerName, setBannerName] = useState("");
  const [priority, setPriority] = useState("");
  const [selectedType, setSelectedType] = useState([]);
  const [language, setLanguage] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const typeOptions = [
    { value: "BANNER", label: "BANNER" },
    { value: "VIDEO", label: "VIDEO" },
    { value: "TEXT", label: "TEXT" },
  ];
  const [selectedActionSource, setSelectedActionSource] = useState([]);
  const actionSourceOptions = [
    { value: "BANNER", label: "BANNER" },
    { value: "VIDEO", label: "VIDEO" },
    { value: "TEXT", label: "TEXT" },
  ];
  const [selectedProgram, setSelectedProgram] = useState({
    value: "0",
    label: "MLP",
  });
  const programOptions = [
    { value: "0", label: "MLP" },
    { value: "1", label: "RLP" },
  ];
  const [selectedDisplayStatus, setSelectedDisplayStatus] = useState({
    value: "1",
    label: "Active",
  });
  const displayStatusOptions = [
    { value: "1", label: "Active" },
    { value: "2", label: "Inactive" },
  ];

  //handle functions
  const handleBanner = (e) => {
    setBannerName(e.target.value);
  };
  const handlePriority = (e) => {
    setPriority(e.target.value);
  };
  const handleType = (e) => {
    setSelectedType(e);
  };
  const handleActionSource = (e) => {
    setSelectedActionSource(e);
  };
  const handleLanguage = (e) => {
    setSelectedLanguage(e);
  };
  const handleProgram = (e) => {
    setSelectedProgram(e);
  };
  const handleDisplayStatus = (e) => {
    setSelectedDisplayStatus(e);
  };
  const handleReset = async () => {
    setSelectedProgram({
      value: "0",
      label: "MLP",
    });
    setSelectedDisplayStatus({
      value: "1",
      label: "Active",
    });
    setSelectedActionSource([]);
    setSelectedLanguage([]);
    setSelectedType([]);
    setBannerName("");
    setPriority("");
    setSelectedFile(null);
    setImageUrl("");
  };
  const handleSubmit = async () => {
    const validateFields = () => {
      if (
        selectedType === "" ||
        bannerName.length === 0 ||
        priority.length === 0 ||
        selectedActionSource === ""
      ) {
        return "Please fill in all required fields.";
      }
    };
    const validationError = validateFields();

    if (validationError) {
      alert(validationError);
    } else {
      // All fields are valid, proceed with form submission
      handleUpload();
    }
  };
  const handleFileChange = (e) => {
    const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];
    // Get the selected file from the input element
    const file = e.target.files[0];

    if (file) {
      // Check if the selected file type is allowed
      if (allowedFileTypes.includes(file.type)) {
        setSelectedFile(file);
        const imageUrl = URL.createObjectURL(file);
        setImageUrl(imageUrl);
      } else {
        // Display an error message or toast to the user
        alert("Please select a valid JPG, JPEG, or PNG file.");
        // Clear the input field
        e.target.value = "";
      }
    } else {
      setSelectedFile(null);
      setImageUrl(""); // Clear the image URL if no file is selected
    }
  };
  const handleUpload = async () => {
    setDisableSubmit(true);
    const formData = new FormData();
    if (bannerName) {
      formData.append("banner_name", bannerName);
    }
    if (priority) {
      formData.append("priority", priority);
    }
    if (selectedLanguage) {
      formData.append("language_id", selectedLanguage.value);
    }
    if (selectedFile) {
      formData.append("banner", selectedFile);
    }
    if (selectedProgram) {
      formData.append("is_rlp", selectedProgram.value);
    }
    if (selectedDisplayStatus) {
      formData.append("is_display_app", selectedDisplayStatus.value);
    }
    try {
      const url = `${CAMPAIGN_URL}banners/add-edit-banner`;
      // Send a POST request to the backend API endpoint
      await axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Required for file uploads
          },
        })
        .then((response) => {
          console.log(response);
          if (response.code === 200)
            console.log("Data Submitted Successfully:", response.data);
          history.push("/banner-list");
          setDisableSubmit(true);
        });

      // Handle the response from the server

      //   alert("Data Submitted Successfully!");
      toast.success("Data Submitted Successfully!", {
        autoClose: 2000,
      });
    } catch (error) {
      if (error.response && error.response.status === 409) {
        // Handle the duplicate entry error
        alert("Duplicate entry. Please check your input data.");
      } else {
        // Handle other errors
        console.error("Error:", error);
        alert("An error occurred while submitting the data.");
      }
      setDisableSubmit(false);
    }
  };

  //calling apis
  const fetchLanguage = async () => {
    const languagedata = await bannerService.getAllLanguageDropdown();
    const options = languagedata.data.map((fmr) => ({
      value: fmr.id,
      label: fmr.name,
    }));
    setLanguage(options);
  };
  //usereffect
  useEffect(() => {
    fetchLanguage();
  }, []);
  return (
    <React.Fragment>
      <div className="page-content " style={{ minHeight: "900px" }}>
        <div className="container-fluid">
          {disableSubmit === true ? (
            <Spinner />
          ) : (
            <Row>
              <Col md={12}>
                <Card>
                  <CardHeader className="mb-3">
                    <CardTitle className="font-size-20">Add Banner</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={6} lg={3} className="mt-1">
                        <h6>
                          Banner Name<span style={{ color: "red" }}>*</span>
                        </h6>
                        <Input
                          maxLength={300}
                          type="search"
                          value={bannerName}
                          placeholder="Enter Banner Name"
                          onChange={(e) => handleBanner(e)}
                        />
                      </Col>
                      <Col md={6} lg={3} className="mt-1">
                        <h6>
                          Language<span style={{ color: "red" }}>*</span>
                        </h6>
                        <Select
                          value={selectedLanguage}
                          placeholder="Select Language"
                          nooptionsmessage={() => "No options found"}
                          // onFocusCapture={fetchFmr}
                          onChange={(e) => handleLanguage(e)}
                          options={language}
                        />
                      </Col>
                      <Col md={6} lg={3} className="mt-1">
                        <h6>
                          Program<span style={{ color: "red" }}>*</span>
                        </h6>
                        <Select
                          value={selectedProgram}
                          placeholder="Select Program"
                          nooptionsmessage={() => "No options found"}
                          // onFocusCapture={fetchFmr}
                          onChange={(e) => handleProgram(e)}
                          options={programOptions}
                        />
                      </Col>
                      <Col md={6} lg={3} className="mt-1">
                        <h6>
                          Priority<span style={{ color: "red" }}>*</span>
                        </h6>
                        <Input
                          maxLength={300}
                          type="number"
                          value={priority}
                          placeholder="Enter Priority"
                          onChange={(e) => handlePriority(e)}
                        />
                      </Col>
                    </Row>
                    {/* <Row> */}
                    {/* <Col md={6} lg={3} className="mt-3">
                        <h6>Type</h6>
                        <Select
                          value={selectedType}
                          placeholder="Select Type"
                          noOptionsMessage={() => "No options found"}
                          onChange={(e) => handleType(e)}
                          options={typeOptions}
                        />
                      </Col> */}
                    {/* {selectedType && selectedType.value === "BANNER" && (
                        <Col md={6} lg={3} className="mt-3">
                          <div>
                            <h6>Image Upload</h6>
                            <Input
                              maxLength={300}
                              className="form-control"
                              type="file"
                              accept="image/*"
                              onChange={handleFileChange}
                            />
                          </div>
                          {!imageUrl && (
                            <span style={{ color: "red" }}>
                              Image ratio must be in 300 X 400 pixel.
                            </span>
                          )}
                          {imageUrl && (
                            <img
                              src={imageUrl}
                              alt="Uploaded"
                              style={{ marginTop: "10px", maxWidth: "100%" }}
                            />
                          )}
                        </Col>
                      )}
                      {selectedType && selectedType.value === "VIDEO" && (
                        <Col md={6} lg={6} className="mt-3">
                          <h6>
                            Video<span style={{ color: "red" }}>*</span>
                          </h6>
                          <Input
                            maxLength={300}
                            type="text"
                            value={priority}
                            placeholder="Enter Video Link"
                            onChange={(e) => handlePriority(e)}
                          />
                        </Col>
                      )} */}

                    {/* <Col md={6} lg={3} className="mt-3">
                        <h6>Action Source</h6>
                        <Select
                          value={selectedActionSource}
                          placeholder="Select Action Source"
                          noOptionsMessage={() => "No options found"}
                          onChange={(e) => handleActionSource(e)}
                          options={actionSourceOptions}
                        />
                      </Col> */}
                    {/* </Row> */}
                    <Row>
                      <Col md={6} lg={3} className="mt-3">
                        <h6>
                          Status<span style={{ color: "red" }}>*</span>
                        </h6>
                        <Select
                          value={selectedDisplayStatus}
                          placeholder="Select Status"
                          nooptionsmessage={() => "No options found"}
                          // onFocusCapture={fetchFmr}
                          onChange={(e) => handleDisplayStatus(e)}
                          options={displayStatusOptions}
                        />
                      </Col>
                      <Col md={6} lg={3} className="mt-3">
                        <div>
                          <h6>Image Upload</h6>
                          <Input
                            maxLength={300}
                            className="form-control"
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                          />
                        </div>
                        {!imageUrl && (
                          <span style={{ color: "red" }}>
                            Image ratio must be in 300 X 400 pixel.
                          </span>
                        )}
                        {imageUrl && (
                          <img
                            src={imageUrl}
                            alt="Uploaded"
                            style={{ marginTop: "10px", maxWidth: "100%" }}
                          />
                        )}
                      </Col>
                    </Row>
                    <Row className="mt-3 float-end">
                      <Col lg={3} className="w-100">
                        <button
                          className="btn btn-outline-warning float-end"
                          onClick={handleReset}
                          // style={{ width: "74px" }}
                        >
                          Reset
                        </button>{" "}
                        <Button
                          className="btn-warning float-end mx-2"
                          onClick={handleSubmit}
                          disabled={disableSubmit}
                        >
                          Submit
                        </Button>{" "}
                        {/* <Button
                          className="btn-warning float-end mx-2"
                          onClick={handleAddForm}
                        >
                          Add New
                        </Button>{" "} */}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddBanner;
