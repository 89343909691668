import axios from "axios";
import { API_URL } from "../Config/config";
import authService from "./auth.service";
const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const getRewardsCatlogue = async (params) => {
  return await axiosApi
  .post(API_URL + "constant/rewards", { ...params })
  .then((response) => {
    if (response.data.status === "success") {
      return { status: true, data: response.data };
    }
  })
  .catch((err) => {
    console.log(err);
    if (err?.response?.status === 403) {
      authService.autoLogout();
      window.location.href = "/login";
    }
  });
};


export default {
    getRewardsCatlogue
};
