import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";

import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";
import fuel from "../../assets/images/petrol-can.png";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.user = JSON.parse(localStorage.getItem("user"));
  }
  componentDidMount() {
    this.initMenu();
    this.user = JSON.parse(localStorage.getItem("user"));
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu" className="overflow-auto">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title text-light">{this.props.t("Menu")}</li>
            {/* {this.user.role_id === 24 ? (
              <li>
                <Link to="/master" className="has-arrow waves-effect">
                  <i className="ri-store-2-line"></i>
                  <span className="ms-1">{this.props.t("Master")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/zone-list">{this.props.t("Zone Head")}</Link>
                  </li>
                  <li>
                    <Link to="/regional-list">
                      {this.props.t("Regional Head")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/state-head-list">
                      {this.props.t("State Head")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/sales-officer-list">
                      {this.props.t("Sales Officer")}
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null} */}

            {/* {this.user.role_id === 22 ? null : (
              <li>
                <Link to="/master" className="has-arrow waves-effect">
                  <i className="ri-store-2-line"></i>
                  <span className="ms-1">{this.props.t("Master")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/zone-head">{this.props.t("Zone Head")}</Link>
                  </li>
                  <li>
                    <Link to="/reginal-head">
                      {this.props.t("Reginal Head")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/state-head">{this.props.t("State Head")}</Link>
                  </li>
                  <li>
                    <Link to="/sales-officer">
                      {this.props.t("Sales Officer")}
                    </Link>
                  </li>
                </ul>
              </li>
            )} */}
            {/* {this.user.role_id === 22 ? null : ( */}
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ms-1">{this.props.t("MLP Dashboard")}</span>
              </Link>
            </li>
            {/* ')} */}
            {/* {this.user.role_id === 22 ? null : ( */}
            <li>
              <Link to="/rlp-dashboard" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ms-1">{this.props.t("RLP Dashboard")}</span>
              </Link>
            </li>
            {/* )} */}
            <li>
              {this.user.role_id === 16 ? (
                <Link to="/kyc-verification-sh" className="waves-effect">
                  <i className="ri-dashboard-line"></i>
                  <span className="ms-1">
                    {this.props.t("KYC Verification SH")}
                  </span>
                </Link>
              ) : null}
            </li>
            <li>
              {this.user.role_id === 22 ? (
                <Link to="/kyc-verification-so" className="waves-effect">
                  <i className="ri-dashboard-line"></i>
                  <span className="ms-1">
                    {this.props.t("KYC Verification SO")}
                  </span>
                </Link>
              ) : null}
            </li>
            {this.user.user_id === 16871604148 || this.user.user_id === 170290210433 || this.user.user_id === 1694161664 ||
            this.user.user_id === 1685633729 || this.user.user_id === 1685633729789 || this.user.user_id === 1694161665 ? (
              <li>
                <Link to="/mechanic-scheme" className="waves-effect">
                  <i className=" ri-user-2-line"></i>
                  <span className="ms-1">
                    {this.props.t("Campaign Manager")}
                  </span>
                </Link>
              </li>
            ) : null}
            {/* {this.user.role_id === 24 || this.user.role_id === 15 ? (
              <li>
                <Link to="/banner-list" className="waves-effect">
                  <i className="ri-image-line"></i>
                  <span className="ms-1">{this.props.t("View Banners")}</span>
                </Link>
              </li>
            ) : null} */}
            {/* {this.user.role_id === 22 ? null : ( */}
            <li>
              <Link to="/view-mechanic" className="waves-effect">
                <i className="fas fa-wrench"></i>
                <span className="ms-1">{this.props.t("View Mechanic")}</span>
              </Link>
            </li>
            {/* )} */}
            {/* {this.user.role_id === 22 ? null : ( */}
            <li>
              <Link to="/view-retailer" className="waves-effect">
                <i className="ri-user-line"></i>
                <span className="ms-1">{this.props.t("View Retailer")}</span>
              </Link>
            </li>

            {/* )} */}
            {/* {this.user.role_id === 22 ? null : ( */}
            <li>
              <Link to="/segment-volume" className="waves-effect">
                <i>
                  <img src={fuel} alt="" height="22"></img>
                </i>
                <span className="ms-1">{this.props.t("User Insights")}</span>
              </Link>
            </li>
            {/* )} */}
            {/* {this.user.role_id === 22 ? null : ( */}
            <li>
              <Link to="/product-insights" className="waves-effect">
                <i className="ri-file-search-line"></i>
                <span className="ms-1">{this.props.t("SKU-wise Data")}</span>
              </Link>
            </li>
            {/* )} */}
            {/* {this.user.role_id === 22 ? null : ( */}
            <li>
              <Link to="/product-segment" className="waves-effect">
                <i className="ri-donut-chart-fill"></i>
                <span className="ms-1">
                  {this.props.t("Slab-based Consumption")}
                </span>
              </Link>
            </li>
            {/* )} */}
            {/* {this.user.role_id === 22 ? null : ( */}
            <li>
              <Link to="/view-redemption" className="waves-effect">
                <i className="ri-gift-line"></i>
                <span className="ms-1">{this.props.t("View Redemption")}</span>
              </Link>
            </li>
            {/* )} */}
            {/* {this.user.role_id === 22 ? null : ( */}
            <li>
              <Link to="/view-expiry" className="waves-effect">
                <i className="far fa-calendar-times"></i>
                <span className="ms-1">{this.props.t("View Expiry")}</span>
              </Link>
            </li>
            {this.user.user_id === 16867585598 || this.user.user_id === 16871604148 ? (
               <li>
               <Link to="/ProductWisePackSize" className="waves-effect">
                 <i className="far fa-calendar-times"></i>
                 <span className="ms-1">{this.props.t("Product Wise PackSize")}</span>
               </Link>
             </li>
             ) : null }
            {/* )} */}
            {/* {this.user.role_id === 22 ? null : ( */}
            <li>
              <Link to="/e-transfer" className="waves-effect">
                <i
                  className="fas fa-money-bill-alt"
                  style={{ fontSize: "15px" }}
                ></i>
                <span className="ms-1">{this.props.t("View E-Transfer")}</span>
              </Link>
            </li>
            {/* )} */}
            {/* <li>
              <Link to="/reward-catlogue" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ms-1">{this.props.t("Rewards Catlogue")}</span>
              </Link>
              </li> */}
            {/* {this.user.role_id === 22 ? null : ( */}
            <li>
              <Link to="/view-transaction" className="waves-effect">
                <i className="ri-coupon-2-line"></i>
                <span className="ms-1">{this.props.t("View Transaction")}</span>
              </Link>
            </li>
            {/* )} */}
            <li>
              <Link to="/reward-catlogue" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ms-1">
                  {this.props.t("Rewards Catalogue")}
                </span>
              </Link>
            </li>
            {this.user.role_id === 22 || this.user.role_id === 16 ? null : (
              <li>
                <Link to="/user-activity" className="waves-effect">
                  <i className="ri-user-line"></i>
                  <span className="ms-1">{this.props.t("User Activity")}</span>
                </Link>
              </li>
            )}
            {this.user.role_id != 15 ? null : (
              // <li>
              //   <Link to="/gamification" className="waves-effect">
              //     <i className="ri-user-line"></i>
              //     <span className="ms-1">{this.props.t("Gamification")}</span>
              //   </Link>
              // </li>
             <li>
              <Link to="/view-game-activity" className="waves-effect">
                <i className="ri-user-line"></i>
                <span className="ms-1">{this.props.t("View Game Activity")}</span>
              </Link>
            </li>
            )}
            {/* <li>
              <Link to="/audience" className="waves-effect">
              <i className="ri-notification-2-line"></i>
                <span className="ms-1">{this.props.t("Notification")}</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(withNamespaces()(SidebarContent))
);
