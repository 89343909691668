import React from "react";
import ReactApexChart from "react-apexcharts";

const ChartApex = (props) => {
  let maxArray = 0;
  const data = props.data;
  const volumeArray = data?.volumes;

  if (volumeArray) {
    maxArray = Math.max(...volumeArray);
  }
  const series = [{ name: "Volume(L)", data: data?.volumes }];
  const options = {
    chart: {
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#FCB92C", "#34c38f"],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      width: [3, 3],
      curve: "straight",
    },
    title: {
      text: "Volume(L)",
      align: "left",
      style: {
        fontWeight: "normal",
        fill: "#black",
        // opacity: 2,
      },
    },
    grid: {
      row: {
        colors: ["transparent", "transparent"],
        opacity: 0.2,
      },
      borderColor: "#f1f1f1",
    },
    markers: {
      style: "inverted",
      size: 5,
    },
    xaxis: {
      categories: data?.dates,
      style: {
        fontWeight: "bold",
        fill: "#black",
        opacity: 100,
      },
      title: {
        text: "Date",
      },
    },
    yaxis: {
      title: {
        text: "Volume(L)",
      },
      min: 0,
      max: maxArray,
      // max: 100
      style: {
        fontWeight: "bold",
        fill: "#black",
        opacity: 100,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            toolbar: {
              show: true,
            },
          },
          legend: {
            show: true,
          },
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height="280"
      />
    </React.Fragment>
  );
};

export default ChartApex;
