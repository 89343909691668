import React, { useState } from "react";
import {
  Table,
  Button,
  ModalFooter,
  CardTitle,
  ModalBody,
  Row,
  Col,
  Modal,
  ModalHeader,
} from "reactstrap";
import "../../pages/Banner/Banner.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import bannerService from "../../services/banner.service";
import { toast } from "react-toastify";

const BannerListTable = ({ data = [], setRefreshTable }) => {
  const [modalStandard, setModalStandard] = useState(false);
  const [bannerId, setBannerId] = useState("");
  const history = useHistory();

  const openImageInNewTab = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };
  const toggle = () => {
    setModalStandard(!modalStandard);
  };
  const handleEditClick = (id) => {
    history.push(`/edit-banner/${id}`);
  };
  const handleDelete = async () => {
    const body = {
      id: bannerId,
    };
    const response = await bannerService.deleteBannerById(body);
    if (response.data.code === 200) {
      toast.success(response.data.message, {
        autoClose: 2000,
      });
      // window.location.reload();
      setRefreshTable(true);
    } else {
      toast.error(response.data.message, {
        autoClose: 2000,
      });
    }
  };
  const rows = data?.map((item, index) => (
    <tr key={index}>
      {/* <td>{item.end_user_name ? item.end_user_name : "N/A"}</td> */}
      <td className="d-flex">
        <Button
          title="Edit Banner"
          size="sm"
          color="warning"
          style={{ marginRight: "10px" }}
          onClick={async () => await handleEditClick(item.id)}
        >
          <i className="warning ri-edit-2-line"></i>
        </Button>
        <Button
          title="Delete Banner"
          size="sm"
          color="warning"
          style={{ marginRight: "10px" }}
          onClick={() => {
            toggle(item.id);
            setBannerId(item.id);
          }}
        >
          <i className="warning ri-delete-bin-line"></i>
        </Button>
      </td>
      <td>{item.banner_name ? item.banner_name : "N/A"}</td>
      {/* <td
        className="pointer-on-hover"
        onClick={() => openImageInNewTab(item.image)}
      >
        <img height={30} src={item.image} alt="Banner Image" />
      </td> */}
      <td>
        {item.image ? (
          <div
            className="pointer-on-hover"
            onClick={() => openImageInNewTab(item.image)}
          >
            <img height={30} src={item.image} alt="Banner Image" />
          </div>
        ) : (
          <img height={30} src={item.image} alt="Banner Image" />
        )}
      </td>

      <td>{item.language_name ? item.language_name : "N/A"}</td>
      <td>{item.type ? item.type : "N/A"}</td>
      <td>{item.action_source ? item.action_source : "N/A"}</td>
      <td>{item.is_display_app === "1" ? "Active" : "Inactive"}</td>
      <td>{item.is_rlp === "1" ? "RLP" : "MLP"}</td>
      {/* <td>{item.priority ? item.priority : "N/A"}</td> */}
      <td>
        {item.priority !== undefined
          ? item.priority === 0
            ? 0
            : item.priority
          : "N/A"}
      </td>
    </tr>
  ));
  return (
    <>
      <div className="table-rep-plugin pt-1">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <Table id="tech-companies-1" striped bordered responsive hover>
            <thead>
              <tr>
                {/* <th></th> */}
                <th>Action</th>
                <th>Banner Name</th>
                <th>Image</th>
                <th>Language</th>
                <th>Type</th>
                <th>Action Source</th>
                <th>Status</th>
                <th>Program</th>
                <th>Priority</th>
                {/* Add more headers for each property in the objects */}
              </tr>
            </thead>
            {data?.length > 0 ? (
              <tbody>{rows}</tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="12" className="text-center">
                    No Data Found
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
      </div>{" "}
      <Row>
        <Col sm={6} md={4} xl={3} className="mt-4">
          <Modal isOpen={modalStandard} toggle={toggle}>
            <ModalHeader toggle={toggle}>
              Banner Id:
              {bannerId}
            </ModalHeader>
            <ModalBody>
              <CardTitle>Do you want to delete?</CardTitle>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                onClick={toggle}
                color="light"
                className="waves-effect"
              >
                No
              </Button>
              <Button
                type="button"
                color="primary"
                className="waves-effect waves-light btn-warning"
                onClick={() => handleDelete()}
              >
                Yes
              </Button>
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default BannerListTable;
