import axios from "axios";
import { API_URL } from "../Config/config";
import authService from "./auth.service";
const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
// const API_URL = "http://devanalyticsapi.eniclub.in/api/v1/";
// const API_URL = "http://localhost:4077/api/v1/";
const API_URL_1 = "https://ieniapi.eniclub.in/api/";

const GetKycVerification = async (params) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  params.sh_id = user.sh_so_id ? user.sh_so_id : user.user_id;
  params.state_head_id = user.sh_so_id ? user.sh_so_id : user.user_id;
  params.role_id = user.role_id;

  // params.sh_id = '1611331402';
  // params.state_head_id = '1611331402';
  // params.role_id = '16';
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `SKIP`;
  }
  return await axiosApi
    .get(API_URL_1 + "kyc/rlp-kyc", { params })
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};
const GetKycVerificationSO = async (params) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  params.so_id = user.sh_so_id ? user.sh_so_id : user.user_id;
  params.role_id = user.role_id;

  // params.sh_id = '1611331402';
  // params.state_head_id = '1611331402';
  // params.role_id = '16';
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `SKIP`;
  }
  return await axiosApi
    .get(API_URL_1 + "kyc/rlp-kyc", { params })
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};
const getStates = async () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = "SKIP";
  }
  let params = {
    user_id: user.sh_so_id ? user.sh_so_id : user.user_id,
    client_dashboard_display: 1,
  };
  return await axiosApi
    .get(API_URL_1 + "areas/state", { params })
    .then((response) => {
      console.log("response--------", response);
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};
const getDistricts = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = "SKIP";
  }
  let params = {
    ...body,
    user_id: user.sh_so_id ? user.sh_so_id : user.user_id,
  };

  return await axiosApi
    .get(API_URL_1 + "areas/district", { params })
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      } else {
        return { status: false };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};
const updateKycDetails_rlp = async (user_id, form) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  const activity = {
    actor_id: user.sh_so_id ? user.sh_so_id : user.user_id,
    // actor_id:'1611331402',
    activity_log: "KYC_UPDATED",
    check_id: user.sh_so_id ? user.sh_so_id : user.user_id,
  };
  // params.sh_id = '1658762333';
  // params.state_head_id = '1658762333';
  // params.role_id = '16';

  form.append("action_by", user.sh_so_id ? user.sh_so_id : user.user_id);
  form.append("user_activity", JSON.stringify(activity));
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `SKIP`;
  }
  return await axiosApi
    .post(API_URL_1 + "kyc/updateKyc-details-rlp", form)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/kyc-verification-sh";
      }
    });
};

export default {
  GetKycVerification,
  getStates,
  getDistricts,
  updateKycDetails_rlp,
  GetKycVerificationSO,
};
