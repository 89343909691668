import axios from "axios";
import { API_URL } from "../Config/config";
import { useHistory } from "react-router-dom";
import authService from "./auth.service";

const axiosApi = axios.create({
  // Increase timeout to 10 seconds (10,000 milliseconds)
  timeout: 120000,
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const GetProgramOverview = async (body) => {
  // let history = useHistory();
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .post(API_URL + "dashboard/rlp/program-overview", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }

      // return {
      //   status: {
      //     code: err?.response?.status,
      //     message: err?.response?.data?.message,
      //   },
      // };
    });
};

const GetProgramOverview1 = async (body) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .post(API_URL + "dashboard/rlp/program-overview-section", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data };
      }
    })
    .catch((err) => {
      console.log(err);

      // return {
      //   status: false,
      // };
    });
};



const GetEarningOverview = async (body) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .post(API_URL + "dashboard/rlp/earning", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        // localStorage.removeItem("user");
        authService.autoLogout();
        window.location.href = "/login";
      }
      // return {
      //   status: {
      //     code: err?.response?.status,
      //     message: err?.response?.data?.message,
      //   },
      // };
    });
};

const GetEarningOverviewCount = async (body) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .post(API_URL + "dashboard/rlp/earning-overview", body)
    .then((response) => {
      if (response?.status === 200) {
        return { status: true, data: response?.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        // localStorage.removeItem("user");
        authService.autoLogout();
        window.location.href = "/login";
      }
      // return {
      //   status: false,
      // };
    });
};

const GetProgramOverviewData = async (body) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .post(API_URL + "dashboard/rlp/data", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        // localStorage.removeItem("user");
        authService.autoLogout();
        window.location.href = "/login";
      }
      // return {
      //   status: false,
      // };
    });
};

const GetRedemptionOverviewDataCount = async (body) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .post(API_URL + "dashboard/rlp/redeemption-overview", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        // localStorage.removeItem("user");
        authService.autoLogout();
        window.location.href = "/login";
      }
      // return {
      //   status: false,
      // };
    });
};

const GetRedemptionOverviewData = async (body) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .post(API_URL + "dashboard/rlp/redeemption", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        // localStorage.removeItem("user");
        authService.autoLogout();
        window.location.href = "/login";
      }
      // return {
      //   status: false,
      // };
    });
};

export default {
  GetProgramOverview,
  GetProgramOverviewData,
  GetRedemptionOverviewDataCount,
  GetRedemptionOverviewData,
  GetEarningOverviewCount,
  GetEarningOverview,
  GetProgramOverview1,
};
