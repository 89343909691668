import React, { useState } from "react";
import { Table, Col, Row } from "reactstrap";
import "../../pages/ViewExpiry/ViewExpiry.css";

const ProductWisePackSizeTable = ({ data = [] }) => {
  console.log("data---",data);


  // Map each object in the data array to a table row

  return (
    <>
      <div className="table-rep-plugin pt-1">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
        <Table id="tech-companies-1" striped bordered responsive hover>
        <thead className="p-3">
        {data.length > 0 ? (
          <tr>
              <th className="p-3">Product Name</th>
              {data[0]['product_data'].map((item) => {
                return (  
                <th>{item['pack_size']}</th>
                
              )})}
              <th className="p-3">Total</th>
          </tr>
          ) : null}
      </thead>
      <tbody>
      {data.map((item) => {
        return (
          <tr>
              <td>{item.product ? item.product : "N/A"}</td>
              {item['product_data'].map((item1) => {
        return (  
        <td>{Math.floor(item1['valume'])}</td>
        )}
        )}
          <td>{Math.floor(item.total)}</td>
        </tr>
    );})}
      </tbody>
        </Table>
        </div>
      </div>{" "}
    </>
  );
};

export default ProductWisePackSizeTable;
