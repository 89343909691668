import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { toggleRightSidebar } from "../../store/actions";
import campaignSer from "../../services/campaignService.js";
import {
  // ToastContainer,
  toast,
} from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  FormGroup,
  Input,
  CardTitle,
} from "reactstrap";
import "./ViewGameActivity.css";
import Select from "react-select";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ViewMechanicTable from "../../components/Table/ViewGameActivity";
import viewMechanicService from "../../services/viewRetailer.service";
import ReportModal from "../../components/Modal/ReportModal";
import viewTransactionService from "../../services/viewTransaction.service";
import { showLoader, hideLoader } from "../../actions/loading";
import { useDispatch, useSelector } from "react-redux";
import Spinner, { SpinnerSmall } from "../../components/Loader/Spinner";
import moment from "moment";
import { DateRange } from "react-date-range";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Master from "../../components/Master/Master";

const ViewRetailer = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const master = useSelector((state) => state.master.setList.body);
  const role = user ? user.role_id : null;
  const loading = useSelector((state) => state.loading.loading);
  const [countLoading, setCountLoading] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isOpenfirstMonth, setIsOpenFirstmonth] = useState(false);
  const currentDate = new Date();
  const [modal, setModal] = useState(false);
  // currentDate.setDate(currentDate.getDate() - 1); // current date
  const [disableDownload, setDisableDownload] = useState(false);
  const initialStartDate = moment(currentDate).subtract(89, "days").toDate(); // subtract 90 days from current date
  const initialDateRange = [
    // {
    //   startDate: new Date(moment().format("YYYY-MM-DD")),
    //   endDate: new Date(moment().format("YYYY-MM-DD")),
    //   key: "selection",
    // },
    {
      startDate: new Date(moment(currentDate)),
      endDate: new Date(moment(currentDate)),
      key: "selection",
    },
  ];

  const [disableSubmit, setDisableSubmit] = useState(false);
  const [totalCount, setTotalCount] = useState(null);
  const [docStatus, setDocStatus] = useState("");
  const [nameMobile, setNameMobile] = useState("");
  const [dateRange, setDateRange] = useState(initialDateRange);
  // const [dateRange, setDateRange] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;
  const [dateFilters, setDateFilters] = useState({
    start_date: "",
    end_date: "",
  });
  const [reportName, setReportName] = useState("");
  const [documentOptions, setDocumentOptions] = useState([
    { value: "", label: "Select Status" },
    { value: "PHYSICAL", label: "PHYSICAL" },
    { value: "COIN", label: "COIN" },
    // { value: "POINTS", label: "POINTS" },
  ]);

  const [state, setState] = useState();
  const [stateOptions, setstateOptions] = useState([]);
  const [stateId, setStateId] = useState([]);

  const [segment, setSegment] = useState("");
  const [segmentOptions, setsegmentOptions] = useState([]);
  const [segmentId, setSegmentId] = useState([]);

  const [game, setGame] = useState("");
  const [gameOption, setGmaoption] = useState([]);
  const [gameId, setGameId] = useState([]);

  //modified Body data
  let bodyFilters = {
    regional_mapping: master ? master.regional_mapping : null,
    limit: pageSize,
    offset: currentPage,
  };

  if (docStatus.length > 0) {
    bodyFilters.status = docStatus;
  }
  if (nameMobile.length > 0) {
    if (parseInt(nameMobile) > 0) {
      bodyFilters.mobile = nameMobile;
    } else {
      bodyFilters.name = nameMobile;
    }
  }

  if (dateFilters.start_date.length > 0 && dateFilters.end_date.length > 0) {
    bodyFilters.start_date = dateFilters.start_date;
    bodyFilters.end_date = dateFilters.end_date;
  }

  if (segment.length > 0) {
    bodyFilters.segment_id = segmentId;
  }

  if (game.length > 0) {
    bodyFilters.game_id = gameId;
  }
  const toggleModal = async () => {
    setModal(!modal);
    setReportName("");
  };
  //handleFunction
  const handledateRange = (item) => {
    setDateRange([item.selection]);
    setDateFilters((prevFilters) => ({
      // ...prevFilters,
      start_date: moment(item.selection.startDate).format("YYYY-MM-DD"),
      end_date: moment(item.selection.endDate).format("YYYY-MM-DD"),
    }));
  };
  const fetchGetStates = async () => {
    const stateData = await viewTransactionService.getStates();
    const options = stateData.data.map((state) => ({
      value: state.name,
      label : state.name,
      id: state.id,
    }));
    setstateOptions(options);
  };

  const fetchGetGames = async () => {
    const stateData = await viewTransactionService.getGames();
    const options = stateData.data.map((state) => ({
      value: state.name,
      label: state.name,
      id: state.id,
      des : state.description
    }));
    setGmaoption(options);
  };

  const handleStateChanges = async (event) => {
    console.log("event",event);
    setState(event);
  };

  const handleSegment = async (e) => {
    console.log("e---",e,segmentOptions);
    const selectedState = segmentOptions.find(
      (option) => option.value === e.target.value
    );
    if (selectedState) {
      console.log("selectedState--",selectedState);
      setSegment(selectedState.value);
      setSegmentId(selectedState.id);
    } else {
      setSegment(""); // set state to empty string if "Select State" is selected
      setSegmentId("");
    }
  };

  const handleGameChanges = async (e) => {
    const selectedState = gameOption.find(
      (option) => option.value === e.target.value
    );
    if (selectedState) {
      setGame(selectedState.value);
      setGameId(selectedState.id);
    } else {
      setGame(""); // set state to empty string if "Select State" is selected
      setGameId("");
    }
  };

  const handleExportMechanic = async () => {
    // setCountRefresh(false);
    setModal(false);
    setDisableDownload(true);
    // toast.success("Preparing your file", {
    //   autoClose: 2000,
    // });
    bodyFilters.report_name = reportName;
    setReportName("");
    console.log("call activity url here ----");
    await viewMechanicService.exportGameActivity(bodyFilters).then((res) => {
      if (res.code === 200) {
        setDisableDownload(false);
        toast.success("Report sent to your email", {
          autoClose: 2000,
        });
      } else {
        setDisableDownload(false);
        toast.error(res.message, {
          autoClose: 2000,
        });
      }
    });
  };

  const handleFirstMonthButtonState = () => {
    setIsOpenFirstmonth(!isOpenfirstMonth);
  };

  const handleDateRangeClose = () => {
    setIsOpenFirstmonth(false);
    // perform some action here
  };


  const handleSubmit = async () => {
    setCurrentPage(1);
    setIsOpenFirstmonth(false);
    fetchViewMechanicsDataAndCount();
  };
  const handleReset = async () => {
    setDocStatus("");
    setNameMobile("");
    setState("");
    setGame("");
    setSegment("");
    setSegmentId([]);
    // setstateOptions([]);
    setStateId([]);
    setDateFilters({
      start_date: "",
      end_date: "",
    });
    setCurrentPage(1);
    bodyFilters = {
      limit: pageSize,
      offset: currentPage,
    };
    setDateRange(initialDateRange);
   
    console.log("state --", state);
    setState("");
    console.log("state -- 1", state);
    fetchViewMechanicsDataAndCount(true);
  };


  //calling API
  const fetchViewMechanicsDataAndCount = async (reset) => {
    console.log("reset",reset)
    let states = [];
    if(reset!=true && (state?.length > 0)) {
      for (let i = 0; i < state.length; i++) {
        states.push(state[i].id);
      }
      bodyFilters.state_id =  JSON.stringify(states)
    }
    dispatch(showLoader());
    console.log("bodyFilters---------", bodyFilters);
    const mechanicList = await viewMechanicService.GetGameActivity(bodyFilters);
    if (mechanicList.status === true) {
      await mechanicList.data.data.forEach(e => {
        if(e.gift_type == 'PHYSICAL') {
          let d = JSON.parse(e.game_settings);
          const dd = d.find(ele => ele.reward_id === e.reward_id);
          e['reward_name'] = dd['reward_name'];
        }

        if(e.play_time) {
          e.play_time = convertSeconds(e.play_time);
        }
        
      });
      setData(mechanicList.data.data);
      console.log("data--",data);
      if (mechanicList.data.count > 0) {
        setTotalPages(mechanicList.data.pagination.totalPages);
        setTotalCount(mechanicList.data.count);
        dispatch(hideLoader());
      } else {
        setTotalPages(0);
        setTotalCount(0);
        dispatch(hideLoader());
      }
    } else if (mechanicList.status === false) {
      setData([]);
      setTotalPages(0);
      setTotalCount(0);
      dispatch(hideLoader());
    }
    dispatch(hideLoader());
  };

  function convertSeconds(seconds) {
    const minutes = Math.floor(seconds / 60); // Get whole minutes
    const remainingSeconds = seconds % 60; // Get the remaining seconds
  
    return `${minutes}m ${remainingSeconds}s`;
  }

  const handlePage = (event) => {
    const buttonElement = event.target; // Your event target
    if (buttonElement.tagName === "BUTTON") {
      const pageNumber = buttonElement.textContent;
      console.log(pageNumber); // Output: "2"
      setCurrentPage(pageNumber);
    }
  };

  useEffect(async () => {
        // GetSegmentList();
        const segmentoptions = await campaignSer.GetSegmentList();
        if (segmentoptions?.status) {
          setsegmentOptions(segmentoptions?.data);
        }
    fetchGetStates();
    fetchViewMechanicsDataAndCount();
  }, [currentPage]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="mb-3">
                  <div className="row table_top">
                    <Col lg={4} md={4} sm={5} xs={6}>
                      <CardTitle className="font-size-18">
                        View Game Activity
                      </CardTitle>
                    </Col>
                    <Col
                      lg={7}
                      md={7}
                      sm={6}
                      xs={5}
                      className="total-count-class"
                    >
                      <div className="d-flex justify-content-end">
                        <span style={{ float: "left", paddingRight: "5px" }}>
                          Total count:
                        </span>
                        {totalCount === null ? (
                          <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                        ) : (
                          totalCount
                        )}
                      </div>
                    </Col>
                    {role === 16 || role === 22 ? null : (
                      <Col lg={1} md={1} sm={1}>
                        {" "}
                        <div
                          className="download-excel"
                          style={{ opacity: disableDownload ? 0.5 : 1 }}
                        >
                          <i
                            title={
                              disableDownload
                                ? "Preparing your file"
                                : "Export to Excel"
                            }
                            className="fa fa-download"
                            onClick={disableDownload ? null : toggleModal}
                            disabled={disableDownload}
                          />{" "}
                          <ReportModal
                            isOpen={modal}
                            toggle={toggleModal}
                            modalHeader="Game Activity Report"
                            handleAPI={handleExportMechanic}
                            reportName={reportName}
                            setReportName={setReportName}
                          />
                        </div>
                      </Col>
                    )}
                  </div>
                  <div>
                    <Row className="mt-3">
                    <Col md={3}>
                        <h6>Game</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={game}
                            onFocusCapture={fetchGetGames}
                            onChange={handleGameChanges}
                          >
                            <option value="">Select Game</option>
                            {gameOption.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label} 
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <h6>Gift Type</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={docStatus}
                            onChange={(e) => setDocStatus(e.target.value)}
                          >
                            {documentOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <h6>Search</h6>
                        <Input
                          type="search"
                        placeholder="Phone no. / Name"
                          id="search_name_mobile"
                          value={nameMobile}
                          onChange={(e) => {
                            setNameMobile(e.target.value);
                          }}
                        />
                      </Col>
                      <Col md={3}>
                        <h6>State Name</h6>
                        <FormGroup className="mb-0">
                        <Select
                                  // isDisabled={isEdit ? true : false}
                                  className="w-100"
                                  closeMenuOnSelect={false}
                                  value={state}
                                  isMulti
                                  options={stateOptions}
                                  id="product_series"
                                  name="product_series"
                                  onFocusCapture={fetchGetStates}
                                  onChange={(event) => {
                                    handleStateChanges(event);
                                  }}
                                />

                          {/* <select
                            className="form-select"
                            value={state}
                            onFocusCapture={fetchGetStates}
                            onChange={handleStateChanges}
                          >
                            <option value="">Select State</option>
                            {stateOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select> */}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                    <Col md={3}>
                    <h6>Segment</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={segment}
                            // onFocusCapture={fetchGetStates}
                            onChange={handleSegment}
                          >
                            <option value="">Select Segment</option>
                            {segmentOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                    <Col md={3}>
                        {isOpenfirstMonth === false ? (
                          <div>
                            <h6>Select Dates</h6>
                            <button
                              className="form-select"
                              onClick={handleFirstMonthButtonState}
                            >
                              {dateFilters.start_date && dateFilters.end_date
                                ? `${new Date(
                                    dateFilters.start_date
                                  ).toLocaleString("default", {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                  })} - ${new Date(
                                    dateFilters.end_date
                                  ).toLocaleString("default", {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                  })}`
                                : "select date range"}
                            </button>
                          </div>
                        ) : (
                          <div className="customDateOverlay">
                            <label className="form-label">Select Dates: </label>
                            <div className="">
                              <DateRange
                                editableDateInputs={true}
                                onChange={handledateRange}
                                moveRangeOnFirstSelection={false}
                                ranges={dateRange}
                                rangeColors={["#FCB92C", "#FCB92C", "#FCB92C"]}
                                showSelectionPreview={true}
                                shouldCloseOnSelect={true}
                                maxDate={currentDate}
                                minDate={new Date(moment("2024-10-01"))}
                                onClose={handleDateRangeClose}
                              //  className="dateRangeWrapper"
                              />
                              <Button
                                className="btn-danger float-end mt-1"
                                onClick={handleFirstMonthButtonState}
                              >
                                Close
                              </Button>
                              <Button
                                className="btn-warning float-end me-2 mt-1"
                                onClick={handleSubmit}
                              >
                                Search
                              </Button>
                            </div>
                          </div>
                        )}
                      </Col>
                      </Row>
                    <Row className="mt-3 float-end">
                      <Col lg={3} className="w-100 mt-2">
                        <Button
                          className="btn btn-danger btn-secondary float-end"
                          onClick={handleReset}
                          // style={{ width: "74px" }}
                        >
                          Reset
                        </Button>{" "}
                        <Button
                          className="btn-warning float-end mx-2"
                          onClick={disableSubmit ? null : handleSubmit}
                          disabled={loading === true ? true : false}
                        >
                          Search
                        </Button>{" "}
                      </Col>
                      
                    </Row>
                    {/* )} */}
                  </div>
                </CardHeader>
                <div className="bg-body pb-3 mt-n3">
                  <Master />{" "}
                </div>
                <CardBody className="">
                  <div className="">
                    <div className="row">
                      {loading === true ? (
                        <Spinner />
                      ) : (
                        <ViewMechanicTable data={data} />
                      )}

                      {totalCount === null ? (
                        <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                      ) : (
                        <>
                          <div className="d-flex justify-content-center ">
                            <Stack spacing={2}>
                              <Pagination
                                count={totalPages}
                                hidePrevButton
                                hideNextButton
                                onClick={handlePage}
                              />
                            </Stack>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>{" "}
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStateToProps, { toggleRightSidebar })(
  withNamespaces()(ViewRetailer)
);
