import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Spinner, { SpinnerSmall } from "../../components/Loader/Spinner";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
} from "reactstrap";
import Select from "react-select";
import moment from "moment";
import { DateRange } from "react-date-range";
import bannerService from "../../services/banner.service";
import "react-daterange-picker/dist/css/react-calendar.css";
import { showLoader, hideLoader } from "../../actions/loading";
import { useDispatch, useSelector } from "react-redux";
import BannerListTable from "../../components/Table/BannerListTable";
import { useHistory } from "react-router-dom";
import ReportModal from "../../components/Modal/ReportModal";
const ViewBanners = () => {
  const [data, setData] = useState([]);
  const [refreshTable, setRefreshTable] = useState(false);
  const [isOpenfirstMonth, setIsOpenFirstmonth] = useState(false);
  const loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const history = useHistory();

  const [disableSubmit, setDisableSubmit] = useState(false);
  const [totalCount, setTotalCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [searchName, setSearchName] = useState("");
  const [selectedType, setSelectedType] = useState({
    value: 0,
    label: "All",
  });
  const typeOptions = [
    { value: 0, label: "All" },
    { value: "BANNER", label: "BANNER" },
    { value: "VIDEO", label: "VIDEO" },
    { value: "TEXT", label: "TEXT" },
  ];
  const [selectedActionSource, setSelectedActionSource] = useState({
    value: 0,
    label: "All",
  });
  const actionSourceOptions = [
    { value: 0, label: "All" },
    { value: "BANNER", label: "BANNER" },
    { value: "VIDEO", label: "VIDEO" },
    { value: "TEXT", label: "TEXT" },
  ];
  const [selectedProgram, setSelectedProgram] = useState({
    value: 0,
    label: "All",
  });
  const programOptions = [
    { value: 0, label: "All" },
    { value: "1", label: "RLP" },
    { value: "0", label: "MLP" },
  ];
  const [selectedDisplayStatus, setSelectedDisplayStatus] = useState({
    value: 0,
    label: "All",
  });
  const displayStatusOptions = [
    { value: 0, label: "All" },
    { value: "1", label: "Active" },
    { value: "2", label: "Inactive" },
  ];
  const [languageOptions, setLanguageOptions] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([
    {
      value: 0,
      label: "ALL",
      id: 0,
    },
  ]);
  let bodyFilters = {
    limit: pageSize,
    offset: currentPage,
    type: selectedType.value,
    action_source: selectedActionSource.value,
    is_rlp: selectedProgram.value,
    is_display_app: selectedDisplayStatus.value,
    language_id: selectedLanguage.value,
  };

  if (searchName.length > 0) {
    bodyFilters.banner_name = searchName;
  }

  //handle function
  const handleType = async (e) => {
    bodyFilters.type = e.value;
    setSelectedType(e);
  };
  const handleActionSource = async (e) => {
    bodyFilters.action_source = e.value;
    setSelectedActionSource(e);
  };
  const handleProgram = async (e) => {
    bodyFilters.is_rlp = e.value;
    setSelectedProgram(e);
  };
  const handleDisplayStatus = async (e) => {
    bodyFilters.is_display_app = e.value;
    setSelectedDisplayStatus(e);
  };
  const handleLanguageChanges = async (e) => {
    bodyFilters.language_id = e.value;
    setSelectedLanguage(e);
  };
  const handlePage = (event) => {
    const buttonElement = event.target; // Your event target
    if (buttonElement.tagName === "BUTTON") {
      const pageNumber = buttonElement.textContent;
      console.log(pageNumber); // Output: "2"
      setCurrentPage(pageNumber);
    }
  };
  const handleAddBanner = () => {
    history.push("/add-banner");
  };
  const handleReset = async () => {
    setSearchName("");
    setSelectedType({
      value: 0,
      label: "ALL",
    });
    setSelectedProgram({
      value: 0,
      label: "ALL",
    });
    setSelectedDisplayStatus({
      value: 0,
      label: "ALL",
    });
    setSelectedActionSource({
      value: 0,
      label: "ALL",
    });
    setSelectedLanguage([
      {
        value: 0,
        label: "ALL",
        id: 0,
      },
    ]);
    bodyFilters = {
      limit: pageSize,
      offset: currentPage,
    };
    handleSubmit();
  };

  const handleSubmit = async () => {
    setCurrentPage(1);
    setIsOpenFirstmonth(false);
    fetchBannerDataAndCount();
  };
  //calling api
  const fetchBannerDataAndCount = async () => {
    dispatch(showLoader());

    const data = await bannerService.GetBannerList(bodyFilters);

    if (data.status === true) {
      setTotalPages(data.data.pagination.totalPages);
      setTotalCount(data.data.pagination.count);
      setData(data.data.data);
    }
    dispatch(hideLoader());
  };
  const fetchLanguage = async () => {
    const languagedata = await bannerService.getAllLanguage();
    const options = languagedata.data.map((fmr) => ({
      value: fmr.id,
      label: fmr.label,
      id: fmr.id,
    }));
    setLanguageOptions(options);
  };

  //usereffect
  useEffect(() => {
    fetchLanguage();
  }, []);
  useEffect(() => {
    if (refreshTable) {
      fetchBannerDataAndCount();
      setRefreshTable(false);
    }
  }, [refreshTable]);
  useEffect(() => {
    fetchBannerDataAndCount();
  }, [currentPage]);
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            {" "}
            <Col md={12}>
              <Card>
                <CardHeader className="mb-3">
                  <div className="row table_top">
                    <Col lg={4} md={4} sm={5} xs={6}>
                      <CardTitle className="font-size-18">
                        Banner List
                      </CardTitle>
                    </Col>
                    <Col
                      lg={7}
                      md={7}
                      sm={6}
                      xs={5}
                      className="total-count-class"
                    >
                      <div className="d-flex justify-content-end">
                        <span style={{ float: "left", paddingRight: "5px" }}>
                          Total count:
                        </span>
                        {totalCount === null ? (
                          <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                        ) : (
                          totalCount
                        )}
                      </div>
                    </Col>{" "}
                    {/* <Col lg={1} md={1} sm={1}>
                      {" "}
                      <div
                        className="download-excel"
                        style={{ opacity: disableDownload ? 0.5 : 1 }}
                      >
                        <i
                          title={
                            disableDownload
                              ? "Preparing your file"
                              : "Export to Excel"
                          }
                          className="fa fa-download"
                          onClick={disableDownload ? null : toggleModal}
                          disabled={disableDownload}
                        />
                        <ReportModal
                          isOpen={modal}
                          toggle={toggleModal}
                          modalHeader="Meet Report"
                          handleAPI={handleExportMeet}
                          reportName={reportName}
                          setReportName={setReportName}
                        />
                      </div>
                    </Col> */}
                  </div>
                  <div>
                    <Row>
                      <Col md={3}>
                        <h6>Type</h6>
                        <Select
                          value={selectedType}
                          placeholder="Select Type"
                          noOptionsMessage={() => "No options found"}
                          onChange={(e) => handleType(e)}
                          options={typeOptions}
                        />
                      </Col>
                      <Col md={3}>
                        <h6>Action Source</h6>
                        <Select
                          value={selectedActionSource}
                          placeholder="Select Action Source"
                          noOptionsMessage={() => "No options found"}
                          onChange={(e) => handleActionSource(e)}
                          options={actionSourceOptions}
                        />
                      </Col>
                      <Col md={6} lg={3}>
                        <h6>Language</h6>
                        <Select
                          value={selectedLanguage}
                          placeholder="Select Language"
                          noOptionsMessage={() => "No options found"}
                          // onFocusCapture={fetchFmr}
                          onChange={(e) => handleLanguageChanges(e)}
                          options={languageOptions}
                        />
                      </Col>
                      <Col md={6} lg={3}>
                        <h6>Banner Search</h6>
                        <Input
                          type="search"
                          placeholder="Name"
                          id="search_name"
                          value={searchName}
                          onChange={(e) => {
                            setSearchName(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={3}>
                        <h6>Program</h6>
                        <Select
                          value={selectedProgram}
                          placeholder="Select Program"
                          noOptionsMessage={() => "No options found"}
                          onChange={(e) => handleProgram(e)}
                          options={programOptions}
                        />
                      </Col>
                      <Col md={3}>
                        <h6>Status</h6>
                        <Select
                          value={selectedDisplayStatus}
                          placeholder="Select DisplayStatus"
                          noOptionsMessage={() => "No options found"}
                          onChange={(e) => handleDisplayStatus(e)}
                          options={displayStatusOptions}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3 float-end">
                      <Col lg={3} className="w-100">
                        <button
                          className="btn btn-outline-warning float-end"
                          onClick={handleReset}
                          // style={{ width: "74px" }}
                        >
                          Reset
                        </button>{" "}
                        <Button
                          className="btn-warning float-end mx-2"
                          onClick={disableSubmit ? null : handleSubmit}
                          disabled={disableSubmit}
                        >
                          Search
                        </Button>{" "}
                        <Button
                          className="btn-warning float-end mx-2"
                          onClick={handleAddBanner}
                        >
                          Add Banner
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </CardHeader>
                <CardBody
                  className=""
                  style={{
                    height: data && data.length === 0 ? "400px" : "auto",
                  }}
                >
                  <div className="">
                    <div className="row">
                      {loading === true ? (
                        <Spinner />
                      ) : (
                        <BannerListTable
                          data={data}
                          setRefreshTable={setRefreshTable}
                        />
                      )}

                      {totalCount === null ? (
                        <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                      ) : (
                        <>
                          {totalCount === 0 ? null : (
                            <div className="d-flex justify-content-center ">
                              <Stack spacing={2}>
                                <Pagination
                                  count={totalPages}
                                  hidePrevButton
                                  hideNextButton
                                  onClick={handlePage}
                                />
                              </Stack>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>{" "}
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewBanners;
