import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  CardTitle,
  FormGroup,
  Input,
} from "reactstrap";
//Import Breadcrumb
import Service from "../../services/catlogue.service";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import { useSelector, useDispatch } from "react-redux";
import { DateRange } from "react-date-range";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
// import CustomModal1 from "../../components/Modal/kycVerificationModalRlpSo";
import { showLoader, hideLoader } from "../../actions/loading";
import "./rewardsCatlogue.css";
import {
  // ToastContainer,
  toast,
} from "react-toastify";
import moment from "moment";
import Spinner, { SpinnerSmall } from "../../components/Loader/Spinner";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Search } from "@mui/icons-material";
import noImage from "../../../src/assets/images/notfoundimage.png";
import { blue } from "@mui/material/colors";

const KycVerificationSo = () => {
  const [totalCount, settotalCount] = useState(0);
  const [totalPages, settotalPages] = useState(1);
  // const [currentPage, setCurrentPage] = useState(1);
  const [rewardCode, setrewardCode] = useState("");
  const [programType, setprogramType] = useState("");
  const [rewardType, setrewardType] = useState("");
  const [rewardName, setrewardName] = useState("");
  const [points, setpoints] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageData, setPagedata] = useState([]);
  const [minPoints, setminPoints] = useState(null);
  const [maxPoints, setmaxPoints] = useState(null);
  const [resetCounter, setresetCounter] = useState(0);
  const [pointsRange, setpointsRange] = useState("");
  let currentPage = 1;
  const programList = [
    { label: "Select Program", value: "" },
    { label: "MLP", value: "mlp" },
    { label: "RLP", value: "rlp" },
  ];
  const rewardTypeList = [
    { label: "Select Reward Type ", value: "" },
    { label: "Physical Gift", value: "DELIVER" },
    { label: "E-Voucher", value: "VOUCHER" },
  ];
  const pointsRangeMap = {
    "0 - 500": { min_points: 0, max_points: 500 },
    "501 - 1000": { min_points: 501, max_points: 1000 },
    "1001 - 10000": { min_points: 1001, max_points: 10000 },
    "10001 - 100000": { min_points: 10001, max_points: 100000 },
    "100001 - 1000000": { min_points: 100001, max_points: 1000000 },
    "1000001 - 1500000": { min_points: 1000001, max_points: 1500000 },
  };
  const pointsRangeList = [
    { label: "Select Points Range", value: "" },
    { label: "0 - 500", value: "0 - 500" },
    { label: "501 - 1000", value: "501 - 1000" },
    { label: "1001 - 10000", value: "1001 - 10000" },
    { label: "10001 - 100000", value: "10001 - 100000" },
    { label: "100001 - 1000000", value: "100001 - 1000000" },
    { label: "1000001 - 1500000", value: "1000001 - 1500000" },
  ];
  const validateInputs = () => {
    if (minPoints < 0 || maxPoints < 0 || minPoints > maxPoints) {
      toast.warn(
        <div>
          Invalid points range <br />
          1. Input must positive number <br />
          2.Min points should be grater than Max points
        </div>,
        {
          autoClose: 2000,
        }
      );
      return true;
    }
    return false;
  };
  const Search = () => {
    fetchPageData();
  };
  const Reset = async () => {
    setprogramType("");
    setpointsRange("");
    setrewardType("");
    setrewardName("");
    setminPoints("");
    setmaxPoints("");
    setrewardCode("");
    setresetCounter((prev) => prev + 1);
  };
  useEffect(async () => {
    await fetchPageData();
  }, [resetCounter]);
  const fetchPageData = async () => {
    setLoading(true);
    let pointsFilter = { min_points: 0, max_points: 1000000000 };
    let body = {
      ...(rewardName && { name: rewardName }),
      ...(programType && { program_type: programType }),
      ...(points && { points: points }),
      ...(rewardType && { type: rewardType }),
      ...(pointsRange && pointsRangeMap[pointsRange]),
      ...(rewardCode && { rewardcode: rewardCode?.trim() }),
      offset: currentPage,
      limit: 12,
    };
    Service.getRewardsCatlogue(body)
      .then((data) => {
        console.log(data);
        if (data?.data?.code == 200) {
          setPagedata(data?.data?.data);
          settotalPages(data?.data?.pagination?.totalPages);
          settotalCount(data?.data?.pagination?.totalCount);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong", {
          autoClose: 2000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
    currentPage = 1;
  };
  // useEffect(async() => {
  //    fetchPageData();
  // }, [currentPage]);
  const handlePage = (event) => {
    const buttonElement = event.target; // Your event target
    if (buttonElement.tagName === "BUTTON") {
      const pageNumber = buttonElement.textContent;
      currentPage = pageNumber;
      fetchPageData();
    }
  };
  const getImgUrl = (url) => {
    return url || noImage;
  };
  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Row>
              <Col md={12}>
                <Card className="mb-3">
                  <CardHeader>
                    <div className="row table_top">
                      <Col lg={4} md={4} sm={5} xs={6}>
                        <CardTitle className="font-size-18">
                          Rewards Catalogue
                        </CardTitle>
                      </Col>
                      <Col
                        lg={7}
                        md={7}
                        sm={6}
                        xs={5}
                        className="total-count-class"
                      >
                        <div className="d-flex justify-content-end">
                          <span style={{ float: "left", paddingRight: "5px" }}>
                            Total count:
                          </span>
                          {totalCount === null ? (
                            <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                          ) : (
                            totalCount
                          )}
                        </div>
                      </Col>
                    </div>
                    <div>
                      <Row className="mt-3">
                        <Col md={2}>
                          <h6>Program</h6>
                          <FormGroup className="mb-0">
                            <select
                              className="form-select"
                              value={programType}
                              onChange={(e) => setprogramType(e.target.value)}
                            >
                              {programList.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md={3}>
                          <h6>Reward Type</h6>
                          <FormGroup className="mb-0">
                            <select
                              className="form-select"
                              value={rewardType}
                              onChange={(e) => setrewardType(e.target.value)}
                            >
                              {rewardTypeList.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md={3}>
                          <h6>Reward</h6>
                          <Input
                            type="search"
                            placeholder="Reward Name"
                            id="search_reward"
                            value={rewardName}
                            onChange={(e) => {
                              setrewardName(e.target.value);
                            }}
                          />
                        </Col>
                        <Col md={2}>
                          <h6>Reward Code</h6>
                          <Input
                            type="search"
                            placeholder="Reward Code"
                            id="search_reward_code"
                            value={rewardCode}
                            onChange={(e) => {
                              setrewardCode(e.target.value);
                            }}
                          />
                        </Col>
                        <Col md={2}>
                          <h6>Points Range</h6>
                          <FormGroup className="mb-0">
                            <select
                              className="form-select"
                              value={pointsRange}
                              onChange={(e) => {
                                console.log(JSON.stringify(e.target.value));
                                setpointsRange(e.target.value);
                              }}
                            >
                              {pointsRangeList.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        {/* <Col>
                                   <Row>
                                     <h6>Points </h6>
                                   </Row>
                                    <Row>
                                    <Col>
                                <p>min</p>
                                <Input
                                    type="number"
                                    placeholder="Points"
                                    id="search_by_points"
                                    value={minPoints}
                                    onChange={(e) => {
                                setminPoints(e.target.value);
                                }}
                                />
                                </Col>
                                To
                                <Col>
                                <p style={{textAlign : 'right'}}>max</p>
                                <Input
                                    type="number"
                                    placeholder="Points"
                                    id="search_by_points"
                                    value={maxPoints}
                                    onChange={(e) => {
                                setmaxPoints(e.target.value);
                                }}
                                />
                                </Col>
                                    </Row> */}
                        {/* </Col> */}
                        <Col md={12} className="mt-4">
                          <Button
                            className="btn btn-danger btn-secondary float-end"
                            onClick={() => {
                              Reset();
                            }}
                            // style={{ width: "74px" }}
                          >
                            Reset
                          </Button>{" "}
                          <Button
                            className="btn-warning float-end mx-2"
                            onClick={() => {
                              Search();
                            }}
                            disabled={loading === true ? true : false}
                          >
                            Search
                          </Button>{" "}
                        </Col>
                      </Row>
                    </div>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
            <Row>
              {loading === true ? (
                <Spinner />
              ) : (
                <Row className="mx-auto">
                  {(pageData || []).map((value, idx) => (
                    <Col className="card col-lg-4 col-md-6 col-12 ">
                      <div className="image-container mx-auto">
                        <img
                          src={getImgUrl(value.image)}
                          alt={value.rewardname}
                        />
                      </div>
                      <div>
                        <h2 className="card-title">{value.rewardname}</h2>
                        <label className="label">
                          Reward Code : {value.rewardcode}
                        </label>
                        <label className="label">
                          Program : {value.is_rlp === 1 ? "RLP" : "MLP"}
                        </label>
                        <label className="label">
                          Reward Type : {value.reward_type}
                        </label>
                        <label className="label">Points : {value.points}</label>
                      </div>
                      <div className="card-text card-text-scroll">
                        {value.description}
                      </div>
                    </Col>
                  ))}
                </Row>
              )}

              <div>
                {totalCount === 0 && (
                  <h3 style={{ textAlign: "center" }}> No data found !</h3>
                )}
              </div>
              <>
                <div className="d-flex justify-content-center ">
                  <Stack spacing={2}>
                    <Pagination
                      count={totalPages}
                      hidePrevButton
                      hideNextButton
                      onClick={handlePage}
                    />
                  </Stack>
                </div>
              </>
            </Row>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};
export default KycVerificationSo;
