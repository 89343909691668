import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Card, CardBody, Table } from "reactstrap";

//Import Breadcrumb
import "react-datepicker/dist/react-datepicker.css";

import "bootstrap/dist/css/bootstrap.css";
import getSegment from "../../services/segment.service";
import "../../pages/Segment/category.css";

import { showLoader, hideLoader } from "../../actions/loading";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/Loader/Spinner";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-datepicker/dist/react-datepicker.css";
import { toggleRightSidebar } from "../../store/actions";
import Pagination from "../../components/Table/Pagination";

const ProductInsights = (props) => {
  const loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();

  const [productList, setProductList] = useState([]);
  const [segment, setSegment] = useState("ALL");

  // const currentDate = new Date();

  const [formattedSecondMonthStartDate, setFormattedSecondMonthStartDate] =
    useState();
  const [formattedSecondMonthEndDate, setFormattedSecondMonthEndDate] =
    useState();
  const [formattedFirstMonthStartDate, setFormattedFirstMonthStartDate] =
    useState();
  const [formattedFirstMonthEndDate, setFormattedFirstMonthEndDate] =
    useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  let body = {
    start_date: props.body.start_date,
    end_date: props.body.end_date,
    segment: segment,
    user_id: props.body.mlp_user_id,
    limit: 10,
    offset: currentPage,
  };

  const submit = async () => {
    dispatch(showLoader());
    await getSegment.GetMLPProduct(body).then((res) => {
      if (res?.status === true) {
        console.log(res.data.data, "//////////////////////////////////");
        setProductList(res.data.data);
        setTotalPages(res.data.pagination.total_pages);
        setFormattedFirstMonthStartDate(res.data.dates.first_month_start);
        setFormattedFirstMonthEndDate(res.data.dates.first_month_end);
        setFormattedSecondMonthStartDate(res.data.dates.second_month_start);
        setFormattedSecondMonthEndDate(res.data.dates.second_month_end);
      }
    });
    dispatch(hideLoader());
  };

  useEffect(() => {
    submit(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="container-fluid">
            <div className="row">
              <h4 className="card-title pt-4">Product Volume(ltr)</h4>
              <div className="table-rep-plugin pt-4">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  {loading === true ? (
                    <Spinner />
                  ) : (
                    <Table id="tech-companies-1" striped bordered responsive>
                      <thead>
                        <tr className="text-center">
                          <th data-priority="1">Segment</th>
                          <th data-priority="1">Product</th>
                          <th data-priority="1">Pack Size</th>
                          <th data-priority="3">
                            {" "}
                            {`${formattedFirstMonthStartDate} 
                                       - 
                                      ${formattedFirstMonthEndDate} (Ltr)`}
                          </th>
                          <th data-priority="1">
                            {`${formattedSecondMonthStartDate} 
                                       - 
                                      ${formattedSecondMonthEndDate} (Ltr)`}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {productList.length === 0 ? (
                          <tr className="align">
                            <td colSpan="" className="text-center">
                              No data available
                            </td>
                          </tr>
                        ) : (
                          productList?.map((product) => (
                            <tr key={product.id}>
                              <td>{product.segment}</td>
                              <td>{product.product}</td>
                              <td>{product.pack_size}</td>
                              <td>{product.first_month_volume}</td>
                              <td>{product.second_month_volume}</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </Table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </CardBody>
        <div className="d-flex justify-content-center ">
          <Pagination
            page={currentPage}
            setPage={setCurrentPage}
            totalPages={totalPages}
          />
        </div>
      </Card>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStateToProps, { toggleRightSidebar })(
  withNamespaces()(ProductInsights)
);
