/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
// Redux
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Label,
  CardTitle,
  Card,
  CardHeader,
  CardBody,
  Input,
  Table
} from "reactstrap";
import campaignSer from "../../../services/campaignService.js";

// availity-reactstrap-validation
import { Formik } from "formik";

// import images

import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { SpinnerSmall } from "../../../components/Loader/Spinner.jsx";
import moment from "moment";
import { campaign_programs } from "../../../Config/config.js";
import ExcelJS from "exceljs";
function RetailerScheme(props) {
  const routeParams = props.match.params;
  const program_id = routeParams.programId;
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();
  if (
    !routeParams ||
    campaign_programs.includes(routeParams.programId) === false
  ) {
    history.goBack();
  }
  const [numberOfUsers, setNumberOfUsers] = useState([]);
  const [offerStatus, setOfferStatus] = useState("");
  const [frequencyButton, setFrequencyButton] = useState(false);
  const [dayWiseButton, setdayWiseButton] = useState(false);
  const [buttonDisabled, setButtonDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [csvFile, setCSVFile] = useState(null);
  const [file, setFile] = useState(null);
  const [isEdit, setIsEdit] = useState(routeParams.id ? true : false);
  const [editDetails, setEditVal] = useState({});
  const [mobile, setMobile] = useState([]);
  const [inputMobile, setInputMobile] = useState("");
  const [segment, setSegment] = useState("ALL");
  const [tempSegment, setTempSegment] = useState("");
  const [bclass, setbClass] = useState("");
  const [segmentOption, setSegmentOption] = useState([
    { value: "Select", label: "Select" },
  ]);
  const [defaultPayoutRs, setDefaultPayoutRs] = useState("0");
  const [defaultPayoutPoints, setDefaultPayoutPoints] = useState("0");
  const [bonusPayoutRs, setBonusPayoutRs] = useState("0");
  const [bonusPayoutPoints, setBonusPayoutPoints] = useState("0");
  const [limitPack, setLimitPack] = useState("");
  const [tempPack, setTempPack] = useState(false);
  const [numberOfCoupon, setNumberOfCoupon] = useState("");
  const [numberOfTimesCouponAvail, setNumberOfTimesCouponAvail] = useState("");
  const [budget, setBudget] = useState("");
  const [title, setTitle] = useState("");
  const [budgetUsed, setBudgetUsed] = useState(0);
  const [totalRupees, setTotalRupees] = useState("");
  const [totalPoints, setTotalPoints] = useState("");
  const [mobileNumbers, setMobileNumbers] = useState([]);
  const [mobileInput, setMobileInput] = useState("");
  const [error, setError] = useState("");
  const [series, setSeries] = useState([]);
  const [seriesOption, setSeriesOptions] = useState([]);
  const [radioValue, setRadioValue] = useState("DRAFT");
  const [existData, setExistData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [showtitle, setShowTitle] = useState(false);
  const [showsegment, setShowSegment] = useState(false);
  const [showseries, setShowSeries] = useState(false);
  const [showclass, setShowClass] = useState(false);
  const [showimage, setShowImage] = useState(false);
  const [showvalue, setShowValue] = useState(false);
  const [showsdate, setShowsDate] = useState(false);
  const [showedate, setShowEdate] = useState(false);
  const [bonusType, setBonusType] = useState("");
  const radios = [
    { name: "Draft", value: "DRAFT" },
    { name: "Publish", value: "PUBLISH" },
  ];

  const stackingOption = [
    { value: 1, label: "ON" },
    { value: 0, label: "OFF" },
  ];
  const multiplyPointOption = [
    { value: 0, label: "Select" },
    { value: 1, label: "1X" },
    { value: 2, label: "2X" },
    { value: 3, label: "3X" },
    { value: 4, label: "4X" },
    { value: 5, label: "5X" },
    { value: 6, label: "6X" },
    { value: 7, label: "7X" },
    { value: 8, label: "8X" },
    { value: 9, label: "9X" },
    { value: 10, label: "10X" },
  ];
  const multiplyRupeesOption = [
    { value: 0, label: "Select" },
    { value: 1, label: "1X" },
    { value: 2, label: "2X" },
    { value: 3, label: "3X" },
    { value: 4, label: "4X" },
    { value: 5, label: "5X" },
    { value: 6, label: "6X" },
    { value: 7, label: "7X" },
    { value: 8, label: "8X" },
    { value: 9, label: "9X" },
    { value: 10, label: "10X" },
  ];

  const classOptions = [
    {value: 0, label: "Select"},
    {value: 1, label: "A"},
    {value: 2, label: "B"},
    {value: 3, label: "C"},
    {value: 4, label: "D"},
  ]

  const [stacking, setStacking] = useState(stackingOption[0]);
  const [multiplyPoint, setmultiplyPoint] = useState(multiplyRupeesOption[0]);
  const [multiplyRupees, setmultiplyRupees] = useState(multiplyPointOption[0]);

  const restrictionOption = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const [restriction, setRestriction] = useState(restrictionOption[1]);
  const [bonusOnceADay, setBonusOnceADay] = useState(restrictionOption[1]);
  const [limitBonus, setLimitBonus] = useState();
  const [targetVolume, setTargetVolume] = useState(0);

  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [tempStartDate, setTempStartDate] = useState(
    moment(new Date()).add(1, "days").format("YYYY-MM-DD")
  );
  // const [endDate, setEndDate] = useState(
  //   moment(new Date()).add(1, "days").format("YYYY-MM-DD")
  // );
  console.log("5" - 3);
  console.log("5" + 3);
  const [tempEndDate, setTempEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [dayWiseList, setdayWiseList]=useState([
    {
      id: 0,
      name: "Sunday",
      value:false
    },
    {
      id: 1,
      name: "Monday",
      value:false
    },
    {
      id: 2,
      name: "Tuesday",
      value:false
    },
    {
      id: 3,
      name: "Wednesday",
      value:false
    },
    {
      id: 4,
      name: "Thursday",
      value:false
    },
    {
      id: 5,
      name: "Friday",
      value:false
    },
    {
      id: 6,
      name: "Saturday",
      value:false
    },
  ])
  const [dayWise, setdayWise] = useState({"Sunday":false,"Monday":false,"Tuesday":false,"Wednesday":false,"Thursday":false,"Friday":false,"Saturday":false});

  const downloadSampleXLSX = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sample Data");
    worksheet.columns = [{ header: "mobile_number", key: "mobile_number" }];
    // Add sample data
    if (isEdit) {
      numberOfUsers.forEach((mobile_number) => {
        worksheet.addRow({ mobile_number: mobile_number.toString() });
      });
    } else {
    }
    // worksheet.addRow({ mobile_number: "1234567890" });

    // Generate XLSX file
    const blob = await workbook.xlsx.writeBuffer();

    // Save and trigger the download
    const blobUrl = window.URL.createObjectURL(
      new Blob([blob], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = "data.xlsx";
    a.click();

    // Clean up resources
    window.URL.revokeObjectURL(blobUrl);
  };
  const updateValues = async (data) => {
    let bonusPoints = parseInt(bonusPayoutPoints * 0.5);
    let minimumBudget = parseInt(bonusPayoutRs, 10) + parseInt(bonusPoints, 10);
    if (!data.title) {
      setShowTitle(true);
      return;
    }
 

    const startMoment = moment(data.start_date, "YYYY-MM-DD");
    const endMoment = moment(data.end_date, "YYYY-MM-DD");
    // if (
    //   startMoment.isBefore(
    //     moment(new Date()).add(1, "days").format("YYYY-MM-DD")
    //   ) ||
    //   endMoment.isBefore(moment(new Date()).add(1, "days").format("YYYY-MM-DD"))
    // ) {
    //   toast.error(
    //     `Please select date greater than ${moment().format("YYYY-MM-DD")}`
    //   );
    //   return;
    // }
    // Compare the dates
    if (endMoment.isBefore(startMoment)) {
      // Raise an error or handle the error as needed
      toast.error("End date is before the start date");
      return;
    }
    if (mobileNumbers.length === 0 && !csvFile) {
      toast.error("Please enter mobile number or upload a file", {
        autoClose: 2000,
      });
      return;
    }
 
    if (!data.title) {
      setShowTitle(true);
      return;
    }

    const tempstartdate = moment(data.start_date, "YYYY-MM-DD").unix();
    const tempenddate = moment(data.end_date, "YYYY-MM-DD").unix();
    setLoading(true);

    let formData = new FormData();
    formData.append("title", title);
    // formData.append("pack", limitPack);
    formData.append("limitBonus", data.limitBonus);
    formData.append("campaign_budget", parseInt(data.budget));
    formData.append("stacking", data.stacking.value);
    formData.append("program_id", routeParams.programId);
    formData.append(
      "start_date",
      moment.unix(tempstartdate).utcOffset("+05:30").unix()
    );
    formData.append(
      "end_date",
      moment.unix(tempenddate).utcOffset("+05:30").unix()
    );
    formData.append("defaultPayoutRs", parseInt(data.defaultPayoutRs));
    formData.append("defaultPayoutPoints", parseInt(data.defaultPayoutPoints));
    formData.append("bonusPayoutRs", parseInt(data.bonusPayoutRs));
    formData.append("bonusPayoutPoints", parseInt(data.bonusPayoutPoints));

    formData.append("numberOfCoupon", data.numberOfCoupon);
    formData.append("numberOfTimesCouponAvail", data.numberOfTimesCouponAvail);
    formData.append("restriction", data.restriction.value.toLowerCase());
    const bonus_a_day =
      data.bonusOnceADay?.value.toLowerCase() === "yes" ? "1" : "0";
    formData.append("bonusOnceADay", bonus_a_day);
    // formData.append("limitPack", pack);
    formData.append("segment", data.segment.id);
    formData.append("offer_id", routeParams.id);
    formData.append("status", offerStatus);
    formData.append("dayWise",dayWiseButton === true ? JSON.stringify(dayWise) : '');
    let series = [];
    for (let i = 0; i < data.product_series.length; i++) {
      series.push(
        data.product_series[i].series_id
          ? data.product_series[i].series_id
          : data.product_series[i].value
      );
    }

    formData.append("series", JSON.stringify(series));
    formData.append("frequency", frequencyButton === true ? "1" : "0");
    formData.append("bonusType", routeParams.bonusType);
    formData.append("cash_multiplier", data.multiplyRupees.value);
    formData.append("points_multiplier", data.multiplyPoint.value);

    if (csvFile) {
      formData.append("excel", csvFile);
    } else {
      let mobiles = [];
      for (let i = 0; i < mobileNumbers.length; i++) {
        mobiles.push({ mobile_number: mobileNumbers[i] });
      }
      formData.append("mobile", JSON.stringify(mobiles));
    }
    await campaignSer.updateCampaignMehanic(formData).then((response) => {
      if (response.status) {
        setLoading(false);
        toast.success(
          !isEdit
            ? "Offer created successfully."
            : "Offer updated successfully."
        );
        if (routeParams.programId == 1) {
          history.push("/eni-club-listing");
        } else {
          history.push("/eni-vistaar-listing");
        }
      }
      if (response?.status === false && response?.code === 409) {
        setExistData(response.data);
        setShowTable(true);
      }
      setLoading(false);
    });
  };

  const submitValuetoApi = async (data,rdValue) => {
    console.log("data---", data,radioValue,rdValue);
    let bonusPoints = parseInt(bonusPayoutPoints * 0.5);
    let minimumBudget = parseInt(bonusPayoutRs, 10) + parseInt(bonusPoints, 10);
    if (!data.title) {
      setShowTitle(true);
    }
    if (!data.segment.id) {
      setShowSegment(true);
    }
    if (data.product_series.length == 0) {
      setShowSeries(true);
    }

    if (data.multiplyRupees.value == 0) {
      setShowClass(true);
    }

    if (!file) {
      setShowImage(true);
    }
    if (!data.target_volume || data.target_volume == 0) {
      setShowValue(true);
    }

    if (!data.end_date) {
      setShowEdate(true);
    }
    if (!data.start_date) {
      setShowsDate(true);
    }


  

    // Parse the dates using Moment.js
    const startMoment = moment(data.start_date, "YYYY-MM-DD");
    const endMoment = moment(data.end_date, "YYYY-MM-DD");
    if (
      startMoment.isBefore(
        moment(new Date()).add(1, "days").format("YYYY-MM-DD")
      ) ||
      endMoment.isBefore(moment(new Date()).add(1, "days").format("YYYY-MM-DD"))
    ) {
      toast.error(
        `Please select date greater than ${moment().format("YYYY-MM-DD")}`
      );
      return;
    }
    // Compare the dates
    if (endMoment.isBefore(startMoment)) {
      // Raise an error or handle the error as needed
      toast.error("End date is before the start date");
      return;
    }
  
    const tempstartdate = moment(data.start_date, "YYYY-MM-DD").unix();
    const tempenddate = moment(data.end_date, "YYYY-MM-DD").unix();
    let formData = new FormData();
    // formData.append("pack", limitPack);
    formData.append("title", title);
    formData.append("form_type", "mechanic_wise");
    formData.append("status", rdValue);
    formData.append("payout_volume", 0);
    // formData.append("stacking", data.stacking.value);
    // formData.append("cash_multiplier", 0);
    // formData.append("points_multiplier", 0);
    formData.append("program_id", 1);
    formData.append(
      "start_date",
      moment.unix(tempstartdate).utcOffset("+05:30").unix()
    );
    formData.append(
      "end_date",
      moment.unix(tempenddate).utcOffset("+05:30").unix()
    );
    formData.append("volume", data.target_volume);
    formData.append("pack_size", 0);
    formData.append("bonanza_class", data.multiplyRupees.label);
    // formData.append("bonusPayoutRs", 0);
    // formData.append("bonusPayoutPoints",0);
    // formData.append("frequency", 0);

    formData.append("numberofPacks", 0);
    // formData.append("numberOfTimesCouponAvail", 0);
    // formData.append("limitBonus", 0);
    // formData.append("restriction", data.restriction.value.toLowerCase());
    const bonus_a_day =
      data.bonusOnceADay?.value.toLowerCase() === "yes" ? "1" : "0";
    // formData.append("bonusOnceADay", bonus_a_day);
    // formData.append("limitPack", limitPack);
    formData.append("segment", data.segment.id);
    // formData.append("segment",  [].concat(data.segment.id ?? []));
    // formData.append("dayWise",dayWiseButton === true ? JSON.stringify(dayWise) : '');
    let series = [];
    for (let i = 0; i < data.product_series.length; i++) {
      series.push(data.product_series[i].series_id);
    }

    formData.append("series", JSON.stringify(series));
    formData.append("bonusType", routeParams.bonusType);
    console.log("mobileNumbers.length---", mobileNumbers.length);
    if (file) {
      formData.append("bonanza_pic", file);
    }
    if (csvFile) {
      formData.append("excel", csvFile);
    } else {
      let mobiles = [];
      for (let i = 0; i < mobileNumbers.length; i++) {
        mobiles.push({ mobile_number: mobileNumbers[i] });
      }
      formData.append("mobile", JSON.stringify(mobiles));
    }

    console.log("true false--",showclass ,showedate, showimage , showsdate , showsegment , showseries , showtitle, showvalue);
    if((showclass == false )&& (showedate==false) && (showimage==false) && (showsdate==false) &&( showsegment==false) && (showseries==false) && (showtitle==false) && (showvalue==false)) {
      setLoading(true);
      await campaignSer
        .saveBonanza(formData)
        .then((res) => {
          setLoading(false);
          if (res?.status === true) {
            setLoading(false);
            toast.success(
              !isEdit
                ? "Bonanza created successfully."
                : "Bonanza updated successfully."
            );
  
            if (routeParams.programId == 1) {
              history.push("/gamification-listing");
            } else {
              history.push("/gamification-listing");
            }
          }
          if (res?.status === false && res?.code === 409) {
            setLoading(false);
            setExistData(res.data);
            setShowTable(true);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  let setFieldValueFunction = null;

  const handleSelectFieldObject = (setFieldValue) => {
    setFieldValueFunction = setFieldValue;
  };
  const addMobileNumber = () => {
    if (mobileInput) {
      const numbers = mobileInput.split(",").map((number) => number.trim());
      // Validate mobile numbers
      if (numbers.length > 10) {
        setError("You can only add a maximum of 10 mobile numbers.");
      } else if (numbers.some((number) => !/^[6-9]\d{9}$/.test(number))) {
        toast.error("Please enter valid 10-digit mobile numbers.");
      } else {
        const newNumbers = [...new Set([...mobileNumbers, ...numbers])];
        if (newNumbers.length > 10) {
          toast.error("Only 10 mobile numbers are allowed");
          return;
        }
        setMobileNumbers(newNumbers);
        setError("");
      }
    }
  };

  const handleNumber = (index) => {
    const updatedMobileNumbers = [...mobileNumbers];
    updatedMobileNumbers.splice(index, 1);
    setMobileNumbers(updatedMobileNumbers);
    setInputMobile("");
  };

  const handleSegment = async (event) => {
    setSeries([]);
    setFieldValueFunction("product_series", []);
    setFieldValueFunction("segment", event);
    setTempSegment(event.id);
    const options = await campaignSer.GetSegmentSeriesList({
      id: event.id,
      program_id: program_id,
    });
    if (options.status) {
      setSeriesOptions(options?.data);
    }
    setShowSegment(false);
  };

  const handleSeries = async (event) => {
    if (event.length === 0 && isEdit==true) {
      const options = await campaignSer.GetSegmentSeriesList({
        id: segment.id,
        program_id: routeParams.programId,
      });
      if (options.status) {
        setSeriesOptions(options?.data);
      }
    }
    // if (event.length === 1) {
    //   const filteredArr = seriesOption.filter(
    //     (item) =>           
    //       item.points === event[0].points && item.rupees === event[0].rupees
    //   );
    //   setSeriesOptions(filteredArr);
    //   setDefaultPayoutRs(parseInt(event[0].rupees));
    //   setFieldValueFunction("defaultPayoutRs", parseInt(event[0].rupees));
    //   setDefaultPayoutPoints(parseInt(event[0].points));
    //   setFieldValueFunction("defaultPayoutPoints", parseInt(event[0].points));
    // } else 
    if (event.length === 0) {
      setDefaultPayoutRs("0");
      setFieldValueFunction("defaultPayoutRs", "0");
      setDefaultPayoutPoints("0");
      setFieldValueFunction("defaultPayoutPoints", "0");
      // await campaignSer
      //   .GetSegmentSeriesList({
      //     id: tempSegment,
      //     program_id: program_id,
      //   })
      //   .then((response) => {
      //     if (response.status === true) {
      //       setSeriesOptions(response?.data);
      //     }
      //   });
    }
    setFieldValueFunction("product_series", event);
    setSeries(event);
    setShowSeries(false);
    await setPayoutData(event);
  };

  const setPayoutData = async (event) => {
    console.log("series",event);
    let ids = event.map((obj) => obj.points);
    let data = ids.length !== 0 && new Set(ids).size !== 1;
    console.log("data---",data);
    if(event.length > 0) {
      if(data == false) {
        setDefaultPayoutRs(parseInt(event[0].rupees));
        setFieldValueFunction("defaultPayoutRs", parseInt(event[0].rupees));
        setDefaultPayoutPoints(parseInt(event[0].points));
        setFieldValueFunction("defaultPayoutPoints", parseInt(event[0].points));
      } else {
        setDefaultPayoutRs("0");
        setFieldValueFunction("defaultPayoutRs", "0");
        setDefaultPayoutPoints("0");
        setFieldValueFunction("defaultPayoutPoints", "0");
      }
    }
  }
  const handleStacking = (event) => {
    // setStacking(event.value);
    setFieldValueFunction("stacking", event);
  };
  const handleMultiplyRupees = (event) => {
    console.log("event ---", event.label);
    // setStacking(event.value);
    setFieldValueFunction("multiplyRupees", event);
    setFieldValueFunction("bclass", event.label);
    // setmultiplyRupees(event.label);
    console.log("multiplyRupees ---", bclass);
    setShowClass(false);
  };
  const handleMultiplyPoint = (event) => {
    // setStacking(event.value);
    setFieldValueFunction("multiplyPoint", event);
  };
  const handleRestriction = (event) => {
    setRestriction(event.value);
    setFieldValueFunction("restriction", event);
  };

  const handleBonusOnceADay = (event) => {
    setBonusOnceADay(event.value);
    setFieldValueFunction("bonusOnceADay", event);
  };
  const updateStatus = async (action) => {
    let response = await campaignSer.updateCampaign(action, routeParams.id);
    if (response.status) {
      toast.success(
        action === "INACTIVE"
          ? "Campaign inactivated successfully."
          : "Campaign Published Successfully."
      );
      if (routeParams.programId == 1) {
        history.push("/eni-club-listing");
      } else {
        history.push("/eni-vistaar-listing");
      }
    } else {
      toast.error("Something went wrong.");
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setCSVFile(file);
  };
  const handleFileChange1 = (event) => {
    const file = event.target.files[0];
    setFile(file);
    setShowImage(false);
  };
  const handleClose = () => {
    if (routeParams.programId == 1) {
      history.push("/eni-club-listing");
    } else {
      history.push("/gamification-listing");
    }
  };
  const removeFile = () => {
    setCSVFile(null);
    const inputElement = document.getElementById("upload");
    if (inputElement) {
      inputElement.value = ""; // Clear the file input value
    }
  };
  const removeFile1 = () => {
    setFile(null);
    const inputElement = document.getElementById("upload1");
    if (inputElement) {
      inputElement.value = ""; // Clear the file input value
    }
  };
  

  useEffect(async () => {
    // GetSegmentList();
    const segmentoptions = await campaignSer.GetSegmentList();
    if (segmentoptions?.status) {
      setSegmentOption(segmentoptions?.data);
    }

    if (isEdit) {
      let params = {
        program_id: routeParams.programId,
        offer_id: routeParams.id,
      };
      let details = await campaignSer.GetOfferDetails(params);
      let editDetails = details.data.offerDetails;
      let selectSegment = segmentoptions.data.find(
        (item) => item.id === editDetails.segment
      );
      const options = await campaignSer.GetSegmentSeriesList({
        id: editDetails.segment,
        program_id: routeParams.programId
      });
      if (options.status) {
        setSeriesOptions(options?.data);
      }
      let multiplyCash = multiplyRupeesOption.find(
        (item) => item.value === editDetails.cash_multiplier
      );
      let multiplypoint = multiplyPointOption.find(
        (item) => item.value === editDetails.points_multiplier
      );
      setEditVal(editDetails);
      setBonusType(editDetails.bonus_type);
      setSegment(selectSegment);
      setmultiplyRupees(multiplyCash);
      setFieldValueFunction(
        "multiplyRupees",
        multiplyCash
      );
      setmultiplyPoint(multiplypoint);
      setFieldValueFunction(
        "multiplyPoint",
        multiplypoint
      );
      if(editDetails.dayWise) {
        setdayWiseButton(true);
        // setFieldValueFunction("dayWiseButton",true);
        console.log("dayWiseButton----",dayWiseButton);
        let dayWiseData = JSON.parse(editDetails.dayWise);
        console.log("dayWise---",dayWiseData);
        setdayWise(dayWiseData);
        setFieldValueFunction("dayWise",dayWiseData);
        await Promise.all(dayWiseList.map(ele => {
          ele.value = dayWiseData[ele.name];
        }))
        setFieldValueFunction("dayWiseList",dayWiseList);
        setdayWiseList(dayWiseList);
        console.log("dayWise List",dayWiseList);
        console.log("dayWise---",dayWise);
      }
      if (details.data.file_path) {
      } else {
        setNumberOfUsers(details.data?.mobiles?.map((obj) => obj.value));
        setMobileNumbers(details.data?.mobiles?.map((obj) => obj.value));
        setMobile(details.data.mobiles);
        setInputMobile("");
        setFieldValueFunction("mobile", details.data.mobiles);
      }
      setBudget(editDetails.budget);
      setFieldValueFunction("budget", editDetails.budget);
      setBudgetUsed(editDetails.budget_used);
      setDefaultPayoutRs(editDetails.defaultPayoutRs);
      setFieldValueFunction("defaultPayoutRs", editDetails.defaultPayoutRs);
      setDefaultPayoutPoints(editDetails.defaultPayoutPoints);
      setFieldValueFunction(
        "defaultPayoutPoints",
        editDetails.defaultPayoutPoints
      );
      setBonusPayoutPoints(Number(editDetails.bonusPayoutPoints));
      setFieldValueFunction(
        "bonusPayoutPoints",
        Number(editDetails.bonusPayoutPoints)
      );
      setBonusPayoutRs(editDetails.bonusPayoutRs);
      const total_rupees =
        parseInt(editDetails.defaultPayoutRs) +
        parseInt(editDetails.bonusPayoutRs);
      const total_points =
        parseInt(editDetails.defaultPayoutPoints) +
        parseInt(editDetails.bonusPayoutPoints);
      setTotalPoints(total_points);
      setTotalRupees(total_rupees);
      setFieldValueFunction("bonusPayoutRs", editDetails.bonusPayoutRs);
      setFieldValueFunction("numberOfCoupon", editDetails.numberOfCoupon);
      setNumberOfCoupon(editDetails.numberOfCoupon);
      setNumberOfTimesCouponAvail(editDetails.numberOfTimesCouponAvail);
      setFieldValueFunction(
        "numberOfTimesCouponAvail",
        editDetails.numberOfTimesCouponAvail
      );
      setLimitBonus(editDetails.limitBonus);
      setFieldValueFunction("limitBonus", editDetails.limitBonus);
      setRestriction(
        editDetails.restriction === 1
          ? { value: "Yes", label: "Yes" }
          : { value: "No", label: "No" }
      );
      setFieldValueFunction(
        "restriction",
        editDetails.restriction === 1
          ? { value: "Yes", label: "Yes" }
          : { value: "No", label: "No" }
      );
      // setLimitPack(editDetails.limitPack);
      // setFieldValueFunction("limitPack", editDetails.limitPack);
      setStacking(
        editDetails.stacking == 1
          ? { value: "1", label: "ON" }
          : { value: "0", label: "OFF" }
      );
      setFieldValueFunction(
        "stacking",
        editDetails.stacking == 1
          ? { value: "1", label: "ON" }
          : { value: "0", label: "OFF" }
      );
      setStartDate(moment(editDetails.start_date).format("YYYY-MM-DD"));
      setTempStartDate(moment(editDetails.start_date).format("YYYY-MM-DD"));
      setFieldValueFunction(
        "start_date",
        moment(editDetails.start_date).format("YYYY-MM-DD")
      );
      setEndDate(moment(editDetails.end_date).format("YYYY-MM-DD"));
      setTempEndDate(moment(editDetails.end_date).format("YYYY-MM-DD"));
      setFieldValueFunction(
        "end_date",
        moment(editDetails.end_date).format("YYYY-MM-DD")
      );
      setSeries(details.data.series);
      setFieldValueFunction("product_series", details.data.series);
      setFieldValueFunction("segment", selectSegment);
      setSegment(selectSegment);
      setOfferStatus(editDetails.status);
      if (editDetails.frequency == "1") {
        setFrequencyButton(true);
      } else {
        setButtonDisable(true);
      }
      setRestriction(
        editDetails.restriction == "1"
          ? restrictionOption[0]
          : restrictionOption[1]
      );
      setFieldValueFunction(
        "restriction",
        editDetails.restriction == "1"
          ? restrictionOption[0]
          : restrictionOption[1]
      );
      setBonusOnceADay(
        editDetails.bonusOnceADay == "1"
          ? restrictionOption[0]
          : restrictionOption[1]
      );
      setFieldValueFunction(
        "bonusOnceADay",
        editDetails.bonusOnceADay == "1"
          ? restrictionOption[0]
          : restrictionOption[1]
      );
      setTitle(editDetails.title);
      setFieldValueFunction("title", editDetails.title);
      const budget_diff = editDetails.budget - editDetails.budget_used;
      const denominator =
        editDetails.bonusPayoutRs + editDetails.bonusPayoutPoints / 2;
      if (editDetails.budget_used != "" && budget_diff < denominator) {
        setTempPack(true);
      }
    }
  }, []);
  const changeDay = async  (event,index,ele) => {
    console.log("dayWise--",dayWise);
   console.log("event---",event.target.value,index,event.target.checked);
   console.log("data---",dayWise[ele.name]);
   dayWise[ele.name] = event.target.checked;
   dayWiseList[index]['value'] = event.target.checked;
   setdayWise(dayWise);
   setdayWiseList(dayWiseList);
   setFieldValueFunction("dayWiseList",dayWiseList);
  };
  useEffect(() => {
    if (mobile?.length <= 10) {
      setFieldValueFunction("mobile", mobile);
    } else {
      // toast.error("Maximum 10 mobile numbers are allowed");
    }
  }, [mobile]);

  useEffect(() => {
    if (routeParams.programId == 5) {
      const d2 = parseInt(bonusPayoutPoints, 10) * 0.5;
      const denominator = d2;
      const pack = parseInt(budget, 10) / denominator;
      const total_points =
        parseInt(defaultPayoutPoints) + parseInt(bonusPayoutPoints);
      // setLimitPack(parseInt(pack));
      setTotalPoints(total_points);
    } else {
      const d2 =
        parseInt(bonusPayoutPoints, 10) * 0.5 + parseInt(bonusPayoutRs, 10);
      const denominator = d2;
      const pack = parseInt(budget, 10) / denominator;
      const total_rupees = parseInt(defaultPayoutRs) + parseInt(bonusPayoutRs);
      setTotalRupees(total_rupees);
      const total_points =
        parseInt(defaultPayoutPoints) + parseInt(bonusPayoutPoints);
      // setLimitPack(parseInt(pack));
      // console.log(limitPack, pack);
      setTotalPoints(total_points);
    }
  }, [budget, bonusPayoutPoints, bonusPayoutRs]);

  const handleInputBlur = (data) => {
    console.log("data----",data,(data.title).trim().length);
    if(((data.title).trim()).length > 0) {
      setShowTitle(false);
    }
    if (data.segment_id) {
      setShowSegment(false);
    }
    if (data.product_series.length > 0) {
      setShowSeries(false);
    }

    if (data.multiplyRupees.value != 0) {
      setShowClass(false);
    }

    if (file) {
      setShowImage(false);
    }
    if (data.target_volume) {
      setShowValue(false);
    }

    if (data.end_date) {
      setShowEdate(false);
    }
    if (data.start_date) {
      setShowsDate(false);
    }

 
  };
  const handleInputBlur1 = (data) => {
    
    console.log("data----",data.target_volume,data.target_volume != 0);
    if (data.target_volume != 0) {
      console.log("data----",data.target_volume != 0);
      setShowValue(false);
      console.log("showvalue--",showvalue);
    } else {
        setShowValue(true);
    }
 
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="g-0 d-flex justify-content-around">
            <Col lg={12}>
              <Card className="m-2">
                <CardHeader className="d-flex align-items-center">
                  <button
                    className="btn btn-secondary float-left"
                    type="submit"
                    onClick={() => {
                      handleClose();
                    }}
                    color="primary"
                  >
                    Back
                  </button>
                  <CardTitle className="font-size-20 text-uppercase mx-3 m-0">
                    Ultimate Bonanza
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Formik
                    initialValues={{
                      mobile: mobileInput,
                      segment: segment,
                      file: null,
                      target_volume: targetVolume,
                      product_series: series,
                      stacking: stacking,
                      multiplyRupees: multiplyRupees,
                      multiplyPoint: multiplyPoint,
                      defaultPayoutRs: defaultPayoutRs,
                      defaultPayoutPoints: defaultPayoutPoints,
                      bonusPayoutRs: bonusPayoutRs,
                      bonusPayoutPoints: bonusPayoutPoints,
                      restriction: restriction,
                      bonusOnceADay: bonusOnceADay,
                      numberOfCoupon: numberOfCoupon,
                      numberOfTimesCouponAvail: numberOfTimesCouponAvail,
                      start_date: startDate,
                      end_date: endDate,
                      // limitPack: limitPack,
                      budget: budget,
                      title: title,
                      limitBonus: limitBonus,
                    }}
                    validate={(values) => {
                      const errors = {};
                      
                      if (!values.title) {
                        errors.title = "Required";
                      }
                      
                      if (!values.segment) {
                        errors.segment = "Required";
                      }
                      // if (values.product_series.length === 0) {
                      //   errors.product_series = "Required";
                      // }
                      if (!values.stacking) {
                        errors.stacking = "Required";
                      }
                      if (!values.budget) {
                        errors.budget = "Required";
                      }
                      if (!values.limitBonus) {
                        errors.limitBonus = "Required";
                      }
                      if (isEdit === false) {
                        if (routeParams.programId == 1) {
                          if (!values.bonusPayoutRs) {
                            errors.bonusPayoutRs = "Required";
                          }
                        }
                        if (!values.bonusPayoutPoints) {
                          errors.bonusPayoutPoints = "Required";
                        }
                        if (!values.target_volume) {
                          errors.target_volume = "Required";
                        }
                        if (frequencyButton === true) {
                          if (!values.restriction) {
                            errors.restriction = "Required";
                          }
                          if (isEdit) {
                            if (!values.numberOfCoupon) {
                              errors.numberOfCoupon = "Required";
                            }
                            // if (!values.bonusOnceADay) {
                            //   errors.bonusOnceADay = "Required";
                            // }
                          }
                        }
                      }

                      if (!values.start_date) {
                        errors.start_date = "Required";
                      }
                      if (!values.end_date) {
                        errors.end_date = "Required";
                      }

                      return errors;
                    }}
                    onSubmit={(values) => {
                      console.log("here");
                      if (isEdit) {
                        updateValues(values);
                      } else {
                        submitValuetoApi(values);
                      }
                    }}
                  >
                    {({
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                      values,
                      errors,
                      touched,
                    }) => (
                      <React.Fragment>
                        {handleSelectFieldObject(setFieldValue)}
                        {showTable === true ? (
                          <Row>
                            <Col>
                              <div>
                                <CardTitle className="text-danger">
                                  Given users are already mapped!
                                </CardTitle>
                              </div>
                              <table
                                border="1"
                                className="table table-bordered bg-danger"
                              >
                                <thead className="bg-danger-subtle">
                                  <tr className="bg-danger-subtle">
                                    <th>Offer Id</th>
                                    <th>Mobile</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                  </tr>
                                </thead>
                                <tbody className="bg-danger-subtle">
                                  {existData.map((item, index) => (
                                    <tr key={index}>
                                      <td
                                        className="bg-danger-subtle"
                                        // onClick={() =>
                                        //   handleTdClick(item.series_offer_id)
                                        // }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item.series_offer_id}
                                      </td>
                                      <td className="bg-danger-subtle">
                                        {item.mobile}
                                      </td>
                                      <td className="bg-danger-subtle">
                                        {moment(item.start_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </td>
                                      <td className="bg-danger-subtle">
                                        {moment(item.end_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                        ) : null}

                        <Row>
                          <Col lg={12}>
                            <button
                              className="btn btn-warning float-end "
                              onClick={downloadSampleXLSX}
                            >
                              <div className="d-flex mt-1">
                                <CardTitle>Sample Excel</CardTitle>
                                <i className="ri-arrow-down-line align middle mx-1"></i>
                              </div>
                            </button>
                          </Col>
                        </Row>
                        <Row>
                        <Col lg={8} md={6} sm={12}>
                            <div className="form-row">
                              <label className="form-label">Ultimate Bonanza Title</label>
                              <div className="input-group">
                                <Input
                                  type="search"
                                  placeholder="Type offer title"
                                  id="title"
                                  name="title"
                                  value={values.title}
                                  disabled={isEdit ? true : false}
                                  onBlur={() => handleInputBlur(values)}
                                  onChange={(e) => {
                                    setTitle(e.target.value);
                                    setFieldValueFunction(
                                      "title",
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                              {/* {errors.title && touched.title && ( */}
                              {showtitle === true ? (
                                <p className="form-error">
                                  Required
                                </p>
                              ) : null}
                              {/* // )} */}
                            </div>
                          </Col>
                          {isEdit ? (
                            <Col lg={4}>
                              <div className="form-row">
                                <label className="form-label" htmlFor="mobile">
                                  Total Users Mapped:
                                </label>
                                <div className="input-group d-flex">
                                  <Input
                                    disabled={true}
                                    type="search"
                                    className="w-75"
                                    value={numberOfUsers?.length}
                                    name="mobile"
                                    id="mobile"
                                  />
                                  <Button
                                    className="btn-primary"
                                    onClick={downloadSampleXLSX}
                                  >
                                    Download
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          ) : null}
                        </Row>
                        <Row className="mt-3">
                          <Col lg={8}>
                            <div className="form-row">
                              <label className="form-label" htmlFor="mobile">
                                Mobile Number:
                              </label>
                              <div className="input-group d-flex">
                                <Input
                                  type="search"
                                  className="w-75"
                                  placeholder="Type single mobile number or type comma-separated mobile numbers and submit..."
                                  value={mobileInput}
                                  disabled={numberOfUsers?.length > 10 ? true : false}
                                  name="mobile"
                                  id="mobile"
                                  onChange={(e) =>
                                    setMobileInput(e.target.value)
                                  }
                                />
                                <Button
                                  className="btn-primary"
                                  onClick={addMobileNumber}
                                >
                                  Add
                                </Button>
                              </div>
                              {numberOfUsers?.length <= 10 ? (<div
                                className="d-flex mt-2 flex-wrap"
                                style={{
                                  maxHeight: "100px",
                                  overflowY: "auto",
                                }}
                              >
                                {mobileNumbers.map((number, index) => (
                                  <div
                                    className="badge-soft-dark  p-2"
                                    key={index}
                                  >
                                    {number}
                                    <button
                                      className="badge-soft-danger border-0 form-select-sm align-middle  mx-2"
                                      onClick={() => handleNumber(index)}
                                    >
                                      <i className="ri-close-line align-middle"></i>
                                    </button>
                                  </div>
                                ))}
                              </div>) : null }
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="form-row">
                              <Label className="form-label" htmlFor="upload">
                                Or Upload Excel File{" "}
                              </Label>
                              <div className="input-group">
                                <input
                                  id="upload"
                                  name="upload"
                                  type="file"
                                  accept=".xlsx"
                                  onChange={handleFileChange}
                                  className="form-control"
                                />
                                <div className="input-group-prepend">
                                  <div className="input-group-text ">
                                    <button
                                      className="btn btn-close"
                                      onClick={() => removeFile()}
                                    ></button>
                                  </div>
                                </div>
                              </div>

                              {errors.file && (
                                <p className="form-error">
                                  {errors.mobile && errors.file}
                                </p>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row className="mt-3">
                          <Col lg={4} md={6} sm={12}>
                            <div className="form-row">
                              <label className="form-label">
                                Select Product Segment:
                              </label>
                              <div
                                className={`input-group select-group ${
                                  errors.segment && touched.segment
                                    ? "input-error"
                                    : ""
                                }`}
                              >
                                <Select
                                  // isDisabled={isEdit ? true : false}
                                  className="w-100"
                                  closeMenuOnSelect={true}
                                  value={values.segment}
                                  isSearchable={false}
                                  options={segmentOption}
                                  blurInputOnSelect={true}
                                  id="segment"
                                  name="segment"
                                  onChange={(event) => {
                                    handleSegment(event);
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                />
                              </div>
                              {errors.segment && touched.segment && (
                                <p className="form-error">
                                  {errors.segment &&
                                    touched.segment &&
                                    errors.segment}
                                </p>
                              )}
                               {showsegment === true ? (
                                <p className="form-error">
                                  Required
                                </p>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={4} md={6} sm={12}>
                            <div className="form-row">
                              <label className="form-label">
                                Choose Product Series:
                              </label>
                              <div
                                className={`input-group select-group ${
                                  errors.product_series &&
                                  touched.product_series
                                    ? "input-error"
                                    : ""
                                }`}
                              >
                                <Select
                                  // isDisabled={isEdit ? true : false}
                                  className="w-100"
                                  closeMenuOnSelect={false}
                                  value={values.product_series}
                                  isMulti
                                  options={seriesOption}
                                  id="product_series"
                                  name="product_series"
                                  onChange={(event) => {
                                    handleSeries(event);
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                />
                              </div>
                              {errors.product_series &&
                                touched.product_series && (
                                  <p className="form-error">
                                    {errors.product_series &&
                                      touched.product_series &&
                                      errors.product_series}
                                  </p>
                                )}
                                 {showseries === true ? (
                                <p className="form-error">
                                  Required
                                </p>
                              ) : null}
                            </div>
                          </Col>
                        </Row>


                        <Row className="mt-3">
                          <Table className="m-3" style={{border:"1px solid black",width:"800px",margin:"5px"}}>
                              <h5 className="m-2">
                                Ultimate Bonanza
                              </h5>
                            <thead>
                            {/* <div className="form-row"> */}
                            {/* </div> */}
                            </thead>
                            <tbody>
                              <td>
                                <Col >
                              <div className="form-row">
                                <label className="form-label">
                                Class
                                </label>
                                <div className="input-group">
                                <Select
                                    className="w-100"
                                    closeMenuOnSelect={true}
                                    value={values.multiplyRupees}
                                    isSearchable={false}
                                    options={classOptions}
                                    blurInputOnSelect={true}
                                    id="multiplyRupees"
                                    name="multiplyRupees"
                                    onChange={(event) => {
                                      handleMultiplyRupees(event);
                                    }}
                                    onBlur={(event) => {
                                      handleBlur(event);
                                    }}
                                  />
                                  {/* <div className="input-group-append">
                                <span className="input-group-text">.00</span>
                              </div> */}
                               {showclass === true ? (
                                <p className="form-error">
                                  Required
                                </p>
                              ) : null}
                                </div>
                                {/* {errors.defaultPayoutRs &&
                                  touched.defaultPayoutRs && (
                                    <p className="form-error">
                                      {errors.defaultPayoutRs &&
                                        touched.defaultPayoutRs &&
                                        errors.defaultPayoutRs}
                                    </p>
                                  )} */}
                              </div>
                                </Col>
                              </td>
                              <td>
                                <Col >
                            <div className="form-row">
                              <Label className="form-label" htmlFor="upload">
                                Browse{" "}
                              </Label>
                              <div className="input-group">
                                <input
                                  id="upload1"
                                  name="upload1"
                                  type="file"
                                  accept="image/*"
                                  onChange={handleFileChange1}
                                  className="form-control"
                                />
                                <div className="input-group-prepend">
                                  <div className="input-group-text ">
                                    <button
                                      className="btn btn-close"
                                      onClick={() => removeFile1()}
                                    ></button>
                                  </div>
                                </div>
                              </div>

                              {errors.file && (
                                <p className="form-error">
                                  {errors.file}
                                </p>
                              )}
                               {showimage === true ? (
                                <p className="form-error">
                                  Required
                                </p>
                              ) : null}
                            </div>
                                </Col>
                              </td>
                              <td>
                              <Col >
                            <div className="form-row">
                              <label className="form-label">Target Volume</label>
                              <div className="input-group">
                                <Input
                                  type="Number"
                                  min="500"
                                  max="1000"
                                  placeholder="Enter"
                                  id="title"
                                  name="title"
                                  value={values.target_volume}
                                  disabled={isEdit ? true : false}
                                  onBlur={() => handleInputBlur1(values)}
                                  onChange={(e) => {
                                    const input = e.target.value;

                                    // Use a regular expression to remove non-numeric characters
                                    const numericInput = input.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    setTargetVolume(numericInput);
                                    setFieldValueFunction(
                                      "target_volume",
                                      numericInput
                                    );
                                  }}
                                />
                              </div>
                              {showvalue === true ? (
                                <p className="form-error">
                                  Required
                                </p>
                              ) : null}
                              {/* {errors.title && touched.title && ( */}
                            
                              {/* // )} */}
                            </div>
                          </Col>
                              </td>
                            </tbody>
                          </Table>
                        </Row>
                        
                        {/* <Row className="mt-3">
                          {routeParams.programId == 1 ? (
                            <Col lg={4} md={6} sm={12}>
                              <div className="form-row">
                                <label className="form-label">
                                  Total Rupees
                                </label>
                                <div className="form-control">
                                  {isNaN(totalRupees)
                                    ? "Total Rupees"
                                    : totalRupees}
                                </div>
                              </div>
                            </Col>
                          ) : null}
                        </Row> */}
                        <Row className="mt-3">
                          <Col lg={4} md={12} sm={12}>
                            <div className="form-row">
                              <label className="form-label">Start Date:</label>
                              <div className="input-group">
                                <Input
                                  type="date"
                                  placeholder="Start Date"
                                  disabled={isEdit ? true : false}
                                  id="start_date"
                                  name="start_date"
                                  value={values.start_date}
                                  min={moment().format("YYYY-MM-DD")}
                                  onChange={(event) => {
                                    setStartDate(
                                      moment(
                                        new Date(event.target.value)
                                      ).format("YYYY-MM-DD")
                                    );
                                    setFieldValueFunction(
                                      "start_date",
                                      moment(
                                        new Date(event.target.value)
                                      ).format("YYYY-MM-DD")
                                    );
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                  invalid={
                                    errors.start_date && touched.start_date
                                  }
                                />
                              </div>
                              {errors.start_date && touched.start_date && (
                                <p className="form-error">
                                  {errors.start_date &&
                                    touched.start_date &&
                                    errors.start_date}
                                </p>
                              )}
                               {showsdate === true ? (
                                <p className="form-error">
                                  Required
                                </p>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={4} md={12} sm={12}>
                            <div className="form-row">
                              <label className="form-label">End Date:</label>
                              <div className="input-group">
                                <Input
                                  type="date"
                                  placeholder="End Date"
                                  id="end_date"
                                  name="end_date"
                                  value={values.end_date}
                                  disabled={values.start_date ? false : true}
                                  min={moment(new Date()).format("YYYY-MM-DD")}
                                  onChange={(event) => {
                                    setEndDate(
                                      moment(
                                        new Date(event.target.value)
                                      ).format("YYYY-MM-DD")
                                    );
                                    setFieldValueFunction(
                                      "end_date",
                                      moment(
                                        new Date(event.target.value)
                                      ).format("YYYY-MM-DD")
                                    );
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                  invalid={errors.end_date && touched.end_date}
                                />
                              </div>
                              {errors.end_date && touched.end_date && (
                                <p className="form-error">
                                  {errors.end_date &&
                                    touched.end_date &&
                                    errors.end_date}
                                </p>
                              )}
                               {showedate === true ? (
                                <p className="form-error">
                                  Required
                                </p>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      
                        {moment().format("YYYY-MM-DD") > tempEndDate ||
                        offerStatus === "EXPIRED" ? (
                          <Row>
                            <Col>
                              <CardTitle className="text-danger float-end">
                                Offer is expired!
                              </CardTitle>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            { user.user_id !== 1685633729789 &&(<Col lg={12}> {
                              (isEdit && isEdit === true && offerStatus === "UNPUBLISH") ? <CardTitle className="text-danger float-end">
                              Offer is unpublish!
                            </CardTitle> : (
                                <div className="d-flex align-items-end justify-content-end">
                                {loading === true ? (
                                  <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                                ) : isEdit && isEdit === true ? (
                                  <>
                                    <Button
                                      className="btn-warning mt-3 me-1"
                                      disabled={loading}
                                      onClick={() => {
                                        handleSubmit();
                                      }}
                                    >
                                      Update
                                    </Button>
                                    {isEdit && (
                                      <Button
                                        className="btn-warning mt-3"
                                        disabled={loading}
                                        onClick={() => {
                                          updateStatus(
                                            (offerStatus === "DRAFT")
                                              ? "PUBLISH"
                                              : (offerStatus === "PUBLISH" ? "UNPUBLISH" : "Save as Draft")
                                          );
                                        }}
                                      >
                                        {(offerStatus === "DRAFT")
                                              ? "PUBLISH"
                                              : (offerStatus === "PUBLISH" ? "UNPUBLISH" : "Save as Draft")}
                                         
                                      </Button>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      className="btn-warning mt-3 me-1"
                                      disabled={loading}
                                      onClick={() => {
                                        setRadioValue("DRAFT");
                                        setFieldValueFunction("radioValue","DRAFT");
                                        submitValuetoApi(values,"DRAFT");
                                      }}
                                    >
                                      Save as draft
                                    </Button>
                                    <Button
                                      className="btn-warning mt-3 me-1"
                                      disabled={loading}
                                      onClick={() => {
                                        setRadioValue("PUBLISH");
                                        setFieldValueFunction("radioValue","PUBLISH");
                                        submitValuetoApi(values,"PUBLISH");
                                      }}
                                    >
                                      Publish
                                    </Button>
                                  </>
                                )}
                              </div>
                              )
                            }
                             
                            </Col>)}
                          </Row>
                        )}
                      </React.Fragment>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RetailerScheme;
