/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

// Redux
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  CardTitle,
  Card,
  CardHeader,
  CardBody,
  Input,
} from "reactstrap";

import { Formik } from "formik";

import { toast } from "react-toastify";
import Select from "react-select";
import moment from "moment";

// import images
import "react-toastify/dist/ReactToastify.css";

// import css
import "../ConsumptionPayout.scss";
import { campaign_programs } from "../../../Config/config";
import campaignSer from "../../../services/campaignService.js";
import { SpinnerSmall } from "../../../components/Loader/Spinner";

function VehicleWiseScheme(props) {
  const routeParams = props.match.params;
  const program_id = routeParams.programId;
  const user = JSON.parse(localStorage.getItem("user"));
  const [budget, setBudget] = useState("");
  const history = useHistory();
  if (
    !routeParams ||
    campaign_programs.includes(routeParams.programId) === false
  ) {
    history.goBack();
  }
  const [offerStatus, setOfferStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(routeParams.id ? true : false);
  const [editDetails, setEditVal] = useState({});
  const [frequencyButton, setFrequencyButton] = useState(false);
  const [pincode, setPincode] = useState([]);
  const [segment, setSegment] = useState("");
  const [segmentOption, setSegmentOption] = useState([
    { value: "Select", label: "Select" },
  ]);
  const [seriesOption, setSeriesOptions] = useState([]);
  const [defaultPayoutRs, setDefaultPayoutRs] = useState("0");
  const [defaultPayoutPoints, setDefaultPayoutPoints] = useState("0");
  const [bonusPayoutRs, setBonusPayoutRs] = useState("");
  const [bonusPayoutPoints, setBonusPayoutPoints] = useState("");
  const [numberOfCoupon, setNumberOfCoupon] = useState("");
  const [numberOfTimesCouponAvail, setNumberOfTimesCouponAvail] = useState("");
  const [totalRupees, setTotalRupees] = useState("");
  const [totalPoints, setTotalPoints] = useState("");
  const mechanicSegmentOption = [
    { value: "2 Wheeler", label: "2 Wheeler" },
    { value: "4 Wheeler", label: "4 Wheeler" },
    { value: "3 Wheeler", label: "3 Wheeler" },
  ];

  const [series, setSeries] = useState([]);

  const stackingOption = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  const [stacking, setStacking] = useState(stackingOption[0]);

  const restrictionOption = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  const [restriction, setRestriction] = useState(restrictionOption[0]);
  const [bonusOnceADay, setBonusOnceADay] = useState(restrictionOption[0]);
  const [tempSegment, setTempSegment] = useState("");
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [limitPack, setLimitPack] = useState("");
  const [mchanicSegmet, setMechanicSegmet] = useState("");

  let setFieldValueFunction = null;

  const handleSelectFieldObject = (setFieldValue) => {
    setFieldValueFunction = setFieldValue;
  };

  const updateValues = async (data) => {
    const startMoment = moment(data.start_date, "YYYY-MM-DD");
    const endMoment = moment(data.end_date, "YYYY-MM-DD");
    // Compare the dates
    if (endMoment.isBefore(startMoment)) {
      // Raise an error or handle the error as needed
      toast.error("End date is before the start date");
      return;
    }
    setLoading(true);
    let params = {
      stacking: data.stacking.value.toLowerCase() === "yes" ? "1" : "0",
      program_id: routeParams.programId,
      start_date: moment(data.start_date, "YYYY-MM-DD").unix(),
      end_date: moment(data.end_date, "YYYY-MM-DD").unix(),
      defaultPayoutRs: data.defaultPayoutRs,
      defaultPayoutPoints: data.defaultPayoutPoints,
      bonusPayoutRs: data.bonusPayoutRs,
      bonusPayoutPoints: data.bonusPayoutPoints,
      numberOfCoupon: data.numberOfCoupon,
      numberOfTimesCouponAvail: data.numberOfTimesCouponAvail,
      restriction: data.restriction.value.toLowerCase() === "yes" ? "1" : "0",
      limitPack: data.limitPack,
      segment: data.segment.id,
      vehicle_segment: data.mechanic_segment.value,
      bonusType: routeParams.bonusType,
      offer_id: routeParams.id,
      campaign_budget: parseInt(data?.budget),
      bonusOnceADay:
        data.bonusOnceADay?.value.toLowerCase() === "yes" ? "1" : "0",
      status: offerStatus,
      frequency: frequencyButton === true ? "1" : "0",
    };
    let series = [];
    for (let i = 0; i < data.product_series.length; i++) {
      series.push(
        data.product_series[i].series_id
          ? data.product_series[i].series_id
          : data.product_series[i].value
      );
    }
    params["series"] = series;

    let response = await campaignSer.updateCampaignSegment(params);
    setLoading(false);
    if (response.status) {
      toast.success(
        !isEdit ? "Offer created successfully." : "Offer updated successfully."
      );
      if (routeParams.programId == 1) {
        history.push("/eni-club-listing");
      } else {
        history.push("/eni-vistaar-listing");
      }
    }
  };

  const submitValuetoApi = async (data) => {
    const startMoment = moment(data.start_date, "YYYY-MM-DD");
    const endMoment = moment(data.end_date, "YYYY-MM-DD");
    // Compare the dates
    if (endMoment.isBefore(startMoment)) {
      // Raise an error or handle the error as needed
      toast.error("End date is before the start date");
      return;
    }
    setLoading(true);
    // let formData = new FormData();
    let params = {
      form_type: "segment_wise",
      title: `Campaign Segment Wise ${data.start_date} - ${data.end_date}`,
      stacking: data.stacking.value.toLowerCase() === "yes" ? "1" : "0",
      program_id: routeParams.programId,
      start_date: moment(data.start_date, "YYYY-MM-DD").unix(),
      end_date: moment(data.end_date, "YYYY-MM-DD").unix(),
      defaultPayoutRs: data.defaultPayoutRs,
      defaultPayoutPoints: data.defaultPayoutPoints,
      bonusPayoutRs: data.bonusPayoutRs,
      bonusPayoutPoints: data.bonusPayoutPoints,
      numberOfCoupon: data.numberOfCoupon,
      numberOfTimesCouponAvail: data.numberOfTimesCouponAvail,
      restriction: data.restriction.value.toLowerCase() === "yes" ? "1" : "0",
      limitPack: data.limitPack,
      segment: data.segment.id,
      vehicle_segment: data.mechanic_segment.value,
      bonusType: routeParams.bonusType,
      campaign_budget: parseInt(data?.budget),
      bonusOnceADay:
        data.bonusOnceADay?.value.toLowerCase() === "yes" ? "1" : "0",
      frequency: frequencyButton === true ? "1" : "0",
    };
    let series = [];
    for (let i = 0; i < data.product_series.length; i++) {
      series.push(data.product_series[i].series_id);
    }
    params["series"] = series;

    console.log("form data is ", params);
    let response = await campaignSer.saveVehicleWiseCampaign(params);
    console.log("response is ", response);
    setLoading(false);
    if (response.status) {
      toast.success(
        !isEdit ? "Offer created successfully." : "Offer updated successfully."
      );
      if (routeParams.programId == 1) {
        history.push("/eni-club-listing");
      } else {
        history.push("/eni-vistaar-listing");
      }
    }
  };

  const GetSegmentList = async () => {
    const options = await campaignSer.GetSegmentList();
    if (options) {
      setSegmentOption(options?.data);
    }
  };
  const handleSegment = async (event) => {
    setFieldValueFunction("segment", event);
    setTempSegment(event.id);
    const options = await campaignSer.GetSegmentSeriesList({
      id: event.id,
      program_id: program_id,
    });
    if (options.status) {
      setSeriesOptions(options?.data);
    }
  };

  const handleSeries = async (event) => {
    console.log(event, segment);

    if (event.length === 0 && isEdit==true) {
      const options = await campaignSer.GetSegmentSeriesList({
        id: segment.id,
        program_id: routeParams.programId,
      });
      if (options.status) {
        setSeriesOptions(options?.data);
      }
    }
    // if (event.length === 1) {
    //   const filteredArr = seriesOption.filter(
    //     (item) =>           
    //       item.points === event[0].points && item.rupees === event[0].rupees
    //   );
    //   setSeriesOptions(filteredArr);
    //   setDefaultPayoutRs(parseInt(event[0].rupees));
    //   setFieldValueFunction("defaultPayoutRs", parseInt(event[0].rupees));
    //   setDefaultPayoutPoints(parseInt(event[0].points));
    //   setFieldValueFunction("defaultPayoutPoints", parseInt(event[0].points));
    // } else 
    if (event.length === 0) {
      setDefaultPayoutRs("0");
      setFieldValueFunction("defaultPayoutRs", "0");
      setDefaultPayoutPoints("0");
      setFieldValueFunction("defaultPayoutPoints", "0");
      // await campaignSer
      //   .GetSegmentSeriesList({
      //     id: tempSegment,
      //     program_id: program_id,
      //   })
      //   .then((response) => {
      //     if (response.status === true) {
      //       setSeriesOptions(response?.data);
      //     }
      //   });
    }
    setFieldValueFunction("product_series", event);
    setSeries(event);
    await setPayoutData(event);
  };

  const setPayoutData = async (event) => {
    console.log("series",event);
    let ids = event.map((obj) => obj.points);
    let data = ids.length !== 0 && new Set(ids).size !== 1;
    console.log("data---",data);
    if(event.length > 0) {
      if(data == false) {
        setDefaultPayoutRs(parseInt(event[0].rupees));
        setFieldValueFunction("defaultPayoutRs", parseInt(event[0].rupees));
        setDefaultPayoutPoints(parseInt(event[0].points));
        setFieldValueFunction("defaultPayoutPoints", parseInt(event[0].points));
      } else {
        setDefaultPayoutRs("0");
        setFieldValueFunction("defaultPayoutRs", "0");
        setDefaultPayoutPoints("0");
        setFieldValueFunction("defaultPayoutPoints", "0");
      }
    }
  }
  const handleBonusOnceADay = (event) => {
    setBonusOnceADay(event.value);
    setFieldValueFunction("bonusOnceADay", event);
  };
  const handleMechanicSegment = (event) => {
    // setSegment(event.value);
    setMechanicSegmet(event);
    setFieldValueFunction("mechanic_segment", event);
  };

  const handleStacking = (event) => {
    setStacking(event);
    setFieldValueFunction("stacking", event);
  };
  const handleRestriction = (event) => {
    setRestriction(event);
    setFieldValueFunction("restriction", event);
  };

  const updateStatus = async (action) => {
    let response = await campaignSer.updateCampaign(action, routeParams.id);
    if (response.status) {
      toast.success(
        action === "INACTIVE"
          ? "Campaign inactivated successfully."
          : "Campaign Published Successfully."
      );
      if (routeParams.programId == 1) {
        history.push("/eni-club-listing");
      } else {
        history.push("/eni-vistaar-listing");
      }
    } else {
      toast.error("Something went wrong.");
    }
  };

  useEffect(async () => {
    const segmentoptions = await campaignSer.GetSegmentList();
    if (segmentoptions?.status) {
      setSegmentOption(segmentoptions?.data);
    }

    if (isEdit) {
      let params = {
        program_id: routeParams.programId,
        offer_id: routeParams.id,
      };
      let details = await campaignSer.GetOfferDetails(params);
      let editDetails = details.data.offerDetails;
      let selectSegment = segmentoptions.data.find(
        (item) => item.id === editDetails.segment
      );
      console.log("select segment ", selectSegment, editDetails);
      const options = await campaignSer.GetSegmentSeriesList({
        id: editDetails.segment,
        program_id: routeParams.programId
      });
      if (options.status) {
        setSeriesOptions(options?.data);
      }

      setEditVal(editDetails);
      setSegment(selectSegment);
      setFieldValueFunction("segment", selectSegment);
      setDefaultPayoutPoints(editDetails.defaultPayoutPoints);
      setMechanicSegmet({
        label: editDetails.vehicle_segment,
        value: editDetails.vehicle_segment,
      });
      setFieldValueFunction("mechanic_segment", {
        label: editDetails.vehicle_segment,
        value: editDetails.vehicle_segment,
      });
      setFieldValueFunction(
        "defaultPayoutPoints",
        editDetails.defaultPayoutPoints
      );
      setDefaultPayoutRs(editDetails.defaultPayoutRs);
      setFieldValueFunction("defaultPayoutRs", editDetails.defaultPayoutRs);
      setBonusPayoutPoints(editDetails.bonusPayoutPoints);
      setFieldValueFunction("bonusPayoutPoints", editDetails.bonusPayoutPoints);
      setBonusPayoutRs(editDetails.bonusPayoutRs);
      setFieldValueFunction("bonusPayoutRs", editDetails.bonusPayoutRs);
      setNumberOfCoupon(editDetails.numberOfCoupon);
      setFieldValueFunction("numberOfCoupon", editDetails.numberOfCoupon);
      setNumberOfTimesCouponAvail(editDetails.numberOfTimesCouponAvail);
      setFieldValueFunction(
        "numberOfTimesCouponAvail",
        editDetails.numberOfTimesCouponAvail
      );
      setRestriction(
        editDetails.restriction === 1
          ? { value: "Yes", label: "Yes" }
          : { value: "No", label: "No" }
      );
      setFieldValueFunction(
        "restriction",
        editDetails.restriction === 1
          ? { value: "Yes", label: "Yes" }
          : { value: "No", label: "No" }
      );

      setLimitPack(editDetails.limitPack);
      setFieldValueFunction("limitPack", editDetails.limitPack);
      setStacking(
        editDetails.stacking === 1
          ? { value: "Yes", label: "Yes" }
          : { value: "No", label: "No" }
      );
      setFieldValueFunction(
        "stacking",
        editDetails.stacking === 1
          ? { value: "Yes", label: "Yes" }
          : { value: "No", label: "No" }
      );
      setStartDate(moment(editDetails.start_date).format("YYYY-MM-DD"));
      setFieldValueFunction(
        "start_date",
        moment(editDetails.start_date).format("YYYY-MM-DD")
      );
      setEndDate(moment(editDetails.end_date).format("YYYY-MM-DD"));
      setFieldValueFunction(
        "end_date",
        moment(editDetails.end_date).format("YYYY-MM-DD")
      );
      console.log(
        "dates are ",
        moment(editDetails.end_date).format("YYYY-MM-DD"),
        moment(editDetails.start_date).format("YYYY-MM-DD")
      );
      setSeries(details.data.series);
      setFieldValueFunction("product_series", details.data.series);
      setOfferStatus(editDetails.status);
      setBudget(editDetails.budget);
      setFieldValueFunction("budget", editDetails.budget);
      const total_rupees =
        parseInt(editDetails.defaultPayoutRs) +
        parseInt(editDetails.bonusPayoutRs);
      const total_points =
        parseInt(editDetails.defaultPayoutPoints) +
        parseInt(editDetails.bonusPayoutPoints);
      setTotalPoints(total_points);
      setTotalRupees(total_rupees);
      if (editDetails.frequency == "1") {
        setFrequencyButton(true);
      }
      setRestriction(
        editDetails.restriction == "1"
          ? restrictionOption[0]
          : restrictionOption[1]
      );
      setFieldValueFunction(
        "restriction",
        editDetails.restriction == "1"
          ? restrictionOption[0]
          : restrictionOption[1]
      );
      setBonusOnceADay(
        editDetails.bonusOnceADay == "1"
          ? restrictionOption[0]
          : restrictionOption[1]
      );
      setFieldValueFunction(
        "bonusOnceADay",
        editDetails.bonusOnceADay == "1"
          ? restrictionOption[0]
          : restrictionOption[1]
      );
    }
  }, []);

  useEffect(() => {
    GetSegmentList();
  }, []);

  useEffect(() => {
    if (pincode.length <= 10) {
      setFieldValueFunction("pincode", pincode);
    } else {
      toast.error("Maximum 10 pincode numbers are allowed");
    }
  }, [pincode]);

  const handleClose = () => {
    history.goBack();
  };
  useEffect(() => {
    // const d1 = defaultPayoutPoints * 0.5 + parseInt(defaultPayoutRs, 10);
    const d2 = bonusPayoutPoints * 0.5 + parseInt(bonusPayoutRs, 10);
    const denominator = d2;
    const pack = parseInt(budget, 10) / denominator;
    const total_rupees = parseInt(defaultPayoutRs) + parseInt(bonusPayoutRs);
    const total_points =
      parseInt(defaultPayoutPoints) + parseInt(bonusPayoutPoints);
    setLimitPack(parseInt(pack));
    setTotalPoints(total_points);
    setTotalRupees(total_rupees);
    console.log(d2, pack);
  }, [budget, bonusPayoutPoints, bonusPayoutRs]);
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="g-0 d-flex justify-content-around">
            <Col lg={12}>
              {/* <div className="d-flex  m-3">
                <CardTitle className="font-size-24 text-danger">
                  MECHANIC WISE: FREQUENCY SCHEME
                </CardTitle>
              </div> */}
              <Card className="m-2">
                <CardHeader className="d-flex align-items-center">
                  <button
                    className="btn btn-secondary float-left"
                    type="submit"
                    onClick={() => {
                      handleClose();
                    }}
                    color="primary"
                  >
                    Back
                  </button>
                  <CardTitle className="font-size-20 text-uppercase mx-3 m-0">
                    Vehicles Wise Scheme
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Formik
                    initialValues={{
                      /* pincode: [], */
                      segment: segment,
                      mechanic_segment: mchanicSegmet,
                      product_series: series,
                      stacking: stacking,
                      defaultPayoutRs: defaultPayoutRs,
                      defaultPayoutPoints: defaultPayoutPoints,
                      bonusPayoutRs: bonusPayoutRs,
                      bonusPayoutPoints: bonusPayoutPoints,
                      restriction: restriction,
                      numberOfCoupon: numberOfCoupon,
                      numberOfTimesCouponAvail: numberOfTimesCouponAvail,
                      start_date: startDate,
                      end_date: endDate,
                      limitPack: limitPack,
                    }}
                    validate={(values) => {
                      const errors = {};
                      /* if (values.pincode.length === 0) {
                        errors.pincode = 'Required';
                      }
                      */
                      if (!values.segment) {
                        errors.segment = "Required";
                      }
                      if (!values.mechanic_segment) {
                        errors.mechanic_segment = "Required";
                      }
                      // if (values.product_series.length === 0) {
                      //   errors.product_series = "Required";
                      // }
                      if (!values.stacking) {
                        errors.stacking = "Required";
                      }
                      if (!values.budget) {
                        errors.budget = "Required";
                      }
                      // if (!values.defaultPayoutPoints) {
                      //   errors.defaultPayoutPoints = "Required";
                      // }
                      if (!values.bonusPayoutRs) {
                        errors.bonusPayoutRs = "Required";
                      }
                      if (!values.bonusPayoutPoints) {
                        errors.bonusPayoutPoints = "Required";
                      }
                      if (frequencyButton === true) {
                        if (!values.restriction) {
                          errors.restriction = "Required";
                        }

                        if (!values.numberOfCoupon) {
                          errors.numberOfCoupon = "Required";
                        }

                        if (!values.bonusOnceADay) {
                          errors.bonusOnceADay = "Required";
                        }
                      }

                      // if (!values.numberOfTimesCouponAvail) {
                      //   errors.numberOfTimesCouponAvail = "Required";
                      // }
                      if (!values.start_date) {
                        errors.start_date = "Required";
                      }
                      if (!values.end_date) {
                        errors.end_date = "Required";
                      }
                      if (!values.budget) {
                        errors.budget = "Required";
                      }
                      return errors;
                    }}
                    onSubmit={(values) => {
                      console.log("submit Value", values);
                      if (isEdit) {
                        updateValues(values);
                      } else {
                        submitValuetoApi(values);
                      }
                    }}
                  >
                    {({
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                      values,
                      errors,
                      touched,
                    }) => (
                      <React.Fragment>
                        {handleSelectFieldObject(setFieldValue)}

                        <Row className="mt-3">
                          <Col lg={8}>
                            <div className="form-row">
                              <label className="form-label">
                                Select Mechanic Segment:
                              </label>
                              <div
                                className={`input-group select-group ${
                                  errors.mechanic_segment &&
                                  touched.mechanic_segment
                                    ? "input-error"
                                    : ""
                                }`}
                              >
                                <Select
                                  className="w-100"
                                  closeMenuOnSelect={false}
                                  value={values.mechanic_segment}
                                  isSearchable={false}
                                  options={mechanicSegmentOption}
                                  blurInputOnSelect={true}
                                  id="mechanic_segment"
                                  name="mechanic_segment"
                                  onChange={(event) => {
                                    handleMechanicSegment(event);
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                />
                              </div>
                              {errors.mechanic_segment &&
                                touched.mechanic_segment && (
                                  <p className="form-error">
                                    {errors.mechanic_segment &&
                                      touched.mechanic_segment &&
                                      errors.mechanic_segment}
                                  </p>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg={4} md={6} sm={12}>
                            <div className="form-row">
                              <label className="form-label">
                                Select Product Segment:
                              </label>
                              <div
                                className={`input-group select-group ${
                                  errors.segment && touched.segment
                                    ? "input-error"
                                    : ""
                                }`}
                              >
                                <Select
                                  className="w-100"
                                  closeMenuOnSelect={false}
                                  value={values.segment}
                                  isSearchable={false}
                                  options={segmentOption}
                                  blurInputOnSelect={true}
                                  id="segment"
                                  name="segment"
                                  onChange={(event) => {
                                    handleSegment(event);
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                />
                              </div>
                              {errors.segment && touched.segment && (
                                <p className="form-error">
                                  {errors.segment &&
                                    touched.segment &&
                                    errors.segment}
                                </p>
                              )}
                            </div>
                          </Col>
                          <Col lg={4} md={6} sm={12}>
                            <div className="form-row">
                              <label className="form-label">
                                Choose Product Series:
                              </label>
                              <div
                                className={`input-group select-group ${
                                  errors.product_series &&
                                  touched.product_series
                                    ? "input-error"
                                    : ""
                                }`}
                              >
                                <Select
                                  className="w-100"
                                  closeMenuOnSelect={false}
                                  value={values.product_series}
                                  isMulti
                                  options={seriesOption}
                                  id="product_series"
                                  name="product_series"
                                  onChange={(event) => {
                                    handleSeries(event);
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                />
                              </div>
                              {/* {errors.product_series &&
                                touched.product_series && (
                                  <p className="form-error">
                                    {errors.product_series &&
                                      touched.product_series &&
                                      errors.product_series}
                                  </p>
                                )} */}
                            </div>
                          </Col>
                          <Col lg={4} md={6} sm={12}>
                            <div className="form-row">
                              <label className="form-label">
                                Existing Stacking Logic:
                              </label>
                              <div
                                className={`input-group select-group ${
                                  errors.stacking && touched.stacking
                                    ? "input-error"
                                    : ""
                                }`}
                              >
                                <Select
                                  className="w-100"
                                  closeMenuOnSelect={false}
                                  value={values.stacking}
                                  isSearchable={false}
                                  options={stackingOption}
                                  blurInputOnSelect={true}
                                  id="stacking"
                                  name="stacking"
                                  onChange={(event) => {
                                    handleStacking(event);
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                />
                              </div>
                              {errors.stacking && touched.stacking && (
                                <p className="form-error">
                                  {errors.stacking &&
                                    touched.stacking &&
                                    errors.stacking}
                                </p>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row className="mt-3">
                          <Col lg={4} md={6} sm={12}>
                            <div className="form-row">
                              <label className="form-label">
                                Default Payout
                              </label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">Rs.</span>
                                </div>
                                <Input
                                  type="search"
                                  placeholder="Default Payout (Rs.)"
                                  id="defaultPayoutRs"
                                  name="defaultPayoutRs"
                                  value={values.defaultPayoutRs}
                                  disabled={true}
                                  onChange={(e) => {
                                    const input = e.target.value;

                                    // Use a regular expression to remove non-numeric characters
                                    const numericInput = input.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    setDefaultPayoutRs(numericInput);
                                    setFieldValueFunction(
                                      "defaultPayoutRs",
                                      numericInput
                                    );
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                />
                                {/* <div className="input-group-append">
                                  <span className="input-group-text">.00</span>
                                </div> */}
                              </div>
                              {errors.defaultPayoutRs &&
                                touched.defaultPayoutRs && (
                                  <p className="form-error">
                                    {errors.defaultPayoutRs &&
                                      touched.defaultPayoutRs &&
                                      errors.defaultPayoutRs}
                                  </p>
                                )}
                            </div>
                          </Col>
                          <Col lg={4} md={6} sm={12}>
                            <div className="form-row">
                              <label className="form-label">
                                Default Payout (Points)
                              </label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    Points
                                  </span>
                                </div>
                                <Input
                                  type="search"
                                  placeholder="Default Payout (Points)"
                                  disabled={true}
                                  id="defaultPayoutPoints"
                                  name="defaultPayoutPoints"
                                  value={values.defaultPayoutPoints}
                                  onChange={(e) => {
                                    const input = e.target.value;

                                    // Use a regular expression to remove non-numeric characters
                                    const numericInput = input.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    setDefaultPayoutPoints(numericInput);
                                    setFieldValueFunction(
                                      "defaultPayoutPoints",
                                      numericInput
                                    );
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                  invalid={
                                    errors.defaultPayoutPoints &&
                                    touched.defaultPayoutPoints
                                  }
                                />
                              </div>
                              {errors.defaultPayoutPoints &&
                                touched.defaultPayoutPoints && (
                                  <p className="form-error">
                                    {errors.defaultPayoutPoints &&
                                      touched.defaultPayoutPoints &&
                                      errors.defaultPayoutPoints}
                                  </p>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg={4} md={6} sm={12}>
                            <div className="form-row">
                              <label className="form-label">Bonus Payout</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">Rs.</span>
                                </div>
                                <Input
                                  type="search"
                                  placeholder="Bonus Payout (Rs.)"
                                  id="bonusPayoutRs"
                                  name="bonusPayoutRs"
                                  value={values.bonusPayoutRs}
                                  onChange={(e) => {
                                    const input = e.target.value;

                                    // Use a regular expression to remove non-numeric characters
                                    const numericInput = input.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    setBonusPayoutRs(numericInput);
                                    setFieldValueFunction(
                                      "bonusPayoutRs",
                                      numericInput
                                    );
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                />
                              </div>
                              {errors.bonusPayoutRs &&
                                touched.bonusPayoutRs && (
                                  <p className="form-error">
                                    {errors.bonusPayoutRs &&
                                      touched.bonusPayoutRs &&
                                      errors.bonusPayoutRs}
                                  </p>
                                )}
                            </div>
                          </Col>
                          <Col lg={4} md={6} sm={12}>
                            <div className="form-row">
                              <label className="form-label">Points</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    Points
                                  </span>
                                </div>
                                <Input
                                  type="search"
                                  placeholder="Bonus Payout (Points)"
                                  id="bonusPayoutPoints"
                                  name="bonusPayoutPoints"
                                  value={values.bonusPayoutPoints}
                                  onChange={(e) => {
                                    const input = e.target.value;

                                    // Use a regular expression to remove non-numeric characters
                                    const numericInput = input.replace(
                                      /[^0-9]/g,
                                      ""
                                    );

                                    setBonusPayoutPoints(numericInput);
                                    setFieldValueFunction(
                                      "bonusPayoutPoints",
                                      numericInput
                                    );
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                  invalid={
                                    errors.bonusPayoutPoints &&
                                    touched.bonusPayoutPoints
                                  }
                                />
                              </div>
                              {errors.bonusPayoutPoints &&
                                touched.bonusPayoutPoints && (
                                  <p className="form-error">
                                    {errors.bonusPayoutPoints &&
                                      touched.bonusPayoutPoints &&
                                      errors.bonusPayoutPoints}
                                  </p>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg={4} md={6} sm={12}>
                            <div className="form-row">
                              <label className="form-label">Total Rupees</label>
                              <div className="form-control">
                                {isNaN(totalRupees)
                                  ? "Total Rupees"
                                  : totalRupees}
                              </div>
                            </div>
                          </Col>
                          <Col lg={4} md={6} sm={12}>
                            <div className="form-row">
                              <label className="form-label">Total Points</label>
                              <div className="form-control">
                                {isNaN(totalPoints)
                                  ? "Total Points"
                                  : totalPoints}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg={4} md={6} sm={12}>
                            <div className="form-row">
                              <label className="form-label">
                                Campaign Budget
                              </label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">Rs.</span>
                                </div>
                                <Input
                                  type="search"
                                  placeholder="Budget(Rs.)"
                                  id="budget"
                                  name="budget"
                                  value={values.budget}
                                  onChange={(e) => {
                                    const input = e.target.value;
                                    // Use a regular expression to remove non-numeric characters
                                    const numericInput = input.replace(
                                      /[^0-9]/g,
                                      ""
                                    );

                                    setBudget(input);
                                    setFieldValueFunction(
                                      "budget",
                                      numericInput
                                    );
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                />
                                {/* <div className="input-group-append">
                                <span className="input-group-text">.00</span>
                              </div> */}
                              </div>
                              {errors.budget && touched.budget && (
                                <p className="form-error">
                                  {errors.budget &&
                                    touched.budget &&
                                    errors.budget}
                                </p>
                              )}
                            </div>
                          </Col>
                          <Col lg={4} md={12} sm={12}>
                            <div className="form-row">
                              <label className="form-label">
                                Limit No of Packs
                              </label>
                              <div className="input-group">
                                <Input
                                  type="search"
                                  placeholder="Max No.of pack"
                                  id="limitPack"
                                  disabled={true}
                                  name="limitPack"
                                  value={isNaN(limitPack) ? "" : limitPack}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                  invalid={
                                    errors.limitPack && touched.limitPack
                                  }
                                />
                              </div>
                              {errors.limitPack && touched.limitPack && (
                                <p className="form-error">
                                  {errors.limitPack &&
                                    touched.limitPack &&
                                    errors.limitPack}
                                </p>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <Button
                              className=""
                              onClick={() =>
                                setFrequencyButton(!frequencyButton)
                              }
                            >
                              Set Frequency
                              <span>
                                {" "}
                                <i
                                  className={`${
                                    frequencyButton === false
                                      ? " ri-checkbox-blank-line"
                                      : " ri-checkbox-line"
                                  } mx-2 font-size-14`}
                                ></i>
                              </span>
                            </Button>
                          </Col>
                        </Row>
                        {frequencyButton === true ? (
                          <Row className="mt-3">
                            <Col lg={4} md={6} sm={12}>
                              <div className="form-row">
                                <label className="form-label">
                                  Sameday Scanning Time Restriction:
                                </label>
                                <div
                                  className={`input-group select-group ${
                                    errors.restriction && touched.restriction
                                      ? "input-error"
                                      : ""
                                  }`}
                                >
                                  <Select
                                    className="w-100"
                                    closeMenuOnSelect={false}
                                    value={values.restriction}
                                    id="restriction"
                                    name="restriction"
                                    isSearchable={false}
                                    options={restrictionOption}
                                    blurInputOnSelect={false}
                                    onChange={(event) => {
                                      handleRestriction(event);
                                    }}
                                    onBlur={(event) => {
                                      handleBlur(event);
                                    }}
                                  />
                                </div>
                                {errors.restriction && touched.restriction && (
                                  <p className="form-error">
                                    {errors.restriction &&
                                      touched.restriction &&
                                      errors.restriction}
                                  </p>
                                )}
                              </div>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                              <div className="form-row">
                                <label className="form-label">
                                  Bonus On Every:
                                </label>
                                <div className="input-group">
                                  <Input
                                    type="search"
                                    placeholder="Coupon"
                                    id="numberOfCoupon"
                                    name="numberOfCoupon"
                                    value={values.numberOfCoupon}
                                    onChange={(e) => {
                                      const input = e.target.value;

                                      // Use a regular expression to remove non-numeric characters
                                      const numericInput = input.replace(
                                        /[^0-9]/g,
                                        ""
                                      );
                                      setNumberOfCoupon(numericInput);
                                      setFieldValueFunction(
                                        "numberOfCoupon",
                                        numericInput
                                      );
                                    }}
                                    onBlur={(event) => {
                                      handleBlur(event);
                                    }}
                                    invalid={
                                      errors.numberOfCoupon &&
                                      touched.numberOfCoupon
                                    }
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">
                                      Coupon
                                    </span>
                                  </div>
                                </div>
                                {errors.numberOfCoupon &&
                                  touched.numberOfCoupon && (
                                    <p className="form-error">
                                      {errors.numberOfCoupon &&
                                        touched.numberOfCoupon &&
                                        errors.numberOfCoupon}
                                    </p>
                                  )}
                              </div>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                              <div className="form-row">
                                <label className="form-label">
                                  Number Of Times Mechanic Can Avail Bonus:
                                </label>
                                <div className="input-group">
                                  <Input
                                    type="search"
                                    placeholder="Enter number"
                                    id="numberOfTimesCouponAvail"
                                    name="numberOfTimesCouponAvail"
                                    value={values.numberOfTimesCouponAvail}
                                    onChange={(e) => {
                                      const input = e.target.value;

                                      // Use a regular expression to remove non-numeric characters
                                      const numericInput = input.replace(
                                        /[^0-9]/g,
                                        ""
                                      );

                                      // Update the state with the numeric input
                                      setNumberOfTimesCouponAvail(numericInput);
                                      setFieldValueFunction(
                                        "numberOfTimesCouponAvail",
                                        numericInput
                                      );
                                    }}
                                    onBlur={(event) => {
                                      handleBlur(event);
                                    }}
                                    invalid={
                                      errors.numberOfTimesCouponAvail &&
                                      touched.numberOfTimesCouponAvail
                                    }
                                  />
                                </div>
                                {errors.numberOfTimesCouponAvail &&
                                  touched.numberOfTimesCouponAvail && (
                                    <p className="form-error">
                                      {errors.numberOfTimesCouponAvail &&
                                        touched.numberOfTimesCouponAvail &&
                                        errors.numberOfTimesCouponAvail}
                                    </p>
                                  )}
                              </div>
                            </Col>
                            <Col lg={4} md={6} sm={12} className="mt-2">
                              <div className="form-row">
                                <label className="form-label">
                                  Is the bonus once a day?
                                </label>
                                <div
                                  className={`input-group select-group ${
                                    errors.bonusOnceADay &&
                                    touched.bonusOnceADay
                                      ? "input-error"
                                      : ""
                                  }`}
                                >
                                  <Select
                                    className="w-100"
                                    closeMenuOnSelect={false}
                                    value={values.bonusOnceADay}
                                    id="bonusOnceADay"
                                    name="bonusOnceADay"
                                    isSearchable={false}
                                    options={restrictionOption}
                                    blurInputOnSelect={false}
                                    onChange={(event) => {
                                      handleBonusOnceADay(event);
                                    }}
                                    onBlur={(event) => {
                                      handleBlur(event);
                                    }}
                                  />
                                </div>
                                {errors.bonusOnceADay &&
                                  touched.bonusOnceADay && (
                                    <p className="form-error">
                                      {errors.bonusOnceADay &&
                                        touched.bonusOnceADay &&
                                        errors.bonusOnceADay}
                                    </p>
                                  )}
                              </div>
                            </Col>
                          </Row>
                        ) : null}

                        <Row className="mt-3">
                          <Col lg={4} md={12} sm={12}>
                            <div className="form-row">
                              <label className="form-label">Start Date:</label>
                              <div className="input-group">
                                <Input
                                  type="date"
                                  placeholder="Start Date"
                                  id="start_date"
                                  name="start_date"
                                  value={values.start_date}
                                  min={moment()
                                    .add(1, "day")
                                    .format("YYYY-MM-DD")}
                                  onChange={(event) => {
                                    setStartDate(
                                      moment(
                                        new Date(event.target.value)
                                      ).format("YYYY-MM-DD")
                                    );
                                    setFieldValueFunction(
                                      "start_date",
                                      moment(
                                        new Date(event.target.value)
                                      ).format("YYYY-MM-DD")
                                    );
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                  invalid={
                                    errors.start_date && touched.start_date
                                  }
                                />
                              </div>
                              {errors.start_date && touched.start_date && (
                                <p className="form-error">
                                  {errors.start_date &&
                                    touched.start_date &&
                                    errors.start_date}
                                </p>
                              )}
                            </div>
                          </Col>
                          <Col lg={4} md={12} sm={12}>
                            <div className="form-row">
                              <label className="form-label">End Date:</label>
                              <div className="input-group">
                                <Input
                                  type="date"
                                  placeholder="End Date"
                                  id="end_date"
                                  name="end_date"
                                  value={values.end_date}
                                  min={values.start_date}
                                  onChange={(event) => {
                                    setEndDate(
                                      moment(
                                        new Date(event.target.value)
                                      ).format("YYYY-MM-DD")
                                    );
                                    setFieldValueFunction(
                                      "end_date",
                                      moment(
                                        new Date(event.target.value)
                                      ).format("YYYY-MM-DD")
                                    );
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                  invalid={errors.end_date && touched.end_date}
                                />
                              </div>
                              {errors.end_date && touched.end_date && (
                                <p className="form-error">
                                  {errors.end_date &&
                                    touched.end_date &&
                                    errors.end_date}
                                </p>
                              )}
                            </div>
                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={["DatePicker", "DatePicker"]}
                            >
                              <DatePicker
                                label="End Date"
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue)}
                                minDate={moment()}
                              />
                            </DemoContainer>
                          </LocalizationProvider> */}
                          </Col>
                        </Row>
                        <Row>
                          {user.user_id !== 1685633729789 && (<Col lg={12}> {
                            (isEdit && isEdit === true && offerStatus === "UNPUBLISH") ? <CardTitle className="text-danger float-end">
                            Offer is unpublish!
                          </CardTitle> : (
                            <div className="d-flex align-items-end justify-content-end">
                              {loading ? (
                                <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                              ) : (
                                <Button
                                  className="btn-warning mt-3 me-1"
                                  disabled={loading}
                                  onClick={() => {
                                    handleSubmit();
                                  }}
                                >
                                  {isEdit ? "Update " : "Submit Draft"}
                                </Button>
                              )}

                              {/* {isEdit && (
                                <Button
                                  className="btn-warning mt-3 me-1"
                                  disabled={loading}
                                  onClick={() => {
                                    updateStatus("UNPUBLISH");
                                  }}
                                >
                                  Mark as Inactive
                                </Button>
                              )} */}
                              {isEdit && (
                                <Button
                                  className="btn-warning mt-3"
                                  disabled={loading}
                                  onClick={() => {
                                    updateStatus(
                                      (offerStatus === "DRAFT")
                                              ? "PUBLISH"
                                              : (offerStatus === "PUBLISH" ? "UNPUBLISH" : "Save as Draft")
                                    );
                                  }}
                                >
                                  {(offerStatus === "DRAFT")
                                              ? "PUBLISH"
                                              : (offerStatus === "PUBLISH" ? "UNPUBLISH" : "Save as Draft")}
                                </Button>
                              )}
                            </div>
                            )
                          }
                          </Col>)}
                        </Row>
                      </React.Fragment>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default VehicleWiseScheme;
