import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Spinner from "../../components/Loader/Spinner";
import Pagination from "../../components/Table/Pagination";

function ListingAudience(props) {
  const history = useHistory();
  const handleSelectionRoute = (val) => {
    history.push(val);
  };
  const [loading, setLoading] = useState(true);
  const [audienceList, setaudienceList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const handlePagination = (item) => {
    setCurrentPage(item);
    getListing(item);
  };
  const getListing = async (pageNumber, status) => {
    setLoading(true);
    setLoading(false);
  };
  useEffect(() => {
    getListing(currentPage);
  }, []);
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Card>
          <CardHeader className="d-flex align-items-end justify-content-end">
            <div>
              <button
                className="btn btn-primary float-left mx-1"
                type="submit"
                onClick={() => {
                  handleSelectionRoute("/audience-type");
                }}
                color="primary"
              >
                Add New
              </button>
            </div>
          </CardHeader>

          <CardBody className="">
            <div className="">
              <div className="row">
                <div className="table-rep-plugin pt-1">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table
                      id="tech-companies-1"
                      striped
                      bordered
                      responsive
                      hover
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>State</th>
                          <th>Segment</th>
                          <th>Product</th>
                          <th>Details</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      {audienceList.length > 0 && !loading && (
                        <tbody>
                          {audienceList.map((item) => {
                            return (
                              <tr key={item.id}>
                                <td>{item.title}</td>
                                <td>{item.status}</td>
                                <td>{item.segment_name}</td>
                                <td>{item.product_name}</td>
                                <td>{item.details || "-"}</td>
                                <td>
                                  <button
                                    className="btn btn-outline-info btn-sm"
                                    disabled={item.is_active === 0}
                                    onClick={() => {
                                      handleSelectionRoute(
                                        `/edit-audience/${item.program_id}/${item.id}`
                                      );
                                    }}
                                  >
                                    Edit
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                      {loading === true && (
                        <tbody>
                          <tr>
                            <td colSpan="11" className="text-center">
                              <Spinner />
                            </td>
                          </tr>
                        </tbody>
                      )}
                      {audienceList.length === 0 && !loading && (
                        <tbody>
                          <tr>
                            <td colSpan="9" className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
          {totalPages > 1 && (
            <div className="d-flex justify-content-center ">
              <Pagination
                page={currentPage}
                setPage={handlePagination}
                totalPages={totalPages}
              />
            </div>
          )}
        </Card>
      </div>
    </div>
  );
}
export default ListingAudience;
