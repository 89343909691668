// import React, { useEffect, useState } from "react";
// import DashboardService from "../../services/dashboard.service";
// import { CardTitle } from "reactstrap";
// import "./ProgramOverView.css";
// import Spinner, { SpinnerSmall } from "../Loader/Spinner";
// import IndiaMap from "../Charts/DbtRedemtionChart";
// import moment from "moment";

// const CashOverview = (props) => {
//   const data = props.data ? undefined : "";
//   const cashOverview = props.cashOverview;
//   const loading = props.loading;
//   const [section, setSection] = useState("success");
//   const [countMap, setCountMap] = useState(null);
//   const [cashMap, setCashMap] = useState(null);
//   const [mapHide, setMapHide] = useState(true);
//   const [loader, setLoader] = useState(false);

//   const centerTextStyle = {
//     textAlign: "center",
//   };
//   const body = {
//     section: section,
//     start_date: data?.start_date,
//     end_date: data?.end_date,
//     user_id: data?.user_id,
//   };

//   const handleCardClick = async (section) => {
//     setLoader(true);
//     setMapHide(false);
//     setSection(section);
//     body.section = section;
//     await DashboardService.GetRegularCashTransfer(body).then((res) => {
//       setCashMap(res.data?.cash_map);
//       setCountMap(res.data?.map_data);
//       setLoader(false);
//     });
//   };

//   const handleClose = () => {
//     setMapHide(true);
//   };

//   return (
//     <>
//       <div className="mt-3">
//         <CardTitle className="text-black-50 font-size-18">
//           Regular Cash Transfer ({moment(body.start_date).format("DD-MM-YYYY")}{" "}
//           - {moment(body.end_date).format("DD-MM-YYYY")})
//         </CardTitle>
//         <div className="row">
//           <div className="col-md-2">
//             <div
//               className={`${section === "requests" ? "cd-border" : ""} card pe`}
//               onClick={() => handleCardClick("requests")}
//             >
//               <CardTitle className="p-3">Transfer Request</CardTitle>
//               {loading === true ? (
//                 <SpinnerSmall />
//               ) : (
//                 <div className=" mx-3">
//                   <p className="d-flex">
//                     <i
//                       className="mdi mdi-currency-inr me-2 align-middle font-size-20"
//                       style={{ color: "#003584" }}
//                     ></i>
//                     <span
//                       className="card-text font-size-20"
//                       title={`Cash ${cashOverview?.requests?.cash_used}`}
//                     >{`${cashOverview?.requests?.cash_used}`}</span>
//                   </p>
//                   <p className="d-flex ">
//                     <i
//                       className="mdi mdi-counter font-size-18 me-2"
//                       style={{ color: "#003584" }}
//                     ></i>
//                     <span
//                       className="card-text font-size-18"
//                       title={`Count ${cashOverview?.requests.count}`}
//                     >
//                       {cashOverview?.requests.count}
//                     </span>
//                   </p>
//                 </div>
//               )}
//             </div>
//           </div>
//           <div className="col-md-2">
//             <div
//               className={`${section === "success" ? "cd-border" : ""} card pe`}
//               onClick={() => handleCardClick("success")}
//             >
//               <CardTitle className="p-3">Transfer Success</CardTitle>
//               {loading === true ? (
//                 <SpinnerSmall />
//               ) : (
//                 <div className=" mx-3">
//                   <p className="d-flex">
//                     <i
//                       className="mdi mdi-currency-inr me-2 align-middle font-size-20"
//                       style={{ color: "#003584" }}
//                     ></i>
//                     <span
//                       className="card-text font-size-20"
//                       title={`Cash ${cashOverview?.success?.cash_used}`}
//                     >{`${cashOverview?.success?.cash_used}`}</span>
//                   </p>
//                   <p className="d-flex ">
//                     <i
//                       className="mdi mdi-counter font-size-18 me-2"
//                       style={{ color: "#003584" }}
//                     ></i>
//                     <span
//                       className="card-text font-size-18"
//                       title={`Count ${cashOverview?.success.count}`}
//                     >
//                       {cashOverview?.success.count}
//                     </span>
//                   </p>
//                 </div>
//               )}
//             </div>
//           </div>
//           <div className="col-md-2">
//             <div
//               className={`${
//                 section === "initiated" ? "cd-border" : ""
//               } card pe`}
//               onClick={() => handleCardClick("initiated")}
//             >
//               {/* <div className="" style={centerTextStyle}> */}
//               <CardTitle className="p-3">Transfer Initiated</CardTitle>
//               {/* </div> */}
//               {loading === true ? (
//                 <SpinnerSmall />
//               ) : (
//                 <div className=" mx-3">
//                   <p className="d-flex">
//                     {/* <span className="card-text me-2">Amount:</span> */}
//                     <i
//                       className="mdi mdi-currency-inr me-2 align-middle font-size-20"
//                       style={{ color: "#003584" }}
//                     ></i>
//                     <span
//                       className="card-text font-size-20"
//                       title={`Cash ${cashOverview?.initiated?.cash_used}`}
//                     >{`${cashOverview?.initiated?.cash_used}`}</span>
//                   </p>
//                   <p className="d-flex ">
//                     {/* <span className="card-text me-2">Count:</span> */}
//                     <i
//                       className="mdi mdi-counter font-size-18 me-2"
//                       style={{ color: "#003584" }}
//                     ></i>
//                     <span
//                       className="card-text font-size-18"
//                       title={`Count ${cashOverview?.initiated.count}`}
//                     >
//                       {cashOverview?.initiated.count}
//                     </span>
//                   </p>
//                 </div>
//               )}
//             </div>
//           </div>

//           <div className="col-md-2">
//             <div
//               className={`${section === "pending" ? "cd-border" : ""} card pe`}
//               onClick={() => handleCardClick("pending")}
//             >
//               {/* <div className="" style={centerTextStyle}> */}
//               <CardTitle className="p-3">Pending Initiation</CardTitle>
//               {/* </div> */}
//               {loading === true ? (
//                 <SpinnerSmall />
//               ) : (
//                 <div className=" mx-3">
//                   <p className="d-flex">
//                     {/* <span className="card-text me-2">Amount:</span> */}
//                     <i
//                       className="mdi mdi-currency-inr me-2 align-middle font-size-20"
//                       style={{ color: "#003584" }}
//                     ></i>
//                     <span
//                       className="card-text font-size-20"
//                       title={`Cash ${cashOverview?.pending?.cash_used}`}
//                     >{`${cashOverview?.pending?.cash_used}`}</span>
//                   </p>
//                   <p className="d-flex ">
//                     {/* <span className="card-text me-2">Count:</span> */}
//                     <i
//                       className="mdi mdi-counter font-size-18 me-2"
//                       style={{ color: "#003584" }}
//                     ></i>
//                     <span
//                       className="card-text font-size-18"
//                       title={`Count ${cashOverview?.pending.count}`}
//                     >
//                       {cashOverview?.pending.count}
//                     </span>
//                   </p>
//                 </div>
//               )}
//             </div>
//           </div>

//           <div className="col-md-2">
//             <div
//               className={`${section === "bounced" ? "cd-border" : ""} card pe`}
//               onClick={() => handleCardClick("bounced")}
//             >
//               {/* <div className="" style={centerTextStyle}> */}
//               <CardTitle className="p-3">Transfer Bounced</CardTitle>
//               {/* </div> */}
//               {loading === true ? (
//                 <SpinnerSmall />
//               ) : (
//                 <div className=" mx-3">
//                   <p className="d-flex">
//                     {/* <span className="card-text me-2">Amount:</span> */}
//                     <i
//                       className="mdi mdi-currency-inr me-2 align-middle font-size-20"
//                       style={{ color: "#003584" }}
//                     ></i>
//                     <span
//                       className="card-text font-size-20"
//                       title={`Cash ${cashOverview?.bounced?.cash_used}`}
//                     >{`${cashOverview?.bounced?.cash_used}`}</span>
//                   </p>
//                   <p className="d-flex ">
//                     {/* <span className="card-text me-2">Count:</span> */}
//                     <i
//                       className="mdi mdi-counter font-size-18 me-2"
//                       style={{ color: "#003584" }}
//                     ></i>
//                     <span
//                       className="card-text font-size-18"
//                       title={`Count ${cashOverview?.bounced.count}`}
//                     >
//                       {cashOverview?.bounced.count}
//                     </span>
//                   </p>
//                 </div>
//               )}
//             </div>
//           </div>

//           <div className="col-md-2">
//             <div
//               className={`${
//                 section === "cancelled" ? "cd-border" : ""
//               } card pe`}
//               onClick={() => handleCardClick("cancelled")}
//             >
//               {/* <div className="" style={centerTextStyle}> */}
//               <CardTitle className="p-3">Transfer Cancelled</CardTitle>
//               {/* </div> */}
//               {loading === true ? (
//                 <SpinnerSmall />
//               ) : (
//                 <div className=" mx-3">
//                   <p className="d-flex">
//                     {/* <span className="card-text me-2">Amount:</span> */}
//                     <i
//                       className="mdi mdi-currency-inr me-2 align-middle font-size-20"
//                       style={{ color: "#003584" }}
//                     ></i>
//                     <span
//                       className="card-text font-size-20"
//                       title={`Cash ${cashOverview?.cancelled?.cash_used}`}
//                     >{`${cashOverview?.cancelled?.cash_used}`}</span>
//                   </p>
//                   <p className="d-flex ">
//                     {/* <span className="card-text me-2">Count:</span> */}
//                     <i
//                       className="mdi mdi-counter font-size-18 me-2"
//                       style={{ color: "#003584" }}
//                     ></i>
//                     <span
//                       className="card-text font-size-18"
//                       title={`Count ${cashOverview?.cancelled.count}`}
//                     >
//                       {cashOverview?.cancelled.count}
//                     </span>
//                   </p>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>

//         {/* <div className=" mt-3">
//         {mapHide === false ? (
//           <div className="row">
//             <div className="col-md-6 ">
//               <div className="card">
//                 <button
//                   className="btn btn-primary w-auto float-end"
//                   onClick={handleClose}
//                 >
//                   close
//                 </button>
//                 <IndiaMap data={cashMap} />
//               </div>
//             </div>
//             <div className="col-md-6">
//               <div className="card"><IndiaMap data={countMap} /></div>
//             </div>
//           </div>
//         ) : null}
//       </div> */}
//       </div>
//       {mapHide === false ? (
//         <>
//           <div className="row mt-3 d-flex">
//             <div className="col-md-12 col-md-12">
//               <button
//                 className="btn btn-danger w-auto float-end"
//                 onClick={handleClose}
//               >
//                 Close
//               </button>
//             </div>
//           </div>
//           <div className="row mt-3">
//             <div className="col-md-6">
//               <div className="card pe p-3" style={{ height: "500px" }}>
//                 <CardTitle className="p-1 text-center font-size-18">
//                   India <i className="mdi mdi-chevron-right"></i> State Wise
//                   {section === "requests"
//                     ? " Cash Transfer Request"
//                     : section === "pending"
//                     ? " Cash Pending Initiated"
//                     : section === "bounced"
//                     ? " Cash Transfer Bounced"
//                     : section === "cancelled"
//                     ? " Cash Transfer Cancelled"
//                     : section === "initiated"
//                     ? " Cash Transfer Initiated"
//                     : " Successfull Cash Transfer"}
//                 </CardTitle>
//                 {cashMap === null || loader === true ? (
//                   <Spinner />
//                 ) : (
//                   <IndiaMap data={cashMap} />
//                 )}
//               </div>
//             </div>
//             <div className="col-md-6">
//               <div className="card pe p-3" style={{ height: "500px" }}>
//                 <CardTitle className="p-1 text-center font-size-18">
//                   India <i className="mdi mdi-chevron-right"></i> State Wise
//                   {section === "requests"
//                     ? " Count of Transfer Request"
//                     : section === "pending"
//                     ? " Count of Pending Initiated"
//                     : section === "bounced"
//                     ? " Count of Transfer Bounced"
//                     : section === "cancelled"
//                     ? " Count of Transfer Cancelled"
//                     : section === "initiated"
//                     ? " Count of Transfer Initiated"
//                     : " Count of Successfull Transfer"}
//                 </CardTitle>
//                 {countMap === null || loader === true ? (
//                   <Spinner />
//                 ) : (
//                   <IndiaMap data={countMap} />
//                 )}
//               </div>
//             </div>
//           </div>
//         </>
//       ) : null}
//     </>
//   );
// };

// export default CashOverview;
import React, { useState } from "react";
import DashboardService from "../../services/dashboard.service";
import { CardTitle, Card } from "reactstrap";
import "./ProgramOverView.css";
import Spinner, { SpinnerSmall } from "../Loader/Spinner";
import IndiaMap from "../Charts/DbtRedemtionChart";
import moment from "moment";

const CashOverview = (props) => {
  const { data, cashOverview, loading } = props;
  const [section, setSection] = useState("success");
  const [countMap, setCountMap] = useState(null);
  const [cashMap, setCashMap] = useState(null);
  const [mapHide, setMapHide] = useState(true);
  const [loader, setLoader] = useState(false);

  // Add a useEffect to fetch data when the component mounts
  // useEffect(() => {
  //   // Define an async function to fetch the data
  //   const fetchData = async () => {
  //     setLoader(true);
  //     setMapHide(false);
  //     body.section = section;
  //     try {
  //       const res = await DashboardService.GetRegularCashTransfer(body);
  //       setCashMap(res.data?.cash_map);
  //       setCountMap(res.data?.map_data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     } finally {
  //       setLoader(false);
  //     }
  //   };

  //   // Call the fetchData function
  //   fetchData();
  // }, [section]); // Run this effect whenever the "section" state changes
  const handleCardClick = async (section) => {
    setMapHide(false);
    setSection(section);
    body.section = section;
    await DashboardService.GetRegularCashTransfer(body).then((res) => {
      setCashMap(res.data?.cash_map);
      setCountMap(res.data?.map_data);
      setLoader(false);
    });
  };

  const body = {
    section: section,
    start_date: data?.start_date || "",
    end_date: data?.end_date || "",
    user_id: data?.user_id || "",
  };

  return (
    <div className="mt-3">
      <CardTitle className="text-black-50 font-size-18">
        Regular Cash Transfer ({moment(body.start_date).format("DD-MM-YYYY")} -{" "}
        {moment(body.end_date).format("DD-MM-YYYY")})
      </CardTitle>
      <div className="row">
        {[
          { section: "requests", title: "Transfer Request" },
          { section: "success", title: "Transfer Success" },
          { section: "initiated", title: "Transfer Initiated" },
          { section: "pending", title: "Pending Initiation" },
          { section: "bounced", title: "Transfer Bounced" },
          { section: "cancelled", title: "Transfer Cancelled" },
        ].map((card, index) => (
          <div key={index} className="col-md-2">
            <Card
              className={`${
                section === card.section ? "cd-border" : ""
              } card pe`}
              onClick={() => handleCardClick(card.section)} // Set section directly when card is clicked
            >
              <CardTitle className="p-3">{card.title}</CardTitle>
              {cashOverview === null || loading === true ? (
                <SpinnerSmall />
              ) : (
                !loader && ( // Only render when loader is false
                  <div className=" mx-3">
                    <p className="d-flex">
                      <i
                        className="mdi mdi-currency-inr me-2 align-middle font-size-20"
                        style={{ color: "#003584" }}
                      ></i>
                      <span
                        className="card-text font-size-20"
                        title={`Cash ${
                          cashOverview?.[card.section]?.cash_used
                        }`}
                      >{`${cashOverview?.[card.section]?.cash_used}`}</span>
                    </p>
                    <p className="d-flex ">
                      <i
                        className="mdi mdi-counter font-size-18 me-2"
                        style={{ color: "#003584" }}
                      ></i>
                      <span
                        className="card-text font-size-18"
                        title={`Count ${cashOverview?.[card.section]?.count}`}
                      >
                        {cashOverview?.[card.section]?.count}
                      </span>
                    </p>
                  </div>
                )
              )}
            </Card>
          </div>
        ))}
      </div>
      {mapHide === false &&
        !loader && ( // Only render when loader is false
          <>
            <div className="row mt-3 d-flex">
              <div className="col-md-12">
                <button
                  className="btn btn-danger w-auto float-end"
                  onClick={() => setMapHide(true)} // Set mapHide to true when button is clicked
                >
                  Close
                </button>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <Card className="pe p-3" style={{ height: "500px" }}>
                  <CardTitle className="p-1 text-center font-size-18">
                    India <i className="mdi mdi-chevron-right"></i> State Wise{" "}
                    {section === "requests"
                      ? "Cash Transfer Request"
                      : section === "pending"
                      ? "Count of Pending Initiated"
                      : section === "bounced"
                      ? "Count of Transfer Bounced"
                      : section === "cancelled"
                      ? "Count of Transfer Cancelled"
                      : section === "initiated"
                      ? "Count of Transfer Initiated"
                      : "Count of Successful Transfer"}
                  </CardTitle>
                  {cashMap === null ? <Spinner /> : <IndiaMap data={cashMap} />}
                </Card>
              </div>
              <div className="col-md-6">
                <Card className="pe p-3" style={{ height: "500px" }}>
                  <CardTitle className="p-1 text-center font-size-18">
                    India <i className="mdi mdi-chevron-right"></i> State Wise{" "}
                    {section === "requests"
                      ? "Count of Transfer Request"
                      : section === "pending"
                      ? "Count of Pending Initiated"
                      : section === "bounced"
                      ? "Count of Transfer Bounced"
                      : section === "cancelled"
                      ? "Count of Transfer Cancelled"
                      : section === "initiated"
                      ? "Count of Transfer Initiated"
                      : "Count of Successful Transfer"}
                  </CardTitle>
                  {countMap === null ? (
                    <Spinner />
                  ) : (
                    <IndiaMap data={countMap} />
                  )}
                </Card>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default CashOverview;
