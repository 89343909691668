import React, { useState } from "react";

import {
  Col,
  Row,
  Button,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import './CustomModal.css'
const ReportModal = ({
  isOpen,
  toggle,
  image
}) => {

  const handleSubmit = async () => {
      handleClose();
    }
  const handleClose = async () => {
    toggle();
  };


  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
        <ModalBody>
        <img src={image}  style={{width:"80%", height:"50%"}} />
          <ModalFooter>
            <Button type="button" color="light" onClick={handleClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ReportModal;
