import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication Module
// import Account from './auth/register/reducer';
// import Login from './auth/login/reducer';
// import Forget from './auth/forgetpwd/reducer';
import auth from '../reducers/auth'
import loading from '../reducers/loading';
import master from '../reducers/master';
//Calendar 
import Calendar from "./calendar/reducer";

//chat
import chat from "./chat/reducer"

//ecommerce
import Ecommerce from "./e-commerce/reducer";

const rootReducer = combineReducers({

    // public
    Layout,
    auth,
    loading,
    master
    // Authentication
    // Account,
    
    // Forget,
    // Calendar,
    // Ecommerce,
    // chat,

});

export default rootReducer;