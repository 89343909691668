import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import axios from "axios";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  Table,
  CardTitle,
} from "reactstrap";

//Import Breadcrumb
import "react-datepicker/dist/react-datepicker.css";

import "bootstrap/dist/css/bootstrap.css";
import moment from "moment";
import getSegment from "../../services/segment.service";
import "./category.css";

import { showLoader, hideLoader } from "../../actions/loading";
import { useDispatch, useSelector } from "react-redux";
import { SpinnerSmall } from "../../components/Loader/Spinner";
import Spinner from "../../components/Loader/Spinner";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-datepicker/dist/react-datepicker.css";
import Master from "../../components/Master/Master";
import { toggleRightSidebar } from "../../store/actions";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const ProductInsights = (props) => {
  const loading = useSelector((state) => state.loading.loading);
  const user = JSON.parse(localStorage.getItem("user"));
  const master = useSelector((state) => state.master.setList.body);
  const dispatch = useDispatch();

  const [productList, setProductList] = useState([]);

  const [isOpenfirstMonth, setIsOpenFirstmonth] = useState(false);
  const [segment, setSegment] = useState("ALL");
  const [segmentOption, setSegmentOption] = useState([
    { value: "Select", Label: "Select" },
  ]);
  // const currentDate = new Date();
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1); // current date
  const initialStartDate = moment(currentDate).subtract(29, "days").toDate(); // subtract 30 days from current date

  const [formattedSecondMonthStartDate, setFormattedSecondMonthStartDate] =
    useState(
      new Date(initialStartDate).toLocaleString("default", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
    );
  const [formattedSecondMonthEndDate, setFormattedSecondMonthEndDate] =
    useState(
      new Date(currentDate).toLocaleString("default", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
    );
  const [formattedFirstMonthStartDate, setFormattedFirstMonthStartDate] =
    useState();
  const [formattedFirstMonthEndDate, setFormattedFirstMonthEndDate] =
    useState();

  const initialDateRange = [
    {
      startDate: new Date(moment(initialStartDate)),
      endDate: new Date(moment(currentDate)),
      key: "selection",
    },
  ];
  const [dateRange, setDateRange] = useState(initialDateRange);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  let body = {
    start_date: moment(dateRange[0].startDate).format("YYYY-MM-DD"),
    end_date: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
    segment: segment,
    regional_mapping: master ? master.regional_mapping : null,
    user_id: user.user_id,
    limit: 10,
    offset: currentPage,
    role_id: user.role_id,
  };

  const handledateRange = (item) => {
    setDateRange([item.selection]);
    body.start_date = moment(item.selection.startDate).format("YYYY-MM-DD");
    body.end_date = moment(item.selection.endDate).format("YYYY-MM-DD");
  };

  const submitDates = async () => {
    handleDateRangeClose();
    dispatch(showLoader());
    setIsOpenFirstmonth(false);
    await getSegment.GetProductData(body).then((res) => {
      if (res?.status === true) {
        setProductList(res.data.data);
        setTotalPages(res.data.pagination.total_pages);
        setFormattedFirstMonthStartDate(res.data.dates.first_month_start);
        setFormattedFirstMonthEndDate(res.data.dates.first_month_end);
        setFormattedSecondMonthStartDate(res.data.dates.second_month_start);
        setFormattedSecondMonthEndDate(res.data.dates.second_month_end);
      }
    });
    dispatch(hideLoader());
  };

  const Reset = () => {
    setSegment("ALL");
    body.segment = "ALL";
    body.start_date = moment(initialDateRange[0].startDate).format(
      "YYYY-MM-DD"
    );
    body.end_date = moment(initialDateRange[0].endDate).format("YYYY-MM-DD");
    body.regional_mapping = null;
    setDateRange(initialDateRange);
    submitDates();
  };

  const handleFirstMonthButtonState = () => {
    setIsOpenFirstmonth(!isOpenfirstMonth);
  };

  const GetSegmentList = async () => {
    const options = await getSegment.GetSegmentList();
    setSegmentOption(options?.data);
    if (options) {
    }
  };

  const handleSegment = (event) => {
    setSegment(event.target.value);
  };

  const handleDateRangeClose = () => {
    setIsOpenFirstmonth(false);
  };

  useEffect(() => {
    GetSegmentList();
    submitDates(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [master]);

  useEffect(() => {
    submitDates(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handlePage = (event) => {
    const buttonElement = event.target; // Your event target
    if (buttonElement.tagName === "BUTTON") {
      const pageNumber = buttonElement.textContent;
      console.log(pageNumber); // Output: "2"
      setCurrentPage(pageNumber);
    }
  };
  useEffect(() => {
    setCurrentPage(1);
    submitDates(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segment]);

  useEffect(() => {
    setCurrentPage(1);
  }, [dateRange]);

  function toggleRightbar() {
    props.toggleRightSidebar();
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <CardTitle className="font-size-18 mb-3">Product Insights</CardTitle>
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        {isOpenfirstMonth === false ? (
                          <div>
                            <label className="form-label">Select Dates: </label>
                            <button
                              className="form-select text-lg-start"
                              onClick={handleFirstMonthButtonState}
                            >
                              {loading === true ? (
                                <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                              ) : (
                                `${
                                  formattedSecondMonthStartDate +
                                  " - " +
                                  formattedSecondMonthEndDate
                                }`
                              )}
                            </button>
                          </div>
                        ) : (
                          <div className="customDateOverlay">
                            <label className="form-label">Select Dates: </label>
                            <div className="">
                              <DateRange
                                editableDateInputs={true}
                                // onChange={(item) => handledateRange(item.selection.startDate,item.selection.endDate)}
                                onChange={handledateRange}
                                moveRangeOnFirstSelection={false}
                                ranges={dateRange}
                                rangeColors={["#FCB92C", "#FCB92C", "#FCB92C"]}
                                shouldCloseOnSelect={true}
                                maxDate={currentDate}
                                minDate={new Date(moment("2021-01-01"))}
                                onClose={handleDateRangeClose}
                              />
                              <div>
                                <Button
                                  className="btn-danger float-end mt-1"
                                  onClick={handleFirstMonthButtonState}
                                >
                                  Close
                                </Button>
                                <Button
                                  className="btn-warning float-end mt-1 margin-right"
                                  onClick={() => submitDates()}
                                >
                                  Search
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="col-lg-2 col-md-6 col-sm-12">
                        <div className="">
                          <label className="form-label">Select Segment: </label>
                          <select
                            className="form-select w-100"
                            aria-label="Default select example"
                            value={segment}
                            onChange={handleSegment}
                          >
                            {segmentOption ? (
                              segmentOption.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))
                            ) : (
                              <option></option>
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-6 col-sm-12">
                        <div className="margin-top d-flex">
                          <Button
                            className="btn-warning margin-right"
                            onClick={() => submitDates()}
                          >
                            Search
                          </Button>
                          <Button
                            className="btn btn-danger btn-secondary "
                            onClick={() => Reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </div>
                      <div className="col-lg-1 col-md-6 col-sm-12 w-auto">
                        {user.role_id !== 22 ? (
                          <div className="margin-top d-flex">
                            <button
                              color="none"
                              onClick={toggleRightbar}
                              // type="button"
                              className="advance-filter-btn"
                            >
                              Team Filter
                              <i className="ri-filter-2-fill mx-1"></i>
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div>
                    <Master />{" "}
                  </div>
                </CardHeader>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <div className="container-fluid">
                    <div className="row">
                      <h4 className="card-title pt-4">Product Volume(ltr)</h4>
                      <div className="table-rep-plugin pt-4">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          {loading === true ? (
                            <Spinner />
                          ) : (
                            <Table
                              id="tech-companies-1"
                              striped
                              bordered
                              responsive
                            >
                              <thead>
                                <tr className="text-center">
                                  <th data-priority="1">Segment</th>
                                  <th data-priority="1">Product</th>
                                  <th data-priority="1">Pack Size</th>
                                  <th data-priority="3">
                                    {" "}
                                    {`${formattedFirstMonthStartDate} 
                                       - 
                                      ${formattedFirstMonthEndDate} (Ltr)`}
                                  </th>
                                  <th data-priority="1">
                                    {`${formattedSecondMonthStartDate} 
                                       - 
                                      ${formattedSecondMonthEndDate} (Ltr)`}
                                  </th>
                                  <th>Difference</th>
                                  <th>% Change</th>
                                </tr>
                              </thead>
                              <tbody>
                                {productList.length === 0 ? (
                                  <tr className="align">
                                    <td colSpan="" className="text-center">
                                      No data available
                                    </td>
                                  </tr>
                                ) : (
                                  productList?.map((product) => (
                                    <tr key={product.id}>
                                      <td>{product.segment}</td>
                                      <td>{product.product}</td>
                                      <td className="text-center">
                                        {product.pack_size}
                                      </td>
                                      <td className="text-center">
                                        {product.first_month_volume}
                                      </td>
                                      <td className="text-center">
                                        {product.second_month_volume}
                                      </td>
                                      <td>{`${product.abs_change}`}</td>
                                      <td>
                                        {product.percentage}
                                        {product.percentage > 0 ? (
                                          <i className="ri-arrow-up-line align-middle icon-green mx-2"></i>
                                        ) : product.percentage < 0 ? (
                                          <i className="ri-arrow-down-line align-middle icon-red mx-2"></i>
                                        ) : null}
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </Table>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
                <div className="d-flex justify-content-center mb-2">
                  <Stack spacing={2}>
                    <Pagination
                      count={totalPages}
                      hidePrevButton
                      hideNextButton
                      onClick={handlePage}
                    />
                  </Stack>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStateToProps, { toggleRightSidebar })(
  withNamespaces()(ProductInsights)
);
