import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Card, CardHeader, CardTitle } from "reactstrap";
import Service from "../../services/rlpDashboard.service";
import DateRangePicker from "react-daterange-picker";

//Import Breadcrumb
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ProgramOverview from "../../components/Dashboard/ProgramOverviewRlp";
import RedemptionOverview from "../../components/Dashboard/RedemptionOverviewRlp";
import Spinner from "../../components/Loader/Spinner";
import EarningOverview from "../../components/Dashboard/EarningOverviewRlp";
import IndiaMap from "../../components/Charts/IndiaMap";
import { set } from "lodash";
import authService from "../../services/auth.service";
import { success } from "toastr";
const moment = extendMoment(originalMoment);

const Dashboard = () => {
  let history = useHistory();
  // const [breadcrumbItems, setBreadcrumbItems] = useState([
  //   { title: "eni", link: "/" },
  //   { title: "Dashboard", link: "#" },
  // ]);
  const { user_id, role_id } = JSON.parse(localStorage.getItem("user")) ?? {};
  const [label, setLabel] = useState("Yesterday");
  const [packSold, setPackSold] = useState(null);
  const [programOverview, setProgramOverview] = useState(null);
  const [redemptionOverview, setRedemptionOverview] = useState(null);
  const [earnOverview, setEarnOverview] = useState(null);
  const [loading, setLoading] = useState(false);
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  const [showOverlay, setShowOverlay] = useState(false);
  const [value, setValue] = useState(
    moment.range(
      moment().clone().subtract(1, "day"),
      moment().clone().subtract(1, "day")
    )
  );

  const body = {
    user_id,
    role_id,
    start_date: moment(value.start._d).format("YYYY-MM-DD"),
    end_date: moment(value.end._d).format("YYYY-MM-DD"),
  };
  const handleLogout = () => {
    authService.logout();
  };

  const fetchData = async () => {
    setLoading(true);

    const redemptionOverviewPromise = Service.GetRedemptionOverviewDataCount(
      body
    ).then((res) => {
      setRedemptionOverview(res?.data);
    });

    const earnOverviewPromise = Service.GetEarningOverviewCount(body).then(
      (res) => {
        setEarnOverview(res?.data);
      }
    );
    const programOverviewPromise = Service.GetProgramOverview(body).then(
      (res) => {
        if (res?.status === true) {
          setProgramOverview(res.data);
        }
      }
    );

    await Promise.all([
      redemptionOverviewPromise,
      earnOverviewPromise,
      programOverviewPromise,
    ]);
    setLoading(false);
  };

  const ProgramOverviewDataCount1 = async () => {
    setLoading(true);
    setProgramOverview(null);
    await Service.GetProgramOverview(body).then((res) => {
      if (res?.status === true) {
        setProgramOverview(res.data);
        setLoading(false);
      }
    });
  };

  const ProgramOverviewDataCount = async () => {
    setLoading(true);

    const pBody = {
      user_id,
      role_id,
      start_date: moment(value.start._d).format("YYYY-MM-DD"),
      end_date: moment(value.end._d).format("YYYY-MM-DD"),
      section: "pack_sold",
    };
    await Service.GetProgramOverview1(pBody)
    .then((res) => {
      if (res?.status === true) {
        setPackSold(res.data.data);
        setLoading(false);
      }
    })
    .finally(setLoading(false));
    // const dBody = {
    //   user_id,
    //   role_id,
    //   start_date: moment(value.start._d).format("YYYY-MM-DD"),
    //   end_date: moment(value.end._d).format("YYYY-MM-DD"),
    //   section: "download",
    // };
    // const rBody = {
    //   user_id,
    //   role_id,
    //   start_date: moment(value.start._d).format("YYYY-MM-DD"),
    //   end_date: moment(value.end._d).format("YYYY-MM-DD"),
    //   section: "registered",
    // };
    // const dbtBody = {
    //   user_id,
    //   role_id,
    //   start_date: moment(value.start._d).format("YYYY-MM-DD"),
    //   end_date: moment(value.end._d).format("YYYY-MM-DD"),
    //   section: "dbt_verified",
    // };
    // const aBody = {
    //   user_id,
    //   role_id,
    //   start_date: moment(value.start._d).format("YYYY-MM-DD"),
    //   end_date: moment(value.end._d).format("YYYY-MM-DD"),
    //   section: "active",
    // };

    // try {
    //   const [download, registered, dbt_verified, active, pack_sold] =
    //     await axios.all([
    //       Service.GetProgramOverview1(dBody),
    //       Service.GetProgramOverview1(rBody),
    //       Service.GetProgramOverview1(dbtBody),
    //       Service.GetProgramOverview1(aBody),
    //       Service.GetProgramOverview1(pBody),
    //     ]);
    //   const responses = [
    //     download.data,
    //     registered.data.data,
    //     dbt_verified.data.data,
    //     active.data.data,
    //     pack_sold.data.data,
    //   ];
    //   console.log("responses", responses);
    //   // setPackSold(responses);
    //   setProgramOverview(responses);
    // } catch (error) {
    //   console.error("Error fetching program overview data:", error);
    // } finally {
    //   setLoading(false);
    // }
  };

 

  const RedemptionOverviewDataCount = async () => {
    await Service.GetRedemptionOverviewDataCount(body).then((res) => {
      setRedemptionOverview(res?.data);
    });
  };
  const EarnOverviewDataCount = async () => {
    setLoading(true);
    await Service.GetEarningOverviewCount(body).then((res) => {
      setEarnOverview(res?.data);
      setLoading(false);
    });
  };


  const onSelect = async (value, states) => {
    setValue(value);
    setLabel("Custom");
    body.start_date = moment(value.start._d).format("YYYY-MM-DD");
    body.end_date = moment(value.end._d).format("YYYY-MM-DD");
  };

  const handleLastSixMonthsClick = async () => {
    const today = moment().subtract(1, "day");
    const sixMonthsAgo = moment().subtract(1, "day").subtract(6, "months");
    setValue(moment.range(sixMonthsAgo, today));
    body.start_date = moment(sixMonthsAgo).format("YYYY-MM-DD");
    body.end_date = moment(today).format("YYYY-MM-DD");
    setLabel("6 Months");
    setShowOverlay(false);
  };

  const handleLastMonthClick = async () => {
    const today = moment().subtract(1, "day");
    const lastMonth = moment().subtract(1, "day").subtract(1, "month");
    setValue(moment.range(lastMonth, today));
    body.start_date = moment(lastMonth).format("YYYY-MM-DD");
    body.end_date = moment(today).format("YYYY-MM-DD");
    setLabel("Month");
    setShowOverlay(false);
  };

  const handleLastQuarterClick = async () => {
    const today = moment().subtract(1, "day");
    const lastQuarter = moment().subtract(1, "day").subtract(3, "months");
    setValue(moment.range(lastQuarter, today));
    body.start_date = moment(lastQuarter).format("YYYY-MM-DD");
    body.end_date = moment(today).format("YYYY-MM-DD");
    setLabel("Quarter");
    setShowOverlay(false);
  };

  const handleLastYearClick = async () => {
    const today = moment().subtract(1, "day");
    const lastYear = moment().subtract(1, "day").subtract(1, "year");
    setValue(moment.range(lastYear, today));
    body.start_date = moment(lastYear).format("YYYY-MM-DD");
    body.end_date = moment(today).format("YYYY-MM-DD");
    setLabel("Year");
    setShowOverlay(false);
  };

  const handleYesterdayClick = async () => {
    const yesterday = moment().subtract(1, "day");
    setValue(moment.range(yesterday, yesterday));
    body.start_date = moment(yesterday).format("YYYY-MM-DD");
    body.end_date = moment(yesterday).format("YYYY-MM-DD");
    setLabel("Yesterday");
    setShowOverlay(false);
  };

  const handleOverallClick = async () => {
    const yesterday = moment().subtract(1, "day");
    const startDate = moment("2022-01-01");
    setValue(moment.range(startDate, yesterday));
    body.start_date = startDate.format("YYYY-MM-DD");
    body.end_date = moment(yesterday).format("YYYY-MM-DD");
    setLabel("Overall");
    setShowOverlay(false);
  };

  function handleClick() {
    setShowOverlay(true);
  }

  const handleApplyClick = async () => {
    setShowOverlay(false);
    setLoading(true);
    ProgramOverviewDataCount();
    RedemptionOverviewDataCount();
    EarnOverviewDataCount();
  };

  useEffect(() => {
    // fetchData();
    ProgramOverviewDataCount();
    RedemptionOverviewDataCount();
    EarnOverviewDataCount();
    ProgramOverviewDataCount1();
  }, [value]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col md={12}>
              <div className="d-flex justify-content-between">
                <CardTitle className="font-size-18">RLP Dashboard</CardTitle>
                <div className="w-auto float-end">
                  <div className="date-container ">
                    <button
                      className="form-control text-lg-start"
                      onClick={handleClick}
                    >
                      {label}
                      <i
                        className="ri-calendar-2-fill align-middle float-end "
                        style={{ marginLeft: "5px" }}
                      ></i>
                    </button>
                  </div>
                  {showOverlay && (
                    <div className="overlay">
                      <div className="content">
                        <div className="d-flex">
                          <DateRangePicker
                            value={value}
                            onSelect={onSelect}
                            singleDateRange={true}
                            rangeColors={["#FCB92C", "#FCB92C", "#FCB92C"]}
                            maximumDate={currentDate}
                            minimumDate={new Date(moment("2022-01-01"))}
                          />
                          <div className="d-flex flex-column mx-1">
                            <button
                              className="date-btn mt-1"
                              onClick={() => handleOverallClick()}
                            >
                              Over All
                            </button>
                            <button
                              className="date-btn mt-1"
                              onClick={() => handleYesterdayClick()}
                            >
                              Yesterday
                            </button>
                            <button
                              className="date-btn mt-1"
                              onClick={() => handleLastMonthClick()}
                            >
                              Month
                            </button>
                            <button
                              className="date-btn mt-1"
                              onClick={() => handleLastQuarterClick()}
                            >
                              Quarter
                            </button>
                            <button
                              className="date-btn mt-1"
                              onClick={() => handleLastSixMonthsClick()}
                            >
                              6 Months
                            </button>
                            <button
                              className="date-btn mt-1"
                              onClick={() => handleLastYearClick()}
                            >
                              Year
                            </button>
                          </div>
                        </div>
                        <div className="d-flex float-end">
                          <button
                            className="date-btn-close mt-3 me-1 "
                            onClick={handleApplyClick}
                          >
                            Apply
                          </button>
                          <button
                            className="date-btn-close mt-3 me-1 "
                            onClick={() => setShowOverlay(false)}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {/* <CardHeader className="bg-transparent border-bottom h-auto pt-5"></CardHeader> */}

              <ProgramOverview
                data={body}
                programOverview={programOverview}
                loading={loading}
                packSold={packSold}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <EarningOverview
                data={body}
                earnOverview={earnOverview}
                loading={loading}
              />
            </Col>
          </Row>
          {/* <Breadcrumbs title="Dashboard" breadcrumbItems={breadcrumbItems} /> */}
          <Row>
            <Col md={12}>
              <RedemptionOverview
                data={body}
                redemptionOverview={redemptionOverview}
                loading={loading}
              />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
