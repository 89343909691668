import axios from "axios";
import { API_URL } from "../Config/config";

// const API_URL = "http://devanalyticsapi.eniclub.in/api/v1/";
// const API_URL = "http://localhost:4077/api/v1/";
// const API_URL = "http://analyticsapi.eniclub.in/api/v1/";

const user = JSON.parse(localStorage.getItem("user"));
const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    // Authorization: `Bearer ${user.token}`,
    "Content-Type": "application/json",
  },
});

if (user) {
  axiosInstance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.token}`;
}

const addUser = async (data) => {
  return await axiosInstance
    .post(API_URL + "master/adduser", data)
    .then((response) => {
      if (response.data.status === "success") {
        return {
          status: true,
          message: response.data.message,
          code: response.data.code,
          data: response.data.data,
        };
      }
    })
    .catch((error) => {
      if (error.response.data.status === "fail") {
        return {
          status: false,
          message: error.response.data.message,
          code: error.response.data.code,
        };
      }
      return {
        status: false,
        message: error.message,
        data: error,
      };
    });
};

const getStateHeadList = async (data) => {
  try {
    const response = await axiosInstance.post(
      API_URL + "sales-officer/getSalesOfficers",
      data
    );
    console.log(response.data, "rrrrrrrrrrrrr");
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const editStateHead = async (data) => {
  // try {
  console.log("hiiiiiiiiiiiiiiiiiiii");
  const response = await axiosInstance.post(
    API_URL + "sales-officer/updateSalesOfficer",
    data
  );

  return response;
  //   } catch (error) {
  // console.log("hiiiiiiiiiiiiiiiiiiii")
  // console.error(error);
  //   }
};

const getPincodes = async (data) => {
  return await axiosInstance
    .post(API_URL + "pincode/getAllPincode", data)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      return {
        status: false,
      };
    });
};

const getCities = async (body) => {
  return await axiosInstance
    .post(API_URL + "district/getAllDistrict", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      return {
        status: false,
      };
    });
};

const getStates = async (body) => {
  return await axiosInstance
    .post(API_URL + "state/getAllStates", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      return {
        status: false,
      };
    });
};

const getUserById = async (body) => {
  console.log(body, "bdyyyyyyyyyyyyyyyyyyyyyyyyyy");
  return await axiosInstance
    .post(API_URL + "master/getUserById", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      return {
        status: false,
      };
    });
};

const updateUser = async (data) => {
  return await axiosInstance
    .post(API_URL + "master/updateUser", data)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((response) => {
      console.log(response);
      return {
        status: false,
        data: response,
      };
    });
};

const getZones = async (data) => {
  return await axiosInstance
    .post(API_URL + "master/getAllUser", data, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      return {
        status: false,
      };
    });
};

const getDistricts = async (body) => {
  return await axiosInstance
    .post(API_URL + "district/getAllDistrict", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      return {
        status: false,
      };
    });
};

const getCities1 = async (body) => {
  return await axiosInstance
    .post(API_URL + "city/getAllCity", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      return {
        status: false,
      };
    });
};

const cloneUser = async (data) => {
  return await axiosInstance
    .post(API_URL + "master/cloneUser", data)
    .then((response) => {
      if (response.data.status === "success") {
        return {
          status: true,
          message: response.data.message,
          code: response.data.code,
          data: response.data.data,
        };
      }
    })
    .catch((error) => {
      if (error.response.data.status === "fail") {
        return {
          status: false,
          message: error.response.data.message,
          code: error.response.data.code,
        };
      }
      return {
        status: false,
        message: error.message,
        data: error,
      };
    });
};

const checkMobileUnique = async (body) => {
  return await axiosInstance
    .post(API_URL + "master/checkUniqueMobile", body)
    .then((response) => {
      if (response.data.status === "success") {
        return {
          status: true,
          message: response.data.message,
          code: response.data.code,
          data: response.data,
        };
      }
    })
    .catch((error) => {
      if (error.response.data.status === "fail") {
        return {
          status: false,
          message: error.response.data.message,
          code: error.response.data.code,
        };
      }
      return {
        status: false,
        message: error.message,
        data: error,
      };
    });
};
const checkEmailUnique = async (body) => {
  return await axiosInstance
    .post(API_URL + "master/checkUniqueEmail", body)
    .then((response) => {
      if (response.data.status === "success") {
        return {
          status: true,
          message: response.data.message,
          code: response.data.code,
          data: response.data,
        };
      }
    })
    .catch((error) => {
      if (error.response.data.status === "fail") {
        return {
          status: false,
          message: error.response.data.message,
          code: error.response.data.code,
        };
      }
      return {
        status: false,
        message: error.message,
        data: error,
      };
    });
};

export default {
  addUser,
  getStateHeadList,
  editStateHead,
  getPincodes,
  getCities,
  getStates,
  getUserById,
  updateUser,
  getZones,
  getDistricts,
  getCities1,
  cloneUser,
  checkMobileUnique,
  checkEmailUnique,
};
