/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

// Redux
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  CardTitle,
  Card,
  CardHeader,
  CardBody,
  Input,
  Label,
} from "reactstrap";

import { Formik } from "formik";

import campaignSer from "../../../services/campaignService";

import { toast } from "react-toastify";
import Select from "react-select";
import moment from "moment";
import ExcelJS from "exceljs";
// import images
import "react-toastify/dist/ReactToastify.css";

// import css
// import "../../ConsumptionPayout.scss";
import { campaign_programs } from "../../../Config/config";
import { SpinnerSmall } from "../../../components/Loader/Spinner";

function FocRetailer(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const routeParams = props.match.params;
  console.log("routeParams---------", routeParams);
  const program_id = routeParams.programId;
  const history = useHistory();
  if (
    !routeParams ||
    campaign_programs.includes(routeParams.programId) == false
  ) {
    history.goBack();
  }

  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(routeParams.id ? true : false);
  const [editDetails, setEditVal] = useState({});
  const [tempSegment, setTempSegment] = useState("");
  const [segment, setSegment] = useState("ALL");
  const [segmentOption, setSegmentOption] = useState([
    { value: "Select", label: "Select" },
  ]);
  const [product, setProduct] = useState("ALL");
  const [productOption, setProductOption] = useState([
    { value: "Select", label: "Select" },
  ]);
  const [packSize, setPackSize] = useState([]);
  const [packSizeOption, setPackSizeOption] = useState([
    { value: "Select", label: "Select" },
  ]);
  const [payoutVolume, setPayoutVolume] = useState("");
  const [numberOfPacks, setNumberOfPack] = useState(1);
  const [dayWiseButton, setdayWiseButton] = useState(false);
  const [series, setSeries] = useState([]);
  const [seriesOption, setSeriesOptions] = useState([]);
  const [buttonDisabled, setButtonDisable] = useState(false);
  const [offerStatus, setOfferStatus] = useState();
  const [radioValue, setRadioValue] = useState("DRAFT");
  const [vehicleSegment, setVehicleSegment] = useState("");
  const [csvFile, setCSVFile] = useState(null);
  const [mobile, setMobile] = useState([]);
  const [inputMobile, setInputMobile] = useState("");
  const [mobileInput, setMobileInput] = useState("");
  const [mobileNumbers, setMobileNumbers] = useState([]);
  const [existData, setExistData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [error, setError] = useState("");

  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [targetVolume, setTargetVolume] = useState(0);
  const [title, setTitle] = useState("");
  const [numberOfUsers, setNumberOfUsers] = useState([]);
  // const formikRef = useRef(null)
  let setFieldValueFunction = null;
  const [dayWiseList, setdayWiseList]=useState([
    {
      id: 0,
      name: "Sunday",
      value:false
    },
    {
      id: 1,
      name: "Monday",
      value:false
    },
    {
      id: 2,
      name: "Tuesday",
      value:false
    },
    {
      id: 3,
      name: "Wednesday",
      value:false
    },
    {
      id: 4,
      name: "Thursday",
      value:false
    },
    {
      id: 5,
      name: "Friday",
      value:false
    },
    {
      id: 6,
      name: "Saturday",
      value:false
    },
  ])
  const [dayWise, setdayWise] = useState({"Sunday":false,"Monday":false,"Tuesday":false,"Wednesday":false,"Thursday":false,"Friday":false,"Saturday":false});
  const downloadSampleXLSX = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sample Data");
    worksheet.columns = [{ header: "mobile_number", key: "mobile_number" }];
    // Add sample data
    if (isEdit) {
      numberOfUsers.forEach((mobile_number) => {
        worksheet.addRow({ mobile_number: mobile_number.toString() });
      });
    } else {
    }
    // worksheet.addRow({ mobile_number: "1234567890" });

    // Generate XLSX file
    const blob = await workbook.xlsx.writeBuffer();

    // Save and trigger the download
    const blobUrl = window.URL.createObjectURL(
      new Blob([blob], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = "data.xlsx";
    a.click();

    // Clean up resources
    window.URL.revokeObjectURL(blobUrl);
  };
  const handleSelectFieldObject = (setFieldValue) => {
    setFieldValueFunction = setFieldValue;
  };

  const handleProduct = async (event) => {
    console.log(event);
    setProduct(event);
    setPackSize([]);
    setFieldValueFunction("pack_size", []);
    setFieldValueFunction("product_id", event);
    // setTempSegment(event.id);
    const options = await campaignSer.GetPackSize({
      product_id: event.value,
    });
    if (options.status) {
      setPackSizeOption(options?.data);
    }
  };

  const handlePackSize = async (event) => {
    setFieldValueFunction("pack_size", event);
    setPackSize(event);
  };
  const handleSegment = async (event) => {
    setSeries([]);
    setFieldValueFunction("product_series", []);
    setFieldValueFunction("segment", event);
    setTempSegment(event.id);
    const options = await campaignSer.GetSegmentSeriesListFoc({
      id: event.map((obj) => obj.id),
      program_id: program_id,
    });
    if (options.status) {
      setSeriesOptions(options?.data);
    }
  };

  const handleSeries = async (event) => {
    setFieldValueFunction("product_series", event);
    setSeries(event);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setCSVFile(file);
  };
  const addMobileNumber = () => {
    if (mobileInput) {
      const numbers = mobileInput.split(",").map((number) => number.trim());
      // Validate mobile numbers
      if (numbers.length > 10) {
        setError("You can only add a maximum of 10 mobile numbers.");
      } else if (numbers.some((number) => !/^[6-9]\d{9}$/.test(number))) {
        toast.error("Please enter valid 10-digit mobile numbers.");
      } else {
        const newNumbers = [...new Set([...mobileNumbers, ...numbers])];
        if (newNumbers.length > 10) {
          toast.error("Only 10 mobile numbers are allowed");
          return;
        }
        setMobileNumbers(newNumbers);
        setError("");
      }
    }
  };

  const handleNumber = (index) => {
    const updatedMobileNumbers = [...mobileNumbers];
    updatedMobileNumbers.splice(index, 1);
    setMobileNumbers(updatedMobileNumbers);
    setInputMobile("");
  };
  const removeFile = () => {
    setCSVFile(null);
    const inputElement = document.getElementById("upload");
    if (inputElement) {
      inputElement.value = ""; // Clear the file input value
    }
  };
  
  const submitValuetoApi = async (data,status) => {
    console.log(data);
    // Parse the dates using Moment.js
    const startMoment = moment(data.start_date, "YYYY-MM-DD");
    const endMoment = moment(data.end_date, "YYYY-MM-DD");
    // if (
    //   startMoment.isBefore(
    //     moment(new Date()).add(1, "days").format("YYYY-MM-DD")
    //   ) ||
    //   endMoment.isBefore(moment(new Date()).add(1, "days").format("YYYY-MM-DD"))
    // ) {
    //   toast.error(
    //     `Please select date greater than ${moment().format("YYYY-MM-DD")}`
    //   );
    //   return;
    // }
    // Compare the dates
    if (endMoment.isBefore(startMoment)) {
      // Raise an error or handle the error as needed
      toast.error("End date is before the start date");
      return;
    }
    if (mobileNumbers.length === 0 && !csvFile) {
      toast.error("Please enter mobile number or upload a file", {
        autoClose: 2000,
      });
      return;
    }
    const tempstartdate = moment(data.start_date, "YYYY-MM-DD").unix();
    const tempenddate = moment(data.end_date, "YYYY-MM-DD").unix();
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("program_id", routeParams.programId);
    formData.append("start_date", tempstartdate);
    formData.append("end_date", tempenddate);
    formData.append("numberofPacks", data.number_of_packs);

    formData.append("product", data.product_id.value);
    formData.append("pack_size", data.pack_size.label);
    formData.append("volume", data.target_volume);
    formData.append("payout_volume", data.pack_size.label * data.number_of_packs);
    formData.append("form_type", "retailer_wise");
    formData.append("status", status);
    formData.append("bonusType", routeParams.bonusType);
    formData.append("dayWise",dayWiseButton === true ? JSON.stringify(dayWise) : '');
    let series = [];
    let segment = [];
    for (let i = 0; i < data.segment.length; i++) {
      segment.push(data.segment[i].id);
    }
    formData.append("segment", JSON.stringify(segment));
    for (let i = 0; i < data.product_series.length; i++) {
      series.push(data.product_series[i].series_id);
    }
    formData.append("series", JSON.stringify(series));
    setLoading(true);

    if (csvFile) {
        formData.append("excel", csvFile);
    } else {
      let mobiles = [];
      for (let i = 0; i < mobileNumbers.length; i++) {
        mobiles.push({ mobile_number: mobileNumbers[i] });
      }
        formData.append("mobile", JSON.stringify(mobiles));
    }
    console.log("formData--------",formData);
    for (var pair of formData.entries()) {
      console.log("data here --------------", `${pair[0]}: ${pair[1]}`);
    }
    await campaignSer
      .saveFOCCampaign(formData)
      .then((res) => {
        setLoading(false);
        if (res?.status === true) {
          setLoading(false);
          toast.success(
            !isEdit
              ? "Offer created successfully."
              : "Offer updated successfully."
          );

          if (routeParams.programId == 5) {
            history.push("/eni-vistaar-listing");
          }
        }
        if (res?.status === false && res?.code === 409) {
          setLoading(false);
          setExistData(res.data);
          setShowTable(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const updateValues = async (data) => {
    const startMoment = moment(data.start_date, "YYYY-MM-DD");
    const endMoment = moment(data.end_date, "YYYY-MM-DD");
    const tempstartdate = moment(data.start_date, "YYYY-MM-DD").unix();
    const tempenddate = moment(data.end_date, "YYYY-MM-DD").unix();
    // Compare the dates
    if (endMoment.isBefore(startMoment)) {
      // Raise an error or handle the error as needed
      toast.error("End date is before the start date");
      return;
    }

    let formData = new FormData();
    formData.append("offer_id", routeParams.id);
    formData.append("title", data.title);
    formData.append("program_id", routeParams.programId);
    formData.append("start_date", tempstartdate);
    formData.append("end_date", tempenddate);
    formData.append("numberofPacks", data.number_of_packs);

    formData.append("product", data.product_id[0].value);
    formData.append("pack_size", packSize);
    formData.append("volume", data.target_volume);
    formData.append("payout_volume", packSize * data.number_of_packs);
    formData.append("form_type", "retailer_wise");
    formData.append("status", radioValue);
    formData.append("bonusType", routeParams.bonusType);
    formData.append("dayWise",dayWiseButton === true ? JSON.stringify(dayWise) : '');
    let series = [];
    let segment = [];
    for (let i = 0; i < data.segment.length; i++) {
      segment.push(data.segment[i].id);
    }
    formData.append("segment", JSON.stringify(segment));
    for (let i = 0; i < data.product_series.length; i++) {
      series.push(data.product_series[i].value);
    }
    formData.append("series", JSON.stringify(series));
    if (csvFile) {
        formData.append("excel", csvFile);
    } else {
      let mobiles = [];
      for (let i = 0; i < mobileNumbers.length; i++) {
        mobiles.push({ mobile_number: mobileNumbers[i] });
      }
        formData.append("mobile", JSON.stringify(mobiles));
    }
    setLoading(true);
    await campaignSer.updateFOCCampaign(formData).then((response) => {
      console.log("response data ----", response);
      if (response.status) {
        setLoading(false);
        toast.success(
          !isEdit
            ? "Offer created successfully."
            : "Offer updated successfully."
        );
        if (routeParams.programId == 1) {
          history.push("/eni-club-listing");
        } else {
          history.push("/eni-vistaar-listing");
        }
      }
    
      if (response?.status === false && response?.code === 409) {
        setExistData(response.data);
        setShowTable(true);
      }
      setLoading(false);
    });
  };
  const updateStatus = async (action) => {
    let response = await campaignSer.updateCampaign(action, routeParams.id);
    if (response.status) {
      toast.success(
        action == "INACTIVE"
          ? "Campaign inactivated successfully."
          : "Campaign Published Successfully."
      );
      if (routeParams.programId == 5) {
        history.push("/eni-vistaar-listing");
      }
    } else {
      toast.error("Something went wrong.");
    }
  };

  useEffect(async () => {
    await campaignSer.GetProductList().then((response) => {
      if (response.status === true) {
        setProductOption(response.data);
      }
    });
    const segmentoptions = await campaignSer.GetSegmentList();
    if (segmentoptions?.status) {
      setSegmentOption(segmentoptions?.data);
    }
    if (isEdit) {
      let params = {
        program_id: routeParams.programId,
        offer_id: routeParams.id,
      };
      let details = await campaignSer.GetOfferDetails(params);
      let editDetails = details.data.offerDetails;
      setFieldValueFunction("segment", details.data.segmentData);
      setSegment(details.data.segmentData);

      setProduct(details.data.productData);
      setFieldValueFunction("product_id", details.data.productData);

      setTargetVolume(editDetails.target_volume);
      setFieldValueFunction("target_volume", editDetails.target_volume);

      setEditVal(editDetails);

      setPackSize(editDetails.pack_size_volume);
      setNumberOfPack(editDetails.packs);
      setFieldValueFunction("number_of_packs", editDetails.packs);
      setPayoutVolume(editDetails.payout_volume);

      setStartDate(moment(editDetails.start_date).format("YYYY-MM-DD"));
      // setTempStartDate(moment(editDetails.start_date).format("YYYY-MM-DD"));
      setFieldValueFunction(
        "start_date",
        moment(editDetails.start_date).format("YYYY-MM-DD")
      );
      setEndDate(moment(editDetails.end_date).format("YYYY-MM-DD"));
      // setTempEndDate(moment(editDetails.end_date).format("YYYY-MM-DD"));
      setFieldValueFunction(
        "end_date",
        moment(editDetails.end_date).format("YYYY-MM-DD")
      );
      setFieldValueFunction(
        "end_date",
        moment(editDetails.end_date).format("YYYY-MM-DD")
      );

      setSeries(details.data.series);
      setFieldValueFunction("product_series", details.data.series);

      setOfferStatus(editDetails.status);

      setTitle(editDetails.title);
      setFieldValueFunction("title", editDetails.title);
      if (details.data.file_path) {
      } else {
        setNumberOfUsers(details.data?.mobiles?.map((obj) => obj.value));
        setMobileNumbers(details.data?.mobiles?.map((obj) => obj.value));
        setMobile(details.data.mobiles);
        setInputMobile("");
        setFieldValueFunction("mobile", details.data.mobiles);
      }
      if(editDetails.dayWise) {
        setdayWiseButton(true);
        // setFieldValueFunction("dayWiseButton",true);
        console.log("dayWiseButton----",dayWiseButton);
        let dayWiseData = JSON.parse(editDetails.dayWise);
        console.log("dayWise---",dayWiseData);
        setdayWise(dayWiseData);
        setFieldValueFunction("dayWise",dayWiseData);
        console.log("true data,",dayWiseData['Sunday']);
        await Promise.all(dayWiseList.map(ele => {
          console.log("ele--", ele);
          console.log("data===",ele.value,typeof ele.name,dayWiseData[ele.name]);
          ele.value = dayWiseData[ele.name];
        }))
        setFieldValueFunction("dayWiseList",dayWiseList);
        setdayWiseList(dayWiseList);
        console.log("dayWise List",dayWiseList);
        console.log("dayWise---",dayWise);
      }
    }
  }, []);

  useEffect(() => {
    if (isEdit === true) {
      setPayoutVolume(parseFloat(packSize * numberOfPacks));
    } else {
      const pack = parseFloat(packSize.value);
      setPayoutVolume(parseFloat(pack * numberOfPacks));
    }});

  const handleClose = () => {
    history.goBack();
  };
  const changeDay = async  (event,index,ele) => {
    console.log("dayWise--",dayWise);
   console.log("event---",event.target.value,index,event.target.checked);
   console.log("data---",dayWise[ele.name]);
   dayWise[ele.name] = event.target.checked;
   dayWiseList[index]['value'] = event.target.checked;
   setdayWise(dayWise);
   setdayWiseList(dayWiseList);
   setFieldValueFunction("dayWiseList",dayWiseList);
  }; 

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="g-0 d-flex justify-content-around">
            <Col lg={12}>
              <Card className="m-2">
                <CardHeader className="d-flex align-items-center">
                  <button
                    className="btn btn-secondary float-left"
                    type="submit"
                    onClick={() => {
                      handleClose();
                    }}
                    color="primary"
                  >
                    Back
                  </button>
                  <CardTitle className="font-size-20 mx-3 m-0 text-uppercase">
                    Retailer Wise FOC Scheme
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Formik
                    initialValues={{
                      segment: segment,
                      product_series: series,
                      target_volume: targetVolume,
                      product_id: product,
                      pack_size: packSize,
                      start_date: startDate,
                      end_date: endDate,
                      title: title,
                      number_of_packs: numberOfPacks,
                      payout_volume: payoutVolume,
                      mobile: mobileInput,
                    }}
                    validate={(values) => {
                      const errors = {};

                      if (!values.title) {
                        errors.title = "Required";
                      }

                      if (!values.segment) {
                        errors.segment = "Required";
                      }
                      // if (!values.product_series) {
                      //   errors.product_series = "Required";
                      // }
                      if (!values.target_volume) {
                        errors.target_volume = "Required";
                      }
                      if (!values.number_of_packs) {
                        errors.number_of_packs = "Required";
                      }
                      if (!values.product) {
                        errors.product = "Required";
                      }
                      if (!values.pack_size) {
                        errors.pack_size = "Required";
                      }
                      if (!values.start_date) {
                        errors.start_date = "Required";
                      }
                      if (!values.end_date) {
                        errors.end_date = "Required";
                      }
                      return errors;
                    }}
                    onSubmit={(values) => {
                      console.log("edit here --", isEdit);
                      if (isEdit) {
                        updateValues(values);
                      } else {
                        submitValuetoApi(values);
                      }
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <React.Fragment>
                        {handleSelectFieldObject(setFieldValue)}
                        {showTable === true ? (
                          <Row>
                            <Col>
                              <div>
                                <CardTitle className="text-danger">
                                  Given users are already mapped!
                                </CardTitle>
                              </div>
                              <table
                                border="1"
                                className="table table-bordered bg-danger"
                              >
                                <thead className="bg-danger-subtle">
                                  <tr className="bg-danger-subtle">
                                    <th>Offer Id</th>
                                    <th>Mobile</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                  </tr>
                                </thead>
                                <tbody className="bg-danger-subtle">
                                  {existData.map((item, index) => (
                                    <tr key={index}>
                                      <td
                                        className="bg-danger-subtle"
                                        // onClick={() =>
                                        //   handleTdClick(item.series_offer_id)
                                        // }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item.series_offer_id}
                                      </td>
                                      <td className="bg-danger-subtle">
                                        {item.mobile}
                                      </td>
                                      <td className="bg-danger-subtle">
                                        {moment(item.start_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </td>
                                      <td className="bg-danger-subtle">
                                        {moment(item.end_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                        ) : null}
                        <Row>
                          <Col lg={12}>
                            <button
                              className="btn btn-warning float-end "
                              onClick={downloadSampleXLSX}
                            >
                              <div className="d-flex mt-1">
                                <CardTitle>Sample Excel</CardTitle>
                                <i className="ri-arrow-down-line align middle mx-1"></i>
                              </div>
                            </button>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={8} md={6} sm={12}>
                            <div className="form-row">
                              <label className="form-label">Offer Title</label>
                              <div className="input-group">
                                <Input
                                  type="search"
                                  placeholder="Type offer title"
                                  id="title"
                                  name="title"
                                  value={values.title}
                                  disabled={isEdit ? true : false}
                                  onChange={(e) => {
                                    setTitle(e.target.value);
                                    setFieldValueFunction(
                                      "title",
                                      e.target.value
                                    );
                                  }}
                                />
                              </div>
                              {errors.title && touched.title && (
                                <p className="form-error">
                                  {errors.title &&
                                    touched.title &&
                                    errors.title}
                                </p>
                              )}
                            </div>
                          </Col>
                          {isEdit ? (
                            <Col lg={4}>
                              <div className="form-row">
                                <label className="form-label" htmlFor="mobile">
                                  Total Users Mapped:
                                </label>
                                <div className="input-group d-flex">
                                  <Input
                                    disabled={true}
                                    type="search"
                                    className="w-75"
                                    value={numberOfUsers?.length}
                                    name="mobile"
                                    id="mobile"
                                  />
                                  <Button
                                    className="btn-primary"
                                    onClick={downloadSampleXLSX}
                                  >
                                    Download
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          ) : null}
                        </Row>
                        <Row className="mt-3">
                          <Col lg={8}>
                            <div className="form-row">
                              <label className="form-label" htmlFor="mobile">
                                Mobile Number:
                              </label>
                              <div className="input-group d-flex">
                                <Input
                                  type="search"
                                  className="w-75"
                                  placeholder="Type single mobile number or type comma-separated mobile numbers and submit..."
                                  value={mobileInput}
                                  disabled={numberOfUsers?.length > 10 ? true : false}
                                  name="mobile"
                                  id="mobile"
                                  onChange={(e) =>
                                    setMobileInput(e.target.value)
                                  }
                                />
                                <Button
                                  className="btn-primary"
                                  onClick={addMobileNumber}
                                >
                                  Add
                                </Button>
                              </div>
                              {numberOfUsers?.length <= 10 ? ( <div
                                className="d-flex mt-2 flex-wrap"
                                style={{
                                  maxHeight: "100px",
                                  overflowY: "auto",
                                }}
                              >
                                {mobileNumbers.map((number, index) => (
                                  <div
                                    className="badge-soft-dark  p-2"
                                    key={index}
                                  >
                                    {number}
                                    <button
                                      className="badge-soft-danger border-0 form-select-sm align-middle  mx-2"
                                      onClick={() => handleNumber(index)}
                                    >
                                      <i className="ri-close-line align-middle"></i>
                                    </button>
                                  </div>
                                ))}
                              </div>) : null}
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="form-row">
                              <Label className="form-label" htmlFor="upload">
                                Or Upload Excel File{" "}
                              </Label>
                              <div className="input-group">
                                <input
                                  id="upload"
                                  name="upload"
                                  type="file"
                                  accept=".xlsx"
                                  onChange={handleFileChange}
                                  className="form-control"
                                />
                                <div className="input-group-prepend">
                                  <div className="input-group-text ">
                                    <button
                                      className="btn btn-close"
                                      onClick={() => removeFile()}
                                    ></button>
                                  </div>
                                </div>
                              </div>

                              {errors.file && (
                                <p className="form-error">
                                  {errors.mobile && errors.file}
                                </p>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg={4} md={6} sm={12}>
                            <div className="form-row">
                              <label className="form-label">
                                Select Product Segment:
                              </label>
                              <div
                                className={`input-group select-group ${
                                  errors.segment && touched.segment
                                    ? "input-error"
                                    : ""
                                }`}
                              >
                                <Select
                                  isMulti
                                  // isDisabled={isEdit ? true : false}
                                  className="w-100"
                                  closeMenuOnSelect={true}
                                  value={values.segment}
                                  isSearchable={false}
                                  options={segmentOption}
                                  blurInputOnSelect={true}
                                  id="segment"
                                  name="segment"
                                  onChange={(event) => {
                                    handleSegment(event);
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                />
                              </div>
                              {errors.segment && touched.segment && (
                                <p className="form-error">
                                  {errors.segment &&
                                    touched.segment &&
                                    errors.segment}
                                </p>
                              )}
                            </div>
                          </Col>
                          <Col lg={4} md={6} sm={12}>
                            <div className="form-row">
                              <label className="form-label">
                                Choose Product Series:
                              </label>
                              <div
                                className={`input-group select-group ${
                                  errors.product_series &&
                                  touched.product_series
                                    ? "input-error"
                                    : ""
                                }`}
                              >
                                <Select
                                  className="w-100"
                                  // isDisabled={isEdit ? true : false}
                                  closeMenuOnSelect={false}
                                  value={values.product_series}
                                  isMulti
                                  options={seriesOption}
                                  id="product_series"
                                  name="product_series"
                                  onChange={(event) => {
                                    handleSeries(event);
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                />
                              </div>
                              {/* {errors.product_series &&
                                touched.product_series && (
                                  <p className="form-error">
                                    {errors.product_series &&
                                      touched.product_series &&
                                      errors.product_series}
                                  </p>
                                )} */}
                            </div>
                          </Col>
                          <Col lg={4} md={6} sm={12}>
                            <div className="form-row">
                              <label className="form-label">
                                Target Volume
                              </label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    Litre
                                  </span>
                                </div>
                                <Input
                                  type="search"
                                  placeholder="Target Volume (Ltr)"
                                  id="target_volume"
                                  name="target_volume"
                                  value={values.target_volume}
                                  onChange={(e) => {
                                    const input = e.target.value;

                                    // Use a regular expression to remove non-numeric characters
                                    const numericInput = input.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    setTargetVolume(numericInput);
                                    setFieldValueFunction(
                                      "target_volume",
                                      numericInput
                                    );
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                />
                              </div>
                              {errors.target_volume &&
                                touched.target_volume && (
                                  <p className="form-error">
                                    {errors.target_volume &&
                                      touched.target_volume &&
                                      errors.target_volume}
                                  </p>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                        <Col lg={4} md={6} sm={12}>
                            <div className="form-row">
                              <label className="form-label">
                                Select Product:
                              </label>
                              <div
                                className={`input-group select-group ${
                                  errors.product_id && touched.product_id
                                    ? "input-error"
                                    : ""
                                }`}
                              >
                                <Select
                                  isDisabled={isEdit ? true : false}
                                  className="w-100"
                                  closeMenuOnSelect={true}
                                  value={values.product_id}
                                  isSearchable={false}
                                  options={productOption}
                                  blurInputOnSelect={true}
                                  id="product_id"
                                  name="product_id"
                                  onChange={(event) => {
                                    handleProduct(event);
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                />
                              </div>
                              {errors.product_id && touched.product_id && (
                                <p className="form-error">
                                  {errors.product_id &&
                                    touched.product_id &&
                                    errors.product_id}
                                </p>
                              )}
                            </div>
                          </Col>
                          {isEdit ? (
                            <Col lg={4} md={6} sm={12}>
                              <div className="form-row">
                                <label className="form-label">
                                  Selected Pack Size:
                                </label>
                                <div className="input-group">
                                  <Input
                                    disabled={true}
                                    type="search"
                                    id="pack_size"
                                    name="pack_size"
                                    value={packSize}
                                  />
                                </div>
                              </div>
                            </Col>
                          ) : (
                            <Col lg={4} md={6} sm={12}>
                              <div className="form-row">
                                <label className="form-label">
                                  Select Pack Size:
                                </label>
                                <div
                                  className={`input-group select-group ${
                                    errors.pack_size && touched.pack_size
                                      ? "input-error"
                                      : ""
                                  }`}
                                >
                                  <Select
                                    isDisabled={isEdit ? true : false}
                                    className="w-100"
                                    closeMenuOnSelect={true}
                                    value={values.pack_size}
                                    isSearchable={false}
                                    options={packSizeOption}
                                    blurInputOnSelect={true}
                                    id="pack_size"
                                    name="pack_size"
                                    onChange={(event) => {
                                      handlePackSize(event);
                                    }}
                                    onBlur={(event) => {
                                      handleBlur(event);
                                    }}
                                  />
                                </div>
                                {errors.pack_size && touched.pack_size && (
                                  <p className="form-error">
                                    {errors.pack_size &&
                                      touched.pack_size &&
                                      errors.pack_size}
                                  </p>
                                )}
                              </div>
                            </Col>
                          )}
                          <Col lg={2} md={4} sm={12}>
                            <div className="form-row">
                              <label className="form-label">
                                Number of Packs
                              </label>
                              <div className="input-group">
                                <Input
                                  type="search"
                                  placeholder="Enter number of packs"
                                  id="number_of_packs"
                                  name="number_of_packs"
                                  value={values.number_of_packs}
                                  onChange={(e) => {
                                    const input = e.target.value;

                                    // Use a regular expression to remove non-numeric characters
                                    const numericInput = input.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    setNumberOfPack(numericInput);
                                    setFieldValueFunction(
                                      "number_of_packs",
                                      numericInput
                                    );
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                />
                              </div>
                              {errors.number_of_packs &&
                                touched.number_of_packs && (
                                  <p className="form-error">
                                    {errors.number_of_packs &&
                                      touched.number_of_packs &&
                                      errors.number_of_packs}
                                  </p>
                                )}
                            </div>
                          </Col>
                          <Col lg={2} md={4} sm={12}>
                            <div className="form-row">
                              <label className="form-label">
                                Payout Volume
                              </label>
                              <div className="form-control">
                                {isNaN(payoutVolume)
                                  ? "Payout Volume"
                                  : payoutVolume}
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row className="mt-3">
                          <Col lg={4} md={12} sm={12}>
                            <div className="form-row">
                              <label className="form-label">Start Date:</label>
                              <div className="input-group">
                                <Input
                                  type="date"
                                  placeholder="Start Date"
                                  id="start_date"
                                  name="start_date"
                                  value={values.start_date}
                                  min={moment()
                                    .add(1, "day")
                                    .format("YYYY-MM-DD")}
                                  onChange={(event) => {
                                    setStartDate(
                                      moment(
                                        new Date(event.target.value)
                                      ).format("YYYY-MM-DD")
                                    );
                                    setFieldValueFunction(
                                      "start_date",
                                      moment(
                                        new Date(event.target.value)
                                      ).format("YYYY-MM-DD")
                                    );
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                  invalid={
                                    errors.start_date && touched.start_date
                                  }
                                />
                              </div>
                              {errors.start_date && touched.start_date && (
                                <p className="form-error">
                                  {errors.start_date &&
                                    touched.start_date &&
                                    errors.start_date}
                                </p>
                              )}
                            </div>
                          </Col>
                          <Col lg={4} md={12} sm={12}>
                            <div className="form-row">
                              <label className="form-label">End Date:</label>
                              <div className="input-group">
                                <Input
                                  type="date"
                                  placeholder="End Date"
                                  id="end_date"
                                  name="end_date"
                                  value={values.end_date}
                                  min={values.start_date}
                                  onChange={(event) => {
                                    setEndDate(
                                      moment(
                                        new Date(event.target.value)
                                      ).format("YYYY-MM-DD")
                                    );
                                    setFieldValueFunction(
                                      "end_date",
                                      moment(
                                        new Date(event.target.value)
                                      ).format("YYYY-MM-DD")
                                    );
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                  invalid={errors.end_date && touched.end_date}
                                />
                              </div>
                              {errors.end_date && touched.end_date && (
                                <p className="form-error">
                                  {errors.end_date &&
                                    touched.end_date &&
                                    errors.end_date}
                                </p>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <Button
                              className=""
                              onClick={() =>
                                setdayWiseButton(!dayWiseButton)
                              }
                            >
                              Day Wise
                              <span>
                                {" "}
                                <i
                                  className={`${
                                    dayWiseButton === false
                                      ? " ri-checkbox-blank-line"
                                      : " ri-checkbox-line"
                                  } mx-2 font-size-14`}
                                ></i>
                              </span>
                            </Button>
                            {dayWiseButton === true ?( <ul>
        {dayWiseList.map((ele, index) => {
          return (
            <th key={index}>
              <div>
                <div>
                  <input
                    type="checkbox"
                    id={`custom-checkbox-${index}`}
                    name={ele.name}
                    value={ele.name}
                    checked={ele.value}
                    onChange={(e) => {
                     changeDay(e,index,ele)
                    }}
                  /> {ele.name}
                </div>
              </div>
            </th>
          );
        })}
      </ul>) : null}
                          </Col>
                        </Row>
                        <Row>
                        {user.user_id !== 1685633729789 && (<Col lg={12}> {
                          (isEdit && isEdit === true && offerStatus === "UNPUBLISH") ? <CardTitle className="text-danger float-end">
                          Offer is unpublish!
                        </CardTitle> : (
                              <div className="d-flex align-items-end justify-content-end">
                                {loading === true ? (
                                  <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                                ) : isEdit && isEdit === true ? (
                                  <>
                                    <Button
                                      className="btn-warning mt-3 me-1"
                                      disabled={loading}
                                      onClick={() => {
                                        // setRadioValue("PUBLISH");
                                        updateValues(values);
                                      }}
                                    >
                                      Update
                                    </Button>
                                    {isEdit && (
                                      <Button
                                        className="btn-warning mt-3"
                                        disabled={loading}
                                        onClick={() => {
                                          updateStatus(
                                            (offerStatus === "DRAFT")
                                              ? "PUBLISH"
                                              : (offerStatus === "PUBLISH" ? "UNPUBLISH" : "Save as Draft")
                                          );
                                        }}
                                      >
                                        {(offerStatus === "DRAFT")
                                              ? "PUBLISH"
                                              : (offerStatus === "PUBLISH" ? "UNPUBLISH" : "Save as Draft")}
                                      </Button>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      className="btn-warning mt-3 me-1"
                                      disabled={loading}
                                      onClick={() => {
                                        setRadioValue("DRAFT");
                                        console.log("radioValue", radioValue);
                                        submitValuetoApi(values,"DRAFT");
                                      }}
                                    >
                                      Save as draft
                                    </Button>
                                    <Button
                                      className="btn-warning mt-3 me-1"
                                      disabled={loading}
                                      type="submit"
                                      onClick={() => {
                                        console.log("test----");
                                        setRadioValue("PUBLISH");
                                        console.log("radioValue", radioValue);
                                        submitValuetoApi(values,"PUBLISH");
                                      }}
                                    >
                                      Publish
                                    </Button>
                                  </>
                                )}
                              </div>
                          )
                        }
                            </Col>)}
                        </Row>
                      </React.Fragment>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FocRetailer;
