import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  Input,
  CardTitle,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import redemptionService from "../../services/redemption.service";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import { showLoader, hideLoader } from "../../actions/loading";
import { useDispatch, useSelector } from "react-redux";
import Spinner, { SpinnerSmall } from "../../components/Loader/Spinner";
import moment from "moment";
import { DateRange } from "react-date-range";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import RedemptionTable from "../../components/Table/RedemptionTable";
import { toggleRightSidebar } from "../../store/actions";
import "./Redemption";
import Master from "../../components/Master/Master";
import ReportModal from "../../components/Modal/ReportModal";

const ViewRedemption = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const master = useSelector((state) => state.master.setList.body);
  const user_id = user ? user.user_id : null;
  const role = user ? user.role_id : null;

  const loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isOpenfirstMonth, setIsOpenFirstmonth] = useState(false);
  const firstDate = new Date("2016-06-01");
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1); // current date
  // const initialStartDate = moment(currentDate).subtract(29, "days").toDate();
  const initialStartDate = moment(firstDate).toDate(); // subtract 30 days from current date
  const initialDateRange = [
    {
      startDate: new Date(moment(initialStartDate)),
      endDate: new Date(moment(currentDate)),
      key: "selection",
    },
  ];
  const [RewardStatusList, setRewardStatusList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRewardStatus, setSelectedRewardStatus] = useState(null);
  const [selectedRewardType, setSelectedRewardType] = useState("");
  const [selectedUserType, setSelectedUserType] = useState("mlp");
  const [selectedSource, setSelectedSource] = useState(null);
  const [nameMobile, setNameMobile] = useState("");
  const [dateRange, setDateRange] = useState(initialDateRange);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;

  const [modal, setModal] = useState(false);
  const [disableDownload, setDisableDownload] = useState(false);
  const [reportName, setReportName] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [countLoading, setCountLoading] = useState(false);

  const [rewardType, setrewardOptions] = useState([
    { value: "", label: "ALL" },
    { value: "DELIVER", label: "PHYSICAL REWARD" },
    { value: "VOUCHER", label: "VOUCHER" },
  ]);
  const [userType, setuserOptions] = useState([
    { value: "mlp", label: "Mechanic" },
    { value: "rlp", label: "Retailer" },
  ]);

  const source = [
    { value: "CALLER", label: "CALLER" },
    { value: "USER", label: "USER" },
  ];

  let body = {
    start_date: moment(dateRange[0].startDate).format("YYYY-MM-DD"),
    end_date: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
    offset: currentPage,
    limit: pageSize,
    user_id: user_id,
    regional_mapping: master?.regional_mapping,
    reward_status: selectedRewardStatus?.value,
    source: selectedSource?.value,
    name_mobile: nameMobile,
    role_id: role,
  };
  if (selectedRewardType.length > 0) {
    body.reward_type = selectedRewardType;
  }
  if (selectedUserType.length > 0) {
    body.user_type = selectedUserType;
  }

  const handleExportRedemptionList = async () => {
    setModal(false);
    setDisableDownload(true);

    body.count_refresh = false;
    body.report_name = reportName;
    setReportName("");
    const redemptionList = await redemptionService.exportRedemptionList(body);
    if (redemptionList.data.code === 200) {
      setDisableDownload(false);
      toast.success(redemptionList.data.message);
    } else {
      setDisableDownload(false);
      toast.error(redemptionList.data.message);
    }
  };

  const checkDaysFromDateRange = async (start_date, end_date) => {
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);

    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

    const diffDays = Math.round(Math.abs((endDate - startDate) / oneDay)) + 1;

    if (diffDays > 92) {
      toast.error("Please choose a date range of 92 days or less.", {
        autoClose: 2000,
      });
      return false;
    }
    return true;
  };

  const toggleModal = async () => {
    // let flag = false;
    // if (flag === false) {
    //   flag = await checkDaysFromDateRange(body.start_date, body.end_date);
    // }
    // if (flag === true) {
      setModal(!modal);
      setReportName("");
    // }
  };

  const handledateRange = (item) => {
    setDateRange([item.selection]);
    body.start_date = moment(item.selection.startDate).format("YYYY-MM-DD");
    body.end_date = moment(item.selection.endDate).format("YYYY-MM-DD");
  };

  const submitDates = async () => {
    handleDateRangeClose();
    dispatch(showLoader());
    setIsOpenFirstmonth(false);
    // body.user_id = role === 22 ? user_id : selectedRewardStatus?.value;
    if (RewardStatusList?.lenght !== 0) {
      const redemption_data = await redemptionService.GetRedemptionList(body);
      if (redemption_data.status === true) {
        setTotalPages(redemption_data.data.pagination.total_pages);
        setData(redemption_data.data.data);
        setTotalCount(redemption_data.data.pagination.count);
        dispatch(hideLoader());
      } else if (redemption_data.status === false) {
        setData([]);
        dispatch(hideLoader());
      }
    } else {
      dispatch(hideLoader());
    }
  };

  const Reset = () => {
    body.start_date = moment(initialDateRange[0].startDate).format(
      "YYYY-MM-DD"
    );
    body.end_date = moment(initialDateRange[0].endDate).format("YYYY-MM-DD");
    body.reward_status = null;
    // body.reward_type = null;
    body.name_mobile = null;
    body.regional_mapping = null;
    body.user_type = null;
    body.reward_type = null;
    setNameMobile("");
    setSelectedRewardStatus(null);
    setSelectedRewardType("");
    setSelectedUserType("mlp");
    setSelectedSource(null);

    setDateRange(initialDateRange);
    setCurrentPage(1);
    submitDates();
  };

  const handlePage = (event) => {
    const buttonElement = event.target; // Your event target
    if (buttonElement.tagName === "BUTTON") {
      const pageNumber = buttonElement.textContent;
      console.log(pageNumber); // Output: "2"
      setCurrentPage(pageNumber);
    }
  };
  const handleFirstMonthButtonState = () => {
    setIsOpenFirstmonth(!isOpenfirstMonth);
  };

  const handleDateRangeClose = () => {
    setIsOpenFirstmonth(false);
    // perform some action here
  };

  const fetchRewardStatus = async () => {
    const list = await redemptionService.GetRewardStatusList();
    if (list.status === true) {
      setRewardStatusList(list.data.data);
    }
  };

  const handleRewardStatus = async (e) => {
    setSelectedRewardStatus(e);
  };
  function toggleRightbar() {
    props.toggleRightSidebar();
  }
  useEffect(() => {
    fetchRewardStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    submitDates();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <React.Fragment>
      <div className="page-content mt-0">
        <div className="container-fluid">
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader>
                  <div className="row table_top">
                    <Col lg={4} md={4} sm={5} xs={6}>
                      <CardTitle className="font-size-18">
                        Redemption List
                      </CardTitle>
                    </Col>
                    <Col
                      lg={7}
                      md={7}
                      sm={6}
                      xs={5}
                      className="total-count-class"
                    >
                      <div className="d-flex justify-content-end">
                        <span style={{ float: "left", paddingRight: "5px" }}>
                          Total count:
                        </span>
                        {totalCount === null ? (
                          <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                        ) : (
                          totalCount
                        )}
                      </div>
                    </Col>
                    {role === 22 && role === 16 ? null : (
                      <Col lg={1} md={1} sm={1}>
                        {" "}
                        <div
                          className="download-excel"
                          style={{ opacity: disableDownload ? 0.5 : 1 }}
                        >
                          <i
                            title={
                              disableDownload
                                ? "Preparing your file"
                                : "Export to Excel"
                            }
                            className="fa fa-download"
                            onClick={disableDownload ? null : toggleModal}
                            disabled={disableDownload}
                          />
                          <ReportModal
                            isOpen={modal}
                            toggle={toggleModal}
                            modalHeader="Redemption List Report"
                            handleAPI={handleExportRedemptionList}
                            reportName={reportName}
                            setReportName={setReportName}
                          />
                        </div>
                      </Col>
                    )}
                  </div>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="mt-1">
                          <h6>Select Reward Type:</h6>
                          <FormGroup className="mb-0">
                            <select
                              className="form-select"
                              value={selectedRewardType}
                              onChange={(e) =>
                                setSelectedRewardType(e.target.value)
                              }
                            >
                              {rewardType.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="">
                          <label className="form-label">Select Source: </label>
                          <Select
                            //   isMulti={true}
                            options={source}
                            value={selectedSource}
                            onChange={setSelectedSource}
                            placeholder="Select options"
                            noOptionsMessage={() => "No options found"}
                            isSearchable
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="">
                          <label className="form-label">
                            Select Reward Status:
                          </label>
                          <Select
                            //   isMulti={true}
                            options={RewardStatusList}
                            value={selectedRewardStatus}
                            onChange={(e) => handleRewardStatus(e)}
                            placeholder="Select options"
                            noOptionsMessage={() => "No options found"}
                            isSearchable
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="mt-1">
                          <h6>Search</h6>
                          <Input
                            type="search"
                            placeholder="Phone no. / Name"
                            id="search_name_mobile"
                            value={nameMobile}
                            onChange={(e) => {
                              setNameMobile(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        {isOpenfirstMonth === false ? (
                          <div>
                            <label className="form-label">Select Dates: </label>
                            <button
                              className="form-select"
                              onClick={handleFirstMonthButtonState}
                            >
                              {`${new Date(body.start_date).toLocaleString(
                                "default",
                                {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                }
                              )} - ${new Date(body.end_date).toLocaleString(
                                "default",
                                {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                }
                              )}`}
                            </button>
                          </div>
                        ) : (
                          <div className="customDateOverlay">
                            <label className="form-label">Select Dates: </label>
                            <div className="">
                              <DateRange
                                editableDateInputs={true}
                                // onChange={(item) => handledateRange(item.selection.startDate,item.selection.endDate)}
                                onChange={handledateRange}
                                moveRangeOnFirstSelection={false}
                                ranges={dateRange}
                                rangeColors={["#FCB92C", "#FCB92C", "#FCB92C"]}
                                shouldCloseOnSelect={true}
                                maxDate={currentDate}
                                minDate={new Date(moment("2016-06-01"))}
                                onClose={handleDateRangeClose}
                                className="dateRangeWrapper"
                              />

                              <Button
                                className="btn-danger float-end mt-1"
                                onClick={handleFirstMonthButtonState}
                              >
                                Close
                              </Button>
                              <Button
                                className="btn-warning float-end mt-1 margin-right"
                                onClick={() => submitDates()}
                              >
                                Search
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="mt-1">
                          <h6>Select User Type:</h6>
                          <FormGroup className="mb-0">
                            <select
                              className="form-select"
                              value={selectedUserType}
                              onChange={(e) =>
                                setSelectedUserType(e.target.value)
                              }
                            >
                              {userType.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="col-lg-1 col-md-6 col-sm-12 w-auto">
                        {user.role_id !== 22 ? (
                          <div className="margin-top d-flex">
                            <button
                              color="none"
                              onClick={toggleRightbar}
                              // type="button"
                              className="advance-filter-btn"
                            >
                              Team Filter
                              <i className="ri-filter-2-fill mx-1"></i>
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="mt-1">
                          <Button
                            className="btn-warning  margin-right"
                            onClick={() => submitDates()}
                          >
                            Search
                          </Button>
                          <Button
                            className="btn btn-danger btn-secondary"
                            onClick={() => Reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <Master />{" "}
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="container-fluid">
                    <div className="row">
                      {loading === true ? (
                        <Spinner />
                      ) : (
                        <>
                          <h4 className="card-title pt-4">
                            {`Redemption List ${moment(body.start_date).format(
                              "MMM DD, YYYY"
                            )} - ${moment(body.end_date).format(
                              "MMM DD, YYYY"
                            )}`}
                          </h4>
                          <RedemptionTable data={data} loading={loading} />
                          {/* <div className="d-flex justify-content-center ">
                            <Pagination
                              page={currentPage}
                              setPage={setCurrentPage}
                              totalPages={totalPages}
                            />
                          </div> */}
                          {totalCount === null ? (
                            <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                          ) : (
                            <>
                              <div className="d-flex justify-content-center ">
                                <Stack spacing={2}>
                                  <Pagination
                                    count={totalPages}
                                    hidePrevButton
                                    hideNextButton
                                    onClick={handlePage}
                                  />
                                </Stack>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStateToProps, { toggleRightSidebar })(
  withNamespaces()(ViewRedemption)
);
