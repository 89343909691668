import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { CardTitle } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";

import "bootstrap/dist/css/bootstrap.css";
import moment from "moment";
import getSegment from "../../services/segment.service";
import "./category.css";

import { useSelector } from "react-redux";

import DateRangePicker from "react-daterange-picker";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-datepicker/dist/react-datepicker.css";
import Master from "../../components/Master/Master";
import { toggleRightSidebar } from "../../store/actions";
import OverAll from "../../components/ProductSegment/OverAll";
import SegmentComparison from "../../components/ProductSegment/SegmentComparison";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProductSegment = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const master = useSelector((state) => state.master.setList.body);

  const [label, setLabel] = useState("Month");
  const [segment, setSegment] = useState("MCO");
  const [segmentOption, setSegmentOption] = useState([
    { value: "Select", Label: "Select" },
  ]);
  // const currentDate = new Date();

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1); // current date
  const initialStartDate = moment(currentDate).subtract(29, "days").toDate(); // subtract 30 days from current date

  const initialDateRange = [
    {
      startDate: new Date(moment(initialStartDate)),
      endDate: new Date(moment(currentDate)),
      key: "selection",
    },
  ];

  const [showOverlay, setShowOverlay] = useState(false);

  const [value, setValue] = useState(
    moment.range(
      moment().clone().subtract(1, "months").startOf("month"),
      moment().clone().subtract(1, "months").endOf("month")
    )
  );

  let body = {
    // start_date: moment(dateRange[0].startDate).format("YYYY-MM-DD"),
    // end_date: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
    start_date: moment(value.start._d).format("YYYY-MM-DD"),
    end_date: moment(value.end._d).format("YYYY-MM-DD"),
    segment: segment,
    regional_mapping: master ? master.regional_mapping : null,
    user_id: user.user_id,
    role_id: user.role_id,
  };

  const Reset = () => {
    body.start_date = moment(initialDateRange[0].startDate).format(
      "YYYY-MM-DD"
    );
    body.end_date = moment(initialDateRange[0].endDate).format("YYYY-MM-DD");
    body.regional_mapping = null;
  };

  const GetSegmentList = async () => {
    const options = await getSegment.GetSegmentList();
    const updatedData = options?.data?.filter((item) => item.value !== "ALL");
    setSegmentOption(updatedData);
  };

  const handleSegment = (event) => {
    setSegment(event.target.value);
  };

  function handleClick() {
    setShowOverlay(true);
  }

  const handleLastSixMonthsClick = async () => {
    const today = moment().subtract(1, "months").endOf("month");
    const sixMonthsAgo = moment().subtract(6, "months").startOf("month");
    setValue(moment.range(sixMonthsAgo, today));
    body.start_date = moment(sixMonthsAgo).format("YYYY-MM-DD");
    body.end_date = moment(today).format("YYYY-MM-DD");
    setLabel("6 Months");
    setShowOverlay(false);
  };

  const handleLastMonthClick = async () => {
    const today = moment().subtract(1, "months").endOf("month");
    const lastMonth = moment().subtract(1, "month").startOf("month");
    setValue(moment.range(lastMonth, today));
    body.start_date = moment(lastMonth).format("YYYY-MM-DD");
    body.end_date = moment(today).format("YYYY-MM-DD");
    setLabel("Month");
    setShowOverlay(false);
  };

  const handleLastQuarterClick = async () => {
    const today = moment().subtract(1, "months").endOf("month");
    const lastQuarter = moment().subtract(3, "months").startOf("month");
    setValue(moment.range(lastQuarter, today));
    body.start_date = moment(lastQuarter).format("YYYY-MM-DD");
    body.end_date = moment(today).format("YYYY-MM-DD");
    setLabel("Quarter");
    setShowOverlay(false);
  };

  const handleLastYearClick = async () => {
    const today = moment().subtract(1, "months").endOf("month");
    const lastYear = moment().subtract(1, "year").startOf("month");
    setValue(moment.range(lastYear, today));
    body.start_date = moment(lastYear).format("YYYY-MM-DD");
    body.end_date = moment(today).format("YYYY-MM-DD");
    setLabel("Year");
    setShowOverlay(false);
  };

  const onSelect = async (value, states) => {
    setValue(value);
    body.start_date = moment(value.start._d).format("YYYY-MM-DD");
    body.end_date = moment(value.end._d).format("YYYY-MM-DD");
    // setLabel(`${body.start_date} - ${body.end_date}`);
    setLabel("Custom Date");
  };

  useEffect(() => {
    GetSegmentList();
  }, [master]);

  function toggleRightbar() {
    props.toggleRightSidebar();
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <CardTitle className="font-size-18 mb-3">Product Segment</CardTitle>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <label className="form-label">Select Dates: </label>
                <div className="date-container d-flex ">
                  <button
                    className="form-control text-lg-start mb-1"
                    onClick={handleClick}
                  >
                    {label}
                  </button>
                </div>
                {showOverlay && (
                  <div className="overlay">
                    <div className="content">
                      <div className="d-flex">
                        <DateRangePicker
                          value={value}
                          onSelect={onSelect}
                          singleDateRange={true}
                          maximumDate={new Date()}
                          minimumDate={new Date(moment("2021-01-01"))}
                          rangeColors={["#FCB92C", "#FCB92C", "#FCB92C"]}
                        />
                        <div className="d-flex flex-column mx-1">
                          <button
                            className="date-btn mt-1"
                            onClick={() => handleLastMonthClick()}
                          >
                            Month
                          </button>
                          <button
                            className="date-btn mt-1"
                            onClick={() => handleLastQuarterClick()}
                          >
                            Quarter
                          </button>
                          <button
                            className="date-btn mt-1"
                            onClick={() => handleLastSixMonthsClick()}
                          >
                            6 Months
                          </button>
                          <button
                            className="date-btn mt-1"
                            onClick={() => handleLastYearClick()}
                          >
                            Year
                          </button>
                          <button
                            className="date-btn-close mt-auto "
                            onClick={() => setShowOverlay(false)}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-lg-1 col-md-6 col-sm-12 w-auto">
                {user.role_id !== 22 ? (
                  <div className="margin-top d-flex">
                    <button
                      color="none"
                      onClick={toggleRightbar}
                      // type="button"
                      className="advance-filter-btn"
                    >
                      Team Filter
                      <i className="ri-filter-2-fill mx-1"></i>
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div>
            <Master />{" "}
          </div>

          <OverAll
            start_date={body.start_date}
            end_date={body.end_date}
            regional_mapping={body.regional_mapping}
            user_id={body.user_id}
            role_id={body.role_id}
            label={label}
          />
          <div className="row mb-3">
            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="">
                <label className="form-label">Select Segment: </label>
                <select
                  className="form-select w-100"
                  aria-label="Default select example"
                  value={segment}
                  onChange={handleSegment}
                >
                  {segmentOption ? (
                    segmentOption.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))
                  ) : (
                    <option></option>
                  )}
                </select>
              </div>
            </div>
          </div>
          <SegmentComparison
            start_date={body.start_date}
            end_date={body.end_date}
            regional_mapping={body.regional_mapping}
            user_id={body.user_id}
            role_id={body.role_id}
            segment={body.segment}
            label={label}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStateToProps, { toggleRightSidebar })(
  withNamespaces()(ProductSegment)
);
