import React, { useState } from "react";
// import * as Icon from "react-feather";

const Pagination = ({ totalPages, page, setPage }) => {
  const offset = 10;

  const negativeOffset = page - offset / 2;
  const positiveOffset = page + offset / 2;
  console.log(
    "negativeOffset",
    negativeOffset,
    "positiveOffset",
    positiveOffset
  );

  const [currentPageRange, setCurrentPageRange] = useState([
    negativeOffset < 1 ? 1 : negativeOffset,
    page,
    positiveOffset > totalPages ? totalPages : positiveOffset,
  ]);

  const [pageNumbers, setPageNumbers] = useState(
    // Array.from(Array(totalPages), (_, i) => i + 1).slice(
    //   currentPageRange[0] - 1,
    //   currentPageRange[2]
    // )
    Array.from(
      Array(currentPageRange[2] - currentPageRange[0] + 1),
      (_, i) => currentPageRange[0] + i
    )
  );
  console.log(
    "totalPages",
    totalPages,
    "page",
    page,
    "pageNumbers",
    pageNumbers,
    "currentPageRange",
    currentPageRange
  );

  const handlePageClick = (pageNumber) => {
    console.log("pageNumber", pageNumber);
    page = pageNumber;
    const negativeOffset = pageNumber - offset / 2;
    const positiveOffset = pageNumber + offset / 2;
    const newCurrentPageRange = [
      negativeOffset < 1 ? 1 : negativeOffset,
      pageNumber,
      positiveOffset > totalPages ? totalPages : positiveOffset,
    ];

    setCurrentPageRange(newCurrentPageRange);
    setPageNumbers(
      Array.from(Array(totalPages), (_, i) => i + 1).slice(
        newCurrentPageRange[0] - 1,
        newCurrentPageRange[2]
      )
    );
    setPage(pageNumber);
    console.log(newCurrentPageRange, "pageNumbers", pageNumbers);
  };

  const handleNextClick = () => {
    if (page < totalPages) {
      const nextPage = page + 1;
      const negativeOffset = nextPage - offset / 2;
      const positiveOffset = nextPage + offset / 2;
      const newCurrentPageRange = [
        negativeOffset < 1 ? 1 : negativeOffset,
        nextPage,
        positiveOffset > totalPages ? totalPages : positiveOffset,
      ];
      setPage(nextPage);
      setCurrentPageRange(newCurrentPageRange);
      setPageNumbers(
        Array.from(Array(totalPages), (_, i) => i + 1).slice(
          newCurrentPageRange[0] - 1,
          newCurrentPageRange[2]
        )
      );
    }
  };

  const handlePrevClick = () => {
    if (page > 1) {
      const prevPage = page - 1;
      const negativeOffset = prevPage - offset / 2;
      const positiveOffset = prevPage + offset / 2;
      const newCurrentPageRange = [
        negativeOffset < 1 ? 1 : negativeOffset,
        prevPage,
        positiveOffset > totalPages ? totalPages : positiveOffset,
      ];
      setPage(prevPage);
      setCurrentPageRange(newCurrentPageRange);
      setPageNumbers(
        Array.from(Array(totalPages), (_, i) => i + 1).slice(
          newCurrentPageRange[0] - 1,
          newCurrentPageRange[2]
        )
      );
    }
  };

  const handleFirstClick = () => {
    const newCurrentPageRange = [1, page, offset];
    setPage(1);
    setCurrentPageRange(newCurrentPageRange);
    setPageNumbers(
      Array.from(Array(totalPages), (_, i) => i + 1).slice(
        newCurrentPageRange[0] - 1,
        newCurrentPageRange[2]
      )
    );
  };

  const handleLastClick = () => {
    const newCurrentPageRange = [totalPages - offset + 1, page, totalPages];
    setPage(totalPages);
    setCurrentPageRange(newCurrentPageRange);
    setPageNumbers(
      Array.from(Array(totalPages), (_, i) => i + 1).slice(
        newCurrentPageRange[0] - 1,
        newCurrentPageRange[2]
      )
    );
  };

  return (
    <div className="pagination">
      <button
        className="btn btn-warning mx-1"
        onClick={handleFirstClick}
        disabled={page === 1}
      >
        First
      </button>
      <button
        className="btn btn-warning mx-1"
        onClick={handlePrevClick}
        disabled={page === 1}
      >
        Prev
      </button>
      {pageNumbers.map((pageNumber) => (
        <button
          key={pageNumber}
          onClick={() => handlePageClick(pageNumber)}
          className={
            pageNumber === page
              ? "btn btn-warning active mx-1"
              : "btn btn-warning mx-1"
          }
        >
          {pageNumber}
        </button>
      ))}
      <button
        className="btn btn-warning mx-1"
        onClick={handleNextClick}
        disabled={page === totalPages}
      >
        Next
      </button>
      <button
        className="btn btn-warning mx-1"
        onClick={handleLastClick}
        disabled={page === totalPages}
      >
        Last
      </button>
      <div className="form-control">
        {page} of {totalPages}
      </div>
    </div>
  );
};

export default Pagination;
