import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Table,
  CardBody,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Input,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";
import campaignSer from "../../../services/campaignService.js";
import { useEffect } from "react";
import Pagination from "../../../components/Table/Pagination";
import { FormGroup } from "reactstrap";
import Spinner from "../../../components/Loader/Spinner";
import { DateRange } from "react-date-range";
import moment from "moment";
import getSegment from "../../../services/segment.service.js";
const EniClubListing = (props) => {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user"));

  const handleSelectionRoute = (val) => {
    history.push(val);
  };

  const handleFormRoutes = (item) => {
    console.log(item);
    let route = "";
    if (item.form_type === "mechanic_wise") {
      route = "/mechanic-wise-scheme/1/" + item.bonus_type + "/" + item.id;
    } else if (item.form_type === "pincode_wise") {
      route =
        "/geography-wise-pincode-scheme/1/" + item.bonus_type + "/" + item.id;
    } else if (
      item.form_type === "state_wise" ||
      item.form_type === "district_wise" ||
      item.form_type === "area_wise"
    ) {
      route =
        "/geography-wise-state-scheme/1/" + item.bonus_type + "/" + item.id;
    } else if (item.form_type === "sku_wise") {
      route = "/vehicle-wise-scheme/1/" + item.bonus_type + "/" + item.id;
    } else if (item.form_type === "foc_scheme") {
      route = "/foc-scheme/1/" + item.bonus_type + "/" + item.id;
    }
    handleSelectionRoute(route);
  };

  const [loading, setLoading] = useState(true);
  const [offerList, setOfferList] = useState([]);
  const [segmentOption, setSegmentOption] = useState([
    { value: "Select", label: "Select" },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  let [appStatus, setAppStatus] = useState(2);
  let [dateStatus, setDateStatus] = useState(1);
  const [nameMobile, setNameMobile] = useState("");
  const [isOpenfirstMonth, setIsOpenFirstmonth] = useState(false);
  const currentDate = new Date(); // current date
  const initialStartDate = moment(currentDate).subtract(29, "days").toDate(); // subtract 30 days from current date
  const initialDateRange = [
    {
      startDate: new Date(moment(initialStartDate)),
      endDate: new Date(moment(currentDate)),
      key: "selection",
    },
  ];
  const [series, setSeries] = useState([]);
  const [segment, setSegment] = useState("ALL");
  const [dateRange, setDateRange] = useState(initialDateRange);
  const [state, setState] = useState("");
  const [stateId, setStateId] = useState([]);
  let [district, setDistrict] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([ { value: "Select", label: "Select" },]);
  let [districtId, setDistrictId] = useState([]);
  const handlePagination = (item) => {
    setCurrentPage(item);
    getListing(item);
  };

  const GetSegmentList = async () => {
    const options = await getSegment.GetSegmentList();
    // options.unshift({ value: "Select", label: "Select" });
    if (options) {
      setSegmentOption(options?.data);
    }
    console.log("segmentOption-----", segmentOption);
  };
  const fetchSeries = async () => {
    let params = {
      id: [],
      program_id: 1,
    };
    if (stateId > 0) {
      params = {
        id: [stateId],
        program_id: 1,
      };
    }
    console.log("params--------", params);
    const districtData = await campaignSer.GetSegmentSeriesList(params);
    if (districtData.status === true) {
      console.log("districtData-----", districtData);
      const options = districtData.data.map((district) => ({
        // series_id: district.series_id,
        id: district.series_id,
        value: district.label,
        label: district.label,
      }));
      setDistrictOptions(options);
      console.log("fget dstrict----", districtOptions);
    } else if (districtData.status === false) {
      toast.error("No Series found.", {
        autoClose: 2000,
      });
    }
  };
  const handleStateChanges = async (e) => {
    const selectedState = segmentOption.find(
      (option) => option.value === e.target.value
    );
    console.log("selectedState---",selectedState);
    if (selectedState) {
      setState(selectedState.value);
      setStateId(selectedState.id);
      setDistrict("");
      setDistrictId("");
      setDistrictOptions([]);
    } else {
      setState(""); // set state to empty string if "Select State" is selected
      setStateId("");
      setDistrict("");
      setDistrictId("");
      setDistrictOptions([]);
    }
  };

  const handleSeries = (event) => {
    const selectedSeries = districtOptions.find(
      (option) => option.value === event.target.value
    );
    console.log("selectedSeries---",selectedSeries);
    setSeries(selectedSeries.value);
    setDistrict(selectedSeries.value);
    setDistrictId(selectedSeries.id);
    // setFieldValueFunction("series", selectedSeries.value);
    console.log("series--",series);
  };


  const [dateFilters, setDateFilters] = useState({
    start_date: "",
    end_date: "",
  });

  let body = {
    program_id: 1,
  };

  if (dateFilters.start_date.length > 0 && dateFilters.end_date.length > 0) {
    body.start_date = dateFilters.start_date;
    body.end_date = dateFilters.end_date;
  }
  const getListing = async (pageNumber, status) => {
    setLoading(true);
    setIsOpenFirstmonth(false);
    let offset = 0;
    if (pageNumber > 1) {
      offset = pageNumber * 10 - 10;
    }
    body.offset = offset;
    body.status = status;
    body.program_id = 1;
    console.log("appStatus-----", appStatus);
    if (appStatus) {
      body.status = appStatus;
    }
    // if (dateStatus) {
    //   body.dateStatus = dateStatus;
    // }
    if (dateFilters.start_date.length > 0 && dateFilters.end_date.length > 0) {
      body.start_date = dateFilters.start_date;
      body.end_date = dateFilters.end_date;
      if (dateStatus) {
        body.dateStatus = dateStatus;
      }
    }
    if (nameMobile.length > 0) {
      body.name = nameMobile;
    }
    if (stateId > 0) {
      body.segment_id = stateId;
    }
    if (districtId > 0) {
      body.series_id = districtId;
    }
    console.log("filter --------", body);
    const options = await campaignSer.GetOffersList(body);
    setLoading(false);
    setOfferList(options.data);
    setTotalPages(options.total);
  };

  const handleDistrictChanges = async (e) => {
    console.log("e.target.value---", e.target.value);
    const selectedDistrict = districtOptions.find(
      (option) => option.value == e.target.value
    );
    console.log("selectedDistrict---", selectedDistrict);
    if (selectedDistrict) {
      setDistrict(selectedDistrict.value);
      setDistrictId(selectedDistrict.id);
      // district = selectedDistrict.value;
      // districtId = selectedDistrict.value;
      console.log("district data -----", district, districtId);
    } else {
      setDistrict("");
      setDistrictId("");
    }
  };
  const handledateRange = (item) => {
    setDateRange([item.selection]);
    setDateFilters((prevFilters) => ({
      start_date: moment(item.selection.startDate).format("YYYY-MM-DD"),
      end_date: moment(item.selection.endDate).format("YYYY-MM-DD"),
    }));
  };
  const handleChangeStatus = async (e) => {
    console.log("value -----", e);
    setAppStatus(e.target.value);
    appStatus = e.target.value;
    setCurrentPage(1);
    getListing(1, e.target.value);
  };
  const handleDateChangeStatus = async (e) => {
    console.log("value -----", e.target.value);
    setDateStatus(e.target.value);
    dateStatus = e.target.value;
  };
  const handleFirstMonthButtonState = () => {
    setIsOpenFirstmonth(!isOpenfirstMonth);
  };
  const handleDateRangeClose = () => {
    setIsOpenFirstmonth(false);
  };
  let setFieldValueFunction = null;

  const Reset = () => {
    setDateRange(initialDateRange);
    setCurrentPage(0);
    setNameMobile("");
    setAppStatus("");
    setDateStatus("");
    setDateFilters({
      start_date: "",
      end_date: "",
    });
    body = {
      offset: 0,
    };
    district = "";
    districtId = "";
    setDistrict("");
    setDistrictId("");
    setDistrict("");
    setDistrictOptions([]);
    setState("");
    setStateId("");
    getListing(currentPage);
  };
  useEffect(() => {
    getListing(currentPage);
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col>
              <Card>
                <CardHeader className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <button
                      className="btn btn-secondary float-left mx-1"
                      type="submit"
                      onClick={() => {
                        handleSelectionRoute("/mechanic-scheme");
                      }}
                      color="primary"
                    >
                      Back
                    </button>
                    <CardTitle className="font-size-20 text-uppercase mx-3 m-0">
                      ENI Club View
                    </CardTitle>
                  </div>
                  {user.user_id !== 1685633729789 && (<div>
                    <button
                      className="btn btn-primary float-left mx-1"
                      type="submit"
                      onClick={() => {
                        handleSelectionRoute("/eni-club-scheme");
                      }}
                      color="primary"
                    >
                      Add New
                    </button>
                  </div>)}
                </CardHeader>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card style={{ minHeight: "500px" }}>
                <CardBody className="">
                  <div>
                    <Row className="mt-3">
                      <Col md={3}>
                        <h6>Status</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={appStatus}
                            onChange={handleChangeStatus}
                          >
                            <option value={2}>All</option>
                            <option value={1}>Publish</option>
                            <option value={0}>Draft</option>
                            <option value={3}>Expire</option>
                            <option value={4}>Unpublish</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <h6>Search</h6>
                        <Input
                          type="search"
                          placeholder="Name"
                          id="search_name_mobile"
                          value={nameMobile}
                          onChange={(e) => {
                            setNameMobile(e.target.value);
                          }}
                        />
                      </Col>
                      <Col md={3}>
                        <div className="form-row">
                          <label className="form-label">
                            Select Product Segment
                          </label>
                          <FormGroup className="mb-0">
                            <select
                              className="form-select"
                              value={state}
                              onFocusCapture={GetSegmentList}
                              onChange={handleStateChanges}
                            >
                              <option value="">Select Segment</option>
                              {segmentOption.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="form-row">
                          <label className="form-label">Select Series</label>
                          <FormGroup className="mb-0">
                            <select
                              className="form-select"
                              value={series}
                              onFocusCapture={fetchSeries}
                              onChange={handleSeries}
                            >
                              <option value="">Select Series</option>
                              {districtOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col lg={3}>
                        <h6>Select Date filter</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={dateStatus}
                            onChange={handleDateChangeStatus}
                          >
                            <option value={1}>Creation Date</option>
                            <option value={0}>Start Date</option>
                            <option value={2}>End Date</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col lg={3}>
                        {isOpenfirstMonth === false ? (
                          <div>
                            <h6>Select Dates</h6>
                            <button
                              className="form-select"
                              onClick={handleFirstMonthButtonState}
                            >
                              {dateFilters.start_date && dateFilters.end_date
                                ? `${new Date(
                                    dateFilters.start_date
                                  ).toLocaleString("default", {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                  })} - ${new Date(
                                    dateFilters.end_date
                                  ).toLocaleString("default", {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                  })}`
                                : "select date range"}
                            </button>
                          </div>
                        ) : (
                          <div className="customDateOverlay">
                            <label className="form-label">Select Dates: </label>
                            <div className="">
                              <DateRange
                                editableDateInputs={true}
                                onChange={handledateRange}
                                moveRangeOnFirstSelection={false}
                                ranges={dateRange}
                                rangeColors={["#FCB92C", "#FCB92C", "#FCB92C"]}
                                showSelectionPreview={true}
                                shouldCloseOnSelect={true}
                                // maxDate={currentDate}
                                minDate={new Date(moment("2023-10-01"))}
                                onClose={handleDateRangeClose}
                              />
                              <Button
                                className="btn-danger float-end mt-1"
                                onClick={handleFirstMonthButtonState}
                              >
                                Close
                              </Button>
                              <Button
                                className="btn-warning float-end me-2 mt-1"
                                onClick={getListing}
                              >
                                Search
                              </Button>
                            </div>
                          </div>
                        )}
                      </Col>
                      <Col lg={6} className="mt-4">
                        <Button
                          className="btn btn-danger btn-secondary float-end"
                          onClick={Reset}
                          // style={{ width: "74px" }}
                        >
                          Reset
                        </Button>{" "}
                        <Button
                          className="btn-warning float-end mx-2"
                          onClick={getListing}
                          // disabled={loading === true ? true : false}
                        >
                          Search
                        </Button>{" "}
                      </Col>
                    </Row>
                  </div>
                  <div className="mt-3">
                    <div className="row">
                      <div className="table-rep-plugin pt-1">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            striped
                            bordered
                            responsive
                            hover
                          >
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Status</th>
                                <th>Segment</th>
                                <th>Product</th>
                                <th>Budget</th>
                                <th>Budget Used</th>
                                {/* <th>Limit No of Packs</th> */}
                                <th>Unique Mechanics availed Bonus</th>
                                <th>Number times received Bonus</th>
                                <th>Creation Date</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            {offerList.length > 0 && !loading && (
                              <tbody>
                                {offerList.map((item) => {
                                  return (
                                    <tr key={item.id}>
                                      <td>{item.title ? item.title : "N/A"}</td>
                                      <td>
                                        {item.status ? item.status : "N/A"}
                                      </td>
                                      <td>
                                        {item.skuSegment
                                          ? item.skuSegment.segment
                                          : "N/A"}
                                      </td>
                                      <td>
                                        {item.product_sae
                                          ? item.product_sae
                                          : "N/A"}
                                      </td>

                                      <td>
                                        {item.budget ? item.budget : "N/A"}
                                      </td>
                                      <td>
                                        {item.budget_used
                                          ? item.budget_used
                                          : "N/A"}
                                      </td>
                                      {/* <td>
                                        {item.limit_no_of_packs
                                          ? item.limit_no_of_packs
                                          : "N/A"}
                                      </td> */}
                                      <td>
                                        {item.uniqusers
                                          ? item.uniqusers
                                          : "N/A"}
                                      </td>
                                      <td>
                                        {item.nooftimesbonus
                                          ? item.nooftimesbonus
                                          : "N/A"}
                                      </td>
                                      <td>
                                        {item.creation_date
                                          ? item.creation_date
                                          : "N/A"}
                                      </td>
                                      <td>
                                        {item.start_date
                                          ? item.start_date
                                          : "N/A"}
                                      </td>
                                      <td>
                                        {item.end_date ? item.end_date : "N/A"}
                                      </td>
                                      <td>
                                        <button
                                          className="btn btn-outline-info btn-sm"
                                          // disabled={item.is_active == 0}
                                          onClick={() => {
                                            handleFormRoutes(item);
                                          }}
                                        >
                                          Edit
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            )}
                            {loading === true && (
                              <tbody>
                                <tr>
                                  <td colSpan="11" className="text-center">
                                    <Spinner />
                                  </td>
                                </tr>
                              </tbody>
                            )}
                            {offerList.length === 0 && !loading && (
                              <tbody>
                                <tr>
                                  <td colSpan="9" className="text-center">
                                    No Data Found
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
                {totalPages > 1 && (
                  <div className="d-flex justify-content-center ">
                    <Pagination
                      page={currentPage}
                      setPage={handlePagination}
                      totalPages={totalPages}
                    />
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EniClubListing;
