//export const API_URL = "https://devanalyticsapi.eniclub.in/api/v1/";
//export const CAMPAIGN_URL = "https://devieniapi.eniclub.in/api/";
export const API_URL = "https://analyticsapi.eniclub.in/api/v1/";
export const CAMPAIGN_URL = "https://ieniapi.eniclub.in/api/";
//export const API_URL = "https://betaanalyticsapi.eniclub.in/api/v1/";
//export const CAMPAIGN_URL = "https://betaieniapi.eniclub.in/api/";
// export const API_URL = "http://localhost:3001/api/v1/";
// export const CAMPAIGN_URL = "http://localhost:4082/api/";

export const user = JSON.parse(localStorage.getItem("user")) ?? {};

export const campaign_programs = ["1", "5"];
