import React from "react";
import ReactApexChart from "react-apexcharts";
import Spinner from "../Loader/Spinner";
import { CardTitle } from "reactstrap";

const BarChart = ({
  data = [],
  maxValue,
  loading,
  start_date,
  end_date,
  label,
  date_range,
}) => {
  console.log(label);
  const options = {
    series: data,
    chart: {
      height: 350,
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    colors: ["#1500FF", "#FF006A", "#EAFF00", "#00FF95", "#E8B417"],
    dataLabels: {
      style: {
        colors: ["#000"],
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      categories: [
        `${date_range?.first_month_start} - ${date_range?.first_month_end}`,
        `${date_range?.second_month_start} - ${date_range?.second_month_end}`,
      ],
    },
    yaxis: {
      max: maxValue,
      labels: {
        show: true,
        trim: false,
      },
    },
  };
  return (
    <div>
      {loading === true ? (
        <Spinner />
      ) : (
        <div>
          <div className="d-flex p-2">
            <div className="d-flex">
              <CardTitle className="text-black font-size-16">{`Current ${label}:`}</CardTitle>
              <CardTitle className="mx-2">
                {`${date_range?.first_month_start} to ${date_range?.first_month_end}`}
              </CardTitle>
            </div>
            <div className="d-flex mx-4">
              <CardTitle className="text-black font-size-16">{`Previous ${label}:`}</CardTitle>
              <CardTitle className="mx-2">
                {`${date_range?.second_month_start} to ${date_range?.second_month_end}`}
              </CardTitle>
            </div>
          </div>

          <ReactApexChart
            options={options}
            series={options.series}
            type="bar"
            height={350}
            width={1000}
          />
        </div>
      )}
    </div>
  );
};

export default BarChart;
