import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import axios from "axios";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  Table,
  CardTitle,
} from "reactstrap";

//Import Breadcrumb
import "react-datepicker/dist/react-datepicker.css";

import "bootstrap/dist/css/bootstrap.css";
import moment from "moment";
import getSegment from "../../services/segment.service";
import "./category.css";

import { showLoader, hideLoader } from "../../actions/loading";
import { useDispatch, useSelector } from "react-redux";
import { SpinnerSmall } from "../../components/Loader/Spinner";
import Spinner from "../../components/Loader/Spinner";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import SegmentDataCard from "../../components/Card/SegmentDataCard";
import SegmentCards from "../../components/Card/SegmentCards";
import "react-datepicker/dist/react-datepicker.css";
import Master from "../../components/Master/Master";
import { toggleRightSidebar } from "../../store/actions";
import { Breadcrumbs } from "@mui/material";
import Breadcrumb from "../../components/Common/Breadcrumb";

const SegmentVolume = (props) => {
  const loading = useSelector((state) => state.loading.loading);
  const user = JSON.parse(localStorage.getItem("user"));
  const master = useSelector((state) => state.master.setList.body);
  const dispatch = useDispatch();
  const [topTenVolumeLoader, setTopTenVolumeLoader] = useState(false);
  const [topAbsLoserloader, setTopAbsLoserLoader] = useState(false);
  const [topAbsGainerloader, setTopAbsGainerLoader] = useState(false);
  const [topPerLoserloader, setTopPerLoserLoader] = useState(false);
  const [topPerGainerloader, setTopPerGainerLoader] = useState(false);
  const [data, setData] = useState([]);
  const [productList, setProductList] = useState([]);
  const [segmentData, setSegmentData] = useState([]);
  const [percentageGainer, setPercentageGainer] = useState([]);
  const [percentageLoser, setPercentageLoser] = useState([]);
  const [absoluteGainer, setAbsoluteGainer] = useState([]);
  const [absoluteLoser, setAbsoluteLoser] = useState([]);
  const [isOpenfirstMonth, setIsOpenFirstmonth] = useState(false);
  const [segment, setSegment] = useState("ALL");
  const [segmentOption, setSegmentOption] = useState([
    {
      value: "ALL",
      label: "ALL",
      id: 0,
    },
    {
      value: "3WO",
      label: "3WO",
      id: 1,
    },
    {
      value: "PCMO",
      label: "PCMO",
      id: 2,
    },
    {
      value: "MCO",
      label: "MCO",
      id: 3,
    },
    {
      value: "DEO",
      label: "DEO",
      id: 4,
    },
    // {
    //   value: "TRANS",
    //   label: "TRANS",
    //   id: 5,
    // },
  ]);
  // const currentDate = new Date();
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1); // current date
  const initialStartDate = moment(currentDate).subtract(29, "days").toDate(); // subtract 30 days from current date

  const [formattedSecondMonthStartDate, setFormattedSecondMonthStartDate] =
    useState(
      new Date(initialStartDate).toLocaleString("default", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
    );
  const [formattedSecondMonthEndDate, setFormattedSecondMonthEndDate] =
    useState(
      new Date(currentDate).toLocaleString("default", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
    );
  const [formattedFirstMonthStartDate, setFormattedFirstMonthStartDate] =
    useState();
  const [formattedFirstMonthEndDate, setFormattedFirstMonthEndDate] =
    useState();

  const initialDateRange = [
    {
      startDate: new Date(moment(initialStartDate)),
      endDate: new Date(moment(currentDate)),
      key: "selection",
    },
  ];
  const [dateRange, setDateRange] = useState(initialDateRange);

  const [totalVolume, setTotalVolume] = useState(0);
  const [totalcontribution, setTotalContribution] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  let body = {
    role_id: user.role_id,
    user_id: user.user_id,
    start_date: moment(dateRange[0].startDate).format("YYYY-MM-DD"),
    end_date: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
    segment: segment,
    regional_mapping: master ? master.regional_mapping : null,
  };
  const handledateRange = (item) => {
    setDateRange([item.selection]);
    body.start_date = moment(item.selection.startDate).format("YYYY-MM-DD");
    body.end_date = moment(item.selection.endDate).format("YYYY-MM-DD");
  };

  const submitDates = async () => {
    handleDateRangeClose();
    dispatch(showLoader());
    setTopTenVolumeLoader(true);
    setTopAbsLoserLoader(true);
    setTopAbsGainerLoader(true);
    setTopPerLoserLoader(true);
    setTopPerGainerLoader(true);
    setIsOpenFirstmonth(false);

    let volume_body = {};
    volume_body.start_date = body.start_date;
    volume_body.end_date = body.end_date;
    volume_body.segment = body.segment;
    volume_body.regional_mapping = master ? master.regional_mapping : null;
    volume_body.user_id = body.user_id;
    volume_body.role_id = user.role_id;
    volume_body.user_id = user.user_id;

    // const segment_category_volume = await getSegment.GetMLPTOP(volume_body);
    // setSegmentData(segment_category_volume.data);
    let percentage_loser = {};
    percentage_loser.role_id = user.role_id;
    percentage_loser.user_id = body.user_id;
    percentage_loser.sort_by = "percentage";
    percentage_loser.filter_by = "loser";
    percentage_loser.start_date = body.start_date;
    percentage_loser.end_date = body.end_date;
    percentage_loser.segment = body.segment;
    percentage_loser.regional_mapping = master ? master.regional_mapping : null;
    // const percentage_loser_data = await getSegment.GetMLPData(body);
    // setPercentageLoser(percentage_loser_data.data);
    let percentage_gainer = {};
    percentage_gainer.rolr_id = user.role_id;
    percentage_gainer.user_id = user.user_id;
    percentage_gainer.sort_by = "percentage";
    percentage_gainer.filter_by = "gainer";
    percentage_gainer.start_date = body.start_date;
    percentage_gainer.end_date = body.end_date;
    percentage_gainer.segment = body.segment;
    percentage_gainer.regional_mapping = master
      ? master.regional_mapping
      : null;
    // const percentage_gainer_data = await getSegment.GetMLPData(body);
    // setPercentageGainer(percentage_gainer_data.data);
    let absolute_gainer = {};
    absolute_gainer.role_id = user.role_id;
    absolute_gainer.user_id = user.user_id;
    absolute_gainer.sort_by = "absolute";
    absolute_gainer.filter_by = "gainer";
    absolute_gainer.start_date = body.start_date;
    absolute_gainer.end_date = body.end_date;
    absolute_gainer.segment = body.segment;
    absolute_gainer.regional_mapping = master ? master.regional_mapping : null;
    // const absolute_loser_data = await getSegment.GetMLPData(body);
    // setAbsoluteLoser(absolute_loser_data.data);
    let absolute_loser = {};
    absolute_loser.role_id = user.role_id;
    absolute_loser.user_id = user.user_id;
    absolute_loser.sort_by = "absolute";
    absolute_loser.filter_by = "loser";
    absolute_loser.start_date = body.start_date;
    absolute_loser.end_date = body.end_date;
    absolute_loser.segment = body.segment;
    absolute_loser.regional_mapping = master ? master.regional_mapping : null;

    // const absolute_gainer_data = await getSegment.GetMLPData(body);
    // setAbsoluteGainer(absolute_gainer_data.data);

    let product_data = {};
    product_data.role_id = user.role_id;
    product_data.user_id = user.user_id;
    product_data.start_date = body.start_date;
    product_data.end_date = body.start_date;
    product_data.segment = body.segment;
    // product_data.product = "TRACTOR MAXX",
    product_data.user_id = user.user_id;
    product_data.offset = currentPage;
    product_data.limit = 10;

    const [
      segment_category_volume,
      percentage_loser_data,
      percentage_gainer_data,
      absolute_loser_data,
      absolute_gainer_data,
      segment_data,
      product_data_list,
    ] = await axios.all([
      getSegment.GetMLPTOP(volume_body),
      getSegment.GetMLPData(percentage_loser),
      getSegment.GetMLPData(percentage_gainer),
      getSegment.GetMLPData(absolute_loser),
      getSegment.GetMLPData(absolute_gainer),
      getSegment.GetSegment(body),
      getSegment.GetProductData(product_data),
    ]);
    if (absolute_loser_data?.status === true) {
      setAbsoluteLoser(absolute_loser_data?.data?.data);
      setTopAbsLoserLoader(false);
      // setTotalVolumeAbsoluteLoser(absolute_loser_data.data.summary.volume);
    } else if (absolute_loser_data?.status === false) {
      setAbsoluteLoser(absolute_loser_data?.data?.data);
      setTopAbsLoserLoader(false);
    }

    if (absolute_gainer_data?.status === true) {
      setAbsoluteGainer(absolute_gainer_data?.data?.data);
      setTopAbsGainerLoader(false);
      // setTotalVolumeAbsoluteGainer(absolute_gainer_data.data.summary.volume);
    } else if (absolute_gainer_data?.status === false) {
      setAbsoluteGainer(absolute_gainer_data?.data?.data);
      setTopAbsGainerLoader(false);
    }

    if (percentage_gainer_data?.status === true) {
      setPercentageGainer(percentage_gainer_data?.data?.data);
      setTopPerGainerLoader(false);
      // setTotalVolumePercentageGainer(
      //   percentage_gainer_data.data.summary.volume
      // );
    } else if (percentage_gainer_data?.status === false) {
      setPercentageGainer(percentage_gainer_data?.data?.data);
      setTopPerGainerLoader(false);
    }

    if (percentage_loser_data?.status === true) {
      setPercentageLoser(percentage_loser_data?.data?.data);
      setTopPerLoserLoader(false);
      // setTotalVolumePercentageLoser(percentage_loser_data.data.summary.volume);
    } else if (percentage_loser_data?.status === false) {
      setPercentageLoser(percentage_loser_data?.data?.data);
      setTopPerLoserLoader(false);
    }

    if (segment_category_volume?.status === true) {
      setSegmentData(segment_category_volume?.data?.data);
      setTopTenVolumeLoader(false);
      setTotalVolume(segment_category_volume?.data?.summary?.volume);
      setTotalContribution(
        segment_category_volume?.data?.summary?.contribution
      );
    } else if (segment_category_volume?.status === false) {
      setSegmentData(segment_category_volume?.data?.data);
      setTopTenVolumeLoader(false);
    }

    // const segment_data = await getSegment.GetSegment(body);
    if (segment_data?.status === true) {
      setFormattedSecondMonthStartDate(
        segment_data?.data?.dates?.second_month_start
      );
      setFormattedSecondMonthEndDate(
        segment_data?.data?.dates?.second_month_end
      );
      setFormattedFirstMonthStartDate(
        segment_data?.data?.dates?.first_month_start
      );
      setFormattedFirstMonthEndDate(segment_data?.data?.dates?.first_month_end);
    }

    if (segment_data?.status === true) {
      // setFormattedSecondMonthStartDate(segment_data.dates.second_month_start)
      const summary = segment_data?.data?.data?.summary;
      summary.type = "Total";
      const finalSummary = Object.fromEntries(
        Object.entries(summary).map(([key, value]) => {
          switch (key) {
            case "firstMoTotalVolume":
              return ["first_month_volume", value];
            case "secondMoTotalVolume":
              return ["second_month_volume", value];
            default:
              return [key, value];
          }
        })
      );
      const rowData = segment_data?.data?.data?.segment_data;
      rowData.push(finalSummary);
      setData(rowData);
    }

    if (product_data_list?.status === true) {
      setProductList(product_data_list.data.data);
    }
    dispatch(hideLoader());
  };

  const Reset = () => {
    setSegment("DEO");
    body.start_date = moment(initialDateRange[0].startDate).format(
      "YYYY-MM-DD"
    );
    body.end_date = moment(initialDateRange[0].endDate).format("YYYY-MM-DD");
    body.regional_mapping = null;
    setDateRange(initialDateRange);
    submitDates();
  };

  const handleFirstMonthButtonState = () => {
    setIsOpenFirstmonth(!isOpenfirstMonth);
  };

  const GetSegmentList = async () => {
    const options = await getSegment.GetSegmentList();
    if (options) {
      setSegmentOption(options?.data);
    }
  };

  const handleSegment = (event) => {
    setSegment(event.target.value);
  };
  const handleDateRangeClose = () => {
    setIsOpenFirstmonth(false);
  };

  useEffect(() => {
    submitDates(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [master]);

  // useEffect(() => {
  //   GetSegmentList();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  function toggleRightbar() {
    props.toggleRightSidebar();
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <CardTitle className="font-size-18 mb-3">Segment Volume</CardTitle>
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        {isOpenfirstMonth === false ? (
                          <div>
                            <label className="form-label">Select Dates: </label>
                            <button
                              className="form-select text-lg-start"
                              onClick={handleFirstMonthButtonState}
                            >
                              {loading === true ? (
                                <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                              ) : (
                                `${
                                  formattedSecondMonthStartDate +
                                  " - " +
                                  formattedSecondMonthEndDate
                                }`
                              )}
                            </button>
                          </div>
                        ) : (
                          <div className="customDateOverlay">
                            <label className="form-label">Select Dates: </label>
                            <div className="">
                              <DateRange
                                editableDateInputs={true}
                                // onChange={(item) => handledateRange(item.selection.startDate,item.selection.endDate)}
                                onChange={handledateRange}
                                moveRangeOnFirstSelection={false}
                                ranges={dateRange}
                                rangeColors={["#FCB92C", "#FCB92C", "#FCB92C"]}
                                shouldCloseOnSelect={true}
                                maxDate={currentDate}
                                minDate={new Date(moment("2021-01-01"))}
                                onClose={handleDateRangeClose}
                              />
                              <div>
                                <Button
                                  className="btn-danger float-end mt-1"
                                  onClick={handleFirstMonthButtonState}
                                >
                                  Close
                                </Button>
                                <Button
                                  className="btn-warning float-end mt-1 margin-right"
                                  onClick={() => submitDates()}
                                >
                                  Search
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="col-lg-2 col-md-6 col-sm-12">
                        <div className="margin-top d-flex">
                          <Button
                            className="btn-warning margin-right"
                            onClick={() => submitDates()}
                          >
                            Search
                          </Button>
                          <Button
                            className="btn btn-danger btn-secondary "
                            onClick={() => Reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </div>
                      <div className="col-lg-1 col-md-6 col-sm-12 w-auto">
                        {user.role_id !== 22 ? (
                          <div className="margin-top d-flex">
                            <button
                              color="none"
                              onClick={toggleRightbar}
                              // type="button"
                              className="advance-filter-btn"
                            >
                              Team Filter
                              <i className="ri-filter-2-fill mx-1"></i>
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div>
                    <Master />{" "}
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="container-fluid">
                    <div className="row">
                      <h4 className="card-title pt-4">Segment Volume(ltr)</h4>
                      <div className="table-rep-plugin pt-4">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          {loading === true ? (
                            <Spinner />
                          ) : (
                            <Table
                              id="tech-companies-1"
                              striped
                              bordered
                              responsive
                            >
                              <thead>
                                <tr className="text-center">
                                  <th className="text-start" data-priority="1">
                                    Type
                                  </th>
                                  <th data-priority="3">
                                    {" "}
                                    {`${formattedFirstMonthStartDate} 
                                       - 
                                      ${formattedFirstMonthEndDate} (Ltr)`}
                                  </th>
                                  <th data-priority="1">
                                    {`${formattedSecondMonthStartDate} 
                                       - 
                                      ${formattedSecondMonthEndDate} (Ltr)`}
                                  </th>
                                  <th data-priority="3">Percentage Change</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data.length === 0 ? (
                                  <tr className="align">
                                    <td colSpan="4" className="text-center">
                                      No data available
                                    </td>
                                  </tr>
                                ) : (
                                  data.map((item, index) => (
                                    <tr key={index}>
                                      {item.type === "Total" ? (
                                        <td className="font-size-18">
                                          {item.type}
                                        </td>
                                      ) : (
                                        <td>{item.type}</td>
                                      )}
                                      {item.type === "Total" ? (
                                        <td className="font-size-18 text-center ">
                                          {item.first_month_volume}
                                        </td>
                                      ) : (
                                        <td className="text-center">
                                          {item.first_month_volume}
                                        </td>
                                      )}
                                      {item.type === "Total" ? (
                                        <td className="font-size-18 text-center">
                                          {item.second_month_volume}
                                        </td>
                                      ) : (
                                        <td className="text-center">
                                          {item.second_month_volume}
                                        </td>
                                      )}

                                      {item.type === "Total" &&
                                      item.percentage < 0 ? (
                                        <td className="text-danger font-size-18 text-center">
                                          {` ${
                                            item.percentage === null
                                              ? 0
                                              : item.percentage
                                          }%`}
                                        </td>
                                      ) : item.type === "Total" &&
                                        item.percentage > 0 ? (
                                        <td className="text-success font-size-18 text-center">
                                          {` ${
                                            item.percentage === null
                                              ? 0
                                              : item.percentage
                                          }%`}
                                        </td>
                                      ) : item.type !== "Total" &&
                                        item.percentage > 0 ? (
                                        <td className="text-success text-center">
                                          {` ${
                                            item.percentage === null
                                              ? 0
                                              : item.percentage
                                          }%`}
                                        </td>
                                      ) : (
                                        <td className="text-danger text-center">
                                          {` ${
                                            item.percentage === null
                                              ? 0
                                              : item.percentage
                                          }%`}
                                        </td>
                                      )}
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </Table>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader className="d-flex flex-row">
                  <div className="col-lg-2 col-md-6 col-sm-12">
                    <div className="">
                      <label className="form-label">Select Segment: </label>
                      <select
                        className="form-select w-100"
                        aria-label="Default select example"
                        value={segment}
                        onChange={handleSegment}
                      >
                        {segmentOption ? (
                          segmentOption.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))
                        ) : (
                          <option></option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-sm-12">
                    <div className="margin-top d-flex mx-3">
                      {/* <Button
                            className="btn btn-danger btn-secondary margin-right"
                            onClick={() => Reset()}
                          >
                            Reset
                          </Button> */}
                      <Button
                        className="btn-warning"
                        onClick={() => submitDates()}
                      >
                        Search
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <h4 className="card-title p-4">
                    Top 10 Users Across Categories {totalVolume}L (
                    {totalcontribution} % Share)
                  </h4>

                  {topTenVolumeLoader === true ? (
                    <Spinner />
                  ) : (
                    <SegmentCards
                      data={segmentData}
                      start_date={body.start_date}
                      end_date={body.end_date}
                    />
                  )}
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h4 className="card-title p-4">
                    Top 10 Gainer in Percentage(%){" "}
                  </h4>

                  {topPerGainerloader === true ? (
                    <Spinner />
                  ) : (
                    <SegmentDataCard
                      data={percentageGainer}
                      start_date={body.start_date}
                      end_date={body.end_date}
                    />
                  )}
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h4 className="card-title p-4">
                    Top 10 Loser in Percentage(%){" "}
                  </h4>

                  {topPerLoserloader === true ? (
                    <Spinner />
                  ) : (
                    <SegmentDataCard
                      data={percentageLoser}
                      start_date={body.start_date}
                      end_date={body.end_date}
                    />
                  )}
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h4 className="card-title p-4">
                    Top 10 Gainer in Absolute No.
                  </h4>

                  {topAbsGainerloader === true ? (
                    <Spinner />
                  ) : (
                    <SegmentDataCard
                      data={absoluteGainer}
                      start_date={body.start_date}
                      end_date={body.end_date}
                    />
                  )}
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h4 className="card-title p-4">
                    Top 10 Loser in Absoulte No.
                  </h4>
                  {topAbsLoserloader === true ? (
                    <Spinner />
                  ) : (
                    <SegmentDataCard
                      data={absoluteLoser}
                      start_date={body.start_date}
                      end_date={body.end_date}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStateToProps, { toggleRightSidebar })(
  withNamespaces()(SegmentVolume)
);
