import React, { useEffect, useState } from "react";
import DashboardService from "../../services/rlpDashboard.service";
import { CardTitle, Col, Row } from "reactstrap";
import "./ProgramOverView.css";
import IndiaMap from "../Charts/DbtRedemtionChart";
import ChartjsChart from "../Charts/PieCharts";
import PieChart from "../Charts/PieCharts";
import Spinner, { SpinnerSmall } from "../Loader/Spinner";
import moment from "moment";
const { user_id, role_id } = JSON.parse(localStorage.getItem("user")) ?? {};
const ProgramOverview = ({ data, programOverview, loading, packSold }) => {
  const [section, setSection] = useState("registered");
  const [pieData, setPieData] = useState(null);
  const [mapData, setMapData] = useState(null);
  const [sevenDays, setSevenDays] = useState(null);
  const [loader, setLoader] = useState(false);

  // Rest of the code...

  const body = {
    section: section,
    start_date: data.start_date,
    end_date: data.end_date,
    user_id: data.user_id,
    role_id: role_id,
  };

  // const ProgramOverviewData = async () => {
  //   setLoader(true);
  //   await DashboardService.GetProgramOverviewData(body).then((res) => {
  //     setPieData(res?.data?.sub_trade_data);
  //     setMapData(res?.data?.map_data);
  //     setSevenDays(res?.data?.last_days?.data);
  //     setLoader(false);
  //   });
  // };

  const handleCardClick = async (section, isClickable) => {
    if (!isClickable) return;
    setLoader(true);
    setSection(section);
    body.section = section;
    await DashboardService.GetProgramOverviewData(body).then((res) => {
      setPieData(res.data?.sub_trade_data);
      setMapData(res.data?.map_data);
      setSevenDays(res.data?.last_days?.data);
      setLoader(false);
    });
  };
  const updateProgramOverviewData = async (newSection) => {
    setLoader(true);
    setSection(newSection);
    body.section = newSection;
    setSevenDays(new Array());
    await DashboardService.GetProgramOverviewData(body)
      .then((res) => {
        setPieData(res?.data?.sub_trade_data);
        setMapData(res?.data?.map_data);
        setSevenDays(res?.data?.last_days?.data);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  // const handleCardClick = async (section, isClickable) => {
  //   if(!isClickable) return;
  //   updateProgramOverviewData(section);
  // };

  // Use the function in the useEffect hook as well
  useEffect(() => {
    updateProgramOverviewData(section);
  }, [data.start_date, data.end_date]);

  // useEffect(() => {
  //   ProgramOverviewData();
  // }, [data.start_date, data.end_date]);

  return (
    <div className="mt-3">
      <CardTitle className="text-black-50 font-size-18">
        Program Overview ({moment(body.start_date).format("DD-MM-YYYY")} -{" "}
        {moment(body.end_date).format("DD-MM-YYYY")})
      </CardTitle>
      <div className="d-flex ">
        <div className="d-flex flex-wrap  w-auto justify-content-space-around">
          {[
            {
              section: "download",
              title: "Total Download",
              isClickable: true,
              value: programOverview?.downloaded?.count,
              Avg: programOverview?.downloaded?.avg,
            },
            {
              section: "registered",
              title: "Total Registered",
              isClickable: true,
              value: programOverview?.registered?.count,
              Avg: programOverview?.registered?.avg,
            },
            {
              section: "active",
              title: "Total Active",
              isClickable: true,
              value: programOverview?.active?.count,
              Avg: programOverview?.active?.avg,
            },
            {
              section: "pack_sold",
              title: "Pack Sold",
              value: packSold?.count,
              isClickable: true,
              Avg: packSold?.avg,
              Volume: packSold?.volume,
            },
            {
              section: "total_review_by_so",
              title: "KYC Reviewed by SO",
              isClickable: false,
              value: programOverview?.kycDetails?.so_reviewed || 0,
            },
            {
              section: "verified_by_so",
              title: "KYC Verified by SO",
              isClickable: false,
              value: programOverview?.kycDetails?.so_verified || 0,
            },
            {
              section: "review_by_sh",
              title: "KYC Reviewed by SH",
              isClickable: false,
              value: programOverview?.kycDetails?.sh_reviewed || 0,
            },
            {
              section: "verified_by_sh",
              title: "KYC Verified by SH",
              isClickable: false,
              value: programOverview?.kycDetails?.sh_verified || 0,
            },
          ].map((card) => (
            <div
              className="col-lg-2 col-md-12 col-sm-12 mb-3  me-1"
              key={card.section}
              style={{ width: "242px", flex: "1 0 21%" }}
            >
              <div
                className={`${
                  section === card.section ? "bg-soft-info" : ""
                } card pe h-auto `}
                onClick={() => handleCardClick(card.section, card.isClickable)}
              >
                <div className="card-body" style={{ minHeight: "150px" }}>
                {!card.isClickable && (
                <span class="float-end mb-4" title="*Note : Dates not applicable.">  <p className="card-text" title={`Count`}>
                </p><i class="fa fa-info-circle" aria-hidden="true"></i></span>
              )}
                  <h5 className="card-title">{card.title}</h5>
                  {loading === true || programOverview === null ? (
                    <SpinnerSmall />
                  ) : (
                    <>
                      <p className="card-text" title={`Count ${card.value}`}>
                        {card.Volume !== undefined ? (
                          <>{`${card.value} (${card.Volume}Ltr)`}</>
                        ) : (
                          `${card.value}`
                        )}
                      </p>
                      <hr></hr>
                      {card.isClickable && (
                        <>
                          <p className="card-text mb-n1 font-size-12 text-black-50 ">
                            Last 7 Days
                          </p>
                          <p
                            className="card-text text-success mb-n1"
                            title={`Average Count ${card.Avg}`}
                          >
                            Avg: {card.Avg}
                          </p>
                          {section === card.section && loader === false ? (
                            <ul className="list ">
                              {sevenDays?.map((day) => {
                                const formattedDate = moment(day.date).format(
                                  "DD-MM-YYYY"
                                );
                                return (
                                  <>
                                    <li key={day.date}>
                                      {`${formattedDate} : ${day.day} 
                              ${day.count}`}
                                    </li>
                                  </>
                                );
                              })}
                            </ul>
                          ) : null}
                        </>
                      )}
                    </>
                  )}
                
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="d-flex flex-wrap  w-auto justify-content-space-around">
          <div
            className="col-lg-2 col-md-12 col-sm-12 mb-3  me-1"
            key={packSold?.section}
            style={{ width: "242px" }}
          >
            <div
              // className={`${
              //   section === packSold.section ? "bg-soft-info" : ""
              // } card pe h-auto `}
              className="card pe h-auto"
              onClick={() => handleCardClick(packSold.section)}
            >
              <div className="card-body">
                <h5 className="card-title">{packSold?.section}</h5>
                {loading === true || programOverview === null ? (
                  <SpinnerSmall />
                ) : (
                  <>
                    <p className="card-text" title={`Count ${packSold?.count}`}>
                      {packSold?.count}
                    </p>
                    <hr></hr>
                    <p className="card-text mb-n1 font-size-12 text-black-50 ">
                      Last 7 Days
                    </p>
                    <p
                      className="card-text text-success mb-n1"
                      title={`Average Count ${packSold?.avg}`}
                    >
                      avg: {packSold?.avg}
                    </p>
                    {section === packSold?.section ? (
                      <ul className="list ">
                        {sevenDays?.map((day) => {
                          const formattedDate = moment(day.date).format(
                            "DD-MM-YYYY"
                          );
                          return (
                            <>
                              <li key={day.date}>
                                {`${formattedDate} : ${day.day} 
                              ${day.count}`}
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="row mt-3 d-flex justify-content-center">
        <div className="col-md-6">
          <div className="card pe p-3 " style={{ height: "500px" }}>
            <CardTitle className="p-1 text-center font-size-18">
              India <i className="mdi mdi-chevron-right"></i>
              Statewise{" "}
              {section === "download"
                ? "Download"
                : section === "registered"
                ? "App Registered Users"
                : section === "dbt_verified"
                ? "DBT Verified"
                : section === "active"
                ? "Active Users"
                : section === "pack_sold"
                ? "Pack Sold"
                : null}{" "}
              Count
            </CardTitle>
            {mapData === null || loader === true ? (
              <Spinner />
            ) : (
              <IndiaMap data={mapData} />
            )}
          </div>
        </div>
        {section === "pack_sold" && (
          <div className="col-md-6">
            <div className="card pe p-3" style={{ height: "500px" }}>
              <CardTitle className="p-1 text-center font-size-18">
                {section === "download"
                  ? "Download"
                  : section === "registered"
                  ? "App Registered Users"
                  : section === "dbt_verified"
                  ? "DBT Verified"
                  : section === "active"
                  ? "Active Users"
                  : section === "pack_sold"
                  ? "Pack Sold"
                  : null}{" "}
                Count
                <i className="mdi mdi-chevron-right"></i>
                Segments
              </CardTitle>
              {pieData === null || loader === true ? (
                <Spinner />
              ) : (
                <PieChart data={pieData} />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProgramOverview;
