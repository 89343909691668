import React from "react";
import { Link } from "react-router-dom";

import { Button, Table } from "reactstrap";
import UserCloneModal from "../Modal/UserCloneModal";

const MasterListTable = ({ data = [], loading, role_id }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [selectedUserId, setSelectedUserId] = React.useState(null);
  const [selectedMobile, setSelectedMobile] = React.useState(null);
  const [role, setRole] = React.useState(null);

  const toggle = ({ user_id, name, mobile, role }) => {
    // console.log(user_id, name, mobile);
    setSelectedUser(name);
    setSelectedUserId(user_id);
    setSelectedMobile(mobile);
    setRole(role);

    setIsOpen(!isOpen);
  };

  const edit_link =
    role_id === 16
      ? "/edit-state-head/"
      : role_id === 18
      ? "/edit-zone-head/"
      : role_id === 19
      ? "/edit-regional-head/"
      : "/edit-sales-officer/";

  // Map each object in the data array to a table row
  const RowData = ({ head_list }) => {
    return head_list.map((head, index) => {
      const {
        name,
        mobile,
        email_id,
        stateName,
        status,
        user_id,
        role_id,
        active_regional_head_name,
        active_state_head_name,
        active_zone_head_name,
      } = head;
      const Length = stateName?.length > 0 ? stateName.length : 0;
      return (
        <tr key={index}>
          <td className="d-flex justify-content-right">
            <Link to={`${edit_link}${user_id}`}>
              <Button
                title="Edit"
                size="sm"
                color="danger"
                style={{ marginRight: "10px" }}
              >
                <i className="fas fa-pencil-alt"></i>
              </Button>
            </Link>
            <Button
              title="Clone"
              size="sm"
              color="warning"
              style={{ marginRight: "10px" }}
              onClick={() => toggle({ user_id, name, mobile, role })}
            >
              <i className="fa fa-copy"></i>
            </Button>
            {/* <Link to={clone_link + user_id}>
              <Button title="Clone" size="sm" color="danger" outline>

                <i className="fa fa-copy"></i>
              </Button>
            </Link> */}
          </td>
          <td>{name}</td>
          <td>{mobile}</td>
          <td>{email_id}</td>
          {Length === 0 ? <td>NA</td> : <td>{`${stateName.join(", ")}`}</td>}

          {role_id !== 18 ? <td>{active_zone_head_name}</td> : null}
          {role_id !== 18 && role_id !== 19 ? (
            <td>{active_regional_head_name}</td>
          ) : null}
          {role_id !== 18 && role_id !== 19 && role_id !== 16 ? (
            <td>{active_state_head_name}</td>
          ) : null}
          <td className={status === "Active" ? "text-success" : "text-danger"}>
            {status}
          </td>
        </tr>
      );
    });
  };

  // Render the table with the mapped rows and cells
  return (
    <>
      <div className="table-rep-plugin pt-4">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <Table id="tech-companies-1" striped bordered responsive>
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Mobile</th>
                <th>Email Id</th>
                <th>States</th>
                {role_id !== 18 ? <th>Zone Head</th> : null}
                {role_id !== 18 && role_id !== 19 ? (
                  <th>Regional Head</th>
                ) : null}
                {role_id !== 18 && role_id !== 19 && role_id !== 16 ? (
                  <th>State Head</th>
                ) : null}
                <th>Status</th>
                {/* Add more headers for each property in the objects */}
              </tr>
            </thead>
            {data?.length === 0 && loading === false ? (
              <tbody>
                <tr>
                  <td colSpan="9" className="text-center">
                    No Data Found
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                <RowData head_list={data} />
              </tbody>
            )}
          </Table>
        </div>
      </div>
      <UserCloneModal
        isOpen={isOpen}
        toggle={toggle}
        user={selectedUser}
        mobile_number={selectedMobile}
        user_id={selectedUserId}
        role_id={role}
      />
    </>
  );
};

export default MasterListTable;
