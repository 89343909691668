import axios from "axios";
import { API_URL } from "../Config/config";
import authService from "./auth.service";
const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
// import { useSelector, useDispatch } from "react-redux";

// const API_URL = "http://devanalyticsapi.eniclub.in/api/v1/";
// const API_URL = "http://localhost:4077/api/v1/";
// const API_URL = "http://analyticsapi.eniclub.in/api/v1/";

const GetSegment = async (body) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  body.user_id = user?.user_id;

  return axiosApi
    .post(API_URL + "segment/data", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const GetSegmentList = async (params) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .get(API_URL + "constant/segmentlist", {
      params,
    })
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const GetTradetList = async (params) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .get(API_URL + "segment/tradelist", {
      params,
    })
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const GetMLPTOP = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));

  // body.user_id = user?.user_id;
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .post(API_URL + "segment/mlp-top", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data, loading: false };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const GetProductData = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));

  // body.user_id = user?.user_id;
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .post(API_URL + "product/data", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data, loading: false };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const GetMLPData = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));

  // body.user_id = user?.user_id;
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .post(API_URL + "segment/mlp-data", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data, loading: false };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const GetUserDetails = async (params) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .get(API_URL + "mlp/profile", {
      params,
    })
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};
const GetRlpUserDetails = async (params) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .get(API_URL + "rlp/profile", {
      params,
    })
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const GetEarnings = async (params) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .get(API_URL + "mlp/earn", {
      params,
    })
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};
const GetRlpEarnings = async (params) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .get(API_URL + "rlp/earn", {
      params,
    })
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const GetRedemption = async (params) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .get(API_URL + "mlp/redeem", {
      params,
    })
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};
const GetRlpRedemption = async (params) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .get(API_URL + "rlp/redeem", {
      params,
    })
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const GetVolume = async (body) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .post(API_URL + "mlp/volume", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const GetCash = async (params) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .get(API_URL + "mlp/cash", {
      params,
    })
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const GetPoint = async (params) => {
  const user = JSON.parse(localStorage.getItem("user")) ?? {};
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .get(API_URL + "rlp/point", {
      params,
    })
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data.data };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};
const GetProductSegment = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));

  body.user_id = user?.user_id;
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .post(API_URL + "product/segment", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data, loading: false };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const GetMLPPoints = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));

  body.user_id = user?.user_id;
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .post(API_URL + "mlp/points", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data, loading: false };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const GetSlab = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .post(API_URL + "mlp/slab", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data, loading: false };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

const GetMLPProduct = async (body) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  if (token) {
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return axiosApi
    .post(API_URL + "mlp/product", body)
    .then((response) => {
      if (response.data.status === "success") {
        return { status: true, data: response.data, loading: false };
      }
    })
    .catch((err) => {
      console.log(err);
      if (err?.response?.status === 403) {
        authService.autoLogout();
        window.location.href = "/login";
      }
    });
};

export default {
  GetSegment,
  GetSegmentList,
  GetTradetList,
  GetMLPTOP,
  GetMLPData,
  GetUserDetails,
  GetEarnings,
  GetRedemption,
  GetVolume,
  GetCash,
  GetProductData,
  GetProductSegment,
  GetMLPPoints,
  GetSlab,
  GetMLPProduct,
  GetRlpUserDetails,
  GetRlpEarnings,
  GetRlpRedemption,
  GetPoint
};
