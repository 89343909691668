import React, { useState } from "react";
import { Table, Col, Row } from "reactstrap";
import "../../pages/ViewGameActivity/ViewGameActivity.css";
import CustomModal from "../Modal/CustomModalRlp";
import getSegment from "../../services/segment.service";
import moment from "moment";

const ViewRetailerTable = ({ data = [] }) => {
  const [profileModal, setProfileModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [modalData, setModalData] = useState([]);
  const [prevSlab, setPrevSlab] = useState("");
  const [currentSlab, setCurrentSlab] = useState("");

  const profileToggleModal = () => {
    setProfileModal(!profileModal);
  };
  const fetchProfile = async (userId) => {
    const user_id = userId;
    setUserId(user_id);
    const params = { user_id: user_id };
    const response = await getSegment.GetUserDetails(params);
    console.log("response--",response);
    if (response.status === true) {
      setModalData(response.data);
      await getSegment.GetSlab(params).then((res) => {
        if (res?.status === true) {
          setCurrentSlab(res.data.data.curr_fin_year.class);
          setPrevSlab(res.data.data.last_fin_year.class);
        }
      });
    }
  };
  // Map each object in the data array to a table row
  console.log("data----",data);

  const rows = data?.map((item, index) => (
    <tr key={index}>
       <td>
        <i
          className="fas fa-external-link-alt icon-table"
          onClick={async () => {
            await fetchProfile(item.user_id);
            profileToggleModal();
          }}
          title="View More"
        />
      </td>
      <td>{item.endUser.name ? item.endUser.name : "N/A"}</td>
      <td>{item.user.mobile ? item.user.mobile : "N/A"}</td>
      <td>{item.endUser.state.state_name ? item.endUser.state.state_name : "N/A"}</td>
      <td>{item.game.name ? item.game.name : "N/A"}</td>
      <td>{item.game.skuSegment ? item.game.skuSegment.segment : "N/A"}</td>
      <td>{item.gift_type == 'PHYSICAL' ? item.gift_type + " (" + item.reward_name + ")": item.gift_type}</td>
      <td>{item.coins ? item.coins : "0"}</td>
      <td>{item.play_time ? item.play_time : "N/A"}</td>
   
      <td>
        {item.play_date
          ? item.play_date
          : "N/A"}
      </td>

      {/* Add more cells for each property in the object */}
    </tr>
  ));
  return (
    <>
      <div className="table-rep-plugin pt-1">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <Table id="tech-companies-1" striped bordered responsive hover>
            <thead>
              <tr>
              <th></th>
                <th>Name</th>
                <th>Mobile</th>
                <th>State</th>
                <th>Game Name</th>
                <th>Segment</th>
                <th>Gift type</th>
                <th>Coins</th>
                <th>Play Time</th>
                <th>Play Date</th>
                {/* Add more headers for each property in the objects */}
              </tr>
            </thead>
            {data?.length > 0 ? (
              <tbody>{rows}</tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="9" className="text-center">
                    No Data Found
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
      </div>{" "}
      <Row>
        <Col sm={6} md={4} xl={3}>
          <CustomModal
            isOpen={profileModal}
            toggle={profileToggleModal}
            user_id={userId}
            onConfirm={() => console.log("Confirmed!")}
            confirmLabel="Confirm"
            data={modalData}
            mechanic={true}
            prevSlab={prevSlab}
            currentSlab={currentSlab}
          >
            {/* <p>Modal Content Here</p> */}
          </CustomModal>
        </Col>
      </Row>
    </>
  );
};

export default ViewRetailerTable;
