import React, { useEffect, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

const ChartComponent = (props) => {
  const { data } = props;

  const chartRef = useRef(null);
  const Series = Object.entries(data).map(([key, value]) => {
    return {
      // category: key,
      name: `${
        key === "download"
          ? "Download"
          : key === "registered"
          ? "Registered"
          : key === "doc_verified"
          ? "Doc Verified"
          : key === "fin_transfered"
          ? "Fin Transfered"
          : key === "dbt_verified"
          ? "Dbt Verified"
          : key === "doc_submitted"
          ? "Doc Submitted"
          : "Regular Transfered"
      }`,
      value: value,
    };
  });

  useEffect(() => {
    const chart = am4core.create(chartRef.current, am4charts.SlicedChart);

    chart.data = Series;

    let series = chart.series.push(new am4charts.FunnelSeries());
    series.colors.step = 2;
    series.dataFields.value = "value";
    series.dataFields.category = "name";
    series.alignLabels = true;
    series.orientation = "horizontal";
    series.bottomRatio = 1;
    // series.labelsContainer.width = 200;

    series.labelsContainer.height = 200;

    chart.legend = new am4charts.Legend();
    chart.legend.position = "top";
    // chart.legend.margin(5, 5, 20, 50);
    // chart.legend.labels.template.maxWidth = 95;
    // chart.legend.labels.template.truncate = true;
    // chart.legend.paddingBottom = 200;
    // chart.legend.contentAlign = "middle";
    // chart.legend.fontSize = 12;
    // chart.legend.itemContainers.template.paddingTop = 260;

    // chart.legend.parent = chart.chartContainer;
    // chart.legend.contentAlign = "middle";
    // chart.appear(1000, 500);
    return () => {
      chart.dispose();
    };
  }, []);

  return (
    <div
      id="chartdiv"
      style={{
        width: "100%",
        height: "550px",
        marginTop: "20px",
        // margin: "20px",
        // marginBottom: "20px",
      }}
      ref={chartRef}
    ></div>
  );
};

export default ChartComponent;
