import React from "react";
import { Table } from "reactstrap";

const RedemptionTable = ({ data = []}) => {
  // const data = props.data;

  // Map each object in the data array to a table row
  const rows = data.map((item, index) => (
    <tr key={index}>
      <td>{item.user_data.name}</td>
      <td>{item.user_data.mobile}</td>
      <td>{item.user_data.state}</td>
      <td>{item.user_data.district}</td>
      <td>{item.user_data.area}</td>
      <td>{item.user_data.mechcode ? item.user_data.mechcode : 'N/A'}</td>
      <td>{item.redeemcode === "" ? "-" : item.redeemcode}</td>
      <td>{item.rewardname === "" ? "-" : item.rewardname}</td>
      <td>{item.reward_type}</td>
      <td>{item.reward_status}</td>
      <td>{item.source}</td>
      <td>{item.points_used}</td>
      <td>{item.approved_date}</td>
      <td>{item.creation_date}</td>

      {/* Add more cells for each property in the object */}
    </tr>
  ));

  // Render the table with the mapped rows and cells
  return (
    <div className="table-rep-plugin pt-4">
      <div className="table-responsive mb-0" data-pattern="priority-columns">
        <Table id="tech-companies-1" striped bordered responsive>
          <thead>
            <tr>
              <th>Name </th>
              <th>Mobile</th>
              <th>State</th>
              <th>District</th>
              <th>Area</th>
              <th>Mechanic Code</th>
              <th>Redeem Code</th>
              <th>Reward Name</th>
              <th>Reward Type</th>
              <th>Reward Status</th>
              <th>Source</th>
              <th>Points Used</th>
              <th>Approve Date</th>
              <th>Creation Date</th>

              {/* Add more headers for each property in the objects */}
            </tr>
          </thead>
          {data?.length > 0 ? (
            <tbody>{rows}</tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan="11" className="text-center">
                  No Data Found
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>
    </div>
  );
};

export default RedemptionTable;
