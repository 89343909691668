import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { toggleRightSidebar } from "../../store/actions";
import {
  // ToastContainer,
  toast,
} from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  FormGroup,
  Input,
  CardTitle,
} from "reactstrap";

import "./ViewMechanic.css";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ViewMechanicTable from "../../components/Table/ViewMechanicTable";
import viewMechanicService from "../../services/viewMechanic.service";
import ReportModal from "../../components/Modal/ReportModal";
import viewTransactionService from "../../services/viewTransaction.service";
import { showLoader, hideLoader } from "../../actions/loading";
import { useDispatch, useSelector } from "react-redux";
import Spinner, { SpinnerSmall } from "../../components/Loader/Spinner";
import moment from "moment";
import { DateRange } from "react-date-range";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Master from "../../components/Master/Master";

const ViewMechanic = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const master = useSelector((state) => state.master.setList.body);
  const role = user ? user.role_id : null;
  const loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isOpenfirstMonth, setIsOpenFirstmonth] = useState(false);
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1); // current date
  const initialDateRange = [
    {
      startDate: new Date(moment(currentDate)),
      endDate: new Date(moment(currentDate)),
      key: "selection",
    },
  ];

  const [disableDownload, setDisableDownload] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [totalCount, setTotalCount] = useState(null);
  const [docStatus, setDocStatus] = useState("");
  const [appStatus, setAppStatus] = useState("app_download_user");
  const [class_type, setClassStatus] = useState("");
  const [nameMobile, setNameMobile] = useState("");
  const [pincode, setPincode] = useState("");
  const [acNumber, setACNumber] = useState("");
  const [reportName, setReportName] = useState("");
  const [dateRange, setDateRange] = useState(initialDateRange);
  // const [dateRange, setDateRange] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [state, setState] = useState("");
  const [stateOptions, setstateOptions] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [district, setDistrict] = useState("");
  const [districtOptions, setDistrictOptions] = useState([]);
  const [districtId, setDistrictId] = useState([]);
  const [area, setArea] = useState("");
  const [areaOptions, setAreaOptions] = useState([]);
  const [areaId, setAreaId] = useState([]);
  const [dateFilters, setDateFilters] = useState({
    start_date: "",
    end_date: "",
  });

  const [documentOptions, setDocumentOptions] = useState([
    { value: "", label: "Select Document Status" },
    { value: "partial_doc", label: "Partial Document" },
    { value: "both_doc", label: "Document Submited" },
    { value: "verification_pending", label: "Verification Pending" },
    { value: "escalated_doc", label: "Escalated" },
    { value: "dbt_verified", label: "DBT Verified" },
    { value: "doc_verified", label: "Document Verified" },
  ]);

  const [appOptions, setAppOptions] = useState([
    { value: "", label: "Select App Status" },
    { value: "app_register_user", label: "App Register User" },
    // { value: "NO", label: "Non-App User" },
    { value: "active_user", label: "Active User" },
    { value: "inactive_user", label: "Inactive User" },
    // { value: "total_enrolled", label: "Total Enrolled" },
    { value: "app_download_user", label: "Total Download" },
  ]);

  const [classOptions, setClassOptions] = useState([
    {value: "", label : 'select class'},
    { value: "A", label: "A CLASS" },
    { value: "B", label: "B CLASS" },
    { value: "C", label: "C CLASS" },
    { value: "D", label: "D CLASS" },
  ]);
  const [modal, setModal] = useState(false);
  function toggleRightbar() {
    props.toggleRightSidebar();
  }
  //modified Body data
  let bodyFilters = {
    regional_mapping: master ? master.regional_mapping : null,
    // start_date: moment(dateRange[0].startDate).format("YYYY-MM-DD"),
    // end_date: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
    limit: pageSize,
    offset: currentPage,
  };
  if (docStatus.length > 0) {
    bodyFilters.document_filter = docStatus;
  }
  if (appStatus.length > 0) {
    bodyFilters.app_filter = appStatus;
  }
  if (class_type.length > 0) {
    bodyFilters.class_type = class_type;
  }
  if (nameMobile.length > 0) {
    if (parseInt(nameMobile) > 0) {
      bodyFilters.mobile = nameMobile;
    } else {
      bodyFilters.name = nameMobile;
    }
  }
  if (acNumber.length > 0) {
    bodyFilters.acc_number = acNumber;
  }

  if (state.length > 0) {
    bodyFilters.state_id = stateId;
  }
  if (area.length > 0) {
    bodyFilters.city_id = areaId;
  }
  if (pincode.length > 0) {
    bodyFilters.pincode_id = pincode.toString();
  }
  if (district.length > 0) {
    bodyFilters.district_id = districtId;
  }
  if (dateFilters.start_date.length > 0 && dateFilters.end_date.length > 0) {
    bodyFilters.start_date = dateFilters.start_date;
    bodyFilters.end_date = dateFilters.end_date;
  }
  //handleFunction
  const handledateRange = (item) => {
    setDateRange([item.selection]);
    setDateFilters((prevFilters) => ({
      // ...prevFilters,
      start_date: moment(item.selection.startDate).format("YYYY-MM-DD"),
      end_date: moment(item.selection.endDate).format("YYYY-MM-DD"),
    }));
  };

  const handleFirstMonthButtonState = () => {
    setIsOpenFirstmonth(!isOpenfirstMonth);
  };

  const handleDateRangeClose = () => {
    setIsOpenFirstmonth(false);
    // perform some action here
  };

  const handleExportMechanic = async () => {
    // setCountRefresh(false);
    setModal(false);
    setDisableDownload(true);
    // toast.success("Preparing your file", {
    //   autoClose: 2000,
    // });
    bodyFilters.report_name = reportName;
    setReportName("");
    await viewMechanicService.exportViewMechanics(bodyFilters).then((res) => {
      if (res.code === 200) {
        setDisableDownload(false);
        toast.success(res.message, {
          autoClose: 2000,
        });
      } else {
        setDisableDownload(false);
        toast.error(res.message, {
          autoClose: 2000,
        });
      }
    });
  };

  const handleStateChanges = async (e) => {
    const selectedState = stateOptions.find(
      (option) => option.value === e.target.value
    );
    if (selectedState) {
      setState(selectedState.value);
      setStateId(selectedState.id);
      setDistrict("");
      setDistrictId("");
      setDistrictOptions([]);
      setArea("");
      setAreaId("");
      setAreaOptions([]);
    } else {
      setState(""); // set state to empty string if "Select State" is selected
      setStateId("");
      setDistrict("");
      setDistrictId("");
      setDistrictOptions([]);
      setArea("");
      setAreaId("");
      setAreaOptions([]);
    }
  };
  const handleDistrictChanges = async (e) => {
    const selectedDistrict = districtOptions.find(
      (option) => option.value === e.target.value
    );
    if (selectedDistrict) {
      setDistrict(selectedDistrict.value);
      setDistrictId(selectedDistrict.district_id);
      setArea("");
      setAreaId("");
      setPincode("");
    } else {
      setDistrict("");
      setDistrictId("");
      setArea("");
      setAreaId("");
      setPincode("");
    }
  };
  const handleAreaChanges = async (e) => {
    console.log("area selected--", e.target.value);
    const selectedArea = areaOptions.find(
      (option) => option.value === e.target.value
    );
    console.log("selectedArea", selectedArea);
    if (selectedArea) {
      setArea(selectedArea.value);
      setAreaId(selectedArea.area_id);
      setPincode("");
    } else {
      setArea("");
      setAreaId("");
      setPincode("");
    }
  };
  const handleSubmit = async () => {
    setCurrentPage(1);
    setIsOpenFirstmonth(false);
    fetchViewMechanicsDataAndCount();
  };
  const handleReset = async () => {
    setDocStatus("");
    setAppStatus("app_register_user");
    setNameMobile("");
    setPincode("");
    setDistrict("");
    setDistrictId([]);
    setDistrictOptions([]);
    setArea("");
    setAreaId([]);
    setAreaOptions([]);
    setState("");
    setstateOptions([]);
    setStateId([]);
    setClassStatus("");
    setACNumber("");
    setDateFilters({
      start_date: "",
      end_date: "",
    });
    bodyFilters = {
      // start_date: moment(initialDateRange[0].startDate).format("YYYY-MM-DD"),
      // end_date: moment(initialDateRange[0].endDate).format("YYYY-MM-DD"),
      limit: pageSize,
      offset: currentPage,
    };
    bodyFilters.app_filter = "app_register_user";
    setDateRange(initialDateRange);

    handleSubmit();
  };

  const checkDaysFromDateRange = async (start_date, end_date) => {
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);

    const oneDay = 22 * 60 * 60 * 1000; // Number of milliseconds in a day

    const diffDays = Math.round(Math.abs((endDate - startDate) / oneDay)) + 1;

    if (diffDays > 92) {
      toast.error("Please choose a date range of 92 days or less.", {
        autoClose: 2000,
      });
      return false;
    }
    return true;
  };
  const toggleModal = async () => {
    setModal(!modal);
    setReportName("");
  };

  //calling API
  const fetchViewMechanicsDataAndCount = async () => {
    dispatch(showLoader());

    const mechanicList = await viewMechanicService.GetMechanicList(bodyFilters);

    if (mechanicList.status === true) {
      setData(mechanicList.data.data);
      if (mechanicList.data.count > 0) {
        setTotalPages(mechanicList.data.pagination.totalPages);
        setTotalCount(mechanicList.data.count);
        dispatch(hideLoader());
      } else {
        setTotalPages(0);
        setTotalCount(0);
        dispatch(hideLoader());
      }
    } else if (mechanicList.status === false) {
      setData([]);
      setTotalPages(0);
      setTotalCount(0);
      dispatch(hideLoader());
    }
    dispatch(hideLoader());
  };

  const fetchGetStates = async () => {
    const stateData = await viewTransactionService.getStates();
    const options = stateData.data.map((state) => ({
      value: state.name,
      label: state.name,
      id: state.id,
    }));
    setstateOptions(options);
  };
  const fetchGetDistricts = async () => {
    if (stateId > 0) {
      const params = {
        state_id: [stateId],
      };
      const districtData = await viewTransactionService.getDistricts(params);
      // if (districtData.status === true) {
      //   setData(transactionList.data.data);
      // } else if (transactionList.status === false) {
      //   setData([]);
      // }
      if (districtData.status === true) {
        const options = districtData.data.map((district) => ({
          district_id: district.id,
          value: district.name,
          label: district.name,
        }));
        setDistrictOptions(options);
      } else if (districtData.status === false) {
        toast.error("No district found.", {
          autoClose: 2000,
        });
      }
    } else {
      toast.error("please select state first. ", {
        autoClose: 2000,
      });
    }
  };
  const fetchGetCity = async () => {
    if (stateId > 0 && districtId > 0) {
      const params = {
        state_id: [stateId],
        district_id: [districtId],
      };
      const areaData = await viewTransactionService.getAreas(params);
      if (areaData.status === true) {
        const options = areaData.data.map((area) => ({
          area_id: area.id,
          value: area.name,
          label: area.name,
        }));
        setAreaOptions(options);
        console.log("options area--",options);
      } else if (areaData.status === false) {
        toast.error("No area found.", {
          autoClose: 2000,
        });
      }
    } else {
      toast.error("please select district first. ", {
        autoClose: 2000,
      });
    }
  };

  const handlePage = (event) => {
    const buttonElement = event.target; // Your event target
    if (buttonElement.tagName === "BUTTON") {
      const pageNumber = buttonElement.textContent;
      console.log(pageNumber); // Output: "2"
      setCurrentPage(pageNumber);
    }
  };
  useEffect(() => {
    fetchViewMechanicsDataAndCount();
  }, [currentPage]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="mb-3">
                  <div className="row table_top">
                    <Col lg={4} md={4} sm={5} xs={6}>
                      <CardTitle className="font-size-18">
                        View Mechanic
                      </CardTitle>
                    </Col>
                    <Col
                      lg={7}
                      md={7}
                      sm={6}
                      xs={5}
                      className="total-count-class"
                    >
                      <div className="d-flex justify-content-end">
                        <span style={{ float: "left", paddingRight: "5px" }}>
                          Total count:
                        </span>
                        {totalCount === null ? (
                          <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                        ) : (
                          totalCount
                        )}
                      </div>
                    </Col>
                    {role === 16 || role === 22 ? null : (
                      <Col lg={1} md={1} sm={1}>
                        {" "}
                        <div
                          className="download-excel"
                          style={{ opacity: disableDownload ? 0.5 : 1 }}
                        >
                          <i
                            title={
                              disableDownload
                                ? "Preparing your file"
                                : "Export to Excel"
                            }
                            className="fa fa-download"
                            onClick={disableDownload ? null : toggleModal}
                            disabled={disableDownload}
                          />{" "}
                          <ReportModal
                            isOpen={modal}
                            toggle={toggleModal}
                            modalHeader="Mechanic Report"
                            handleAPI={handleExportMechanic}
                            reportName={reportName}
                            setReportName={setReportName}
                          />
                        </div>
                      </Col>
                    )}
                  </div>
                  <div>
                    <Row className="mt-3">
                      <Col md={3}>
                        <h6>App Status</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={appStatus}
                            onChange={(e) => setAppStatus(e.target.value)}
                          >
                            {appOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <h6>Document Status</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={docStatus}
                            onChange={(e) => setDocStatus(e.target.value)}
                          >
                            {documentOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>

                      <Col md={3}>
                        <h6>Search</h6>
                        <Input
                          type="search"
                          placeholder="Phone no. / Name"
                          id="search_name_mobile"
                          value={nameMobile}
                          onChange={(e) => {
                            setNameMobile(e.target.value);
                          }}
                        />
                      </Col>
                      <Col md={3}>
                        <h6>Ac Number</h6>
                        <Input
                          type="search"
                          placeholder="AC Number"
                          id="search_ac_number"
                          value={acNumber}
                          onChange={(e) => {
                            setACNumber(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={3}>
                        <h6>State Name</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={state}
                            onFocusCapture={fetchGetStates}
                            onChange={handleStateChanges}
                          >
                            <option value="">Select State</option>
                            {stateOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <h6>District Name</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={district}
                            onFocusCapture={fetchGetDistricts}
                            onChange={handleDistrictChanges}
                          >
                            <option value="">Select District</option>
                            {districtOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <h6>Area Name</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={area}
                            onFocusCapture={fetchGetCity}
                            onChange={handleAreaChanges}
                          >
                            <option value="">Select Area</option>
                            {areaOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <h6>Pincode</h6>
                        <Input
                          type="search"
                          placeholder="Pincode"
                          id="pincode"
                          value={pincode}
                          onChange={(e) => {
                            setPincode(e.target.value);
                          }}
                        />
                      </Col>
                      </Row>
                      <Row className="mt-3">
                      <Col md={3}>
                        {isOpenfirstMonth === false ? (
                          <div>
                            <h6>Select Dates</h6>
                            <button
                              className="form-select"
                              onClick={handleFirstMonthButtonState}
                            >
                              {dateFilters.start_date && dateFilters.end_date
                                ? `${new Date(
                                    dateFilters.start_date
                                  ).toLocaleString("default", {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                  })} - ${new Date(
                                    dateFilters.end_date
                                  ).toLocaleString("default", {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                  })}`
                                : "select date range"}
                            </button>
                          </div>
                        ) : (
                          <div className="customDateOverlay">
                            <label className="form-label">Select Dates: </label>
                            <div className="">
                              <DateRange
                                editableDateInputs={true}
                                onChange={handledateRange}
                                moveRangeOnFirstSelection={false}
                                ranges={dateRange}
                                rangeColors={["#FCB92C", "#FCB92C", "#FCB92C"]}
                                showSelectionPreview={true}
                                shouldCloseOnSelect={true}
                                maxDate={currentDate}
                                minDate={new Date(moment("2016-01-01"))}
                                onClose={handleDateRangeClose}
                              />
                              <Button
                                className="btn-danger float-end mt-1"
                                onClick={handleFirstMonthButtonState}
                              >
                                Close
                              </Button>
                              <Button
                                className="btn-warning float-end me-2 mt-1"
                                onClick={handleSubmit}
                              >
                                Search
                              </Button>
                            </div>
                          </div>
                        )}
                      </Col>
                      <Col md={3}>
                        <h6>Class(Current FY)</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={class_type}
                            onChange={(e) => setClassStatus(e.target.value)}
                          >
                            {classOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        {user.role_id !== 22 ? (
                          <div className="margin-top d-flex">
                            <button
                              color="none"
                              onClick={toggleRightbar}
                              // type="button"
                              className="advance-filter-btn"
                            >
                              Team Filter
                              <i className="ri-filter-2-fill mx-1"></i>
                            </button>
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                    {/* {loading === true ? (
                      <SpinnerSmall />
                    ) : ( */}
                    <Row className="mt-3 float-end">
                      <Col lg={3} className="w-100">
                        <Button
                          className="btn btn-danger btn-secondary float-end"
                          onClick={handleReset}
                          // style={{ width: "74px" }}
                        >
                          Reset
                        </Button>{" "}
                        <Button
                          className="btn-warning float-end mx-2"
                          onClick={disableSubmit ? null : handleSubmit}
                          disabled={loading === true ? true : false}
                        >
                          Search
                        </Button>{" "}
                      </Col>
                    </Row>
                    {/* )} */}
                  </div>
                </CardHeader>
                <div className="bg-body pb-3 mt-n3">
                  <Master />{" "}
                </div>
                <CardBody className="">
                  <div className="">
                    <div className="row">
                      {loading === true ? (
                        <Spinner />
                      ) : (
                        <ViewMechanicTable data={data} />
                      )}

                      {totalCount === null ? (
                        <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                      ) : (
                        <>
                          {totalCount === 0 ? null : (
                            <div className="d-flex justify-content-center ">
                              <Stack spacing={2}>
                                <Pagination
                                  count={totalPages}
                                  hidePrevButton
                                  hideNextButton
                                  onClick={handlePage}
                                />
                              </Stack>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>{" "}
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStateToProps, { toggleRightSidebar })(
  withNamespaces()(ViewMechanic)
);
