import React, { useState, useEffect } from "react";
import {
  // ToastContainer,
  toast,
} from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardTitle,
} from "reactstrap";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import "./Etransfer.css";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ETransferTable from "../../components/Table/ETransferTable";
import viewETransferService from "../../services/etransfer.service";
import viewTransactionService from "../../services/viewTransaction.service";
import { showLoader, hideLoader } from "../../actions/loading";
import { useDispatch, useSelector } from "react-redux";
import Spinner, { SpinnerSmall } from "../../components/Loader/Spinner";
import moment from "moment";
import ReportModal from "../../components/Modal/ReportModal";
import { DateRange } from "react-date-range";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const Etransfer = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const role = user ? user.role_id : null;
  const loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isOpenfirstMonth, setIsOpenFirstmonth] = useState(false);
  const currentDate = new Date(); // current date
  currentDate.setDate(currentDate.getDate() - 1); // current date
  const initialDateRange = [
    // {
    //   startDate: new Date(moment().format("YYYY-MM-DD")),
    //   endDate: new Date(moment().format("YYYY-MM-DD")),
    //   key: "selection",
    // },
    {
      startDate: new Date(moment(currentDate)),
      endDate: new Date(moment(currentDate)),
      key: "selection",
    },
  ];

  const [disableDownload, setDisableDownload] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [totalCount, setTotalCount] = useState(null);
  const [transferType, setTransferType] = useState("");
  const [requestType, setRequestType] = useState("");
  const [subTradeType, setSubTradeType] = useState("");
  const [cashType, setCashType] = useState("");
  const [cashUsed, setCashUsed] = useState("");

  const [nameMobile, setNameMobile] = useState("");
  const [crnNo, setCrnNo] = useState("");
  const [reportName, setReportName] = useState("");
  const [dateRange, setDateRange] = useState(initialDateRange);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [state, setState] = useState("");
  const [stateOptions, setstateOptions] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [district, setDistrict] = useState("");
  const [districtOptions, setDistrictOptions] = useState([]);
  const [districtId, setDistrictId] = useState([]);
  const [appDisplayStatus, setAppDisplayStatus] = useState("");
  const [displayStatusOptions, setDisplayStatusOptions] = useState([]);
  const [dateFilters, setDateFilters] = useState({
    start_date: "",
    end_date: "",
  });

  const [transferTypeOptions, setTransferTypeOptions] = useState([
    { value: "", label: "Select Transfer Type" },
    { value: "BANK", label: "BANK" },
    { value: "E-WALLET", label: "E-WALLET" },
  ]);
  const [requestTypeOptions, setRequestTypeOptions] = useState([
    { value: "", label: "Select Request Type" },
    { value: "FVT", label: "FVT" },
    { value: "REGULAR", label: "REGULAR" },
  ]);
  const [subTradeTypeOptions, setSubTradeTypeOptions] = useState([
    { value: "", label: "Select Sub Trade Type" },
    { value: "BIKE AND SCOOTER", label: "BIKE AND SCOOTER" },
    { value: "AUTO", label: "AUTO" },
    { value: "CAR & UV", label: "CAR & UV" },
    { value: "TRUCK", label: "TRUCK" },
    { value: "TRACTOR", label: "TRACTOR" },
  ]);
  const [cashTypeOptions, setCashTypeOptions] = useState([
    { value: "", label: "Select" },
    { value: "less", label: "Less Than" },
    { value: "equal", label: "Equal to" },
    { value: "greater", label: "Greater than" },
  ]);

  const [modal, setModal] = useState(false);

  //modified Body data
  let bodyFilters = {
    // start_date: moment(dateRange[0].startDate).format("YYYY-MM-DD"),
    // end_date: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
    limit: pageSize,
    offset: currentPage,
  };

  if (transferType.length > 0) {
    bodyFilters.transfer_type = transferType;
  }
  if (requestType.length > 0) {
    bodyFilters.request_type = requestType;
  }
  if (subTradeType.length > 0) {
    bodyFilters.sub_trade_type = subTradeType;
  }

  if (nameMobile.length > 0) {
    if (parseInt(nameMobile) > 0) {
      bodyFilters.mobile = nameMobile;
    } else {
      bodyFilters.name = nameMobile;
    }
  }

  if (crnNo.length > 0) {
    bodyFilters.transfer_id = crnNo;
  }
  if (state.length > 0) {
    bodyFilters.state_id = stateId;
  }
  if (appDisplayStatus.length > 0) {
    bodyFilters.app_display_status = appDisplayStatus;
  }
  if (district.length > 0) {
    bodyFilters.district_id = districtId;
  }
  if (dateFilters.start_date.length > 0 && dateFilters.end_date.length > 0) {
    bodyFilters.start_date = dateFilters.start_date;
    bodyFilters.end_date = dateFilters.end_date;
  }
  if (cashType && cashUsed) {
    bodyFilters.cash_filter = { cash_used: cashUsed, op: cashType };
  }

  //handleFunction
  const handledateRange = (item) => {
    setDateRange([item.selection]);
    setDateFilters((prevFilters) => ({
      // ...prevFilters,
      start_date: moment(item.selection.startDate).format("YYYY-MM-DD"),
      end_date: moment(item.selection.endDate).format("YYYY-MM-DD"),
    }));
  };

  const handleFirstMonthButtonState = () => {
    setIsOpenFirstmonth(!isOpenfirstMonth);
  };

  const handleDateRangeClose = () => {
    setIsOpenFirstmonth(false);
    // perform some action here
  };

  const handleExportEtransfer = async () => {
    // setCountRefresh(false);
    setModal(false);
    setDisableDownload(true);
    // toast.success("Preparing your file", {
    //   autoClose: 2000,
    // });
    bodyFilters.report_name = reportName;
    setReportName("");
    const mechanicList = await viewETransferService.exportETransfer(
      bodyFilters
    );

    if (mechanicList.code === 200) {
      setDisableDownload(false);
      toast.success(mechanicList.message, {
        autoClose: 2000,
      });
    } else {
      setDisableDownload(false);
      toast.error(mechanicList.message, {
        autoClose: 2000,
      });
    }
  };

  const handleStateChanges = async (e) => {
    const selectedState = stateOptions.find(
      (option) => option.value === e.target.value
    );
    if (selectedState) {
      setState(selectedState.value);
      setStateId(selectedState.id);
      setDistrict("");
      setDistrictId("");
      setDistrictOptions([]);
    } else {
      setState(""); // set state to empty string if "Select State" is selected
      setStateId("");
      setDistrict("");
      setDistrictId("");
      setDistrictOptions([]);
    }
  };
  const handleDisplayStatusChanges = async (e) => {
    const selectedStatus = displayStatusOptions.find(
      (option) => option.value === e.target.value
    );
    if (selectedStatus) {
      setAppDisplayStatus(selectedStatus.value);
    } else {
      setAppDisplayStatus(""); // set state to empty string if "Select Status" is selected
    }
  };

  const handleDistrictChanges = async (e) => {
    const selectedDistrict = districtOptions.find(
      (option) => option.value === e.target.value
    );
    if (selectedDistrict) {
      setDistrict(selectedDistrict.value);
      setDistrictId(selectedDistrict.district_id);
    } else {
      setDistrict("");
      setDistrictId("");
    }
  };
  const handleSubmit = async () => {
    setCurrentPage(1);
    setIsOpenFirstmonth(false);
    fetchETransferDataAndCount();
  };
  const handleReset = async () => {
    setCrnNo("");
    setTransferType("");
    setRequestType("");
    setTransferType("");
    setRequestType("");
    setSubTradeType("");
    setCashType("");
    setCashUsed("");
    setNameMobile("");

    setDistrict("");
    setDistrictId([]);
    setDistrictOptions([]);
    setState("");
    setstateOptions([]);
    setStateId([]);
    setAppDisplayStatus("");
    setDisplayStatusOptions([]);
    setDateFilters({
      start_date: "",
      end_date: "",
    });

    bodyFilters = {
      // start_date: moment(initialDateRange[0].startDate).format("YYYY-MM-DD"),
      // end_date: moment(initialDateRange[0].endDate).format("YYYY-MM-DD"),
      limit: pageSize,
      offset: currentPage,
    };
    setDateRange(initialDateRange);
    // Wait for state updates to complete
    handleSubmit();
  };
  const checkDaysFromDateRange = async (start_date, end_date) => {
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);

    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

    const diffDays = Math.round(Math.abs((endDate - startDate) / oneDay)) + 1;

    if (diffDays > 92) {
      toast.error("Please choose a date range of 92 days or less.", {
        autoClose: 2000,
      });
      return false;
    }
    return true;
  };
  const toggleModal = async () => {
    setModal(!modal);
    setReportName("");
  };

  //calling API
  const fetchETransferDataAndCount = async () => {
    dispatch(showLoader());

    const mechanicList = await viewETransferService.GetEtransferList(
      bodyFilters
    );

    if (mechanicList.status === true) {
      setData(mechanicList.data.data);
      if (mechanicList.data.count > 0) {
        setTotalPages(mechanicList.data.pagination.totalPages);
        setTotalCount(mechanicList.data.count);
      } else {
        setTotalPages(0);
        setTotalCount(0);
      }
    } else if (mechanicList.status === false) {
      setData([]);
      setTotalPages(0);
      setTotalCount(0);
    }
    dispatch(hideLoader());
  };

  const fetchGetStates = async () => {
    const stateData = await viewTransactionService.getStates();
    const options = stateData.data.map((state) => ({
      value: state.name,
      label: state.name,
      id: state.id,
    }));
    setstateOptions(options);
  };
  const fetchGetDisplayStatus = async () => {
    const statusData = await viewETransferService.getDisplayStatus();
    const options = statusData.data.map((status) => ({
      value: status.dashboard_display_status,
      label: status.dashboard_display_status,
      id: status.id,
    }));
    setDisplayStatusOptions(options);
  };
  const fetchGetDistricts = async () => {
    if (stateId > 0) {
      const params = {
        state_id: [stateId],
      };
      const districtData = await viewTransactionService.getDistricts(params);
      const options = districtData.data.map((district) => ({
        district_id: district.id,
        value: district.name,
        label: district.name,
      }));

      setDistrictOptions(options);
    } else {
      toast.error("please select state first. ", {
        autoClose: 2000,
      });
    }
  };

  const handlePage = (event) => {
    const buttonElement = event.target; // Your event target
    if (buttonElement.tagName === "BUTTON") {
      const pageNumber = buttonElement.textContent;
      console.log(pageNumber); // Output: "2"
      setCurrentPage(pageNumber);
    }
  };
  useEffect(() => {
    fetchETransferDataAndCount();
  }, [currentPage]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="mb-3">
                  <div className="row table_top">
                    <Col lg={4} md={4} sm={5} xs={6}>
                      <CardTitle className="font-size-18">E-Transfer</CardTitle>
                    </Col>
                    <Col
                      lg={7}
                      md={7}
                      sm={6}
                      xs={5}
                      className="total-count-class"
                    >
                      <div className="d-flex justify-content-end">
                        <span style={{ float: "left", paddingRight: "5px" }}>
                          Total count:
                        </span>
                        {totalCount === null ? (
                          <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                        ) : (
                          totalCount
                        )}
                      </div>
                    </Col>
                    {role === 16 || role === 22 ? null : (
                      <Col lg={1} md={1} sm={1}>
                        {" "}
                        <div
                          className="download-excel"
                          style={{ opacity: disableDownload ? 0.5 : 1 }}
                        >
                          <i
                            title={
                              disableDownload
                                ? "Preparing your file"
                                : "Export to Excel"
                            }
                            className="fa fa-download"
                            onClick={disableDownload ? null : toggleModal}
                            disabled={disableDownload}
                          />
                          <ReportModal
                            isOpen={modal}
                            toggle={toggleModal}
                            modalHeader="E-Transfer Report"
                            handleAPI={handleExportEtransfer}
                            reportName={reportName}
                            setReportName={setReportName}
                          />
                        </div>
                      </Col>
                    )}
                  </div>
                  <div>
                    <Row className="mt-3">
                      <Col md={3}>
                        <h6>Transfer Type</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={transferType}
                            onChange={(e) => {
                              setTransferType(e.target.value);
                            }}
                          >
                            {transferTypeOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <h6>Request Type</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={requestType}
                            onChange={(e) => {
                              setRequestType(e.target.value);
                            }}
                          >
                            {requestTypeOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <h6>App Display Status</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={appDisplayStatus}
                            onFocusCapture={fetchGetDisplayStatus}
                            onChange={handleDisplayStatusChanges}
                          >
                            <option value="">Select Display Status</option>
                            {displayStatusOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <h6>Sub Trade Type</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={subTradeType}
                            onChange={(e) => {
                              setSubTradeType(e.target.value);
                            }}
                          >
                            {subTradeTypeOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={3}>
                        <h6>Search</h6>
                        <Input
                          type="search"
                          placeholder="Phone no. / Name"
                          id="search_name_mobile"
                          value={nameMobile}
                          onChange={(e) => {
                            setNameMobile(e.target.value);
                          }}
                        />
                      </Col>
                      <Col md={3}>
                        <h6>State Name</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={state}
                            onFocusCapture={fetchGetStates}
                            onChange={handleStateChanges}
                          >
                            <option value="">Select State</option>
                            {stateOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      {/* <Col md={3}>
                        <h6>District Name</h6>
                        <FormGroup className="mb-0">
                          <select
                            className="form-select"
                            value={district}
                            onFocusCapture={fetchGetDistricts}
                            onChange={handleDistrictChanges}
                          >
                            <option value="">Select District</option>
                            {districtOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col> */}
                      <Col md={3}>
                        <h6>Cash Used</h6>
                        <FormGroup className="mb-0 d-flex">
                          <select
                            className="form-select me-2"
                            value={cashType}
                            onChange={(e) => {
                              setCashType(e.target.value);
                            }}
                          >
                            {cashTypeOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <Input
                            type="text"
                            className="dashboard-select"
                            placeholder="Cash Used"
                            id="cash_used"
                            value={cashUsed}
                            onChange={(e) => {
                              setCashUsed(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <h6>Search CRN ID</h6>
                        <Input
                          type="search"
                          placeholder="CRN ID"
                          id="search_crn_no"
                          value={crnNo}
                          onChange={(e) => {
                            setCrnNo(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={4}>
                        {isOpenfirstMonth === false ? (
                          <div>
                            <h6>Select Bank Request Date</h6>
                            <button
                              className="form-select"
                              onClick={handleFirstMonthButtonState}
                            >
                              {dateFilters.start_date && dateFilters.end_date
                                ? `${new Date(
                                    dateFilters.start_date
                                  ).toLocaleString("default", {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                  })} - ${new Date(
                                    dateFilters.end_date
                                  ).toLocaleString("default", {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                  })}`
                                : "select date range"}
                            </button>
                          </div>
                        ) : (
                          <div className="customDateOverlay">
                            <label className="form-label">
                              Select Bank Request Date:{" "}
                            </label>
                            <div className="">
                              <DateRange
                                editableDateInputs={true}
                                onChange={handledateRange}
                                moveRangeOnFirstSelection={false}
                                ranges={dateRange}
                                rangeColors={["#FCB92C", "#FCB92C", "#FCB92C"]}
                                shouldCloseOnSelect={true}
                                maxDate={new Date()}
                                minDate={new Date(moment("2021-01-01"))}
                                onClose={handleDateRangeClose}
                                className="dateRangeWrapper"
                              />
                              <Button
                                className="btn-danger float-end mt-1"
                                onClick={handleFirstMonthButtonState}
                              >
                                Close
                              </Button>
                              <Button
                                className="btn-warning float-end me-2 mt-1"
                                onClick={handleSubmit}
                              >
                                Search
                              </Button>
                            </div>
                          </div>
                        )}
                      </Col>
                      <Col lg={3} className="w-100 flex-1 mt-4">
                        <Button
                          className="btn btn-danger btn-secondary float-end"
                          onClick={handleReset}
                          // style={{ width: "74px" }}
                        >
                          Reset
                        </Button>{" "}
                        <Button
                          className="btn-warning float-end mx-2"
                          onClick={disableSubmit ? null : handleSubmit}
                          disabled={disableSubmit}
                        >
                          Search
                        </Button>{" "}
                      </Col>
                    </Row>
                  </div>
                </CardHeader>
                <CardBody className="">
                  <div className="">
                    <div className="row">
                      {loading === true ? (
                        <Spinner />
                      ) : (
                        <ETransferTable data={data} />
                      )}

                      {totalCount === null ? (
                        <SpinnerSmall className="spinner-grow-sm spinner-small-size" />
                      ) : (
                        <>
                          <div className="d-flex justify-content-center ">
                            <Stack spacing={2}>
                              <Pagination
                                count={totalPages}
                                hidePrevButton
                                hideNextButton
                                onClick={handlePage}
                              />
                            </Stack>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>{" "}
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Etransfer;
