import React, { useEffect, useState } from "react";
import Service from "../../services/segment.service";
import MechanicCountTable from "../Table/MechanicCountTable";
import { Card, CardBody, CardTitle } from "reactstrap";
import VolumeConsumptionTable from "../Table/VolumeConsumptionTable";
import StackedBarChart from "../../components/Charts/StackedBarChart";

const OverAll = ({
  start_date,
  end_date,
  role_id,
  regional_mapping,
  user_id,
  label,
}) => {
  const body = {
    start_date: start_date,
    end_date: end_date,
    segment: "ALL",
    regional_mapping: regional_mapping,
    user_id: user_id,
    role_id: role_id,
    label: label,
  };
  const [mechanicCount, setMechanicCount] = useState([]);
  const [mechanicLoading, setMechanicLoading] = useState(false);
  const [monthRange, setMonthRange] = useState([]);
  const [consumption, setConsumption] = useState([]);
  const [consumptionChart, setConsumptionChart] = useState([]);
  const [countChart, setCountChart] = useState([]);
  const [maxCount, setMaxCount] = useState([]);
  const [maxConsumption, setMaxConsumption] = useState([]);
  const [Switch, setSwitch] = useState(true);
  const [buttonDisable, setButtonDisable] = useState(true);

  const fetchData = async () => {
    setMechanicLoading(true);
    await Service.GetProductSegment(body).then((res) => {
      if (res?.status === true) {
        setButtonDisable(false);
        setConsumptionChart(res.data.data.consumption_serise);
        const max_consumption =
          Math.max(res.data.data.consumption.summary.first_month_volume_total) >
          Math.max(res.data.data.consumption.summary.second_month_volume_total)
            ? Math.max(
                res.data.data.consumption.summary.first_month_volume_total
              )
            : Math.max(
                res.data.data.consumption.summary.second_month_volume_total
              );
        setMaxConsumption(max_consumption);
        setCountChart(res.data.data.count_serise);
        const max_count =
          res.data.data.count.summary.first_month_count_total >
          res.data.data.count.summary.second_month_count_total
            ? res.data.data.count.summary.first_month_count_total
            : res.data.data.count.summary.second_month_count_total;
        setMaxCount(max_count);
        setMechanicLoading(false);
        setMonthRange(res.data.dates);
        const final = res.data.data.count.table.map((row) => ({
          ...row,
        }));
        final.push({
          classification: "Total",
          slab: "-",
          first_month_count:
            res.data.data.count.summary.first_month_count_total,
          second_month_count:
            res.data.data.count.summary.second_month_count_total,
          difference: res.data.data.count.summary.difference,
          change: res.data.data.count.summary.change,
        });
        setMechanicCount(final);

        const final_consumption = res.data.data.consumption.table.map(
          (row) => ({
            ...row,
          })
        );
        final_consumption.push({
          classification: "Total",
          slab: "-",
          first_month_volume:
            res.data.data.consumption.summary.first_month_volume_total,
          second_month_volume:
            res.data.data.consumption.summary.second_month_volume_total,
          difference: res.data.data.consumption.summary.difference,
          change: res.data.data.consumption.summary.change,
        });

        setConsumption(final_consumption);
      }
    });
    setMechanicLoading(false);
  };

  const handleSwitch = () => {
    setSwitch(!Switch);
  };

  useEffect(() => {
    fetchData();
  }, [start_date, end_date, regional_mapping]);
  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-between">
        <CardTitle> Overall Count</CardTitle>
        <button
          className="btn btn-primary float-end mb-2"
          onClick={handleSwitch}
          disabled={buttonDisable}
        >
          {Switch === true ? "Table" : "Chart"}
        </button>
      </div>

      <div>
        <Card>
          {Switch === true ? (
            <>
              <h4 className="card-title m-2">Overall: Mechanics Count</h4>
              <StackedBarChart
                data={countChart}
                maxValue={maxCount}
                loading={buttonDisable}
                start_date={start_date}
                end_date={end_date}
                label={label}
                date_range={monthRange}
              />
            </>
          ) : (
            <CardBody>
              <h4 className="card-title pt-4">Overall: Mechanics Count</h4>
              <MechanicCountTable
                data={mechanicCount}
                loading={mechanicLoading}
                date_range={monthRange}
              />
            </CardBody>
          )}
        </Card>
        <Card>
          {Switch === true ? (
            <>
              <h4 className="card-title m-2"> Overall: Volume Consumption</h4>
              <StackedBarChart
                data={consumptionChart}
                maxValue={maxConsumption}
                loading={buttonDisable}
                start_date={start_date}
                end_date={end_date}
                date_range={monthRange}
                label={label}
              />
            </>
          ) : (
            <CardBody>
              <h4 className="card-title pt-4"> Overall: Volume Consumption</h4>
              <VolumeConsumptionTable
                data={consumption}
                loading={mechanicLoading}
                date_range={monthRange}
              />
            </CardBody>
          )}
        </Card>
      </div>
    </div>
  );
};

export default OverAll;
