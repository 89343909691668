import React, { useState } from "react";
import { Table, Col, Row } from "reactstrap";
import "../../pages/Etransfer/Etransfer.css";
import CustomModal from "../Modal/CustomModal";
import getSegment from "../../services/segment.service";
import moment from "moment";

const ETransferTable = ({ data = [] }) => {
  const [profileModal, setProfileModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [modalData, setModalData] = useState([]);
  const [prevSlab, setPrevSlab] = useState("");
  const [currentSlab, setCurrentSlab] = useState("");

  const profileToggleModal = () => {
    setProfileModal(!profileModal);
  };
  const fetchProfile = async (userId) => {
    const user_id = userId;
    setUserId(user_id);
    const params = { user_id: user_id };
    const response = await getSegment.GetUserDetails(params);

    if (response.status === true) {
      setModalData(response.data);
    }
    await getSegment.GetSlab(params).then((res) => {
      if (res?.status === true) {
        setCurrentSlab(res.data.data.curr_fin_year.class);
        setPrevSlab(res.data.data.last_fin_year.class);
      }
    });
  };
  // Map each object in the data array to a table row
  const rows = data?.map((item, index) => (
    <tr key={index}>
      <td>
        <i
          className="fas fa-external-link-alt icon-table"
          onClick={async () => {
            await fetchProfile(item.user_id);
            profileToggleModal();
          }}
          title="View More"
        />
      </td>

      <td>
        {item.first_name || item.last_name
          ? item.first_name + " " + item.last_name
          : "N/A"}
      </td>
      <td>{item.mechcode ? item.mechcode : "N/A"}</td>
      <td>{item.sub_trade ? item.sub_trade : "N/A"}</td>
      <td>{item.state ? item.state : "N/A"}</td>
      {/* <td>{item.district ? item.district : "N/A"}</td>
      <td>{item.city ? item.city : "N/A"}</td> */}
      <td>{item.mobile ? item.mobile : "N/A"}</td>
      <td>
        {item.dbt_reg_date
          ? moment.unix(item.dbt_reg_date).format("DD/MM/YYYY")
          : "N/A"}
      </td>
      <td>{item.transfer_id ? item.transfer_id : "N/A"}</td>
      {/* <td>{item.redeemcode ? item.redeemcode : "N/A"}</td> */}
      <td>{item.status ? item.status.dashboard_display_status : "N/A"}</td>
      <td>{item.request_type ? item.request_type : "N/A"}</td>
      <td>{item.account_number ? item.account_number : "N/A"}</td>
      <td>{item.transfer_type ? item.transfer_type : "N/A"}</td>
      {/* <td>{item.sub_trade ? item.sub_trade : "N/A"}</td> */}
      <td align="right">{item.cash_used ? item.cash_used : "N/A"}</td>
      <td>{item.transfer_date ? item.transfer_date : "N/A"}</td>
      <td>
        {item.added_date
          ? moment.unix(item.added_date).format("DD/MM/YYYY")
          : "N/A"}
      </td>
      {/* Add more cells for each property in the object */}
    </tr>
  ));
  return (
    <>
      <div className="table-rep-plugin pt-1">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <Table id="tech-companies-1" striped bordered responsive hover>
            <thead>
              <tr>
                <th></th>
                <th>Mechanic Name</th>
                <th>Mech Code</th>
                <th>Sub Trades</th>
                <th>State</th>
                {/* <th>District</th>
                <th>City</th> */}
                <th>Phone No</th>
                <th>App Reg Date</th>
                <th>CRN ID</th>
                {/* <th>Redeem Code</th> */}
                <th>Status</th>
                <th>Request Type</th>
                <th>Account Number</th>
                <th>Transfer Type</th>
                {/* <th>Sub Trade Type</th> */}
                <th>Cash Used</th>
                <th>Transfer Date</th>
                <th>Bank Request Date</th>
                {/* Add more headers for each property in the objects */}
              </tr>
            </thead>
            {data?.length > 0 ? (
              <tbody>{rows}</tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="9" className="text-center">
                    No Data Found
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
      </div>{" "}
      <Row>
        <Col sm={6} md={4} xl={3}>
          <CustomModal
            isOpen={profileModal}
            toggle={profileToggleModal}
            user_id={userId}
            onConfirm={() => console.log("Confirmed!")}
            confirmLabel="Confirm"
            data={modalData}
            prevSlab={prevSlab}
            currentSlab={currentSlab}
          >
            {/* <p>Modal Content Here</p> */}
          </CustomModal>
        </Col>
      </Row>
    </>
  );
};

export default ETransferTable;
