import axios from "axios";
import { API_URL } from "../Config/config";

// const API_URL = "http://devanalyticsapi.eniclub.in/api/v1/";
// const API_URL = "http://analyticsapi.eniclub.in/api/v1/";
// const API_URL1 = "https://devcableapi.aparpower.com/api/support";

//
const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const submitSupport = async (body) => {
  return await axiosApi
    .post(API_URL + "support", body)
    .then((response) => {
      if (response.status === 200) {
        return {
          status: true,
          message: "Request Sent Successfully",
          code: 200,
        };
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export default {
  submitSupport,
};
